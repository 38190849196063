import React, { Component } from 'react';
import { Stage, Layer } from 'react-konva';
import { getFigures, createFigure, deleteFigures } from './figuresServices';

import LineDrawable from './Figures/LineDrawable';
import ArrowDrawable from './Figures/ArrowDrawable';
import CircleDrawable from './Figures/CircleDrawable';
import RectangleDrawable from './Figures/RectangleDrawable';
import CrossDrawable from './Figures/CrossDrawable';
import SelectDrawable from './Figures/SelectDrawable';
import { Modal, Button } from 'antd';

class DrawDesk extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawables: [],
            newDrawable: [],
            serverDrawables: [],
            newDrawableType: 'LineDrawable',
            selectDrawable: [],
            visible: false,
            confirmDelete: false,
            deleteFigures: [],
        };
    }

    getNewDrawableBasedOnType = (x, y, type) => {
        const drawableClasses = {
            line: LineDrawable,
            arrow: ArrowDrawable,
            circle: CircleDrawable,
            rectangle: RectangleDrawable,
            cross: CrossDrawable,
            select: SelectDrawable,
        };

        return new drawableClasses[type](x, y, type);
    };

    handleMouseDown = e => {
        if (e.evt.buttons === 1) {
            if (this.props.buttonAction === 'Draw' || this.props.buttonAction === 'Select') {
                const { newDrawable } = this.state;
                if (newDrawable.length === 0) {
                    let { x, y } = e.target.getStage().getPointerPosition();

                    x = this.recalcCoordsX(x);
                    y = this.recalcCoordsX(y);

                    let newDrawable;

                    if (this.props.buttonAction === 'Select') {
                        newDrawable = this.getNewDrawableBasedOnType(x, y, 'select');
                    } else {
                        newDrawable = this.getNewDrawableBasedOnType(x, y, this.props.drawAction);
                    }

                    this.setState({
                        newDrawable: [newDrawable],
                    });
                }
            }
        }
    };

    handleMouseUp = e => {
        const { newDrawable, drawables } = this.state;
        if (newDrawable.length === 1) {
            let { x, y } = e.target.getStage().getPointerPosition();

            const drawableToAdd = newDrawable[0];

            x = this.recalcCoordsX(x);
            y = this.recalcCoordsX(y);

            drawableToAdd.registerMovement(x, y);
            if (this.props.buttonAction === 'Select') {
                this.setState(
                    {
                        newDrawable: [],
                        selectDrawable: [drawableToAdd],
                    },
                    this.getFigureInside,
                );

                return;
            }

            // drawables.push(drawableToAdd);

            // send data to server
            this.writeFigure(drawableToAdd);
            this.setState({
                newDrawable: [],
                drawables,
            });
        }
    };

    handleMouseMove = e => {
        const { newDrawable } = this.state;
        if (newDrawable.length === 1) {
            let { x, y } = e.target.getStage().getPointerPosition();

            const updatedNewDrawable = newDrawable[0];

            x = this.recalcCoordsX(x);
            y = this.recalcCoordsX(y);

            updatedNewDrawable.registerMovement(x, y);

            this.setState({
                newDrawable: [updatedNewDrawable],
            });
        }
    };

    recalcCoordsX = x => {
        return x / this.props.data.zoom;
    };

    recalcCoordsY = y => {
        return y / this.props.data.zoom;
    };

    convertData(data) {
        const drawableClasses = {
            line: LineDrawable,
            arrow: ArrowDrawable,
            circle: CircleDrawable,
            rectangle: RectangleDrawable,
            cross: CrossDrawable,
        };

        return data.map(item => {
            const position = item.points.split(',');
            const convertItem = new drawableClasses[item.type](
                (this.props.data.width / 100) * Math.abs(position[0]),
                (this.props.data.height / 100) * Math.abs(position[1]),
                item.type,
                item.uuid,
            );

            convertItem.registerMovement(
                (this.props.data.width / 100) * Math.abs(position[2]),
                (this.props.data.height / 100) * Math.abs(position[3]),
            );

            return convertItem;
        });
    }

    updateFigures = async () => {
        getFigures(this.props.data.documentId)
            .then(data => {
                this.setState({
                    serverDrawables: this.convertData(data.data.data),
                });
            })
            .catch(error => console.log('error', error));
    };

    writeFigure = async item => {
        const { documentId, width, height } = this.props.data;

        const sendingData = {
            document_id: documentId,
            type: item.type,
            points: [(item.startx / width) * 100, (item.starty / height) * 100, (item.x / width) * 100, (item.y / height) * 100].join(','),
        };

        await createFigure(documentId, sendingData)
            .then(data => {})
            .catch(error => console.log('error', error));

        this.updateFigures();
    };

    setFiguresCheck = () => {
        // update every 5 secs @TODO - replace it with normal push events or websocket
        this.figuresUpdaterInterval = setInterval(() => {
            this.updateFigures();
        }, 5000);
    };

    delFiguresCheck = () => {
        if (this.figuresUpdaterInterval) clearInterval(this.figuresUpdaterInterval);
    };

    pointsSort(points) {
        return points.sort((a, b) => a - b);
    }

    pointIsInside(rect, point) {
        return point.x >= rect.x1 && point.x <= rect.x2 && point.y >= rect.y1 && point.y <= rect.y2;
    }

    isInside(rect, item) {
        if (item.type === 'circle') {
            const radius = Math.sqrt(Math.pow(item.startx - item.x, 2) + Math.pow(item.starty - item.y, 2));

            const dx = item.startx - Math.max(rect.x1, Math.min(item.startx, rect.x2));
            const dy = item.starty - Math.max(rect.y1, Math.min(item.starty, rect.y2));

            return dx * dx + dy * dy < Math.pow(radius, 2);
        }

        if (item.type === 'rectangle' || item.type === 'cross') {
            return false;
        }

        return this.pointIsInside(rect, { x: item.startx, y: item.starty }) || this.pointIsInside(rect, { x: item.x, y: item.y });
    }

    lineIntersection(x1, y1, x2, y2, x3, y3, x4, y4) {
        let denominator = (y4 - y3) * (x1 - x2) - (x4 - x3) * (y1 - y2);
        if (denominator == 0) {
            return (
                (x1 * y2 - x2 * y1) * (x4 - x3) - (x3 * y4 - x4 * y3) * (x2 - x1) == 0 &&
                (x1 * y2 - x2 * y1) * (y4 - y3) - (x3 * y4 - x4 * y3) * (y2 - y1) == 0
            );
        } else {
            let numerator_a = (x4 - x2) * (y4 - y3) - (x4 - x3) * (y4 - y2);
            let numerator_b = (x1 - x2) * (y4 - y2) - (x4 - x2) * (y1 - y2);
            let Ua = numerator_a / denominator;
            let Ub = numerator_b / denominator;

            return Ua >= 0 && Ua <= 1 && Ub >= 0 && Ub <= 1;
        }
    }

    intersection(rect, item) {
        if (item.type !== 'circle') {
            if (item.type === 'rectangle' || item.type === 'cross') {
                let newRect = { ...item };
                let [x1, x2] = this.pointsSort([newRect.startx, newRect.x]);
                let [y1, y2] = this.pointsSort([newRect.starty, newRect.y]);

                newRect.startx = x1;
                newRect.x = x2;
                newRect.starty = y1;
                newRect.y = y2;

                return (
                    (rect.x1 <= newRect.x && rect.x2 >= newRect.startx && rect.y2 >= newRect.starty && rect.y1 <= newRect.y) ||
                    (newRect.startx <= rect.x2 && newRect.x >= rect.x1 && newRect.y >= rect.y1 && newRect.starty <= rect.y2)
                );
            }

            return (
                this.lineIntersection(rect.x1, rect.y1, rect.x2, rect.y1, item.startx, item.starty, item.x, item.y) ||
                this.lineIntersection(rect.x2, rect.y1, rect.x2, rect.y2, item.startx, item.starty, item.x, item.y) ||
                this.lineIntersection(rect.x1, rect.y2, rect.x2, rect.y2, item.startx, item.starty, item.x, item.y) ||
                this.lineIntersection(rect.x1, rect.y1, rect.x1, rect.y2, item.startx, item.starty, item.x, item.y)
            );
        }

        return false;
    }

    getFigureInside() {
        this.delFiguresCheck();

        let figures = [];
        let rect = {};
        let [x1, x2] = this.pointsSort([this.state.selectDrawable[0].startx, this.state.selectDrawable[0].x]);
        let [y1, y2] = this.pointsSort([this.state.selectDrawable[0].starty, this.state.selectDrawable[0].y]);

        rect.x1 = x1;
        rect.x2 = x2;
        rect.y1 = y1;
        rect.y2 = y2;

        if (rect.x1 !== rect.x2 && rect.y1 !== rect.y2) {
            this.state.serverDrawables.forEach(item => {
                // if (this.isInside(rect, item)) {
                //     console.log('isInside', item);
                // }
                // if (this.intersection(rect, item)) {
                //     console.log('intersection', item);
                // }

                if (this.isInside(rect, item) || this.intersection(rect, item)) {
                    // console.log(item);
                    if (item.id) figures.push(item.id);
                }
            });
        }

        if (figures.length > 0) {
            this.setState({
                visible: true,
                deleteFigures: figures,
            });

            return;
        }

        this.setFiguresCheck();

        this.setState({
            selectDrawable: [],
        });

        return;
    }

    handleConfirmDelete = async () => {
        const { documentId } = this.props.data;
        const sendingData = {
            uuids: this.state.deleteFigures,
        };

        this.setState({
            confirmDelete: true,
        });

        await deleteFigures(documentId, sendingData)
            .then(data => {
                // console.log('sendData', data);
            })
            .catch(error => console.log('error', error));

        this.updateFigures();
        this.setFiguresCheck();

        this.setState({
            confirmDelete: false,
            visible: false,
            deleteFigures: [],
            selectDrawable: [],
        });
    };

    handleCancelDelete = () => {
        this.setFiguresCheck();

        this.setState({
            visible: false,
            selectDrawable: [],
        });
    };

    componentDidMount() {
        this.updateFigures();
        this.setFiguresCheck();
    }

    componentWillUnmount() {
        this.delFiguresCheck();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.sleep && this.props.sleep) {
            this.delFiguresCheck();
        }

        if (prevProps.sleep && !this.props.sleep) {
            this.setFiguresCheck();
        }
    }

    render() {
        const drawables = [...this.state.serverDrawables, ...this.state.drawables, ...this.state.newDrawable, ...this.state.selectDrawable];
        const { width, height, zoom } = this.props.data;
        const { visible, confirmDelete, deleteFigures } = this.state;

        return (
            <div style={{ position: 'absolute', top: 0, left: 0 }}>
                <Stage
                    onMouseDown={this.handleMouseDown}
                    onMouseUp={this.handleMouseUp}
                    onMouseMove={this.handleMouseMove}
                    width={width * zoom}
                    height={height * zoom}
                    scaleX={zoom}
                    scaleY={zoom}>
                    <Layer>
                        {drawables.map((drawable, i) => {
                            return drawable.render(i);
                        })}
                    </Layer>
                </Stage>
                <Modal
                    visible={visible}
                    onOk={this.handleConfirmDelete}
                    onCancel={this.handleCancelDelete}
                    footer={[
                        <Button key="cancel" onClick={this.handleCancelDelete}>
                            Отмена
                        </Button>,
                        <Button key="confirm" type="primary" loading={confirmDelete} onClick={this.handleConfirmDelete}>
                            Удалить
                        </Button>,
                    ]}>
                    <p>Удалить выбранные фигуры?</p>
                    <p>Количество: {deleteFigures.length}</p>
                </Modal>
            </div>
        );
    }
}

export default DrawDesk;
