import axios from 'axios';
import { api } from '../../../services/api';
import * as types from '../../actionTypes';
import { apiConfig } from '../../../services/api/config';
import utils from '../../../helpers/utils';

export const getAnalytics = (projectId, data, urlType) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    const url = projectId ? `${apiConfig.url}project/${projectId}/report/${urlType}` : `${apiConfig.url}common/${urlType}`;
    dispatch({ type: types.GET_ANALYTICS_LOADING });

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: url,
        data: data,
    })
        .then(response => {
            // console.log('RESPONSE ANALYTICS ', response);
            if (response.data.success) {
                dispatch({
                    type: types.GET_ANALYTICS,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors);
        });
};

export const resetAnalytics = () => dispatch => {
    dispatch({ type: types.RESET_ANALYTICS });
};
