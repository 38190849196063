import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'antd';
import Text from 'antd/lib/typography/Text';

import { setCopiedNote } from '../../redux/actions/projectModule/getNotes';

import './MarkerCopyInfo.scss';

const MarkerCopyInfo = props => {
    const { copiedNote, setCopiedNote, match } = props;
    const { projectId } = match.params;
    const base = 'marker-copy-info';

    // Effects
    useEffect(() => {
        if (copiedNote.data) {
            console.log(parseInt(projectId), copiedNote.data.projectId, parseInt(projectId) !== copiedNote.data.projectId);
            if (parseInt(projectId) !== copiedNote.data.projectId) {
                setCopiedNote(null);
                console.log('Очищено');
            }
        }
    }, [match]);

    // Handlers
    const handleCancel = () => {
        setCopiedNote(null);
    };

    return (
        copiedNote.data && (
            <div className={base}>
                <div className={`${base}__text-wrap`}>
                    <Text className={`${base}__text`} type="warning">
                        <div className={`${base}__icon-wrap`}>
                            <Icon type="warning" theme="outlined" />
                        </div>
                        Режим клонирования замечания {copiedNote.data.id}
                    </Text>
                </div>
                <div className={`${base}__btn-wrap`}>
                    <Button icon="close-circle" onClick={handleCancel}>
                        Отменить
                    </Button>
                </div>
            </div>
        )
    );
};

const mapStateToProps = state => ({
    copiedNote: state.projectModule.notes.copiedNote,
});

const mapDispatchToProps = dispatch => ({ setCopiedNote: data => dispatch(setCopiedNote(data)) });

export default connect(mapStateToProps, mapDispatchToProps)(MarkerCopyInfo);
