import React from 'react';
import { Line, Group } from 'react-konva';
import { Drawable } from './Drawable';

class CrossDrawable extends Drawable {
    constructor(startx, starty, type, id) {
        super(startx, starty, type, id);
        this.x = startx;
        this.y = starty;
    }

    render(i) {
        const points = [this.startx, this.starty, this.x, this.y];
        const points2 = [this.startx, this.y, this.x, this.starty];

        return (
            <Group key={`cross-${i}`}>
                <Line points={points} fill="#f00000" stroke="#f00000" />
                <Line points={points2} fill="#f00000" stroke="#f00000" />
            </Group>
        );
    }
}

export default CrossDrawable;
