import axios from 'axios';
import * as types from '../../actionTypes';
import { apiConfig } from '../../../services/api/config';
import { message } from 'antd';

export const getFavorites = () => dispatch => {
    dispatch({ type: types.GET_FAVORITES_LOADING });

    let accessToken = localStorage.getItem('accessToken');

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}favorites`,
    })
        .then(response => {
            if (!response.data.success) {
                response.data.errors.forEach(element => {
                    message.error(element);
                });
                dispatch({
                    type: types.GET_FAVORITES_SUCCESS,
                    data: {},
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                dispatch({
                    type: types.GET_FAVORITES_SUCCESS,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            }
        })
        .catch(errors => {
            console.log(errors);
        });
};

export const setFavorites = data => dispatch => {
    dispatch({
        type: types.SET_FAVORITES,
        data: data,
    });
};

export const changeFavorite = (section, itemId, isFavorite, callback = () => {}) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');

    const url = !isFavorite ? `${apiConfig.url}favorites/add` : `${apiConfig.url}favorites/delete`;
    const method = !isFavorite ? 'POST' : 'DELETE';
    // return;
    return axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: method,
        url: url,
        data: {
            bind_to: section,
            bind_id: itemId,
        },
    })
        .then(response => {
            if (!response.data.success) {
                response.data.errors.forEach(element => {
                    message.error(element);
                });
                throw response.data.errors[0];
            } else {
                callback();
                return response;
            }
        })
        .catch(errors => {
            console.log(errors.response);
            throw errors;
        });
};
