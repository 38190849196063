import React from 'react';
import { Button, Icon } from 'antd';

class FavoriteButton extends React.Component {
    render() {
        const { handleFavorite, isFavorite } = this.props;

        return (
            <Button
                title={'Избранное'}
                type={'link'}
                onClick={handleFavorite}
                className={isFavorite ? 'button-favorite button-favorite_active' : 'button-favorite'}
                /* className={data.is_important ? 'button-important button-important_active' : 'button-important'} */
            >
                <Icon type="star" theme="filled" />
            </Button>
        );
    }
}

export default FavoriteButton;
