import { GET_SETTINGS } from '../../actionTypes';

const initialState = {
    data: [],
    success: false,
    errors: [],
};

export const getSettings = (state = initialState, action) => {
    switch (action.type) {
        case GET_SETTINGS:
            return {
                ...state,
                data: action.data,
                success: action.success,
                errors: action.errors,
            };

        default:
            return state;
    }
};
