import * as types from '../../actionTypes';

const initialState = {
    teamList: {
        data: [],
        success: false,
        errors: [],
    },
    organizations: {
        data: [],
        success: false,
    },
    users: {
        data: [],
        success: false,
        errors: [],
    },
    assignedOrganization: {
        data: [],
        success: false,
        errors: [],
        loading: false,
    },
    assignedUser: {
        data: [],
        success: false,
        errors: [],
    },
    deletedOrganization: {
        data: [],
        success: false,
        errors: [],
    },
    deletedUser: {
        data: [],
        success: false,
        errors: [],
    },
};

export const getTeam = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_TEAM:
            return {
                ...state,
                teamList: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                },
            };

        case types.GET_ORGANIZATIONS:
            return {
                ...state,
                organizations: {
                    data: action.data,
                    success: action.success,
                },
            };

        case types.GET_FILTRED_ORGANIZATIONS:
            return {
                ...state,
                organizations: {
                    data: action.data,
                    success: action.success,
                },
            };

        case types.GET_COUNTERPARTY_LIST:
            return {
                ...state,
                organizations: {
                    data: action.data,
                    success: action.success,
                },
            };

        case types.CLEAR_ORGANIZATIONS:
            return {
                ...state,
                organizations: {
                    data: [],
                    success: false,
                    errors: [],
                },
            };

        case types.GET_USERS:
            return {
                ...state,
                users: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                },
            };

        case types.ASSIGN_ORGANIZATION_LOADING:
            return {
                ...state,
                assignedOrganization: {
                    data: [],
                    success: false,
                    errors: [],
                    loading: true,
                },
            };

        case types.ASSIGN_ORGANIZATION_SUCCESS:
            return {
                ...state,
                assignedOrganization: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };

        case types.ASSIGN_USER:
            return {
                ...state,
                assignedUser: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                },
            };

        case types.DELETE_ORGANIZATION:
            return {
                ...state,
                deletedOrganization: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                },
            };

        case types.DELETE_USER:
            return {
                ...state,
                deletedUser: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                },
            };

        default:
            return state;
    }
};
