export const notesListFiltersDefaultState = {
    openStatusIsActive: true,
    inworkStatusIsActive: true,
    completedStatusIsActive: true,
    openStatus: 'open',
    inworkStatus: 'inwork',
    completedStatus: 'completed',
    filterModalVisible: false,
    selectedOrganization: null,
    shownFilterList: [],
    filterTitles: {
        sinceCreateDate: 'С даты создания',
        byCreateDate: 'По дату создания',
        startDateExecution: 'С даты выполнения',
        endDateExecution: 'По дату выполнения',
        startCheckDate: 'С даты проверки',
        endCheckDate: 'По дату проверки',
        startCompleteDate: 'С даты срока устранения',
        endCompleteDate: 'По дату срока устранения',
        day: 'День',
        week: 'Неделя',
        month: 'Месяц',
        onDate: 'Просроченные на дату',
        authorOrganization: 'Организация автора',
        author: 'Автор',
        executor: 'Исполнитель',
        auditor: 'Требует внимание',
        dictionary: 'Категории',
        isReopen: 'Повторно открытые',
        document: 'По привязке к документу',
        retention: 'Статус работ',
        counterparty: 'Контрагент',
        executorType: 'Тип исполнителя',
    },
    selectedExecutor: [],
    selectedAuditor: null,
    selectedDictionaries: [],
    selectedIsReopen: false,
    selectedAuthor: [],
    selectedRetention: null,
    selectedCounterparty: null,
    selectedExecutorType: null,
    searchValue: '',
    filterParams: '',
    statusParams: '',
    searchParams: '',
    sortParams: '',
    selectedDateFilter: 'Сначала новые',
    dateFilter: {
        begin: '',
        end: '',
        type: '',
        display: '',
        startDateExecution: '',
        endDateExecution: '',
        startCheckDate: '',
        endCheckDate: '',
        startCompleteDate: '',
        endCompleteDate: '',
    },
    currentListKey: 'filterList',
    listKey: [],
    selectedDate: null,
    selectedTime: null,
    pastDateFilter: {
        begin: '',
        end: '',
        type: '',
    },
    docFilter: null,
    prevStateFilters: null,
};

export const sortTypes = [
    { id: 0, title: 'Сначала новые', sortVal: '-created_at' },
    { id: 1, title: 'Сначала старые', sortVal: 'created_at' },
    { id: 2, title: 'Старые изменения', sortVal: 'updated_at' },
    { id: 3, title: 'Новые изменения', sortVal: '-updated_at' },
];
