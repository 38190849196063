import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon, Button, Modal, Input, Empty, Popconfirm, Select } from 'antd';

import {
    createStructureItem,
    getStructure,
    deleteStructureItem,
    resetDeletedItemStatus,
    restoreStructureItem,
    resetRestoredItemStatus,
    setStructure,
    updateStructureItem,
    changeStructureItemTitle,
} from '../../redux/actions/projectModule/getStructure';
import { changeFavorite, editStructureType } from '../../redux/actions/projectModule';
import { getPermissions } from '../../redux/actions/common';

import { BreadCrumbs } from '../BreadCrumbs';
import { SearchForm } from '../SearchForm';
import { Loading } from '../Loading';

import './ProjectStructure.scss';
import MarkerCopyInfo from '../ProjectDocs/MarkerCopyInfo';

class ProjectStructure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            structureTitle: '',
            searchValue: '',
            searchedStructures: null,
            editItemId: null,
            isEdit: false,
            isFocused: false,
            currentChildrenTitle: '',
            isEditConfirmModal: false,
        };
        this.collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
        this.structureTitleRef = createRef();
    }

    // async componentDidUpdate(prevProps, prevState) {
    //     const { match } = this.props;
    //     const { currentStructureId } = match.params;

    //     if (prevProps.match.params.currentStructureId !== currentStructureId) {
    //         this.getData();
    //     }
    // }

    getData = () => {
        const { rootStructureId, getStructure, match } = this.props;
        const { projectId, currentStructureId } = match.params;

        getStructure(projectId, currentStructureId ? currentStructureId : rootStructureId);
    };

    handleOpen = () => {
        this.setState({
            structureTitle: '',
            isModalVisible: true,
        });
    };

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
        });
    };

    handleConfirm = title => {
        const { rootStructureId, createStructureItem, match } = this.props;
        const { projectId, currentStructureId } = match.params;
        createStructureItem(projectId, currentStructureId ? currentStructureId : rootStructureId, title);

        this.setState({
            isModalVisible: false,
        });
    };

    handleInput = e => {
        this.setState({
            structureTitle: e.target.value,
        });
    };

    handlePopConfirm = (id, status) => {
        const { deleteStructureItem, restoreStructureItem, match } = this.props;
        const { projectId, currentStructureId } = match.params;

        switch (status) {
            case 'restore':
                restoreStructureItem(projectId, currentStructureId, id);
                break;
            case 'delete':
                deleteStructureItem(projectId, currentStructureId, id);
                break;
        }
    };

    handleSearchFilter = value => {
        const structures = this.getStructures();
        const filteredStructures = structures.filter(item => {
            return Object.keys(item).some(() => {
                if (item['title'] !== null) {
                    return item['title'].toLowerCase().includes(value.toLowerCase());
                }
            });
        });

        this.setState({
            searchedStructures: !value ? null : filteredStructures,
        });
    };

    handleSearchChange = e => {
        const { value } = e.target;

        if (value.length < 1) {
            this.setState({
                searchedStructures: null,
            });
        }
        this.setState({
            searchValue: value,
        });
        this.handleSearchFilter(value);
    };

    handleSearchReset = () => {
        this.setState({
            searchValue: '',
            searchedStructures: null,
        });
    };

    handleLinkClick = e => {
        if (this.state.isEdit || e.target.tagName === 'BUTTON') {
            e.preventDefault();
        } else {
            this.handleSearchReset();
        }
    };

    getStructures = () => {
        const { currentItem } = this.props.structure;
        return currentItem.data && currentItem.data.children
            ? currentItem.data.children.sort((a, b) => this.collator.compare(a.title, b.title))
            : [];
    };

    handleFavorite = (section, itemId, isFavorite) => {
        let copyData = Object.assign({}, this.props.structure.currentItem.data);

        const itemIndex = copyData.children.findIndex(item => item.id === itemId);
        if (itemIndex > -1) {
            copyData.children[itemIndex].is_favorite = !copyData.children[itemIndex].is_favorite;
        }

        this.props.setStructure(copyData);
        this.props
            .changeFavorite(section, itemId, isFavorite)
            .then(response => {
                // console.log('handleFavorite -> response', response);
            })
            .catch(error => {
                let copyData = Object.assign({}, this.props.structure.currentItem.data);
                // copyData.is_favorite = !copyData.is_favorite;
                const itemIndex = copyData.children.findIndex(item => item.id === itemId);
                if (itemIndex > -1) {
                    copyData.children[itemIndex].is_favorite = !copyData.children[itemIndex].is_favorite;
                    this.props.setStructure(copyData);
                }
            });
    };

    handleStructureTypeChange = (projectId, structureId, type) => {
        this.props.editStructureType(projectId, structureId, type);
    };

    handleEditStructure = id => e => {
        if (this.state.isEdit) {
            e.preventDefault();
            this.handleOpenModal();
        } else {
            const { structure } = this.props;
            const currentItem = structure.currentItem.data.children.find(item => item.id === id);
            this.setState(prevState => {
                return {
                    ...prevState,
                    isEdit: !prevState.isEdit,
                    editItemId: id,
                    currentChildrenTitle: currentItem.title,
                };
            });
        }
    };

    handleChangeStructureTitle = title => {
        const { changeStructureItemTitle, structure } = this.props;

        const children = structure.currentItem.data.children;
        const idx = children.findIndex(el => el.id === this.state.editItemId);
        const oldItem = children[idx];
        const newItem = { ...oldItem, title };
        const updatedChildren = [...children.slice(0, idx), newItem, ...children.slice(idx + 1)];
        changeStructureItemTitle(updatedChildren);
    };

    handleSaveStructureTitle = () => {
        const { match, updateStructureItem, structure } = this.props;
        const { projectId } = match.params;
        const currentItem = structure.currentItem.data.children.find(item => item.id === this.state.editItemId);

        if (currentItem) {
            updateStructureItem(projectId, this.state.editItemId, currentItem.title, () => {
                this.setState({
                    isEdit: false,
                    editItemId: null,
                    isFocused: false,
                    isEditConfirmModal: false,
                });
            });
        }
    };

    handleOpenModal = () => {
        this.setState({
            isEditConfirmModal: true,
        });
    };

    handleClearStructureTitleState = () => {
        this.setState({
            isEdit: false,
            editItemId: null,
            isFocused: false,
            isEditConfirmModal: false,
            currentChildrenTitle: '',
        });
    };

    handleCancelSaveStructureTitle = () => {
        this.handleChangeStructureTitle(this.state.currentChildrenTitle);
        this.handleClearStructureTitleState();
    };

    componentDidMount() {
        const { getPermissions, match } = this.props;
        const { projectId } = match.params;
        this.getData();
        getPermissions(projectId);
    }

    componentDidUpdate(prevProps, prevState) {
        const { match } = this.props;
        const { currentStructureId } = match.params;

        if (prevProps.match.params.currentStructureId !== currentStructureId) {
            this.getData();
        }

        if (this.state.isEdit !== prevState.isEdit && this.structureTitleRef.current) {
            this.structureTitleRef.current.focus();
            this.setState({
                isFocused: true,
            });
        }
    }

    componentWillUnmount() {
        this.handleClearStructureTitleState();
    }

    render() {
        const { path, projectId, section } = this.props.match.params;
        const { isModalVisible, isEditConfirmModal, structureTitle, searchValue, searchedStructures } = this.state;
        const { currentItem } = this.props.structure;
        const { Option } = Select;

        const editPermission =
            this.props.permission.success && this.props.permission.data.find(item => item.name === 'structure.edit.settings');
        const isCanEditStructure = editPermission && editPermission.isAllowed;

        const items = this.getStructures();

        const renderStructureTitle = item => {
            const isEditCurrentItem = this.state.isEdit && this.state.editItemId === item.id;

            return (
                <>
                    {this.state.isEdit && item.id === this.state.editItemId ? (
                        <Input
                            ref={this.structureTitleRef}
                            value={item.title}
                            placeholder="Название структуры"
                            onChange={e => this.handleChangeStructureTitle(e.target.value)}
                        />
                    ) : (
                        item.title
                    )}
                    {isEditCurrentItem ? (
                        <>
                            <Button
                                title="Сохранить"
                                type="link"
                                onClick={this.handleOpenModal}
                                style={{
                                    color: '#27ae60',
                                    margin: '0 5px 0 10px',
                                }}
                                className={'button-favorite'}>
                                <Icon type={'check-circle'} theme="outlined" />
                            </Button>
                            <Button
                                title="Отменить"
                                type="link"
                                onClick={this.handleCancelSaveStructureTitle}
                                style={{
                                    color: 'rgb(211, 47, 47)',
                                    margin: '0 5px',
                                }}
                                className={'button-favorite'}>
                                <Icon type={'close-circle'} theme="outlined" />
                            </Button>
                        </>
                    ) : (
                        <Button
                            title="Изменить"
                            type="link"
                            onClick={this.handleEditStructure(item.id)}
                            style={{ marginLeft: 'auto' }}
                            disabled={this.state.isEdit && item.id !== this.state.editItemId}
                            className="button-favorite">
                            <Icon type={'edit'} theme="outlined" />
                        </Button>
                    )}
                </>
            );
        };

        return (
            <div className="structure">
                {currentItem.data === null || Object.keys(currentItem.data).length === 0 ? null : (
                    <BreadCrumbs prevItems={currentItem.data} link={this.props.match.params} />
                )}
                <MarkerCopyInfo {...{ match: this.props.match }} />
                <div className="row">
                    <Button onClick={this.handleOpen.bind(this)} type="primary" shape="circle" icon="plus" size="large" />
                    <SearchForm
                        searchValue={searchValue}
                        handleSearchFilter={this.handleSearchFilter}
                        handleSearchChange={this.handleSearchChange}
                        handleSearchReset={this.handleSearchReset}
                    />
                </div>
                {currentItem.loading ? (
                    <Loading />
                ) : !currentItem.success || currentItem.data === null || !currentItem.data.children.length ? (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Структур не найдено" />
                ) : (
                    <>
                        {(searchedStructures || items).map(item => {
                            return (
                                <div key={item.id} className="flexRow">
                                    <div className="structure__item-wrap">
                                        <Link
                                            className={`${
                                                item.deleted_at === null ? 'structure__link flexRow' : 'structure__link flexRowOpacity'
                                            }`}
                                            to={`/${path}/${projectId}/${section}/${item.id}`}
                                            onClick={this.handleLinkClick}>
                                            <li className="structure__item">
                                                <div className="structure__data">
                                                    <div className="structure__description">
                                                        <div className="structure__title">
                                                            {isCanEditStructure ? renderStructureTitle(item) : item.title}
                                                            {item.hasDocument && (
                                                                <div>
                                                                    <span className="note-item__icon" title="Прикреплены документы">
                                                                        <Icon type="file" theme="outlined" />
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="structure__dates">{item.date}</div>
                                                    </div>
                                                    <ul className="structure__labels">
                                                        <li className="structure__label danger">{item.notes_cnt_danger}</li>
                                                        <li className="structure__label warning">{item.notes_cnt_warning}</li>
                                                        <li className="structure__label success">{item.notes_cnt_success}</li>
                                                    </ul>
                                                    <div className="structure__arrow">
                                                        <Icon type="right" theme="outlined" style={{ color: '#666666' }} />
                                                    </div>
                                                </div>
                                            </li>
                                        </Link>

                                        {item.type && item.type.show && (
                                            <div className="structure__type">
                                                <div className="structure__type-label">Признак для AX:</div>
                                                <Select
                                                    defaultValue={item.type.name}
                                                    className="structure__type-select"
                                                    onChange={value => this.handleStructureTypeChange(projectId, item.id, value)}>
                                                    <Option value="">Не указан</Option>
                                                    <Option value="section">Секция</Option>
                                                    <Option value="floor">Этаж</Option>
                                                    <Option value="technical">Технический этаж</Option>
                                                    <Option value="roofing">Кровля</Option>
                                                    <Option value="ground">Цокольный этаж</Option>
                                                    <Option value="parking">Парковка</Option>
                                                </Select>
                                            </div>
                                        )}
                                    </div>
                                    <div className={`structure__btns${item.type && item.type.show ? ' marginBottom' : ''}`}>
                                        <Button
                                            title={'Избранное'}
                                            type={'link'}
                                            onClick={() => this.handleFavorite('structure', item.id, item.is_favorite)}
                                            className={item.is_favorite ? 'button-favorite button-favorite_active' : 'button-favorite'}>
                                            <Icon type="star" theme="filled" />
                                        </Button>
                                        <Popconfirm
                                            title={
                                                item.deleted_at === null
                                                    ? 'Вы точно хотите удалить элемент структуры?'
                                                    : 'Вы точно хотите восстановить элемент структуры?'
                                            }
                                            onConfirm={() =>
                                                this.handlePopConfirm(item.id, item.deleted_at !== null ? 'restore' : 'delete')
                                            }
                                            onCancel={() => console.log('')}
                                            okText="Да"
                                            cancelText="Нет">
                                            <div className="structure__remove">
                                                {item.deleted_at === null ? (
                                                    <Icon type="delete" style={{ fontSize: 20, color: '#d32f2f' }} size={24} />
                                                ) : (
                                                    <Icon type="redo" style={{ fontSize: 20, color: '#666666' }} size={24} />
                                                )}
                                            </div>
                                        </Popconfirm>
                                    </div>
                                </div>
                            );
                        })}
                    </>
                )}
                <Modal
                    title="Добавление структуры"
                    visible={isModalVisible}
                    onOk={this.handleConfirm.bind(this, structureTitle)}
                    onCancel={this.handleCancel}
                    okText="Ок"
                    cancelText="Отменить">
                    <div>
                        {/* <span>Введите название</span> */}
                        <Input onChange={this.handleInput.bind(this)} value={this.state.structureTitle} placeholder="Название структуры" />
                    </div>
                </Modal>
                <Modal
                    title="Внимание!"
                    visible={isEditConfirmModal}
                    onOk={this.handleSaveStructureTitle}
                    onCancel={this.handleCancelSaveStructureTitle}
                    okText="Да"
                    cancelText="Нет">
                    Вы хотите сохранить изменения?
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        structure: {
            currentItem: state.projectModule.structure.currentItem,
        },
        permission: state.permission,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createStructureItem: function (projectId, structureId, title) {
            return dispatch(createStructureItem(projectId, structureId, title));
        },
        getStructure: function (projectId, structureId) {
            return dispatch(getStructure(projectId, structureId));
        },
        setStructure: function (projectId, structureId) {
            return dispatch(setStructure(projectId, structureId));
        },
        deleteStructureItem: function (projectId, currentStructureId, structureId) {
            return dispatch(deleteStructureItem(projectId, currentStructureId, structureId));
        },
        resetDeletedItemStatus: function () {
            return dispatch(resetDeletedItemStatus());
        },
        restoreStructureItem: function (projectId, currentStructureId, structureId) {
            return dispatch(restoreStructureItem(projectId, currentStructureId, structureId));
        },
        resetRestoredItemStatus: function () {
            return dispatch(resetRestoredItemStatus());
        },
        changeFavorite: function (section, itemId, isFavorite, callback) {
            return dispatch(changeFavorite(section, itemId, isFavorite, callback));
        },
        editStructureType: function (projectId, structureId, type) {
            return dispatch(editStructureType(projectId, structureId, type));
        },
        updateStructureItem: function (projectId, structureId, title, callback) {
            return dispatch(updateStructureItem(projectId, structureId, title, callback));
        },
        changeStructureItemTitle: function (data) {
            return dispatch(changeStructureItemTitle(data));
        },
        getPermissions: function (projectId) {
            return dispatch(getPermissions(projectId));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectStructure);
