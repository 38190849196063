import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getNoteById, changeNoteStatus } from '../../redux/actions/projectModule/getNotes';

import { ProjectNoteDetail } from './Components';
import { Loading } from '../Loading';
import { BreadCrumbs } from '../BreadCrumbs';

import './ProjectNotes.scss';

class ProjectNotesDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noteDetails: undefined,
            statusArray: [
                { status: 'open', statusTitle: 'Открыто', color: '#ed2d23' },
                { status: 'inwork', statusTitle: 'Выполнено', color: '#f2c94c' },
                { status: 'completed', statusTitle: 'Проверено', color: '#27ae60' },
            ],
        };
    }

    componentDidMount() {
        const { getNoteById, match } = this.props;
        const { projectId, currentStructureId, currentSectionItemDetails } = match.params;
        getNoteById(projectId, currentStructureId, currentSectionItemDetails);
    }

    changeStatus = status => {
        const { changeNoteStatus, match } = this.props;
        const { projectId, currentStructureId, currentSectionItemDetails } = match.params;

        changeNoteStatus(projectId, currentStructureId, currentSectionItemDetails, status);
    };

    render() {
        const { singleNote, match } = this.props;
        const { statusArray } = this.state;

        return (
            <div className="structure">
                {singleNote.loading || !singleNote.data ? (
                    <Loading />
                ) : (
                    singleNote.data.id === +match.params.currentSectionItemDetails && (
                        <>
                            {Object.keys(singleNote.data).length === 0 || !singleNote.data.breadcrumbsObject ? null : (
                                <BreadCrumbs prevItems={singleNote.data} link={match.params} />
                            )}
                            <ProjectNoteDetail
                                params={match.params}
                                statusData={statusArray}
                                data={singleNote.data}
                                changeStatus={this.changeStatus.bind(this)}
                                rootDocumentId={this.props.rootDocumentId}
                                history={this.props.history}
                            />
                        </>
                    )
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    singleNote: state.projectModule.notes.singleNote,
});

const mapDispatchToProps = dispatch => ({
    getNoteById: (projectId, structureId, itemId) => dispatch(getNoteById(projectId, structureId, itemId)),
    changeNoteStatus: (projectId, structureId, itemId, status) => dispatch(changeNoteStatus(projectId, structureId, itemId, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectNotesDetails);
