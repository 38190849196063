import * as types from '../../actionTypes';

const initialState = {
    ntd: {
        data: {},
        success: false,
        errors: [],
    },
    treeNtd: {
        data: {},
        success: false,
        errors: [],
    },
};

export const getNtd = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_NTD:
            return {
                ...state,
                ntd: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                },
            };
        case types.GET_NTD_BY_ID:
            return {
                ...state,
                ntd: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                },
            };
        case types.GET_TREE_NTD:
            return {
                ...state,
                treeNtd: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                },
            };
        default:
            return state;
    }
};
