import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { getCurrentPage } from '../../redux/actions/common';

import './BreadCrumbs.scss';

const BreadCrumbs = props => {
    const { prevItems, link } = props;
    const { path, projectId, section } = link;
    const { breadcrumbs, breadcrumbsObject } = prevItems;

    const renderBreadcrumbs = data => {
        const currentItem = data[data.length - 1];
        const linkedItems = data.slice(0, -1);
        return (
            <ul className="breadcrumbs">
                {linkedItems.map(item => (
                    <li className="breadcrumbs__item" key={item.id}>
                        <Link
                            to={
                                (!path ? null : `/${path}`) +
                                (!projectId ? null : `/${projectId}`) +
                                (!section ? null : `/${section}`) +
                                (item.type === 'project-document' && item.structure_id ? `/${item.structure_id}` : '') +
                                (!item.id ? '' : `/${item.id}`) +
                                (section === 'documents' && item.type === 'project-structure' && item.root_document_id
                                    ? `/${item.root_document_id}`
                                    : '')
                            }>
                            <span className="breadcrumbs__title">{item.title}</span>
                        </Link>
                        <span className="breadcrumbs__separator">/</span>
                    </li>
                ))}
                <li>
                    <span>{currentItem.title}</span>
                </li>
            </ul>
        );
    };

    if (breadcrumbsObject && breadcrumbsObject.length) {
        return renderBreadcrumbs(breadcrumbsObject);
    }

    if (breadcrumbs && breadcrumbs.length) {
        return renderBreadcrumbs(breadcrumbs);
    }

    return null;
};

function mapDispatchToProps(dispatch) {
    return {
        getCurrentPage: function (title) {
            return dispatch(getCurrentPage(title));
        },
    };
}

export default connect(null, mapDispatchToProps)(BreadCrumbs);
