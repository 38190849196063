import React from 'react';
import { Route } from 'react-router-dom';
import { Layout } from 'antd';
import { PageTitle } from '../../PageTitle';
import { Navigation } from '../../Navigation';
import packageJson from '../../../../package.json';

import './TempDefault.scss';

const { Header, Sider, Content } = Layout;

class TempDefault extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: true,
        };
    }

    onCollapse = collapsed => {
        this.setState({
            collapsed,
        });
    };

    render() {
        const { component: Component, computedMatch, ...rest } = this.props;

        return (
            <Layout style={{ minHeight: '100vh', position: 'relative' }}>
                <Sider
                    collapsible
                    collapsed={this.state.collapsed}
                    onCollapse={this.onCollapse}
                    width={250}
                    theme="light"
                    style={{ boxShadow: '0 0 10px 0 rgba(0,0,0,0.12)' }}>
                    {/* Here will be profile card */}
                    <Route render={matchProps => <Navigation {...matchProps} />} />
                    <span
                        style={{
                            position: 'fixed',
                            bottom: '60px',
                            left: this.state.collapsed ? '20px' : '0',
                            padding: !this.state.collapsed ? '0 25px' : '0',
                            transition: 'all 0.3s ease',
                        }}>
                        v {packageJson.version}
                    </span>
                </Sider>

                <Layout>
                    <Header
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0 30px',
                            backgroundColor: '#00ADEF',
                        }}>
                        <PageTitle {...computedMatch} />
                        {/* Here will be add to favorites */}
                    </Header>
                    <Content style={{ backgroundColor: '#ffffff' }}>
                        <Route {...rest} render={matchProps => <Component {...matchProps} />} />
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default TempDefault;
