import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Button, message, Row, Modal, Calendar } from 'antd';

import { getNotes, createNote, setNotes, createPrescriptionForCounterparty, notesStatusChange } from '../../redux/actions/projectModule';
import { apiConfig } from '../../services/api/config';

import { NotesList } from './Components';
import { BreadCrumbs } from '../BreadCrumbs';
import { Loading } from '../Loading';

import NotesListFilters from './Components/NotesListFilters/NotesListFilters';
import NotesFiles from './Components/NotesFiles';

import './ProjectNotes.scss';
import moment from 'moment';

class ProjectNotes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            prescriptionIsLoading: false,
            limit: 10,
            params: '',
            createLoading: false,
            reportLoading: false,
            ignoreNoteIds: [],
            selectedNotes: {},
            visiblePeriodExecution: false,
            selectedDateDeadline: moment().format('YYYY-MM-DD'),
            setDeadlineIsLoading: false,
            useFilter: false,
            showFormModal: false, // модалка Печатные формы
            showNoteModal: false, // модалка Перенос замечаний
            formModalCheckedButton: '', // выбранное состояние модалки Печатные формы app|prescriptionForCounterparty|prescription|note
            noteModalCheckedButton: '', // выбранное состояние модалки Перенос замечаний done|date
            appModalVisible: false,
        };
    }

    setParams = (params, isNeedCounts, isNeedBreadcrumbs, defaultLimit, scrollTop) => {
        const { limit } = this.state;
        const { notesData } = this.props;
        let callback;

        if (scrollTop) {
            callback = () => {
                window.scrollTo(0, scrollTop);
            };
        }

        this.setState(
            {
                params: params,
            },
            () => {
                this.getData(
                    `offset=0&limit=${defaultLimit ? defaultLimit : limit}${params}`,
                    { ...notesData.data, notes: [] },
                    isNeedCounts,
                    isNeedBreadcrumbs,
                    callback,
                );
            },
        );
    };

    getData = (params, notes, isNeedCounts, isNeedBreadcrumbs, callback) => {
        const { getNotes } = this.props;
        const { projectId, currentStructureId } = this.props.match.params;

        if (isNeedCounts) {
            params += '&calculateCounts=1';
        }
        if (isNeedBreadcrumbs) {
            params += '&createBreadcrumbs=1';
        }

        getNotes(projectId, currentStructureId, params, notes, callback);
    };

    handleReport = () => {
        const { projectId, currentStructureId } = this.props.match.params;
        const accessToken = localStorage.getItem('accessToken');
        const { params } = this.state;

        const sendData = {
            structureId: currentStructureId,
        };

        this.setState({
            reportLoading: true,
        });

        axios({
            headers: {
                'Access-Token': accessToken,
            },
            method: 'POST',
            url: `${apiConfig.url}project/${projectId}/report/notes?${params.slice(1)}`,
            data: sendData,
        })
            .then(response => {
                if (!response.data.success) {
                    response.data.errors.forEach(element => {
                        message.error(element);
                    });
                } else {
                    window.open(response.data.data.full_path, '_blank');
                }
                this.setState({
                    reportLoading: false,
                });
            })
            .catch(errors => {
                console.log(errors.response);
                this.setState({
                    reportLoading: false,
                });
            });
    };

    handleDeadlineReport = () => {
        const { projectId, currentStructureId } = this.props.match.params;
        const accessToken = localStorage.getItem('accessToken');
        const { params } = this.state;

        const sendData = {
            structureId: currentStructureId,
        };

        this.setState({
            reportLoading: true,
        });

        axios({
            headers: {
                'Access-Token': accessToken,
            },
            method: 'POST',
            url: `${apiConfig.url}project/${projectId}/report/change-deadline-notes?${params.slice(1)}`,
            data: sendData,
        })
            .then(response => {
                if (!response.data.success) {
                    response.data.errors.forEach(element => {
                        message.error(element);
                    });
                } else {
                    window.open(response.data.data.full_path, '_blank');
                }
                this.setState({
                    reportLoading: false,
                });
            })
            .catch(errors => {
                console.log(errors.response);
                this.setState({
                    reportLoading: false,
                });
            });
    };

    handlePrescription = () => {
        const { projectId, currentStructureId } = this.props.match.params;
        const accessToken = localStorage.getItem('accessToken');
        const { params } = this.state;

        this.setState({
            prescriptionIsLoading: true,
        });

        axios({
            headers: {
                'Access-Token': accessToken,
            },
            method: 'POST',
            url: `${apiConfig.url}project/${projectId}/project-structure/${currentStructureId}/note/prescription?${params.slice(1)}`,
        })
            .then(response => {
                if (!response.data.success) {
                    response.data.errors.forEach(element => {
                        message.error(element);
                    });
                } else {
                    message.info(response.data.data.message);
                    // response.data.data.forEach(element => {
                    //     message.info(element);
                    // });
                }
                this.setState({
                    prescriptionIsLoading: false,
                });
            })
            .catch(errors => {
                console.log(errors.response);
                this.setState({
                    prescriptionIsLoading: false,
                });
            });
    };

    handlePrescriptionForCounterparty = () => {
        const { createPrescriptionForCounterparty, match } = this.props;
        const { projectId } = match.params;
        const { selectedNotes } = this.state;
        const selectedNotesArray = Object.values(selectedNotes).length ? Object.values(selectedNotes).map(({ id }) => id) : [];

        createPrescriptionForCounterparty(projectId, { noteIds: selectedNotesArray }, () => {
            this.setState({
                selectedNotes: {},
            });
        });
    };

    handleCreateNote = e => {
        e.preventDefault();
        const { createNote } = this.props,
            { projectId, currentStructureId } = this.props.match.params,
            data = {
                title: '',
                description: '',
                isEnabled: false,
                ntdData: null,
                ntdFolderName: null,
                executor: undefined,
                dictionary: [],
                date: null,
                auditors: [],
            };

        this.setState({
            createLoading: true,
        });

        createNote(projectId, currentStructureId, data, data => {
            this.props.history.push(`${this.props.history.location.pathname}/${data.id}`);
        }).catch(() => {
            this.setState({
                createLoading: false,
            });
        });
    };

    handleCheckAll = () => {
        const { notesData } = this.props;
        const { selectedNotes } = this.state;

        const notes = {};
        if (notesData.data && !Object.keys(selectedNotes).length) {
            notesData.data.notes.forEach(note => {
                notes[note.id] = {
                    id: note.id,
                    retention_status_id: note.retention_status_id,
                };
            });

            this.setState({ useFilter: true, ignoreNoteIds: [] });
        } else {
            this.setState({ useFilter: false, ignoreNoteIds: [] });
        }

        this.setState({
            selectedNotes: notes,
        });
    };

    handleToggleCheckNote = item => {
        const copySelectedNotes = Object.assign({}, this.state.selectedNotes);

        if (copySelectedNotes[item.id]) {
            if (this.state.useFilter) {
                this.setState(prevState => ({
                    ignoreNoteIds: [...prevState.ignoreNoteIds, item.id],
                }));
            }

            delete copySelectedNotes[item.id];
        } else {
            copySelectedNotes[item.id] = {
                id: item.id,
                retention_status_id: item.retention_status_id,
            };

            if (this.state.useFilter) {
                this.setState(prevState => ({
                    ignoreNoteIds: prevState.ignoreNoteIds.filter(id => id !== item.id),
                }));
            }
        }

        this.setState({
            selectedNotes: copySelectedNotes,
        });
    };

    handleSetDateDeadline = () => {
        const { projectId, currentStructureId } = this.props.match.params;
        const accessToken = localStorage.getItem('accessToken');
        const { selectedNotes, selectedDateDeadline, params } = this.state;
        const copyNotes = JSON.parse(JSON.stringify(this.props.notesData.data.notes));

        const data = {
            ignoreNoteIds: this.state.ignoreNoteIds,
            selectedNoteIds: [],
            date_deadline: selectedDateDeadline,
            useFilter: this.state.useFilter,
        };

        for (const id in selectedNotes) {
            if (selectedNotes.hasOwnProperty(id)) {
                if (selectedNotes[id].retention_status_id !== 0) {
                    data.selectedNoteIds.push(id);
                }
            }
        }

        if (!data.selectedNoteIds.length) {
            data.useFilter = true;
        } else {
            data.useFilter = false;
        }

        this.setState({
            setDeadlineIsLoading: true,
        });

        axios({
            headers: {
                'Access-Token': accessToken,
            },
            method: 'POST',
            url: `${apiConfig.url}project/${projectId}/project-structure/${currentStructureId}/web-multiselect?${params.slice(1)}`,
            data,
        })
            .then(response => {
                if (!response.data.success) {
                    response.data.errors.forEach(element => {
                        message.error(element);
                    });

                    this.setState({
                        setDeadlineIsLoading: false,
                    });
                } else {
                    message.info(response.data.data.message);
                    response.data.data.errors.forEach(element => {
                        message.error(element);
                    });
                    response.data.data.updatedNoteIds.forEach(id => {
                        for (let i = 0; i < copyNotes.length; i++) {
                            if (copyNotes[i].id === id) {
                                copyNotes[i].date_deadline = selectedDateDeadline;
                                break;
                            }
                        }
                    });

                    this.props.setNotes(copyNotes);

                    this.setState({
                        setDeadlineIsLoading: false,
                        visiblePeriodExecution: false,
                        selectedDateDeadline: moment().format('YYYY-MM-DD'),
                        selectedNotes: {},
                    });
                }
            })
            .catch(errors => {
                console.log(errors);
                this.setState({
                    setDeadlineIsLoading: false,
                    visiblePeriodExecution: false,
                    selectedDateDeadline: moment().format('YYYY-MM-DD'),
                });
            });
    };

    handleCancelSetDateDeadline = () => {
        this.setState({
            selectedDateDeadline: moment().format('YYYY-MM-DD'),
            visiblePeriodExecution: false,
        });
    };

    handleScroll = () => {
        window.scrollTo(500);
    };

    handleOpenFormModal = event => {
        event.target.blur();

        this.setState({
            showFormModal: true,
            formModalCheckedButton: '',
        });
    };

    handleOkFormModal = () => {
        this.setState({
            showFormModal: false,
        });

        switch (this.state.formModalCheckedButton) {
            case 'app':
                this.setState({
                    appModalVisible: true,
                });
                break;
            case 'prescriptionForCounterparty':
                this.handlePrescriptionForCounterparty();
                break;
            case 'prescription':
                this.handlePrescription();
                break;
            case 'note':
                this.handleReport();
                break;
            case 'deadline-note':
                this.handleDeadlineReport();
                break;
            default:
                break;
        }
    };

    handleCancelFormModal = () => {
        this.setState({
            showFormModal: false,
            formModalCheckedButton: '',
        });
    };

    handleOpenNoteModal = event => {
        event.target.blur();

        this.setState({
            showNoteModal: true,
            noteModalCheckedButton: '',
        });
    };

    handleOkNoteModal = () => {
        this.setState({
            showNoteModal: false,
        });

        switch (this.state.noteModalCheckedButton) {
            case 'done':
                const noteIds = Object.values(this.state.selectedNotes)
                    .filter(item => item.hasOwnProperty('id'))
                    .map(item => item.id);

                this.props.notesStatusChange(noteIds);
                break;
            case 'date':
                this.setState({ visiblePeriodExecution: true });
                break;
            default:
                break;
        }
    };

    handleCancelNoteModal = () => {
        this.setState({
            showNoteModal: false,
            noteModalCheckedButton: '',
        });
    };

    handleCloseAppModal = () => {
        this.setState({
            appModalVisible: false,
        });
    };

    componentDidMount() {
        const { limit } = this.state;

        this.getData(`offset=0&limit=${limit}`, null, true, true);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match && +prevProps.match.params.currentStructureId !== +this.props.match.params.currentStructureId) {
            this.getData(`offset=0&limit=${this.state.limit}${this.state.params}`, null, true, true);
        }

        if (this.state.params !== prevState.params) {
            this.setState({
                selectedNotes: {},
            });
        }

        // Если кнопка Выделить все нажата то последуюущие загруженные замечании тоже отмечаются
        if (this.state.useFilter) {
            const { notesData } = this.props;

            if (notesData.data.notes.length > prevProps.notesData.data.notes.length) {
                const notes = {};

                if (notesData.data) {
                    notesData.data.notes.slice(-this.state.limit).forEach(note => {
                        notes[note.id] = {
                            id: note.id,
                            retention_status_id: note.retention_status_id,
                        };
                    });
                }

                this.setState(prevState => ({
                    selectedNotes: { ...prevState.selectedNotes, ...notes },
                }));
            }
        }
    }

    render() {
        const { notesData, match, history, projects, isNotesChangeStatusLoading } = this.props;
        const { loading } = notesData;
        const {
            prescriptionIsLoading,
            limit,
            createLoading,
            params,
            reportLoading,
            selectedNotes,
            setDeadlineIsLoading,
            appModalVisible,
            formModalCheckedButton,
            noteModalCheckedButton,
        } = this.state;
        const today = moment(new Date(new Date().setHours(0, 0, 0, 0)));

        return (
            <div className="structure">
                {notesData.data && notesData.data.breadcrumbs && <BreadCrumbs prevItems={notesData.data} link={match.params} />}
                <div className="add_structure_item mt16">
                    <Button
                        icon="form"
                        onClick={this.handleOpenFormModal}
                        className="pull-right"
                        loading={projects.createPrescriptionForCounterPartyLoading || prescriptionIsLoading || reportLoading}>
                        Печатные формы
                    </Button>

                    <Button
                        type="primary"
                        shape="circle"
                        icon="plus"
                        size="large"
                        loading={createLoading}
                        onClick={this.handleCreateNote}
                    />
                </div>
                <Row>
                    <Button icon="check-square" className="pull-right" onClick={this.handleCheckAll}>
                        {notesData.data && Object.keys(selectedNotes).length ? 'Снять все' : 'Выбрать все'}
                    </Button>
                    <Button
                        loading={isNotesChangeStatusLoading}
                        icon="form"
                        style={{ marginRight: 10 }}
                        onClick={this.handleOpenNoteModal}
                        className="pull-right">
                        Перенос замечаний
                    </Button>
                </Row>
                <Modal
                    title="Срок исполнения"
                    visible={this.state.visiblePeriodExecution}
                    onOk={this.handleSetDateDeadline}
                    onCancel={this.handleCancelSetDateDeadline}
                    confirmLoading={setDeadlineIsLoading}
                    cancelText="Отменить"
                    okButtonProps={{ disabled: !this.state.selectedDateDeadline }}>
                    <div style={{ width: 300, border: '1px solid #d9d9d9', borderRadius: 4, margin: 'auto' }}>
                        <Calendar
                            value={moment(this.state.selectedDateDeadline, 'YYYY-MM-DD')}
                            fullscreen={false}
                            disabledDate={value => {
                                return value < today;
                            }}
                            onChange={date => {
                                if (date < today) {
                                    return;
                                }

                                this.setState({
                                    selectedDateDeadline: date.format('YYYY-MM-DD'),
                                });
                            }}
                        />
                    </div>
                </Modal>
                <NotesListFilters
                    history={history}
                    match={match}
                    setParams={this.setParams}
                    data={notesData.data}
                    getDataWithParams={this.getDataWithParams}
                    limit={notesData.data && notesData.data.pagination && notesData.data.pagination.currentOffset}
                    success={notesData.success}
                />
                {notesData.data ? (
                    <div className="note-list">
                        <NotesList
                            link={match.params}
                            data={notesData.data.notes}
                            selectedNotes={selectedNotes}
                            handleToggleCheckNote={this.handleToggleCheckNote}
                        />
                        {notesData.data.notes && notesData.data.pagination.currentOffset !== notesData.data.pagination.totalCount && (
                            <div className="note-list__show-more-wrap">
                                {loading ? (
                                    <Loading />
                                ) : (
                                    <Button
                                        type="primary"
                                        className="note-list__show-more"
                                        onClick={() =>
                                            this.getData(
                                                `offset=${notesData.data.pagination.currentOffset}&limit=${limit}${this.state.params}`,
                                                notesData.data,
                                                false,
                                                false,
                                            )
                                        }>
                                        Показать еще
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                ) : (
                    <Loading />
                )}
                <Modal
                    title="Печатные формы"
                    okText="OK"
                    visible={this.state.showFormModal}
                    onOk={this.handleOkFormModal}
                    onCancel={this.handleCancelFormModal}
                    footer={[
                        <Button key="cancel" onClick={this.handleCancelFormModal}>
                            Отмена
                        </Button>,
                        <Button key="confirm" type="primary" onClick={this.handleOkFormModal}>
                            OK
                        </Button>,
                    ]}>
                    <div className="form-modal">
                        <Button
                            icon="download"
                            type={formModalCheckedButton === 'app' ? 'primary' : 'default'}
                            onClick={() => {
                                this.setState({ formModalCheckedButton: 'app' });
                            }}>
                            Приложения
                        </Button>

                        <Button
                            icon="form"
                            type={formModalCheckedButton === 'prescriptionForCounterparty' ? 'primary' : 'default'}
                            onClick={() => {
                                this.setState({ formModalCheckedButton: 'prescriptionForCounterparty' });
                            }}
                            className="mt16">
                            Предписание для субподрядчика
                        </Button>

                        <Button
                            icon="form"
                            type={formModalCheckedButton === 'prescription' ? 'primary' : 'default'}
                            onClick={() => {
                                this.setState({ formModalCheckedButton: 'prescription' });
                            }}
                            className="mt16">
                            Создать предписание
                        </Button>

                        <Button
                            className="mt16"
                            icon="download"
                            type={formModalCheckedButton === 'note' ? 'primary' : 'default'}
                            onClick={() => {
                                this.setState({ formModalCheckedButton: 'note' });
                            }}>
                            Отчет
                        </Button>

                        <Button
                            className="mt16"
                            icon="download"
                            type={formModalCheckedButton === 'deadline-note' ? 'primary' : 'default'}
                            onClick={() => {
                                this.setState({ formModalCheckedButton: 'deadline-note' });
                            }}>
                            Отчет по перенесенным замечаниям
                        </Button>
                    </div>
                </Modal>

                <Modal
                    title="Перенос замечаний"
                    okText="OK"
                    visible={this.state.showNoteModal}
                    onOk={this.handleOkNoteModal}
                    onCancel={this.handleCancelNoteModal}
                    footer={[
                        <Button key="cancel" onClick={this.handleCancelNoteModal}>
                            Отмена
                        </Button>,
                        <Button key="confirm" type="primary" onClick={this.handleOkNoteModal}>
                            OK
                        </Button>,
                    ]}>
                    <div className="form-modal">
                        <Button
                            icon="calendar"
                            type={noteModalCheckedButton === 'date' ? 'primary' : 'default'}
                            onClick={() => {
                                this.setState({ noteModalCheckedButton: 'date' });
                            }}>
                            Задать срок исполнения
                        </Button>
                        <Button
                            icon="carry-out"
                            type={noteModalCheckedButton === 'done' ? 'primary' : 'default'}
                            onClick={() => {
                                this.setState({ noteModalCheckedButton: 'done' });
                            }}
                            className="mt16">
                            Перевести в проверенные
                        </Button>
                    </div>
                </Modal>

                <NotesFiles
                    data={notesData.data}
                    filters={params}
                    match={match}
                    visible={appModalVisible}
                    onClose={this.handleCloseAppModal}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    notesData: state.projectModule.notes.notesList,
    isNotesChangeStatusLoading: state.projectModule.notes.isNotesChangeStatusLoading,
    projects: state.projectModule.projects,
});

const mapDispatchToProps = dispatch => ({
    getNotes: (projectId, structureId, params, notes, callback) => dispatch(getNotes(projectId, structureId, params, notes, callback)),
    setNotes: data => dispatch(setNotes(data)),
    createNote: (projectId, structureId, data, callback = () => {}) => dispatch(createNote(projectId, structureId, data, callback)),
    createPrescriptionForCounterparty: (projectId, data, callback) =>
        dispatch(createPrescriptionForCounterparty(projectId, data, callback)),
    notesStatusChange: noteIds => dispatch(notesStatusChange(noteIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectNotes);
