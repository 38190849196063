import axios from 'axios';
import * as types from '../../actionTypes';
import { apiConfig } from '../../../services/api/config';
import { message } from 'antd';

export const getStructure = (projectId, structureId) => dispatch => {
    dispatch({ type: types.GET_STRUCTURE_ITEM_LOADING });

    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}project/` + projectId + '/project-structure/' + structureId,
    })
        .then(response => {
            if (response && response.data)
                dispatch({
                    type: types.GET_STRUCTURE_ITEM_SUCCESS,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
        })
        .catch(errors => {
            console.log(errors);
        });
};

export const setStructure = data => dispatch => {
    dispatch({
        type: types.SET_STRUCTURE,
        data: data,
    });
};

export const createStructureItem = (projectId, structureId, title) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}`,
        data: {
            title: title,
        },
    })
        .then(response => {
            // dispatch({
            //     type: types.CREATE_STRUCTURE_ITEM,
            //     success: response.data.success,
            // });
            if (response.data.success) {
                dispatch(getStructure(projectId, structureId));
            }
        })
        .catch(errors => {
            console.log(errors);
        });
};

export const deleteStructureItem = (projectId, currentStructureId, structureId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'DELETE',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}`,
    })
        .then(response => {
            message.info(response.data.data !== null ? response.data.data[0] : '');
            dispatch({
                type: types.SUCCESS_DELETE_STRUCTURE_ITEM,
                // message: response.data.data,
            });
            dispatch(getStructure(projectId, currentStructureId));
            // console.log('RESPONSE', response.data.data);
        })
        .catch(errors => {
            console.log(errors);
        });
};
export const resetDeletedItemStatus = () => dispatch => {
    dispatch({ type: types.RESET_DELETED_ITEM_STATUS });
};
export const restoreStructureItem = (projectId, currentStructureId, structureId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/restore`,
    })
        .then(response => {
            message.info(response.data.data !== null ? response.data.data[0] : '');
            dispatch({
                type: types.SUCCESS_RESTORE_STRUCTURE_ITEM,
            });
            dispatch(getStructure(projectId, currentStructureId));
            // console.log('RESPONSE', response.data.data);
        })
        .catch(errors => {
            console.log(errors);
        });
};

export const resetRestoredItemStatus = () => dispatch => {
    dispatch({ type: types.RESET_RESTORED_ITEM_STATUS });
};

export const editStructureType = (projectId, structureId, type) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/update`,
        data: {
            type,
        },
    })
        .then(response => {
            if (response.data.success) {
                message.info('Успешно сохранено');
            }
        })
        .catch(errors => {
            console.log(errors);
        });
};

export const updateStructureItem =
    (projectId, structureId, title, callback = () => {}) =>
    dispatch => {
        let accessToken = localStorage.getItem('accessToken');
        axios({
            headers: {
                'Access-Token': accessToken,
            },
            method: 'POST',
            url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/update`,
            data: {
                title,
            },
        })
            .then(response => {
                if (response.data.success) {
                    dispatch({
                        type: types.UPDATE_STRUCTURE_ITEM,
                        data: response.data.data,
                        success: response.data.success,
                        errors: response.data.errors,
                    });
                    callback();
                    message.info('Успешно сохранено');
                } else {
                    utils.showError(response.data.errors[0]);
                }
            })
            .catch(errors => {
                console.log(errors);
            });
    };

export const changeStructureItemTitle = data => dispatch => {
    dispatch({
        type: types.CHANGE_STRUCTURE_ITEM_TITLE,
        data: data,
    });
};
