import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Modal, Alert, Collapse } from 'antd';
import { BreadCrumbs } from '../BreadCrumbs';
import {
    getTeam,
    getOrganizations,
    getUsers,
    assignOrganization,
    assignUser,
    deleteOrganization,
    deleteUser,
} from '../../redux/actions/projectModule/getTeam';
import { Loading } from '../Loading';

import './ProjectTeam.scss';

const { Panel } = Collapse;

class ProjectTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOrganizationListVisible: false,
            isUserListVisible: false,
            role: '',
            errorText: null,
            organizationId: null,
            assignedUserIsLoading: false,
            currentOrganizations: [],
            currentOrganizationUsers: [],
            currentAssignedUserId: null,
            errorAssignedOrganization: false,
            currentOrganizationListKey: 0,
        };
    }

    handleOrganizationListOpen = (e, roleName, organizations, key) => {
        e.preventDefault();
        this.setState({
            isOrganizationListVisible: true,
            role: roleName,
            currentOrganizations: organizations,
            currentOrganizationListKey: key,
        });

        this.props.getOrganizations();
    };

    handleUserListOpen = (e, roleName, organizationId, users) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            isUserListVisible: true,
            role: roleName,
            organizationId: organizationId,
            currentOrganizationUsers: users,
        });

        this.props.getUsers(organizationId);
    };

    handleClose = () => {
        const { getTeam, match } = this.props;
        const { projectId, currentStructureId } = match.params;

        this.setState({
            isOrganizationListVisible: false,
            isUserListVisible: false,
            errorText: null,
            currentOrganizationUsers: [],
            errorAssignedOrganization: false,
        });

        getTeam(projectId, currentStructureId);
    };

    handleAssignOrganization = (e, organization) => {
        e.preventDefault();
        const parentSelector = e.currentTarget.closest('.team-modal__item');
        const { assignOrganization, match, assignedOrganization } = this.props;
        const { projectId, currentStructureId } = match.params;
        const { role } = this.state;

        assignOrganization(projectId, currentStructureId, role, organization.id, () => {
            document.getElementById(`collapse-${organization.id}-${role}`).children[0].click();
        });

        // this.setState(state => {
        //     return {
        //         errorAssignedOrganization: true,
        //         currentAssignedOrganizationId: organization.id,
        //         currentOrganizations: [...state.currentOrganizations, ...organization],
        //     };
        // });

        this.setState({
            errorAssignedOrganization: true,
            // currentAssignedOrganizationId: organization.id,
        });

        // setTimeout(() => {
        //     parentSelector.classList.add('assigned');
        // }, 1000);
    };

    filterAssignedItems = (list, id) => {
        return list.filter(item => item.id === id).length > 0;
    };

    handleAssignUser = (e, userId) => {
        e.preventDefault();
        const parentSelector = e.currentTarget.closest('.team-modal-user__item');
        const { assignUser, match } = this.props;
        const { projectId, currentStructureId } = match.params;
        const { role, organizationId } = this.state;

        this.setState({
            assignedUserIsLoading: true,
            errorText: null,
        });

        assignUser(projectId, currentStructureId, role, userId, organizationId)
            .then(response => {
                if (response.data.success) {
                    if (!this.props.assignedUser.success) {
                        this.setState({
                            errorText: this.props.assignedUser.errors[0],
                        });
                    } else {
                        parentSelector.classList.add('assigned');
                        this.setState({
                            errorText: null,
                            currentAssignedUserId: userId,
                        });
                    }
                }

                this.setState({
                    assignedUserIsLoading: false,
                });
            })
            .catch(errors => {
                console.log(errors);
                this.setState({
                    assignedUserIsLoading: false,
                });
            });
    };

    handleEdit = e => {
        e.preventDefault();
        const parentSelector = e.currentTarget.closest('.team-role');

        if (parentSelector.classList.contains('editable')) {
            parentSelector.classList.remove('editable');
        } else {
            parentSelector.classList.add('editable');
        }
    };

    handleOrganizationDelete = (e, role, organizationId) => {
        e.preventDefault();
        e.stopPropagation();
        const { deleteOrganization, match } = this.props;
        const { projectId, currentStructureId } = match.params;

        deleteOrganization(projectId, currentStructureId, role, organizationId);
    };

    handleUserDelete = (e, role, userId) => {
        e.preventDefault();
        const { deleteUser, match } = this.props;
        const { projectId, currentStructureId } = match.params;

        deleteUser(projectId, currentStructureId, role, userId);
    };

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps, prevState) {
        const { currentStructureId } = this.props.match.params;

        if (prevProps.match.params.currentStructureId !== currentStructureId) {
            this.getData();
        }
    }

    getData = () => {
        const { getTeam, match } = this.props;
        const { projectId, currentStructureId } = match.params;

        getTeam(projectId, currentStructureId);
    };

    render() {
        const {
            isOrganizationListVisible,
            isUserListVisible,
            errorText,
            assignedUserIsLoading,
            currentOrganizationUsers,
            currentOrganizations,
            errorAssignedOrganization,
        } = this.state;
        const { team, organizations, users, match, assignedOrganization } = this.props;
        const { data, success } = team;

        if (data && data.id !== +match.params.currentStructureId) {
            return (
                <div className="structure">
                    <Loading />
                </div>
            );
        }

        return (
            <div className="team">
                {data === null || Object.keys(data).length === 0 ? null : <BreadCrumbs prevItems={data} link={match.params} />}

                {success &&
                    (data['children'] || []).map((item, i) => (
                        <div className="team-role" key={`${i}-${item.role.name}`}>
                            <div className="team-role-head">
                                <div className="team-role__title">{item.role.title}</div>
                                <div className="team-role__action">
                                    <a
                                        href=""
                                        className="team-role__btn add"
                                        onClick={e => this.handleOrganizationListOpen(e, item.role.name, item.organizations, i)}>
                                        <Icon type="plus" theme="outlined" />
                                        <span>Добавить</span>
                                    </a>
                                    <span className="divider">|</span>
                                    <a href="" className="team-role__btn delete" onClick={this.handleEdit}>
                                        <Icon type="delete" theme="outlined" />
                                        <span>Удалить</span>
                                    </a>
                                    <a href="" className="team-role__btn green view" onClick={this.handleEdit}>
                                        <Icon type="delete" theme="outlined" />
                                        <span>Вернуться в просмотр</span>
                                    </a>
                                </div>
                            </div>
                            <Collapse
                                bordered={false}
                                expandIconPosition="right"
                                expandIcon={({ isActive }) => <Icon type="down" rotate={isActive ? 180 : 0} />}
                                // activeKey={0}
                                defaultActiveKey={item.organizations.map(org => org.id)}>
                                {/* {console.log(
                                    item.role.title,
                                    item.organizations.map(org => `${org.id}-${item.role.name}`),
                                )} */}
                                {(item.organizations || []).map(org => (
                                    <Panel
                                        id={`collapse-${org.id}-${item.role.name}`}
                                        header={
                                            <div className="team-organization">
                                                <div
                                                    className={
                                                        !org.deleted_at
                                                            ? 'team-organization__title'
                                                            : 'team-organization__title team-organization__title_deleted'
                                                    }>
                                                    {org.title}
                                                </div>
                                                <div className="team-role__action">
                                                    <a
                                                        href=""
                                                        className="team-role__btn assign"
                                                        onClick={e => this.handleUserListOpen(e, item.role.name, org.id, org.users)}>
                                                        <Icon type="user-add" theme="outlined" />
                                                        <span>Назначить</span>
                                                    </a>
                                                    {!org.deleted_at && (
                                                        <a
                                                            href=""
                                                            className="team-role__btn remove"
                                                            onClick={e => this.handleOrganizationDelete(e, item.role.name, org.id)}>
                                                            <Icon type="close" theme="outlined" />
                                                        </a>
                                                    )}
                                                </div>
                                            </div>
                                        }
                                        key={org.id}
                                        // showArrow={org.users && org.users.length > 0}
                                        // disabled={!(org.users && org.users.length > 0)}
                                    >
                                        <ul className="team-user">
                                            {(org.users || []).map(user => (
                                                <li className="team-user__item" key={user.id}>
                                                    <span className="team-user__img">
                                                        <Icon type="user" theme="outlined" />
                                                    </span>
                                                    <span>
                                                        <span
                                                            className={
                                                                !user.deleted_at
                                                                    ? 'team-user__name'
                                                                    : 'team-user__name team-user__name_deleted'
                                                            }>
                                                            {user.fullname}
                                                        </span>
                                                        <br />
                                                        <small>
                                                            {user.position} {user.position ? '/' : null} {user.sector}
                                                        </small>
                                                    </span>
                                                    <a href="" className="team-user__phone">
                                                        <Icon type="phone" theme="outlined" />
                                                    </a>
                                                    {!user.deleted_at && (
                                                        <a
                                                            href=""
                                                            className="team-user__remove"
                                                            onClick={e => this.handleUserDelete(e, item.role.name, user.id)}>
                                                            <Icon type="close" theme="outlined" />
                                                        </a>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </Panel>
                                ))}
                            </Collapse>
                        </div>
                    ))}

                <Modal
                    title="Назначить организацию"
                    visible={isOrganizationListVisible}
                    onCancel={this.handleClose}
                    footer={null}
                    className={assignedOrganization.loading ? 'team-modal--loading' : ''}>
                    {assignedOrganization.errors && errorAssignedOrganization && assignedOrganization.errors.length > 0 && (
                        <Alert message={assignedOrganization.errors[0]} type="error" />
                    )}
                    <div className="team__loading">{assignedOrganization.loading && <Loading />}</div>
                    <ul className="team-modal-list">
                        {isOrganizationListVisible &&
                            (organizations || []).map(item => (
                                <li key={item.id}>
                                    <a
                                        href=""
                                        onClick={e => this.handleAssignOrganization(e, item)}
                                        className={
                                            this.filterAssignedItems(
                                                data.children[this.state.currentOrganizationListKey].organizations || [],
                                                item.id,
                                            )
                                                ? 'team-modal__item assigned'
                                                : 'team-modal__item'
                                        }>
                                        {item.title}
                                    </a>
                                </li>
                            ))}
                    </ul>
                </Modal>

                <Modal
                    title="Назначить участников"
                    visible={isUserListVisible}
                    onCancel={this.handleClose}
                    footer={null}
                    className={assignedUserIsLoading ? 'team-modal--loading' : ''}>
                    {errorText && <Alert message={errorText} type="error" />}
                    <div className="team__loading">{assignedUserIsLoading && <Loading />}</div>
                    <ul className="team-modal-user">
                        {isUserListVisible &&
                            (users || []).map(user => (
                                <li key={user.id}>
                                    <a
                                        href=""
                                        className={
                                            this.filterAssignedItems(currentOrganizationUsers, user.id)
                                                ? 'team-modal-user__item assigned'
                                                : 'team-modal-user__item'
                                        }
                                        onClick={e => this.handleAssignUser(e, user.id)}>
                                        <span className="team-user__img">
                                            <Icon type="user" theme="outlined" />
                                        </span>
                                        <span>{user.fullname}</span>
                                        <span className="team-user__check">
                                            <Icon type={'check'} theme="outlined" />
                                        </span>
                                        <span className="team-user__add">
                                            <Icon type={'user-add'} theme="outlined" />
                                        </span>
                                    </a>
                                </li>
                            ))}
                    </ul>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        team: state.projectModule.team.teamList,
        organizations: state.projectModule.team.organizations.data,
        users: state.projectModule.team.users.data,
        projects: state.projectModule.projects,
        assignedUser: state.projectModule.team.assignedUser,
        assignedOrganization: state.projectModule.team.assignedOrganization,
        deletedOrganization: state.projectModule.team.deletedOrganization,
        deletedUser: state.projectModule.team.deletedUser,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getTeam: function (projectId, structureId) {
            return dispatch(getTeam(projectId, structureId));
        },
        getOrganizations: function () {
            return dispatch(getOrganizations());
        },
        getUsers: function (organizationId) {
            return dispatch(getUsers(organizationId));
        },
        assignOrganization: function (projectId, structureId, role, organizationId, callback) {
            return dispatch(assignOrganization(projectId, structureId, role, organizationId, callback));
        },
        assignUser: function (projectId, structureId, role, userId, organizationId) {
            return dispatch(assignUser(projectId, structureId, role, userId, organizationId));
        },
        deleteOrganization: function (projectId, structureId, role, organizationId) {
            return dispatch(deleteOrganization(projectId, structureId, role, organizationId));
        },
        deleteUser: function (projectId, structureId, role, userId) {
            return dispatch(deleteUser(projectId, structureId, role, userId));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTeam);
