import axios from 'axios';
import * as types from '../../actionTypes';
import { apiConfig } from '../../../services/api/config';
import { message } from 'antd';
import utils from '../../../helpers/utils';

export const getDocuments = (projectId, structureId, id) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');

    if (id) {
        dispatch({
            type: types.GET_DOCUMENTS_LOADING,
            loading: true,
        });
        axios({
            headers: {
                'Access-Token': accessToken,
            },
            method: 'GET',
            url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/project-document/${id}`,
        })
            .then(response => {
                // console.log('RESPONS GET DOCS', response);
                dispatch({
                    type: types.GET_DOCUMENTS,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
                if (!response.data.success) {
                    utils.showError(response.data.errors[0]);
                }
                dispatch({
                    type: types.GET_DOCUMENTS_LOADING,
                    loading: false,
                });
            })
            .catch(errors => {
                console.log(errors);
            });
    }
};

export const createFolder = (projectId, structureId, rootStructureId, documentId, folderName) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}project/${projectId}/project-structure/${
            structureId ? structureId : rootStructureId
        }/project-document/${documentId}/create-folder`,
        data: {
            title: folderName,
        },
    })
        .then(response => {
            dispatch({
                type: types.CREATE_FOLDER,
                data: response.data.data,
            });
            if (response.data.success) {
                dispatch(getDocuments(projectId, structureId, documentId));
            }
        })
        .catch(errors => {
            console.log(errors);
        });
};

export const setCurrentDoc = data => dispatch => {
    dispatch({
        type: types.SET_CURRENT_DOC,
        data: data,
    });
};

//UPLOAD
export const uploadFile = (projectId, structureId, rootStructureId, documentId, file, fileName) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    const formData = new FormData();
    formData.append('uploadFile', file);
    formData.append('fileName', fileName);
    axios({
        headers: {
            'Access-Token': accessToken,
            'content-type': 'multipart/form-data',
        },
        method: 'POST',
        url: `${apiConfig.url}project/${projectId}/project-structure/${
            structureId ? structureId : rootStructureId
        }/project-document/${documentId}/upload-file`,
        data: formData,
    })
        .then(response => {
            dispatch({
                type: types.UPLOAD_FILE,
                data: response.data.data,
            });
            if (response.data.success) {
                dispatch(getDocuments(projectId, structureId, documentId));
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors);
        });
};

//END__UPLOAD
export const resetCreateStatus = () => dispatch => {
    dispatch({ type: types.RESET_CREATE_STATUS });
};

export const resetUploadStatus = () => dispatch => {
    dispatch({ type: types.RESET_UPLOAD_STATUS });
};
//DELETE DOCUMENT
export const deleteDocument = (projectId, structureId, sectionId, documentId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'DELETE',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/project-document/${documentId}`,
    })
        .then(response => {
            message.info(response.data.data !== null ? response.data.data[0] : '');

            dispatch({
                type: types.SUCCESS_DELETE_DOCUMENT,
            });
            dispatch(getDocuments(projectId, structureId, sectionId));
        })
        .catch(errors => {
            console.log(errors);
        });
};
export const resetDeleteDocStatus = () => dispatch => {
    dispatch({
        type: types.RESET_DELETED_DOC_STATUS,
    });
};
export const restoreDocument = (projectId, structureId, sectionId, documentId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'PUT',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/project-document/${documentId}/restore`,
    })
        .then(response => {
            message.info(response.data.data !== null ? response.data.data[0] : '');

            dispatch({
                type: types.SUCCESS_RESTORE_DOCUMENT,
            });
            dispatch(getDocuments(projectId, structureId, sectionId));
        })
        .catch(errors => {
            console.log(errors);
        });
};
export const resetRestoredDocStatus = () => dispatch => {
    dispatch({
        type: types.RESET_RESTORED_DOC_STATUS,
    });
};
