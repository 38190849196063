import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Button } from 'antd';

class FavoriteStructureList extends React.Component {
    renderItems = data => {
        return data.map(item =>
            item.is_favorite ? (
                <div key={item.id} className="flexRow">
                    <Link
                        style={{ width: '100%' }}
                        className={`${item.deleted_at === null ? 'structure__link flexRow' : 'structure__link flexRowOpacity'}`}
                        // to={`/projects/0/section/${item.id}`}
                        to={`/projects/${item.project_id}/structure/${item.id}`}
                        onClick={this.handleSearchReset}>
                        <li className="structure__item">
                            <div className="structure__data">
                                <div className="structure__description">
                                    <div className="structure__title">
                                        {item.title}
                                        {item.hasDocument && (
                                            <div>
                                                <span className="note-item__icon" title="Прикреплены документы">
                                                    <Icon type="file" theme="outlined" />
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="structure__dates">{item.date}</div>
                                </div>
                                <ul className="structure__labels">
                                    <li className="structure__label danger">{item.notes_cnt_danger}</li>
                                    <li className="structure__label warning">{item.notes_cnt_warning}</li>
                                    <li className="structure__label success">{item.notes_cnt_success}</li>
                                </ul>
                                <div className="structure__arrow">
                                    <Icon type="right" theme="outlined" style={{ color: '#666666' }} />
                                </div>
                            </div>
                        </li>
                    </Link>
                    <Button
                        title={'Избранное'}
                        type={'link'}
                        onClick={() => this.props.handleFavorite('structure', item.id, item.is_favorite)}
                        className={item.is_favorite ? 'button-favorite button-favorite_active' : 'button-favorite'}>
                        <Icon type="star" theme="filled" />
                    </Button>
                </div>
            ) : null,
        );
    };

    render() {
        const { data } = this.props;

        if (data) {
            return this.renderItems(data);
        }

        return null;
    }
}

export default FavoriteStructureList;
