import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/ru';
import { Button, Icon, Modal, Empty } from 'antd';
import FileSaver from 'file-saver';

import { apiConfig } from '../../../services/api/config';

import '../ProjectNotes.scss';
import { getNotesFiles } from '../../../redux/actions/projectModule';
import { Loading } from '../../Loading';

class NotesFiles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            limit: 10,
        };
    }

    getData = (params, files) => {
        const { getNotesFiles } = this.props;
        const { projectId = 0, currentStructureId = 0 } = this.props.match.params;

        getNotesFiles(projectId, currentStructureId, params, files);
    };

    downloadFile = (e, hash, id, fileName) => {
        e.preventDefault();

        let accessToken = localStorage.getItem('accessToken');

        axios({
            headers: {
                'Access-Token': accessToken,
            },
            responseType: 'blob',
            method: 'GET',
            url: `${apiConfig.url}file/${hash}/${id}`,
        })
            .then(response => {
                var blob = new Blob([response.data]);
                FileSaver.saveAs(blob, fileName, { type: response.headers['content-type'] });
            })
            .catch(errors => {
                console.log(errors.response);
            });
    };

    renderFiles = () => {
        const { data, notesFiles, filters } = this.props;
        let files = [];

        if (!notesFiles.data) {
            if (notesFiles.loading) {
                return (
                    <div className="note-list__show-more-wrap">
                        <Loading />
                    </div>
                );
            }

            return null;
        }

        if (notesFiles.data.files)
            notesFiles.data.files.forEach((item, i) => {
                files.push(
                    <div key={`${i}-${item.id}-${item.file.id}`} className="note-file note-file_s1 note-file_download">
                        <div className="note-file__parent-title">
                            <b>{item.note.id}</b> {item.note.title}
                        </div>
                        <a href={item.file.full_path} download target="_blank" className="note-file__info">
                            <img src={item.file.thumb} alt={item.file.title} className="note-file__img" />
                            <p className="note-file__name">{item.file.title}</p>
                            <p className="secondary-info">{moment(item.file.created_at).format('DD.MM.YYYY, HH:mm:ss')}</p>
                            <p className="secondary-info">{item.file.user.fullname}</p>
                        </a>
                        {/* <a href={item.file.full_path} download target="_blank" className="note-file__download">
                            <Icon type="download" theme="outlined" />
                        </a> */}
                        <a
                            href=""
                            onClick={e => this.downloadFile(e, item.file.hash, item.file.id, item.file.title)}
                            className="note-file__download">
                            <Icon type="download" theme="outlined" />
                        </a>
                    </div>,
                );
            });

        return (
            <>
                {files.length ? files : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Данных нет" />}
                {notesFiles.data.files && notesFiles.data.pagination.currentOffset !== notesFiles.data.pagination.totalCount && (
                    <div className="note-list__show-more-wrap">
                        {notesFiles.loading ? (
                            <Loading />
                        ) : (
                            <Button
                                type="primary"
                                className="note-list__show-more"
                                onClick={() =>
                                    this.getData(
                                        `offset=${notesFiles.data.pagination.currentOffset}&limit=${this.state.limit}${filters}`,
                                        notesFiles.data,
                                        false,
                                        false,
                                    )
                                }>
                                Показать еще
                            </Button>
                        )}
                    </div>
                )}
            </>
        );
    };

    componentDidUpdate(prevProps) {
        if (!prevProps.visible && this.props.visible) {
            const { filters } = this.props;

            this.getData(`offset=0&limit=${this.state.limit}${filters}`, null);
        }
    }

    render() {
        const { data, visible, onClose } = this.props;

        return data ? (
            <div>
                <Modal title="Приложения" visible={visible} onOk={onClose} onCancel={onClose} footer={null}>
                    <div className="modal-list modal-filter__content">{visible && this.renderFiles()}</div>
                </Modal>
            </div>
        ) : null;
    }
}

const mapStateToProps = state => ({
    notesFiles: state.projectModule.notes.notesFiles,
});

const mapDispatchToProps = dispatch => ({
    getNotesFiles: (projectId, structureId, params, files) => dispatch(getNotesFiles(projectId, structureId, params, files)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesFiles);
