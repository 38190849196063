import React from 'react';
import 'moment/locale/ru';
import { Button, Icon, Modal } from 'antd';

import FilterListObject from './FilterListObject';

const FiltersModal = props => {
    const {
        // Handlers
        filterChoose,
        filterListBack,
        handleFilterApply,
        handleFilterClose,
        // Variables
        filterModalVisible,
        currentListKey,
    } = props;

    return (
        <Modal
            title={
                <>
                    {currentListKey !== 'filterList' && (
                        <a href="" className="modal__back" onClick={filterListBack}>
                            <Icon type="left" />
                        </a>
                    )}
                    <span>Выберите тип фильтра</span>
                </>
            }
            visible={filterModalVisible}
            centered
            footer={
                currentListKey === 'filterList' ? (
                    <Button type="primary" onClick={() => handleFilterApply(true, false)}>
                        Применить
                    </Button>
                ) : (
                    <Button onClick={() => filterChoose(currentListKey)}>Выбрать</Button>
                )
            }
            onCancel={handleFilterClose}>
            <FilterListObject {...props} />
        </Modal>
    );
};

export default FiltersModal;
