import React, { Component } from 'react';
import { Routes } from '../routing';

import 'antd/dist/antd.css';
import './App.scss';

class App extends Component {
    render() {
        return <Routes />;
    }
}

export default App;
