import { apiConfig } from './config.js';

export const api = {
    projectsModule: {
        getProjects: {
            method: 'GET',
            url: `${apiConfig.url}project`,
        },
    },
};
