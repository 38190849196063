import React from 'react';
import { Rect } from 'react-konva';
import { Drawable } from './Drawable';

export default class RectangleDrawable extends Drawable {
    constructor(startx, starty, type, id) {
        super(startx, starty, type, id);
        this.x = startx;
        this.y = starty;
    }

    render(i) {
        return (
            <Rect
                key={`rect-${i}`}
                x={this.startx}
                y={this.starty}
                width={this.x - this.startx}
                height={this.y - this.starty}
                stroke="#f00000"
            />
        );
    }
}
