import axios from 'axios';
import * as types from '../../actionTypes';
import { apiConfig } from '../../../services/api/config';
import { message } from 'antd';

export const getSettings = () => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}user-setting`,
    })
        .then(response => {
            dispatch({
                type: types.GET_SETTINGS,
                data: response.data.data,
                success: response.data.success,
                errors: response.data.errors,
            });
        })
        .catch(errors => {
            console.log(errors);
        });
};
export const updateSetting = data => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}user-setting/update`,
        data: data,
    })
        .then(response => {
            response.data.errors !== null
                ? message.info('Ошибка сохранения')
                : dispatch({
                      type: types.GET_SETTINGS,
                      data: response.data.data,
                      success: response.data.success,
                      errors: response.data.errors,
                  });
        })
        .catch(errors => {
            console.log(errors);
        });
};
