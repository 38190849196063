import { GET_ANALYTICS, GET_ANALYTICS_LOADING, RESET_ANALYTICS } from '../../actionTypes';

const initialState = {
    data: {},
    success: false,
    errors: [],
    loading: false,
};

export const getAnalytics = (state = initialState, action) => {
    switch (action.type) {
        case GET_ANALYTICS:
            return {
                ...state,
                data: action.data,
                success: action.success,
                errors: action.errors,
                loading: false,
            };
        case GET_ANALYTICS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case RESET_ANALYTICS:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
};
