import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Form, Icon, Input, Button } from 'antd';

import { updateUserSuccess, updateUserError, updateUserLoading } from '../../redux/actions/common';
import { apiConfig } from '../../services/api/config';

import logo from '../../assets/logo1.png';
import { Loading } from '../../components/Loading';

import './SignIn.scss';

function hasErrors(fieldError) {
    return Object.keys(fieldError).some(field => fieldError[field]);
}

class SignInForm extends Component {
    constructor(props) {
        super(props);
        this.errorsElem = React.createRef();
    }

    componentDidMount() {
        this.loginInput.focus();
        this.props.form.validateFields();
    }

    handleSubmit = e => {
        e.preventDefault();
        const { updateUserLoading, updateUserSuccess, updateUserError, form } = this.props;

        updateUserLoading();

        form.validateFields((err, values) => {
            if (!err) {
                let formData = {
                    login: values.username.trim(),
                    password: values.password,
                    type: 'web',
                };

                axios({
                    method: 'POST',
                    url: `${apiConfig.url}auth`,
                    data: formData,
                })
                    .then(response => {
                        const errors = response.data.errors;

                        if (errors !== null) {
                            updateUserError(errors);
                            if (errors.length > 1) {
                                this.errorsElem.current.innerHTML = errors[1];
                            } else {
                                this.errorsElem.current.innerHTML = errors[0];
                            }
                        } else {
                            const accessToken = response.data.data.accessToken;
                            const currentUser = response.data.data;
                            localStorage.setItem('accessToken', accessToken);
                            localStorage.setItem('currentUser', JSON.stringify(currentUser));
                            updateUserSuccess(currentUser);
                            this.props.history.push('/projects');
                        }
                    })
                    .catch(error => {
                        updateUserError(error);
                    });
            }
        });
    };

    render() {
        const { user, form } = this.props;
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = form;
        const usernameError = isFieldTouched('username') && getFieldError('username');
        const passwordError = isFieldTouched('password') && getFieldError('password');

        return (
            <div className={user.loading ? 'page-loading' : ''}>
                {user.loading && <Loading />}
                <section className="signInWrap">
                    <div className="signIn">
                        <div className="signIn__logo">
                            <img src={logo} alt="cds" />
                        </div>
                        <div className="signIn__form">
                            <Form layout="vertical" onSubmit={this.handleSubmit}>
                                <div className="signIn__errors" ref={this.errorsElem} />

                                <Form.Item
                                    validateStatus={usernameError ? 'error' : ''}
                                    help={usernameError || ''}
                                    style={{ padding: 0, marginBottom: 20 }}>
                                    {getFieldDecorator('username', {
                                        rules: [{ required: true, validator: this.checkField }],
                                    })(
                                        <Input
                                            size="large"
                                            prefix={<Icon type="error" style={{ color: 'rgba(0,0,0,0.25' }} />}
                                            placeholder="Username"
                                            ref={input => {
                                                this.loginInput = input;
                                            }}
                                        />,
                                    )}
                                </Form.Item>

                                <Form.Item
                                    validateStatus={passwordError ? 'error' : ''}
                                    help={passwordError || ''}
                                    style={{ padding: 0, marginBottom: 20 }}>
                                    {getFieldDecorator('password', {
                                        rules: [{ required: true, validator: this.checkField }],
                                    })(
                                        <Input
                                            size="large"
                                            prefix={<Icon type="error" style={{ color: 'rgba(0,0,0,0.25' }} />}
                                            type="password"
                                            placeholder="Password"
                                        />,
                                    )}
                                </Form.Item>

                                <Form.Item style={{ padding: 0, marginBottom: 0 }}>
                                    <Button type="primary" size="large" htmlType="submit" disabled={hasErrors(getFieldsError())}>
                                        Войти
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const SignIn = Form.create({ name: 'horizontal_login' })(SignInForm);

const mapStateToProps = state => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => ({
    updateUserSuccess: data => dispatch(updateUserSuccess(data)),
    updateUserLoading: () => dispatch(updateUserLoading()),
    updateUserError: data => dispatch(updateUserError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
