import React from 'react';
import { Rect } from 'react-konva';
import { Drawable } from './Drawable';

class SelectDrawable extends Drawable {
    constructor(startx, starty, type) {
        super(startx, starty, type);
        this.x = startx;
        this.y = starty;
    }

    render(i) {
        return (
            <Rect
                key={`rect-${i}`}
                x={this.startx}
                y={this.starty}
                width={this.x - this.startx}
                height={this.y - this.starty}
                stroke="#404040"
                fill="#404040"
                opacity={0.5}
            />
        );
    }
}

export default SelectDrawable;
