import { GET_PERMISSION } from '../../actionTypes';

const initialState = {
    data: {},
    errors: null,
    success: false,
};

export const permission = (state = initialState, action) => {
    switch (action.type) {
        case GET_PERMISSION:
            return {
                data: action.data.data,
                errors: action.data.errors,
                success: action.data.success,
            };
        default:
            return state;
    }
};
