import React, { Component } from 'react';

import './TempError.scss';

class TempError extends Component {
    render() {
        return <>TempError</>;
    }
}

export default TempError;
