import React from 'react';
import { Icon, List } from 'antd';

import './Help.scss';

class Help extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [
                { title: '+7 812 676 06 99', icon: 'phone' },
                { title: '+7 921 998 08 58', icon: 'phone' },
                { title: 'support@cds.spb.ru', icon: 'mail' },
            ],
        };
    }

    render() {
        const { data } = this.state;

        return (
            <>
                <div className="mt16 ml32">
                    <h1>Сопровождение</h1>
                    <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={item => (
                            <List.Item>
                                <div className="iconContainer">
                                    <Icon style={{ fontSize: 28, color: '#4AACE9' }} type={item.icon} />
                                </div>
                                <span className="bold14 ml16">{item.title}</span>
                            </List.Item>
                        )}
                    />
                </div>
            </>
        );
    }
}

export default Help;
