import { DatePicker, Icon, TimePicker } from 'antd';
import moment from 'moment';
import 'moment/locale/ru';
import React, { useRef } from 'react';

import { Loading } from '../../../Loading';
import '../../ProjectNotes.scss';

const FilterListObject = props => {
    const {
        // Handlers
        handleFilterListOpen,
        // Setters
        setIsReopenFilter,
        setOrganizationFilter,
        setDate,
        setTime,
        setRetentionFilter,
        setExecutorTypeFilter,
        setCounterpartyFilter,
        setDictionaryFilter,
        setAuthorFilter,
        setAuditorFilter,
        setExecutorFilter,
        // Variables
        filterTitles,
        selectedOrganization,
        selectedExecutor,
        selectedDictionaries,
        selectedIsReopen,
        selectedAuthor,
        selectedRetention,
        selectedCounterparty,
        currentListKey,
        selectedExecutorType,
        selectedAuditor,
        dateFilter,
        pastDateFilter,
        organizations, // this.props.organizations
        dictionary, // this.props.dictionary
        test,
        authors,
        user,
        executors,
        auditors,
    } = props;

    const weekpicker = useRef(null);

    // Renders
    const renderCreatedAtBegin = () => {
        let defaultDate = moment(new Date(), 'YYYY-MM-DD'),
            defaultTime = moment('12:00:00', 'HH:mm:ss');

        if (dateFilter.type === 'singleDate') {
            if (dateFilter.begin) {
                let parseDate = dateFilter.begin.split(' ');
                defaultDate = moment(parseDate[0], 'YYYY-MM-DD');
                defaultTime = moment(parseDate[1], 'HH:mm:ss');
            }
        }

        return (
            <div className="calendar-picker">
                <DatePicker
                    placeholder="Выберите дату"
                    onChange={setDate}
                    onOk={value => console.log(value)}
                    className="calendar-picker__field-calendar"
                    size="large"
                    defaultValue={defaultDate}
                    format="YYYY-MM-DD"
                    showToday={false}
                    allowClear={false}
                />
                <TimePicker
                    defaultValue={defaultTime}
                    onChange={setTime}
                    className="calendar-picker__field-time"
                    size="large"
                    allowClear={false}
                />
            </div>
        );
    };

    const renderFiltersList = () => {
        return (
            <ul className="modal-list modal-filter__content">
                <li>
                    <a
                        href=""
                        className={dateFilter.type ? 'modal__item checked' : 'modal__item'}
                        // onClick={e => handleFilterListOpen(e, 1)}>
                        onClick={e => handleFilterListOpen(e, 'dateFilterList')}>
                        <Icon type="clock-circle" className="filter-check__icon" />
                        <span>Время</span>
                        <Icon type="right" />
                    </a>
                </li>
                <li>
                    <a
                        href=""
                        className={pastDateFilter.type ? 'modal__item checked' : 'modal__item'}
                        onClick={e => handleFilterListOpen(e, 'pastDueFilterList')}>
                        <Icon type="clock-circle" className="filter-check__icon" />
                        <span>Просроченные</span>
                        <Icon type="right" />
                    </a>
                </li>
                <li>
                    <a
                        href=""
                        className={selectedOrganization ? 'modal__item checked' : 'modal__item'}
                        onClick={e => handleFilterListOpen(e, 'organiztionFilterList')}>
                        <Icon type="home" className="filter-check__icon" />
                        <span>Организация</span>
                        <Icon type="right" />
                    </a>
                </li>
                <li>
                    <a
                        href=""
                        className={selectedAuthor.length ? 'modal__item checked' : 'modal__item'}
                        onClick={e => handleFilterListOpen(e, 'authorFilter')}>
                        <Icon type="check" className="filter-check__icon" />
                        <span>Автор</span>
                        <Icon type="right" />
                    </a>
                </li>
                <li>
                    <a
                        href=""
                        className={selectedExecutor.length ? 'modal__item checked' : 'modal__item'}
                        onClick={e => handleFilterListOpen(e, 'executorFilter')}>
                        <Icon type="check" className="filter-check__icon" />
                        <span>Исполнитель</span>
                        <Icon type="right" />
                    </a>
                </li>
                <li>
                    <a
                        href=""
                        className={selectedDictionaries.length > 0 ? 'modal__item checked' : 'modal__item'}
                        onClick={e => handleFilterListOpen(e, 'dictionaryFilter')}>
                        <Icon type="check" className="filter-check__icon" />
                        <span>Категории</span>
                        <Icon type="right" />
                    </a>
                </li>
                <li>
                    <a
                        href=""
                        className={selectedRetention ? 'modal__item checked' : 'modal__item'}
                        onClick={e => handleFilterListOpen(e, 'retentionFilter')}>
                        <Icon type="check" className="filter-check__icon" />
                        <span>Статус работ</span>
                        <Icon type="right" />
                    </a>
                </li>
                <li>
                    <a
                        href=""
                        className={selectedCounterparty ? 'modal__item checked' : 'modal__item'}
                        onClick={e => handleFilterListOpen(e, 'counterpartyFilter')}>
                        <Icon type="check" className="filter-check__icon" />
                        <span>Контрагент</span>
                        <Icon type="right" />
                    </a>
                </li>
                <li>
                    <a
                        href=""
                        className={selectedExecutorType ? 'modal__item checked' : 'modal__item'}
                        onClick={e => handleFilterListOpen(e, 'executorTypeFilter')}>
                        <Icon type="check" className="filter-check__icon" />
                        <span>Тип Исполнителя</span>
                        <Icon type="right" />
                    </a>
                </li>
                <li>
                    <a
                        href=""
                        className={selectedAuditor ? 'modal__item checked' : 'modal__item'}
                        onClick={e => handleFilterListOpen(e, 'auditorFilter')}>
                        <Icon type="check" className="filter-check__icon" />
                        <span>Требует Внимания</span>
                        <Icon type="right" />
                    </a>
                </li>
                <li>
                    <a href="" className={selectedIsReopen ? 'modal__item checked' : 'modal__item'} onClick={setIsReopenFilter}>
                        <Icon type="check" className="filter-check__icon" />
                        <span>Повторно открытые</span>
                    </a>
                </li>
            </ul>
        );
    };

    const renderDateFilterList = () => {
        return (
            <ul className="modal-list modal-filter__content">
                <li>
                    <a
                        href=""
                        className={dateFilter.type === 'singleDate' && dateFilter.begin ? 'modal__item checked' : 'modal__item'}
                        // onClick={e => handleFilterListOpen(e, 5)}
                        onClick={e => handleFilterListOpen(e, 'createAtBegin')}>
                        <Icon type="check" />
                        <span>{filterTitles.sinceCreateDate}</span>
                        <Icon type="right" />
                    </a>
                </li>
                <li>
                    <a
                        href=""
                        className={dateFilter.type === 'singleDate' && dateFilter.end ? 'modal__item checked' : 'modal__item'}
                        // onClick={e => handleFilterListOpen(e, 6)}
                        onClick={e => handleFilterListOpen(e, 'createAtEnd')}>
                        <Icon type="check" />
                        <span>{filterTitles.byCreateDate}</span>
                        <Icon type="right" />
                    </a>
                </li>
                {/* Start */}
                <li>
                    <a
                        href=""
                        className={
                            dateFilter.type === 'singleDate' && dateFilter.startDateExecution ? 'modal__item checked' : 'modal__item'
                        }
                        onClick={e => handleFilterListOpen(e, 'startDateExecution')}>
                        <Icon type="check" />
                        <span>{filterTitles.startDateExecution}</span>
                        <Icon type="right" />
                    </a>
                </li>
                <li>
                    <a
                        href=""
                        className={dateFilter.type === 'singleDate' && dateFilter.endDateExecution ? 'modal__item checked' : 'modal__item'}
                        onClick={e => handleFilterListOpen(e, 'endDateExecution')}>
                        <Icon type="check" />
                        <span>{filterTitles.endDateExecution}</span>
                        <Icon type="right" />
                    </a>
                </li>
                <li>
                    <a
                        href=""
                        className={dateFilter.type === 'singleDate' && dateFilter.startCheckDate ? 'modal__item checked' : 'modal__item'}
                        onClick={e => handleFilterListOpen(e, 'startCheckDate')}>
                        <Icon type="check" />
                        <span>{filterTitles.startCheckDate}</span>
                        <Icon type="right" />
                    </a>
                </li>
                <li>
                    <a
                        href=""
                        className={dateFilter.type === 'singleDate' && dateFilter.endCheckDate ? 'modal__item checked' : 'modal__item'}
                        onClick={e => handleFilterListOpen(e, 'endCheckDate')}>
                        <Icon type="check" />
                        <span>{filterTitles.endCheckDate}</span>
                        <Icon type="right" />
                    </a>
                </li>
                <li>
                    <a
                        href=""
                        className={dateFilter.type === 'singleDate' && dateFilter.startCompleteDate ? 'modal__item checked' : 'modal__item'}
                        onClick={e => handleFilterListOpen(e, 'startCompleteDate')}>
                        <Icon type="check" />
                        <span>{filterTitles.startCompleteDate}</span>
                        <Icon type="right" />
                    </a>
                </li>
                <li>
                    <a
                        href=""
                        className={dateFilter.type === 'singleDate' && dateFilter.endCompleteDate ? 'modal__item checked' : 'modal__item'}
                        onClick={e => handleFilterListOpen(e, 'endCompleteDate')}>
                        <Icon type="check" />
                        <span>{filterTitles.endCompleteDate}</span>
                        <Icon type="right" />
                    </a>
                </li>
                {/* End */}
                <li>
                    <a
                        href=""
                        className={dateFilter.type === 'day' ? 'modal__item checked' : 'modal__item'}
                        // onClick={e => handleFilterListOpen(e, 7)}
                        onClick={e => handleFilterListOpen(e, 'day')}>
                        <Icon type="check" />
                        <span>{filterTitles.day}</span>
                        <Icon type="right" />
                    </a>
                </li>
                {/* <li>
            <a
                href=""
                className={dateFilter.type === 'week' ? 'modal__item checked' : 'modal__item'}
                // onClick={e => handleFilterListOpen(e, 8)}
                onClick={e => handleFilterListOpen(e, 'week')}>
                <Icon type="check" />
                <span>{filterTitles.week}</span>
                <Icon type="right" />
            </a>
          </li> */}

                <li>
                    <a
                        href=""
                        className={dateFilter.type === 'month' ? 'modal__item checked' : 'modal__item'}
                        // onClick={e => handleFilterListOpen(e, 9)}
                        onClick={e => handleFilterListOpen(e, 'month')}>
                        <Icon type="check" />
                        <span>{filterTitles.month}</span>
                        <Icon type="right" />
                    </a>
                </li>
            </ul>
        );
    };

    const renderCreatedAtEnd = () => {
        let defaultDate = moment(new Date(), 'YYYY-MM-DD'),
            defaultTime = moment('12:00:00', 'HH:mm:ss');

        if (dateFilter.type === 'singleDate') {
            if (dateFilter.end) {
                let parseDate = dateFilter.end.split(' ');
                defaultDate = moment(parseDate[0], 'YYYY-MM-DD');
                defaultTime = moment(parseDate[1], 'HH:mm:ss');
            }
        }

        return (
            <div className="calendar-picker">
                <DatePicker
                    placeholder="Выберите дату"
                    onChange={setDate}
                    onOk={value => console.log(value)}
                    className="calendar-picker__field-calendar"
                    size="large"
                    defaultValue={defaultDate}
                    format="YYYY-MM-DD"
                    showToday={false}
                    allowClear={false}
                />
                <TimePicker
                    defaultValue={defaultTime}
                    onChange={setTime}
                    className="calendar-picker__field-time"
                    size="large"
                    allowClear={false}
                />
            </div>
        );
    };

    const renderChangedAtBegin = () => {
        let defaultDate = moment(new Date(), 'DD-MM-YYYY');

        if (dateFilter.type === 'singleDate') {
            if (dateFilter.startDateExecution) {
                let parseDate = dateFilter.startDateExecution.split(' ');
                defaultDate = moment(parseDate[0], 'YYYY-MM-DD');
            }
        }

        return (
            <div className="calendar-picker">
                <DatePicker
                    placeholder="Выберите дату"
                    onChange={setDate}
                    onOk={value => console.log(value)}
                    className="calendar-picker__field-calendar"
                    size="large"
                    defaultValue={defaultDate}
                    format="YYYY-MM-DD"
                    showToday={false}
                    allowClear={false}
                />
            </div>
        );
    };

    const renderChangedAtEnd = () => {
        let defaultDate = moment(new Date(), 'DD-MM-YYYY');

        if (dateFilter.type === 'singleDate') {
            if (dateFilter.endDateExecution) {
                let parseDate = dateFilter.endDateExecution.split(' ');
                defaultDate = moment(parseDate[0], 'YYYY-MM-DD');
            }
        }

        return (
            <div className="calendar-picker">
                <DatePicker
                    placeholder="Выберите дату"
                    onChange={setDate}
                    onOk={value => console.log(value)}
                    className="calendar-picker__field-calendar"
                    size="large"
                    defaultValue={defaultDate}
                    format="YYYY-MM-DD"
                    showToday={false}
                    allowClear={false}
                />
            </div>
        );
    };

    const renderCheckAtBegin = () => {
        let defaultDate = moment(new Date(), 'DD-MM-YYYY');

        if (dateFilter.type === 'singleDate') {
            if (dateFilter.startCheckDate) {
                let parseDate = dateFilter.startCheckDate.split(' ');
                defaultDate = moment(parseDate[0], 'YYYY-MM-DD');
            }
        }

        return (
            <div className="calendar-picker">
                <DatePicker
                    placeholder="Выберите дату"
                    onChange={setDate}
                    onOk={value => console.log(value)}
                    className="calendar-picker__field-calendar"
                    size="large"
                    defaultValue={defaultDate}
                    format="YYYY-MM-DD"
                    showToday={false}
                    allowClear={false}
                />
            </div>
        );
    };

    const renderCheckAtEnd = () => {
        let defaultDate = moment(new Date(), 'DD-MM-YYYY');

        if (dateFilter.type === 'singleDate') {
            if (dateFilter.endCheckDate) {
                let parseDate = dateFilter.endCheckDate.split(' ');
                defaultDate = moment(parseDate[0], 'YYYY-MM-DD');
            }
        }

        return (
            <div className="calendar-picker">
                <DatePicker
                    placeholder="Выберите дату"
                    onChange={setDate}
                    onOk={value => console.log(value)}
                    className="calendar-picker__field-calendar"
                    size="large"
                    defaultValue={defaultDate}
                    format="YYYY-MM-DD"
                    showToday={false}
                    allowClear={false}
                />
            </div>
        );
    };

    const renderChooseDay = () => {
        let defaultDate = moment(new Date(), 'YYYY-MM-DD');

        if (dateFilter.type === 'day') {
            if (dateFilter.begin) {
                defaultDate = moment(dateFilter.begin, 'YYYY-MM-DD');
            }
        }

        return (
            <div className="calendar-picker">
                <DatePicker
                    placeholder="Выберите дату"
                    onChange={setDate}
                    onOk={value => console.log(value)}
                    className="calendar-picker__field-calendar"
                    size="large"
                    defaultValue={defaultDate}
                    format="YYYY-MM-DD"
                    showToday={false}
                    allowClear={false}
                />
            </div>
        );
    };

    const renderChooseWeek = () => {
        let defaultDate = [moment(new Date().getDate() - 6, 'YYYY-MM-DD'), moment(new Date(), 'YYYY-MM-DD')];

        // if (dateFilter.type === 'week') {
        //     if (dateFilter.begin) {
        //         defaultDate = moment(dateFilter.begin, 'DD-MM-YYYY');
        //     }
        // }

        return (
            <div className="calendar-picker">
                <DatePicker.RangePicker
                    ref={weekpicker}
                    placeholder="Выберите дату"
                    defaultValue={defaultDate}
                    format="YYYY-MM-DD"
                    size="large"
                    className="calendar-picker__field-calendar"
                    onChange={(dates, dateStrings) => {
                        console.log('onChange', dates, dateStrings);
                    }}
                    onCalendarChange={(dates, dateStrings) => {
                        weekpicker.current?.picker?.handleRangeClick(dates);
                    }}
                    showToday={false}
                    value={test}
                    allowClear={false}
                />
            </div>
        );
    };

    const renderChooseMonth = () => {
        let defaultDate = moment(new Date(), 'DD-MM-YYYY');

        if (dateFilter.type === 'month') {
            if (dateFilter.begin) {
                defaultDate = moment(dateFilter.begin, 'DD-MM-YYYY');
            }
        }

        return (
            <div className="calendar-picker">
                <DatePicker.MonthPicker
                    placeholder="Выберите дату"
                    onChange={setDate}
                    onOk={value => console.log(value)}
                    className="calendar-picker__field-calendar"
                    size="large"
                    defaultValue={defaultDate}
                    format="MMMM YYYY"
                    showToday={false}
                    allowClear={false}
                />
            </div>
        );
    };

    const renderPastDueFilterList = () => {
        return (
            <ul className="modal-list modal-filter__content">
                <li>
                    <a
                        href=""
                        className={pastDateFilter.type ? 'modal__item checked' : 'modal__item'}
                        onClick={e => handleFilterListOpen(e, 'pastDueFilterDay')}>
                        <Icon type="check" />
                        <span>{filterTitles.onDate}</span>
                        <Icon type="right" />
                    </a>
                </li>
            </ul>
        );
    };

    const renderChoosePastDueDay = () => {
        let defaultDate = moment(new Date(), 'YYYY-MM-DD');

        if (pastDateFilter.type === 'pastDueFilterDay') {
            if (pastDateFilter.begin) {
                defaultDate = moment(pastDateFilter.begin, 'YYYY-MM-DD');
            }
        }

        return (
            <div className="calendar-picker">
                <DatePicker
                    placeholder="Выберите дату"
                    onChange={setDate}
                    onOk={value => console.log(value)}
                    className="calendar-picker__field-calendar"
                    size="large"
                    defaultValue={defaultDate}
                    format="YYYY-MM-DD"
                    showToday={false}
                    allowClear={false}
                />
            </div>
        );
    };

    const renderOrganiztionFilterList = () => {
        return (
            <ul className="modal-list modal-filter__content">
                <li>
                    <a
                        href=""
                        className={selectedOrganization ? 'modal__item checked' : 'modal__item'}
                        onClick={e => handleFilterListOpen(e, 'organiztionFilter')}>
                        <Icon type="check" />
                        <span>{filterTitles.authorOrganization}</span>
                        <Icon type="right" />
                    </a>
                </li>
            </ul>
        );
    };

    const renderOrganiztionFilter = () => {
        return (
            <ul className="modal-list modal-filter__content">
                {(organizations || []).map(item => (
                    <li key={item.id}>
                        <a
                            href=""
                            className={
                                selectedOrganization && selectedOrganization.organization === item.organization
                                    ? 'modal__item checked'
                                    : 'modal__item'
                            }
                            onClick={e => setOrganizationFilter(e, item)}>
                            <span>{item.organization}</span>
                            <Icon type="check" className="icon-check" />
                        </a>
                    </li>
                ))}
            </ul>
        );
    };

    const renderRetentionFilter = () => {
        return (
            <ul className="modal-list modal-filter__content">
                <li>
                    <a
                        href=""
                        className={selectedRetention && selectedRetention.id === -1 ? 'modal__item checked' : 'modal__item'}
                        onClick={e => setRetentionFilter(e, { title: 'Не заполнен', id: -1 })}>
                        <span>Не заполнен</span>
                        <Icon type="check" className="icon-check" />
                    </a>
                </li>
                <li>
                    <a
                        href=""
                        className={selectedRetention && selectedRetention.id === 1 ? 'modal__item checked' : 'modal__item'}
                        onClick={e => setRetentionFilter(e, { title: 'Требует устранения', id: 1 })}>
                        <span>Требует устранения</span>
                        <Icon type="check" className="icon-check" />
                    </a>
                </li>
                <li>
                    <a
                        href=""
                        className={selectedRetention && selectedRetention.id === 0 ? 'modal__item checked' : 'modal__item'}
                        onClick={e => setRetentionFilter(e, { title: 'Не требует устранения', id: 0 })}>
                        <span>Не требует устранения</span>
                        <Icon type="check" className="icon-check" />
                    </a>
                </li>
            </ul>
        );
    };

    const renderExecutorTypeFilter = () => {
        return (
            <ul className="modal-list modal-filter__content">
                <li>
                    <a
                        href=""
                        className={selectedExecutorType && selectedExecutorType.id === 0 ? 'modal__item checked' : 'modal__item'}
                        onClick={e => setExecutorTypeFilter(e, { title: 'Собственными силами', id: 0 })}>
                        <span>Собственными силами</span>
                        <Icon type="check" className="icon-check" />
                    </a>
                </li>
                <li>
                    <a
                        href=""
                        className={selectedExecutorType && selectedExecutorType.id === 1 ? 'modal__item checked' : 'modal__item'}
                        onClick={e => setExecutorTypeFilter(e, { title: 'Субподрядная организация', id: 1 })}>
                        <span>Субподрядная организация</span>
                        <Icon type="check" className="icon-check" />
                    </a>
                </li>
            </ul>
        );
    };

    const renderCounterpartyFilter = () => {
        return (
            <ul className="modal-list modal-filter__content">
                {(organizations || []).map(item => (
                    <li key={item.id}>
                        <a
                            href=""
                            className={
                                selectedCounterparty && selectedCounterparty.title === item.title ? 'modal__item checked' : 'modal__item'
                            }
                            onClick={e => setCounterpartyFilter(e, item)}>
                            <span>{item.title}</span>
                            <Icon type="check" className="icon-check" />
                        </a>
                    </li>
                ))}
            </ul>
        );
    };

    const renderAuthorFilter = (user, selectedAuthor) => {
        if (authors.loading) {
            return (
                <div className="modal__loading-wrap">
                    <Loading />
                </div>
            );
        }

        const showMe = !selectedOrganization || (selectedOrganization && selectedOrganization.title === user.organization);

        return (
            <ul className="modal-list modal-filter__content">
                {showMe && (
                    <li>
                        <a
                            href=""
                            className={selectedAuthor.some(i => i.id === user.id) ? 'modal__item checked' : 'modal__item'}
                            onClick={e => setAuthorFilter(e, { title: 'Я', id: user.id })}>
                            <Icon type="user" />
                            <div>
                                <span>Я</span>
                                <br />
                                <span className="secondary-info">{user.organization}</span>
                            </div>
                            <Icon type="check" className="icon-check" />
                        </a>
                    </li>
                )}
                {(authors.data.filter(i => i.id !== user.id) || []).map(item => (
                    <li key={item.id}>
                        <a
                            href=""
                            className={selectedAuthor.some(i => i.id === item.id) ? 'modal__item checked' : 'modal__item'}
                            onClick={e => setAuthorFilter(e, { title: item.fullname, id: item.id })}>
                            <Icon type="user" />
                            <div>
                                <span>{item.fullname}</span>
                                <br />
                                <span className="secondary-info">{item.organization}</span>
                            </div>
                            <Icon type="check" className="icon-check" />
                        </a>
                    </li>
                ))}
            </ul>
        );
    };

    const renderDictionaryFilters = (dictionary, selectedDictionaries) => {
        const prevItem =
            dictionary.data.breadcrumbs && dictionary.data.breadcrumbs.length > 0
                ? dictionary.data.breadcrumbs[dictionary.data.breadcrumbs.length - 1]
                : null;
        if (dictionary.loading) {
            return (
                <div className="modal__loading-wrap">
                    <Loading />
                </div>
            );
        }

        if (dictionary.success) {
            return (
                <ul className="modal-list modal-filter__content">
                    {prevItem && (
                        <li>
                            <a
                                href=""
                                className="modal__item"
                                onClick={e => setDictionaryFilter(e, prevItem)}
                                style={{ background: '#e2e2e2' }}>
                                <span className="modal-item__icon">
                                    <Icon type="rollback" theme="outlined" />
                                </span>
                                <span>{prevItem.title}</span>
                            </a>
                        </li>
                    )}
                    {dictionary.data.breadcrumbs.length === 0 && (
                        <li>
                            <a
                                href=""
                                className={
                                    selectedDictionaries.length > 0 && selectedDictionaries.some(i => i.title === 'Без категории')
                                        ? 'modal__item checked'
                                        : 'modal__item'
                                }
                                onClick={e => setDictionaryFilter(e, { title: 'Без категории', id: 0 })}>
                                <span>Без категории</span>
                                <Icon type="check" className="icon-check" />
                            </a>
                        </li>
                    )}

                    {(dictionary.data.children || []).map(item => renderDictionaryItem(item, selectedDictionaries))}
                </ul>
            );
        }

        return null;
    };

    const renderDictionaryItem = (item, selectedDictionaries) => {
        if (item.type === 'folder') {
            return (
                <li key={item.id}>
                    <a
                        href=""
                        // className="modal__item"
                        className={
                            selectedDictionaries.length > 0 && selectedDictionaries.some(i => i.title === item.title)
                                ? 'modal__item checked'
                                : 'modal__item'
                        }
                        onClick={e => setDictionaryFilter(e, item)}>
                        {/* <span className="modal-item__icon">
                        <Icon type="folder" theme="outlined" />
                    </span> */}
                        <span>{item.title}</span>
                        <Icon type="check" className="icon-check" />
                    </a>
                </li>
            );
        }

        return (
            <li key={item.id}>
                <a
                    href=""
                    className={
                        selectedDictionaries.length > 0 && selectedDictionaries.some(i => i.title === item.title)
                            ? 'modal__item checked'
                            : 'modal__item'
                    }
                    onClick={e => setDictionaryFilter(e, item)}>
                    {/*<span className="modal-item__icon">*/}
                    {/*    <Icon type="file" theme="outlined" />*/}
                    {/*</span>*/}
                    <span>{item.title}</span>
                    <Icon type="check" className="icon-check" />
                </a>
            </li>
        );
    };

    const renderExecutor = (selectedExecutor, user, executors) => {
        return (
            <ul className="modal-list modal-filter__content">
                <li>
                    <a
                        href=""
                        className={selectedExecutor.some(i => i.id === 0) ? 'modal__item checked' : 'modal__item'}
                        onClick={e => setExecutorFilter(e, { title: 'Нет участника', id: 0 })}>
                        <Icon type="user" />
                        <span>Нет участника</span>
                        <Icon type="check" className="icon-check" />
                    </a>
                </li>
                <li>
                    <a
                        href=""
                        className={selectedExecutor.some(i => i.id === user.id) ? 'modal__item checked' : 'modal__item'}
                        onClick={e => setExecutorFilter(e, { title: 'Я', id: user.id })}>
                        <Icon type="user" />
                        <div>
                            <span>Я</span>
                            <br />
                            <span className="secondary-info">{user.organization}</span>
                        </div>
                        <Icon type="check" className="icon-check" />
                    </a>
                </li>
                {(executors.data.filter(i => i.id !== user.id) || []).map(item => (
                    <li key={item.id}>
                        <a
                            href=""
                            className={
                                selectedExecutor && selectedExecutor.some(i => i.id === item.id) ? 'modal__item checked' : 'modal__item'
                            }
                            onClick={e => setExecutorFilter(e, { title: item.fullname, id: item.id })}>
                            <Icon type="user" />
                            <div>
                                <span>{item.fullname}</span>
                                <br />
                                <span className="secondary-info">{item.organization}</span>
                            </div>
                            <Icon type="check" className="icon-check" />
                        </a>
                    </li>
                ))}
            </ul>
        );
    };

    const renderAuditorFilter = (selectedAuditor, user, auditors) => {
        return (
            <ul className="modal-list modal-filter__content">
                <li>
                    <a
                        href=""
                        className={selectedAuditor && selectedAuditor.id === 0 ? 'modal__item checked' : 'modal__item'}
                        onClick={e => setAuditorFilter(e, { title: 'Нет участника', id: 0 })}>
                        <Icon type="user" />
                        <span>Нет участника</span>
                        <Icon type="check" className="icon-check" />
                    </a>
                </li>
                <li>
                    <a
                        href=""
                        className={selectedAuditor && selectedAuditor.id === user.id ? 'modal__item checked' : 'modal__item'}
                        onClick={e => setAuditorFilter(e, { title: 'Я', id: user.id })}>
                        <Icon type="user" />
                        <div>
                            <span>Я</span>
                            <br />
                            <span className="secondary-info">{user.organization}</span>
                        </div>
                        <Icon type="check" className="icon-check" />
                    </a>
                </li>
                {(auditors.data.filter(i => i.id !== user.id) || []).map(item => (
                    <li key={item.id}>
                        <a
                            href=""
                            className={selectedAuditor && selectedAuditor.id === item.id ? 'modal__item checked' : 'modal__item'}
                            onClick={e => setAuditorFilter(e, { title: item.fullname, id: item.id })}>
                            <Icon type="user" />
                            <div>
                                <span>{item.fullname}</span>
                                <br />
                                <span className="secondary-info">{item.organization}</span>
                            </div>
                            <Icon type="check" className="icon-check" />
                        </a>
                    </li>
                ))}
            </ul>
        );
    };

    const renderCompleteBegin = () => {
        let defaultDate = moment(new Date(), 'DD-MM-YYYY');

        if (dateFilter.type === 'singleDate') {
            if (dateFilter.startCompleteDate) {
                let parseDate = dateFilter.startCompleteDate.split(' ');
                defaultDate = moment(parseDate[0], 'YYYY-MM-DD');
            }
        }

        return (
            <div className="calendar-picker">
                <DatePicker
                    placeholder="Выберите дату"
                    onChange={setDate}
                    onOk={value => console.log(value)}
                    className="calendar-picker__field-calendar"
                    size="large"
                    defaultValue={defaultDate}
                    format="YYYY-MM-DD"
                    showToday={false}
                    allowClear={false}
                />
            </div>
        );
    };

    const renderCompleteEnd = () => {
        let defaultDate = moment(new Date(), 'DD-MM-YYYY');

        if (dateFilter.type === 'singleDate') {
            if (dateFilter.endCompleteDate) {
                let parseDate = dateFilter.endCompleteDate.split(' ');
                defaultDate = moment(parseDate[0], 'YYYY-MM-DD');
            }
        }

        return (
            <div className="calendar-picker">
                <DatePicker
                    placeholder="Выберите дату"
                    onChange={setDate}
                    onOk={value => console.log(value)}
                    className="calendar-picker__field-calendar"
                    size="large"
                    defaultValue={defaultDate}
                    format="YYYY-MM-DD"
                    showToday={false}
                    allowClear={false}
                />
            </div>
        );
    };

    const Components = {
        filterList: renderFiltersList(),
        dateFilterList: renderDateFilterList(),
        createAtBegin: renderCreatedAtBegin(),
        createAtEnd: renderCreatedAtEnd(),
        startDateExecution: renderChangedAtBegin(),
        endDateExecution: renderChangedAtEnd(),
        startCheckDate: renderCheckAtBegin(),
        endCheckDate: renderCheckAtEnd(),
        day: renderChooseDay(),
        week: renderChooseWeek(),
        month: renderChooseMonth(),
        pastDueFilterList: renderPastDueFilterList(),
        pastDueFilterDay: renderChoosePastDueDay(),
        organiztionFilterList: renderOrganiztionFilterList(),
        organiztionFilter: renderOrganiztionFilter(),
        authorFilter: renderAuthorFilter(user, selectedAuthor),
        executorFilter: renderExecutor(selectedExecutor, user, executors),
        dictionaryFilter: renderDictionaryFilters(dictionary, selectedDictionaries),
        retentionFilter: renderRetentionFilter(),
        counterpartyFilter: renderCounterpartyFilter(),
        auditorFilter: renderAuditorFilter(selectedAuditor, user, auditors),
        executorTypeFilter: renderExecutorTypeFilter(),
        startCompleteDate: renderCompleteBegin(),
        endCompleteDate: renderCompleteEnd(),
    };

    return <>{Components?.[currentListKey] ?? null}</>;
};

export default FilterListObject;
