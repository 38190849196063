import React from 'react';
import { connect } from 'react-redux';
import { Empty } from 'antd';

import { getFavorites, changeFavorite, setFavorites } from '../../redux/actions/projectModule';
import { Loading } from '../../components/Loading';
import FavoriteNotesList from './components/FavoriteNotesList';
import FavoriteProjectsList from './components/FavoriteProjectsList';
import FavoriteStructureList from './components/FavoriteStructureList';
import FavoriteDocsList from './components/FavoriteDocsList';

import './Favorites.scss';

class Favorites extends React.Component {
    componentDidMount() {
        this.props.getFavorites();
    }

    handleFavorite = (section, itemId, isFavorite) => {
        const { setFavorites, changeFavorite } = this.props;
        let itemIndex = -1;
        let copyData = {};

        copyData = Object.assign({}, this.props.favorites.data);
        itemIndex = copyData[section].children.findIndex(item => item.id === itemId);
        if (itemIndex > -1) {
            copyData[section].children[itemIndex].is_favorite = !copyData[section].children[itemIndex].is_favorite;
        }

        setFavorites(copyData);

        changeFavorite(section, itemId, isFavorite)
            .then(response => {
                copyData = Object.assign({}, this.props.favorites.data);
                itemIndex = copyData[section].children.findIndex(item => item.id === itemId);

                if (itemIndex > -1) {
                    copyData[section].children.splice(itemIndex, 1);
                }

                setFavorites(copyData);
            })
            .catch(error => {
                copyData = Object.assign({}, this.props.favorites.data);
                itemIndex = copyData[section].children.findIndex(item => item.id === itemId);
                if (itemIndex > -1) {
                    copyData[section].children[itemIndex].is_favorite = !copyData[section].children[itemIndex].is_favorite;
                }

                setFavorites(copyData);
            });
    };

    renderNotes = data => {
        return (
            <div className="favorite__row">
                <h2 className="favorite__title">{data.title}</h2>
                {data.children.length ? (
                    <FavoriteNotesList link={this.props.match.params} data={data.children} handleFavorite={this.handleFavorite} />
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Список пуст" />
                )}
            </div>
        );
    };

    renderProjects = data => {
        return (
            <div className="favorite__row">
                <h2 className="favorite__title">{data.title}</h2>
                {data.children.length ? (
                    <FavoriteProjectsList data={data.children} handleFavorite={this.handleFavorite} />
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Список пуст" />
                )}
            </div>
        );
    };

    renderStructure = data => {
        return (
            <div className="favorite__row">
                <h2 className="favorite__title">{data.title}</h2>
                {data.children.length ? (
                    <FavoriteStructureList data={data.children} handleFavorite={this.handleFavorite} />
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Список пуст" />
                )}
            </div>
        );
    };

    renderDocuments = data => {
        return (
            <div className="favorite__row">
                <h2 className="favorite__title">{data.title}</h2>
                {data.children.length ? (
                    <FavoriteDocsList data={data.children} handleFavorite={this.handleFavorite} />
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Список пуст" />
                )}
            </div>
        );
    };

    render() {
        const { favorites } = this.props;

        if (!favorites.loading) {
            if (favorites.success) {
                return (
                    <div className="page-container">
                        <div className="favorite">
                            {this.renderNotes(favorites.data.note)}
                            {/* {this.renderProjects(favorites.data.project)} */}
                            {this.renderStructure(favorites.data.structure)}
                            {this.renderDocuments(favorites.data.document)}
                            {/* <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Нет избранных" /> */}
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        } else {
            return (
                <div className="structure">
                    <Loading />
                </div>
            );
        }
    }
}

const mapStateToProps = state => ({
    favorites: state.projectModule.favorite.favorites,
});

const mapDispatchToProps = dispatch => ({
    getFavorites: () => dispatch(getFavorites()),
    setFavorites: data => dispatch(setFavorites(data)),
    changeFavorite: (section, itemId, isFavorite, callback) => dispatch(changeFavorite(section, itemId, isFavorite, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);
