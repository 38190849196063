import React from 'react';
import { Arrow } from 'react-konva';

import { Drawable } from './Drawable';

class ArrowDrawable extends Drawable {
    constructor(startx, starty, type, id) {
        super(startx, starty, type, id);
        this.x = startx;
        this.y = starty;
    }

    render(i) {
        const points = [this.startx, this.starty, this.x, this.y];
        return <Arrow key={`arrow-${i}`} points={points} fill="#f00000" stroke="#f00000" />;
    }
}

export default ArrowDrawable;
