import React, { Component } from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from './privateRoute';
import { TempSignIn, TempDefault } from '../components/templates';

import { CommonPanel, Favorites, Help, Profile, SignIn, ProjectDetails, Settings } from '../screens';

export class Routes extends Component {
    render() {
        return (
            <Switch>
                <TempSignIn exact path="/" component={SignIn} />
                <TempSignIn exact path="/signin" component={SignIn} />

                <TempDefault exact path="/projects" component={PrivateRoute(CommonPanel)} />
                <TempDefault exact path="/:path/:section" component={PrivateRoute(CommonPanel)} />
                <TempDefault exact path="/:path/:projectId/:section" component={PrivateRoute(ProjectDetails)} />
                <TempDefault exact path="/:path/:projectId/:section/:currentStructureId" component={PrivateRoute(ProjectDetails)} />
                <TempDefault
                    exact
                    path="/:path/:projectId/:section/:currentStructureId/:currentSectionItemDetails"
                    component={PrivateRoute(ProjectDetails)}
                />
                <TempDefault exact path="/:path/:projectId/:section/:currentStructureId/new" component={PrivateRoute(ProjectDetails)} />
                <TempDefault exact path="/:path/:projectId/:section/:currentTeam" component={PrivateRoute(ProjectDetails)} />

                <TempDefault exact path="/favorites" component={PrivateRoute(Favorites)} />
                <TempDefault exact path="/help" component={PrivateRoute(Help)} />
                <TempDefault exact path="/settings" component={PrivateRoute(Settings)} />
                <TempDefault exact path="/profile" component={PrivateRoute(Profile)} />
            </Switch>
        );
    }
}
