import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getTaskById, changeTaskStatus } from '../../redux/actions/projectModule/getTasks';

import { ProjectTaskDetail } from './Components';
import { Loading } from '../Loading';
import { BreadCrumbs } from '../BreadCrumbs';

class ProjectTasksDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taskDetails: undefined,
            statusArray: [
                { status: 'open', statusTitle: 'Открыто', color: '#00ADEF' },
                { status: 'inwork', statusTitle: 'Выполнено', color: '#f2c94c' },
                { status: 'completed', statusTitle: 'Проверено', color: '#27ae60' },
            ],
        };
    }

    componentDidMount() {
        const { getTaskById, match } = this.props;
        const { projectId, currentStructureId, currentSectionItemDetails } = match.params;
        getTaskById(projectId, currentStructureId, currentSectionItemDetails);
    }

    changeStatus = status => {
        const { changeTaskStatus, match } = this.props;
        const { projectId, currentStructureId, currentSectionItemDetails } = match.params;

        changeTaskStatus(projectId, currentStructureId, currentSectionItemDetails, status);
    };

    render() {
        const { singleTask, match } = this.props;
        const { statusArray } = this.state;

        return (
            <div className="structure">
                {singleTask.loading || !singleTask.data ? (
                    <Loading />
                ) : (
                    // singleTask.data.id === +match.params.currentSectionItemDetails && (
                    <>
                        {Object.keys(singleTask.data).length === 0 || !singleTask.data.breadcrumbs ? null : (
                            <BreadCrumbs prevItems={singleTask.data} link={match.params} />
                        )}
                        <ProjectTaskDetail
                            params={match.params}
                            statusData={statusArray}
                            data={singleTask.data}
                            changeStatus={this.changeStatus.bind(this)}
                            history={this.props.history}
                            rootDocumentId={this.props.rootDocumentId}
                        />
                    </>
                    // )
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    singleTask: state.projectModule.tasks.singleTask,
});

const mapDispatchToProps = dispatch => ({
    getTaskById: (projectId, structureId, itemId) => dispatch(getTaskById(projectId, structureId, itemId)),
    changeTaskStatus: (projectId, structureId, itemId, status) => dispatch(changeTaskStatus(projectId, structureId, itemId, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTasksDetails);
