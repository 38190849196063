import { combineReducers } from 'redux';
import { currentUser, getCurrentPage, permission } from './common';
import {
    getProjects,
    getStructure,
    getDocuments,
    getTeam,
    getAnalytics,
    getSettings,
    getNotes,
    getTasks,
    createComment,
    getNtd,
    favorite,
} from './projectsModule';

export default function reducerMarkerDrawable(state = { value: 'default', drawables: [], newDrawableType: 'MarkerDrawable' }, action) {
    switch (action.type) {
        case 'ADD_FIGURE':
            const newState = {
                drawables: [...state.drawables, action.payload],
                newDrawableType: state.newDrawableType,
            };
            return newState;
            break;
        case 'CHANGE_FIGURE_TYPE':
            return {
                drawables: state.drawables,
                newDrawableType: action.payload,
            };
            break;
    }
    return state;
}

export const rootReducer = combineReducers({
    routing: getCurrentPage,
    user: currentUser,
    permission: permission,
    reducerMarkerDrawable: reducerMarkerDrawable,
    projectModule: combineReducers({
        projects: getProjects,
        structure: getStructure,
        documents: getDocuments,
        comments: createComment,
        team: getTeam,
        analytics: getAnalytics,
        settings: getSettings,
        notes: getNotes,
        tasks: getTasks,
        ntd: getNtd,
        favorite: favorite,
    }),
});
