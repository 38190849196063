import { Button, DatePicker, Divider, Form, Icon, Input, Row, Select, Upload } from 'antd';
import locale from 'antd/es/locale/ru_RU';
import axios from 'axios';
import FileSaver from 'file-saver';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { connect } from 'react-redux';
import {
    archiveTask,
    changeStageLock,
    changeTask,
    changeTaskStatus,
    createNote,
    // getWorkStages,
    createTaskComment,
    deleteTaskFile,
    getEngineers,
    getFreeTimes,
    getTaskById,
    getWorkSections,
    getWorkTypes,
    resetArchiveStatus,
    resetTaskStore,
    restoreTask,
    setTask,
    uploadTaskFile,
} from '../../../redux/actions/projectModule';
import { apiConfig } from '../../../services/api/config';

import Text from 'antd/lib/typography/Text';
import ModalNotAcceptedStage from './ModalNotAcceptedStage';
import ModalStages from './ModalStages';
import TaskHistory from './TaskHistory';
import { CustomSelect } from './index';

const { TextArea } = Input;

const ProjectTaskDetail = props => {
    const {
        data,
        createComment,
        getTaskById,
        changeTask,
        changeTaskStatus,
        changeStageLock,
        getEngineers,
        getFreeTimes,
        getWorkSections,
        getWorkTypes,
        // getWorkStages,
        engineers,
        deleteTaskFile,
        uploadTaskFile,
        isTaskArchived,
        archiveTask,
        restoreTask,
        resetArchiveStatus,
        params,
        workSections,
        workTypes,
        workStages,
        freeTimes,
        createNote,
        resetTaskStore,
        permission,
        user,
    } = props;

    const projectId = !props.match ? +params.projectId : props.match.params.projectId;
    const rootStructureId = !props.match ? +params.currentStructureId : props.match.params.currentStructureId;

    const stageSelect = useRef();

    const [selectedFileList, setSelectedFileList] = useState(null);
    const [removeBtnIsShown, setRemoveBtnIsShown] = useState(false);
    const [isShowGallery, setShowGallery] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [comment, setComment] = useState('');

    const [workType, setWorkType] = useState(null);
    const [workStage, setWorkStage] = useState([]);
    const [countDates, setCountDates] = useState(1);
    const [selectedDate, setSelectedDate] = useState({});
    const [selectedTime, setSelectedTime] = useState({});

    const [isVisibleStages, setIsVisibleStages] = useState(false);
    const [stageIdCommentModal, setStageIdCommentModal] = useState(null);
    const [isDisabledEngineers, setIsDisabledEngineers] = useState(true);

    const [createNoteLoading, setCreateNoteLoading] = useState(false);

    const isAdmin = useMemo(() => {
        if (!permission?.data?.length) {
            return false;
        }

        return !!permission.data.find(item => item.name === 'task.executor')?.isAllowed;
    }, [permission]);

    const getData = () => {
        getWorkSections();
        getEngineers(projectId);
        if (data.work_section_id) {
            getWorkTypes(data.work_section_id);
        }
        // if (data.work_type_id) {
        //     getWorkStages(data.work_type_id);
        // }
    };

    const handleSelectSubmit = (value, item, callback = () => {}) => {
        let changedData = {};

        switch (item) {
            case 1:
                changedData.work_section_id = value;
                break;
            case 2:
                changedData.work_type_id = value;
                break;
            case 3:
                changedData.work_stage_ids = value;
                break;
            case 4:
                changedData.engineer_id = value;
                break;
            case 5:
                const datetimes = [];
                const copySelectedTimes = Object.assign({}, selectedTime);
                copySelectedTimes[value.index] = value.time;

                for (const i in copySelectedTimes) {
                    if (copySelectedTimes.hasOwnProperty(i)) {
                        datetimes.push(`${selectedDate[i]} ${copySelectedTimes[i]}`);
                    }
                }
                changedData.review_datetimes = datetimes;
                changedData.engineer_id = data.engineer_id;
                break;
            case 6:
                changedData.work_stage_ids = value;
                break;
            default:
                break;
        }

        return changeTask(projectId, rootStructureId, data.id, changedData, callback);
    };

    const handleRequest = ({ onSuccess, file }) => {
        const formData = new FormData();
        formData.append('uploadFile', file);
        formData.append('fileName', file.name);
        uploadTaskFile(projectId, rootStructureId, data.id, formData, updateUploadedFiles);
    };

    const updateUploadedFiles = response => {
        getTaskById(projectId, rootStructureId, data.id, false);
    };

    const handleAppChange = info => {
        return;
        switch (info.file.status) {
            case 'uploading':
                setSelectedFileList(info.fileList);
                break;
            case 'done':
                setSelectedFileList(info.fileList);
                break;
            case 'error':
                break;
            default:
                setSelectedFileList(info.fileList);
                break;
        }
    };

    const disabledDate = current => {
        return current && current < moment().subtract(1, 'days').endOf('day') - 1;
    };

    const disabledDateTime = () => {
        return {
            disabledHours: () => range(0, 24).splice(4, 20),
            disabledMinutes: () => range(30, 60),
            disabledSeconds: () => [55, 56],
        };
    };

    const handleFilesRemove = e => {
        e.preventDefault();
        setRemoveBtnIsShown(!removeBtnIsShown);
    };

    const handleFileRemove = (e, fileId) => {
        e.preventDefault();
        // setFilesValue(filesValue.filter(item => item.id !== fileId));
        deleteTaskFile(projectId, rootStructureId, data.id, fileId);
    };

    const downloadFile = (e, hash, id, fileName) => {
        e.preventDefault();
        let accessToken = localStorage.getItem('accessToken');

        axios({
            headers: {
                'Access-Token': accessToken,
            },
            responseType: 'blob',
            method: 'GET',
            url: `${apiConfig.url}file/${hash}/${id}`,
        })
            .then(response => {
                var blob = new Blob([response.data]);
                FileSaver.saveAs(blob, fileName, { type: response.headers['content-type'] });
            })
            .catch(errors => {
                console.log(errors.response);
            });
    };

    useEffect(() => {
        resetTaskStore();
        getData();
    }, []);

    useEffect(() => {
        if (isTaskArchived) {
            props.history.push(`/projects/${projectId}/tasks/${data.structure_id}`);
            resetArchiveStatus();
        }
    }, [isTaskArchived]);

    const handleTaskArchive = async (structureId, taskId) => {
        await archiveTask(projectId, structureId, taskId, { data: history });
    };

    const handleTaskRestore = async (structureId, taskId) => {
        restoreTask(projectId, structureId, taskId);
    };

    const handleFavorite = (section, itemId, isFavorite) => {
        let copyData = Object.assign({}, data);
        copyData.is_favorite = !copyData.is_favorite;
        props.setTask(copyData);
        props
            .changeFavorite(section, itemId, isFavorite)
            .then(response => {
                // console.log('handleFavorite -> response', response);
                // console.log('DATA', data);
            })
            .catch(error => {
                copyData.is_favorite = !copyData.is_favorite;
                props.setTask(copyData);
            });
    };

    const handleCreateComment = () => {
        if (comment.length) {
            createComment(data.id, comment);
            setComment('');
        }
    };

    const handleChangeWorkSection = e => {
        handleSelectSubmit(e, 1)
            .then(response => {
                if (response && response.work_section_id) {
                    getWorkTypes(response.work_section_id);
                    setWorkType(null);
                    setWorkStage([]);
                }
            })
            .catch(error => {
                setWorkType(null);
                setWorkStage([]);
            });
    };

    const handleChangeWorkType = e => {
        handleSelectSubmit(e, 2, () => {
            getWorkStages(e);
            setWorkStage([]);
        });
    };

    const handleChangeWorkStage = e => {
        setWorkStage(e);
    };

    const getValueWorkStage = () => {
        if (workStage.length) {
            return workStage;
        }
        if (data.workStage.length) {
            // if (data.work_type_id && workStages.success) {
            //     return data.workStage.map(stage => stage.title);
            // }
            if (workTypes.success) {
                return data.workStage.map(stage => stage.title);
            }
        }
        return [];
    };

    const handleChangeEngineer = e => {
        // setEngineer(e);
        handleSelectSubmit(e, 4).then(response => {
            if (response) {
                setSelectedDate({});
                setSelectedTime({});
            }
        });
    };

    const handleSelectDate = (date, index) => {
        if (!date) {
            return;
        }

        const value = moment(date).format('YYYY-MM-DD');

        setSelectedDate({ ...selectedDate, [index]: value });

        getFreeTimes(projectId, data.engineer.id, value, index, freeTimes[index]).then(response => {
            if (!response) {
                if (data.reviewDatetimes.length && data.reviewDatetimes[index]) {
                    setSelectedDate({ ...selectedDate, [index]: data.reviewDatetimes[index].date });
                } else {
                    setSelectedDate({ ...selectedDate, [index]: null });
                }
            } else {
                const copySelectedTimes = Object.assign({}, selectedTime);
                delete copySelectedTimes[index];
                setSelectedTime(copySelectedTimes);
            }
        });
    };

    const handleSelectTime = (time, index) => {
        setSelectedTime({ ...selectedTime, [index]: time });
        handleSelectSubmit({ time: time, index: index }, 5);
    };

    const getTimeValue = index => {
        data.reviewDatetimes[index] ? data.reviewDatetimes[index].time : selectedTime[index] || null;

        if (data.reviewDatetimes[index] && selectedDate[index] !== data.reviewDatetimes[index].date) {
            return null;
        }

        if (data.reviewDatetimes[index]) {
            return data.reviewDatetimes[index].time;
        }

        return null;
    };

    const renderDateRow = index => {
        return (
            <div key={index} className="taskDetailsView__content taskDetailsView__content_row">
                <div className="taskDetailsView__item">
                    <Form.Item label="Выбрать дату:">
                        <DatePicker
                            style={{ display: 'inline-block', width: '100%' }}
                            locale={locale}
                            onChange={date => handleSelectDate(date, index)}
                            value={selectedDate[index] ? moment(selectedDate[index], 'YYYY-MM-DD') : null}
                            placeholder="Выберите дату"
                            format="DD-MM-YYYY"
                            showToday={true}
                            disabledDate={disabledDate}
                            disabledTime={disabledDateTime}
                            disabled={!data.engineer || !data.engineer.id}
                            allowClear={false}
                        />
                    </Form.Item>
                </div>
                <div className="taskDetailsView__item">
                    <Form.Item label="Выбрать время:">
                        <Select
                            style={{ width: '100%' }}
                            value={getTimeValue(index)}
                            onChange={date => handleSelectTime(date, index)}
                            disabled={
                                (freeTimes[index] && !freeTimes[index].success) ||
                                !freeTimes[index] ||
                                (freeTimes[index] && freeTimes[index].loading)
                            }>
                            {freeTimes[index] &&
                                freeTimes[index].success &&
                                freeTimes[index].data.freeTimes &&
                                freeTimes[index].data.freeTimes.map((item, key) => (
                                    <Select.Option key={key} value={item}>
                                        {item}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </div>
            </div>
        );
    };

    const renderDateRows = () => {
        const content = [];

        for (let i = 0; i < countDates; i++) {
            content.push(renderDateRow(i));
        }

        return content;
    };

    const handleAddInterval = () => {
        if (countDates < 3) {
            setCountDates(countDates + 1);
        }
    };

    const handleChangeStatus = (stageId, status, stage) => {
        if (status === 'not_accepted' && stage && stage.state.name !== 'not_accepted') {
            setIsVisibleStages(false);
            setStageIdCommentModal(stageId);
        } else {
            changeTaskStatus(projectId, rootStructureId, data.id, stageId, status, () => {}, null, data.state?.name);
        }
    };

    const handleStageComment = comment => {
        changeTaskStatus(
            projectId,
            rootStructureId,
            data.id,
            stageIdCommentModal,
            'not_accepted',
            () => {
                setStageIdCommentModal(null);
            },
            comment,
            data.state?.name,
        );
    };

    const handleChangeLock = (stageId, status) => {
        changeStageLock(projectId, rootStructureId, data.id, stageId, status);
    };

    const handleCreateNote = e => {
        e.preventDefault();

        if (!createNoteLoading) {
            const sendData = {
                title: '',
                description: '',
                isEnabled: false,
                ntdData: null,
                ntdFolderName: null,
                executor: undefined,
                dictionary: [],
                date: null,
                auditors: [],
            };
            setCreateNoteLoading(true);

            const structureId = !!data.breadcrumbs?.length
                ? data.breadcrumbs[data.breadcrumbs.length - 1]?.id ?? rootStructureId
                : rootStructureId;

            createNote(projectId, structureId, sendData, data => {
                props.history.push(`/projects/${projectId}/comments/${structureId}/${data.id}`);
            }).catch(() => {
                setCreateNoteLoading(false);
            });
        }
    };

    useEffect(() => {
        setCountDates(data.reviewDatetimes.length || 1);

        const defaultDate = {},
            defaultTimes = {};

        data.reviewDatetimes.forEach((date, index) => {
            defaultDate[index] = date.date;
            defaultTimes[index] = date.time;
            getFreeTimes(projectId, data.engineer.id, date.date, index);
        });

        setSelectedDate(defaultDate);
        setSelectedTime(defaultTimes);
    }, [data.engineer.id]);

    useEffect(() => {
        setCountDates(data.reviewDatetimes.length || 1);

        const defaultDate = {},
            defaultTimes = {};
        data.reviewDatetimes.forEach((date, index) => {
            defaultDate[index] = date.date;
            defaultTimes[index] = date.time;
        });

        setSelectedDate(defaultDate);
        setSelectedTime(defaultTimes);
    }, [data.review_datetimes]);

    useEffect(() => {
        if (permission.success) {
            const canAddExecutor = permission.data.find(item => item.name === 'task.appoint.executor');
            const canAddOwnExecutor = permission.data.find(item => item.name === 'task.appoint.own.executor');

            if (canAddExecutor && canAddOwnExecutor) {
                if (canAddExecutor.isAllowed) {
                    setIsDisabledEngineers(false);
                } else if (canAddOwnExecutor.isAllowed) {
                    if (user && data) {
                        if (user.id === data.engineer_id || user.id === data.user.id) {
                            setIsDisabledEngineers(false);
                        }
                    }
                }
            }
        }
    }, [permission, user, data]);

    return data ? (
        <>
            <Form>
                <div className="flexRow spaceBetween">
                    <h2>№ - {data.id}</h2>
                    <div className="task-status">
                        {data.isEngineer && (
                            <a href="" className="form-action__btn" onClick={handleCreateNote}>
                                <Icon type="exclamation-circle" theme="outlined" />
                                <span>Создать замечание</span>
                            </a>
                        )}
                        <Button
                            className="ml16"
                            style={{
                                borderColor: data.state.color,
                                backgroundColor: data.state.color,
                                borderStyle: 'solid',
                                borderWidth: 2,
                                color: '#fff',
                            }}
                            disabled>
                            <span>{data.state.title}</span>
                        </Button>
                    </div>
                </div>

                <div className="mt16">
                    <div className="mt16">
                        <div className="taskDetailsView">
                            <Form.Item label="Раздел работ:">
                                <CustomSelect
                                    isMultiple={false}
                                    data={workSections.success ? workSections.data.sections : []}
                                    value={(workSections.success && data.work_section_id) || null}
                                    onChange={handleChangeWorkSection}
                                    isDictionary
                                    disabled={workSections.loading}
                                />
                            </Form.Item>
                        </div>

                        {/* <div className="taskDetailsView">
                            <Form.Item label="Вид работ:">
                                <CustomSelect
                                    isMultiple={false}
                                    data={workTypes.success ? workTypes.data.workTypes : []}
                                    value={(workTypes.success && data.work_type_id) || null}
                                    onChange={handleChangeWorkType}
                                    isDictionary
                                    disabled={workTypes.loading || !workTypes.success}
                                />
                            </Form.Item>
                        </div> */}

                        <div className="taskDetailsView">
                            <Form.Item label="Этап:">
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    value={getValueWorkStage()}
                                    notFoundContent={null}
                                    removeIcon={<div></div>}
                                    disabled={workTypes.loading || !workTypes.success}
                                    onDropdownVisibleChange={() => setIsVisibleStages(true)}
                                    ref={stageSelect}
                                />
                            </Form.Item>
                            <ModalStages
                                visible={isVisibleStages}
                                data={workTypes.success ? workTypes.data.workStages : []}
                                selectedData={data.workStage}
                                setHide={() => {
                                    setIsVisibleStages(false);
                                    setTimeout(() => {
                                        stageSelect.current.blur();
                                    }, 100);
                                }}
                                handleSet={value => handleSelectSubmit(value, 6)}
                                isEngineer={data.isEngineer || isAdmin}
                                changeStatus={handleChangeStatus}
                                changeLock={handleChangeLock}
                            />
                            <ModalNotAcceptedStage
                                visible={stageIdCommentModal !== null}
                                setHide={() => {
                                    setStageIdCommentModal(null);
                                }}
                                handleOkay={handleStageComment}
                            />
                        </div>

                        <div className="taskDetailsView">
                            <Form.Item label="Инженер">
                                <CustomSelect
                                    isMultiple={false}
                                    data={engineers.success ? engineers.data : []}
                                    value={data.engineer.id || null}
                                    onChange={handleChangeEngineer}
                                    disabled={engineers.loading || isDisabledEngineers}
                                />
                            </Form.Item>
                        </div>

                        <div className="taskDetailsView">
                            <div className="form__field">
                                <div className="form__label">Дата и время проверки:</div>
                            </div>
                            {renderDateRows()}
                            {countDates < 3 && (
                                <Row type="flex" justify="center">
                                    <Button type="link" onClick={handleAddInterval}>
                                        Добавить интервал
                                    </Button>
                                </Row>
                            )}
                        </div>

                        <div className="taskDetailsView">
                            <div className="form-item files">
                                <div className="form__field">
                                    <div className="form__label">Приложения:</div>
                                    <div className={removeBtnIsShown ? 'form-action remove' : 'form-action'}>
                                        <Upload
                                            showUploadList={false}
                                            fileList={selectedFileList}
                                            customRequest={handleRequest}
                                            onChange={handleAppChange}
                                            listType="picture"
                                            multiple={true}>
                                            {!removeBtnIsShown && (
                                                <>
                                                    <a href="" className="form-action__btn" onClick={e => e.preventDefault()}>
                                                        <Icon type="upload" theme="outlined" />
                                                        <span>Загрузить</span>
                                                    </a>
                                                </>
                                            )}
                                        </Upload>
                                        {!removeBtnIsShown ? (
                                            <>
                                                |
                                                <a href="" className="form-action__btn" onClick={handleFilesRemove}>
                                                    <Icon type="delete" theme="outlined" />
                                                    <span>Удалить</span>
                                                </a>
                                            </>
                                        ) : (
                                            <a href="" className="form-action__btn green" onClick={handleFilesRemove}>
                                                <Icon type="delete" theme="outlined" />
                                                <span>Вернуться в просмотр</span>
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {data.files.length !== 0 &&
                                data.files.map((item, index) => (
                                    <div key={index} className="task-file task-file_s1">
                                        <a
                                            target="_blank"
                                            className="task-file__info"
                                            onClick={() => {
                                                setPhotoIndex(index);
                                                setShowGallery(true);
                                            }}>
                                            <img src={item.thumb} alt={item.title} className="task-file__img" />
                                            <p className="task-file__name">{item.title}</p>
                                            <p className="secondary-info">{moment(item.created_at).format('DD.MM.YYYY, HH:mm:ss')}</p>
                                            <p className="secondary-info">{item.user.fullname}</p>
                                        </a>
                                        {removeBtnIsShown && (
                                            <a href="" className="task-file__remove" onClick={e => handleFileRemove(e, item.id)}>
                                                <Icon type="delete" theme="outlined" />
                                            </a>
                                        )}
                                        {!removeBtnIsShown && (
                                            <a
                                                href=""
                                                onClick={e => downloadFile(e, item.hash, item.id, item.title)}
                                                className="task-file__download">
                                                <Icon type="download" theme="outlined" />
                                            </a>
                                        )}
                                    </div>
                                ))}
                            {data.files.length === 0 && <span className="secondary-info">Не загружено</span>}
                        </div>

                        <div className="taskDetailsView">
                            <Form.Item label="Автор заявки">
                                <Input value={data.user.fullname} disabled />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div className="divider mt32" />
                <div className="mt32 view-comments">
                    <h2>Комментарий</h2>
                    {data.comments.length !== 0 ? (
                        data.comments.map((item, key) => (
                            <div key={key}>
                                <Row className="view-comments__item">
                                    <div className="view-comments__row">
                                        <div className="view-comments__label">
                                            <Text>Комментарий</Text>
                                        </div>
                                        <div className="view-comments__content">
                                            <Text className="view-comments__comment">{item.content}</Text>
                                        </div>
                                    </div>
                                    <div className="view-comments__row">
                                        <div className="view-comments__label">
                                            <Text>Автор</Text>
                                        </div>
                                        <div className="view-comments__content">
                                            <Text className="view-comments__user">{item.user ? item.user.fullname : 'Нет автора'}</Text>
                                        </div>
                                    </div>
                                    <div className="view-comments__row">
                                        <div className="view-comments__label">
                                            <Text>Дата</Text>
                                        </div>
                                        <div className="view-comments__content">
                                            <Text className="view-comments__date">
                                                {moment(item.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm:ss')}
                                            </Text>
                                        </div>
                                    </div>
                                </Row>
                                <Divider />
                            </div>
                        ))
                    ) : (
                        <Text>Нет комментариев</Text>
                    )}
                    <div className="mt32">
                        <TextArea onChange={e => setComment(e.target.value)} value={comment} rows={4} placeholder={'Введите комментарий'} />
                        <Button onClick={handleCreateComment} className="mt16">
                            <span>Отправить</span>
                        </Button>
                    </div>
                </div>
                <TaskHistory projectId={projectId} structureId={rootStructureId} taskId={data.id} history={props.history} />

                {isShowGallery && (
                    <Lightbox
                        mainSrc={data.files[photoIndex].full_path}
                        nextSrc={data.files[(photoIndex + 1) % data.files.length].full_path}
                        prevSrc={data.files[(photoIndex + data.files.length - 1) % data.files.length].full_path}
                        onCloseRequest={() => setShowGallery(false)}
                        onMovePrevRequest={() => setPhotoIndex((photoIndex + data.files.length - 1) % data.files.length)}
                        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % data.files.length)}
                    />
                )}

                 {!data.deleted_at ? (
                    <div className="mt32">
                        <Button type="danger" onClick={() => {
                            handleTaskArchive(data.structure_id, data.id)
                        }}>
                            <span>Архивировать</span>
                        </Button>
                    </div>
                ) : (
                    <div className="mt32">
                        <Button type="primary" onClick={() => handleTaskRestore(data.structure_id, data.id)}>
                            <span>Восстановить</span>
                        </Button>
                    </div>
                )}
            </Form>
        </>
    ) : null;
};

const mapStateToProps = state => {
    return {
        user: state.user.user,
        currentStructure: state.projectModule.structure.currentItem,
        engineers: state.projectModule.tasks.engineers,
        freeTimes: state.projectModule.tasks.freeTimes,
        workSections: state.projectModule.tasks.workSections,
        workTypes: state.projectModule.tasks.workTypes,
        workStages: state.projectModule.tasks.workStages,
        uploadedFile: state.projectModule.tasks.uploadedFile,
        deletedFile: state.projectModule.tasks.deletedFile,
        ntd: state.projectModule.ntd.ntd,
        treeNtd: state.projectModule.ntd.treeNtd,
        permission: state.permission,
        isTaskArchived: state.projectModule.tasks.isTaskArchived,
        retentionUnits: state.projectModule.tasks.retentionUnits,
    };
};

const mapDispatchToProps = dispatch => ({
    changeTask: (projectId, structureId, taskId, data, callback) => dispatch(changeTask(projectId, structureId, taskId, data, callback)),
    changeTaskStatus: (projectId, structureId, taskId, stageId, data, callback, comment, state) =>
        dispatch(changeTaskStatus(projectId, structureId, taskId, stageId, data, callback, comment, state)),
    changeStageLock: (projectId, structureId, taskId, stageId, data) =>
        dispatch(changeStageLock(projectId, structureId, taskId, stageId, data)),
    getTaskById: (projectId, structureId, taskId, needLoader) => dispatch(getTaskById(projectId, structureId, taskId, needLoader)),
    getEngineers: projectId => dispatch(getEngineers(projectId)),
    getFreeTimes: (projectId, engineerId, date, index, freetimes) => dispatch(getFreeTimes(projectId, engineerId, date, index, freetimes)),
    getWorkSections: () => dispatch(getWorkSections()),
    getWorkTypes: id => dispatch(getWorkTypes(id)),
    // getWorkStages: id => dispatch(getWorkStages(id)),
    uploadTaskFile: (projectId, structureId, taskId, uploadFile, callback) =>
        dispatch(uploadTaskFile(projectId, structureId, taskId, uploadFile, callback)),
    deleteTaskFile: (projectId, structureId, taskId, fileId) => dispatch(deleteTaskFile(projectId, structureId, taskId, fileId)),
    archiveTask: (projectId, structureId, taskId) => dispatch(archiveTask(projectId, structureId, taskId)),
    restoreTask: (projectId, structureId, taskId) => dispatch(restoreTask(projectId, structureId, taskId)),
    resetArchiveStatus: () => dispatch(resetArchiveStatus()),
    // changeFavorite: (section, itemId, isFavorite, callback) => dispatch(changeFavorite(section, itemId, isFavorite, callback)),
    setTask: data => dispatch(setTask(data)),
    createComment: (id, comment) => dispatch(createTaskComment(id, comment)),
    createNote: (projectId, structureId, data, callback = () => {}) => dispatch(createNote(projectId, structureId, data, callback)),
    resetTaskStore: () => dispatch(resetTaskStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTaskDetail);
