import * as types from '../../actionTypes';

const initialState = {
    favorites: {
        data: {},
        success: false,
        errors: [],
        loading: false,
    },
};

export const favorite = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_FAVORITES_SUCCESS:
            return {
                ...state,
                favorites: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };
        case types.SET_FAVORITES:
            return {
                ...state,
                favorites: {
                    ...state.favorites,
                    data: action.data,
                },
            };
        case types.GET_FAVORITES_LOADING:
            return {
                ...state,
                favorites: {
                    ...state.favorites,
                    loading: true,
                },
            };
        default:
            return state;
    }
};
