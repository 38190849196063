import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Button, message } from 'antd';

import { getTasks, createTask } from '../../redux/actions/projectModule';
import { apiConfig } from '../../services/api/config';

import { TasksList, TasksListFilters, TasksFiles } from './Components';
import { BreadCrumbs } from '../BreadCrumbs';
import { Loading } from '../Loading';

import './ProjectTasks.scss';
import { getPermissions } from '../../redux/actions/common';
import utils from '../../helpers/utils';

class ProjectTasks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            prescriptionIsLoading: false,
            limit: 10,
            params: '',
            createLoading: false,
            reportLoading: false,
            showFields: false,
            showButtonAdd: false,
        };
    }

    setParams = (params, isNeedCounts, isNeedBreadcrumbs, defaultLimit, scrollTop) => {
        const { limit } = this.state;
        const { tasksData } = this.props;
        let callback;

        if (scrollTop) {
            callback = () => {
                window.scrollTo(0, scrollTop);
            };
        }

        this.setState(
            {
                params: params,
            },
            () => {
                this.getData(
                    `offset=0&limit=${defaultLimit ? defaultLimit : limit}${params}`,
                    { ...tasksData.data, tasks: [] },
                    isNeedCounts,
                    isNeedBreadcrumbs,
                    callback,
                );
            },
        );
    };

    getData = (params, tasks, isNeedCounts, isNeedBreadcrumbs, callback) => {
        const { getTasks } = this.props;
        const { projectId, currentStructureId } = this.props.match.params;

        if (isNeedCounts) {
            params += '&calculateCounts=1';
        }
        if (isNeedBreadcrumbs) {
            params += '&createBreadcrumbs=1';
        }

        getTasks(projectId, currentStructureId, params, tasks, callback);
    };

    handleReport = () => {
        const { projectId, currentStructureId } = this.props.match.params;
        const accessToken = localStorage.getItem('accessToken');
        const { params } = this.state;

        const sendData = {
            structureId: currentStructureId,
        };

        this.setState({
            reportLoading: true,
        });

        axios({
            headers: {
                'Access-Token': accessToken,
            },
            method: 'POST',
            url: `${apiConfig.url}project/${projectId}/report/tasks?${params.slice(1)}`,
            data: sendData,
        })
            .then(response => {
                if (!response.data.success) {
                    response.data.errors.forEach(element => {
                        message.error(element);
                    });
                } else {
                    window.open(response.data.data.full_path, '_blank');
                }
                this.setState({
                    reportLoading: false,
                });
            })
            .catch(errors => {
                console.log(errors.response);
                this.setState({
                    reportLoading: false,
                });
            });
    };

    handlePrescription = () => {
        const { projectId, currentStructureId } = this.props.match.params;
        const accessToken = localStorage.getItem('accessToken');
        const { params } = this.state;

        this.setState({
            prescriptionIsLoading: true,
        });

        axios({
            headers: {
                'Access-Token': accessToken,
            },
            method: 'POST',
            url: `${apiConfig.url}project/${projectId}/project-structure/${currentStructureId}/task/prescription?${params.slice(1)}`,
        })
            .then(response => {
                if (!response.data.success) {
                    response.data.errors.forEach(element => {
                        message.error(element);
                    });
                } else {
                    response.data.data.forEach(element => {
                        message.info(element);
                    });
                }
                this.setState({
                    prescriptionIsLoading: false,
                });
            })
            .catch(errors => {
                console.log(errors.response);
                this.setState({
                    prescriptionIsLoading: false,
                });
            });
    };

    handleCreateTask = e => {
        e.preventDefault();
        const { createTask } = this.props,
            { projectId, currentStructureId } = this.props.match.params;

        this.setState({
            createLoading: true,
        });

        createTask(projectId, currentStructureId, null, data => {
            this.props.history.push(`${this.props.history.location.pathname}/${data.id}`);
        }).catch(() => {
            this.setState({
                createLoading: false,
            });
        });
    };

    handleScroll = () => {
        window.scrollTo(500);
    };

    checkAddPermission = permission => {
        let addPermission = permission.find(item => item.name === 'task.add');

        if (addPermission.isAllowed) {
            this.setState({
                showButtonAdd: true,
            });
        }
    };

    checkPermission = permission => {
        let tasksPermission = permission.find(item => item.name === 'page.view.volume');

        if (tasksPermission.isAllowed) {
            this.setState({
                showFields: true,
            });
            // const { limit } = this.state;

            // this.getData(`offset=0&limit=${limit}`, null, true, true);
            this.checkAddPermission(permission);
        } else {
            utils.showError('Недостаточно прав для совершения операции');
        }
    };

    getPermission = () => {
        this.props
            .getPermission(this.props.match.params.projectId)
            .then(response => {
                this.checkPermission(response.data.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    componentDidMount() {
        this.getPermission();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match && +prevProps.match.params.currentStructureId !== +this.props.match.params.currentStructureId) {
            this.getData(`offset=0&limit=${this.state.limit}${this.state.params}`, null, true, true);
        }
    }

    render() {
        const { tasksData, match, history } = this.props;
        const { loading } = tasksData;
        const { limit, createLoading, showFields, showButtonAdd } = this.state;

        if (!showFields) {
            return null;
        }

        return (
            <div className="structure">
                {tasksData.data && tasksData.data.breadcrumbs && <BreadCrumbs prevItems={tasksData.data} link={match.params} />}

                <div className="add_structure_item mt16">
                    {/* <Button icon="download" onClick={this.handleReport} className="pull-right" loading={reportLoading}>
                        Отчет
                    </Button> */}

                    {/* <Button
                        icon="form"
                        onClick={this.handlePrescription}
                        className="pull-right"
                        style={{ marginRight: 10 }}
                        loading={prescriptionIsLoading}>
                        Создать предписание
                    </Button> */}

                    {/* <TasksFiles data={tasksData.data} filters={params} match={match} /> */}
                    {showButtonAdd && (
                        <Button
                            type="primary"
                            shape="circle"
                            icon="plus"
                            size="large"
                            loading={createLoading}
                            onClick={this.handleCreateTask}
                        />
                    )}
                </div>

                <TasksListFilters
                    history={history}
                    match={match}
                    setParams={this.setParams}
                    data={tasksData.data}
                    getDataWithParams={this.getDataWithParams}
                    limit={tasksData.data && tasksData.data.pagination && tasksData.data.pagination.currentOffset}
                    success={tasksData.success}
                />
                {tasksData.data ? (
                    <div className="task-list">
                        <TasksList link={match.params} data={tasksData.data.tasks} />
                        {tasksData.data.tasks && tasksData.data.pagination.currentOffset !== tasksData.data.pagination.totalCount && (
                            <div className="task-list__show-more-wrap">
                                {loading ? (
                                    <Loading />
                                ) : (
                                    <Button
                                        type="primary"
                                        className="task-list__show-more"
                                        onClick={() =>
                                            this.getData(
                                                `offset=${tasksData.data.pagination.currentOffset}&limit=${limit}${this.state.params}`,
                                                tasksData.data,
                                                false,
                                                false,
                                            )
                                        }>
                                        Показать еще
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                ) : (
                    <Loading />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    tasksData: state.projectModule.tasks.tasksList,
});

const mapDispatchToProps = dispatch => ({
    getTasks: (projectId, structureId, params, tasks, callback) => dispatch(getTasks(projectId, structureId, params, tasks, callback)),
    createTask: (projectId, structureId, data, callback = () => {}) => dispatch(createTask(projectId, structureId, data, callback)),
    getPermission: projectId => dispatch(getPermissions(projectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTasks);
