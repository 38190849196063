import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Button } from 'antd';
import moment from 'moment';

class MarkerInfo extends Component {
    render() {
        const { title, noteComments, noteFiles, date_created, id, number, document_id } = this.props.marker,
            { projectId, currentStructureId, handleClose, handleCopy, handleCopyOtherLayer } = this.props;

        return (
            <div className="marker-info" style={this.props.style}>
                <Button type="primary" shape="circle" icon="close" className="marker-info__close" onClick={handleClose} />
                <div className="marker-info__content">
                    <div className="marker-info__row">
                        <div className="marker-info__number">{number}</div>
                        <div className="marker-info__title">
                            <Link to={`/projects/${projectId}/comments/${currentStructureId}/${id}`}>{title}</Link>
                        </div>
                    </div>
                    <div className="marker-info__row">
                        <span className="marker-info__dop-info">
                            <Icon type="message" /> {noteComments.length}
                        </span>
                        <span className="marker-info__dop-info">
                            <Icon type="paper-clip" /> {noteFiles.length}
                        </span>
                        <span className="marker-info__dop-info">
                            <Icon type="calendar" /> {moment(date_created).format('l')}
                        </span>
                    </div>
                    <div className="marker-info__row">
                        <Button
                            type="primary"
                            icon="arrow-right"
                            onClick={e => {
                                localStorage.setItem('clickedNoteInfo', JSON.stringify({ id: id, document_id: document_id }));
                                this.props.history.push(`/projects/${projectId}/comments/${currentStructureId}/${id}`);
                            }}
                            className="marker-info__button">
                            Перейти
                        </Button>
                        <Button icon="copy" onClick={handleCopy} className="marker-info__button">
                            Клонировать
                        </Button>
                        <Button onClick={handleCopyOtherLayer} className="marker-info__button">
                            На другой чертеж
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default MarkerInfo;
