import React, { useState, useEffect } from 'react';
import { Icon, Modal, Row, Col, Checkbox, Divider, Button } from 'antd';
import Text from 'antd/lib/typography/Text';

const ModalStages = ({ data, selectedData, visible, setHide, handleSet, isEngineer, changeStatus, changeLock }) => {
    // console.log('ModalStages -> data, selectedData', data, selectedData);
    const [stages, setStages] = useState([]);

    const handleCheck = (e, item, index) => {
        const copyStages = JSON.parse(JSON.stringify(stages));
        copyStages[index].checked = !copyStages[index].checked;

        setStages(copyStages);
    };

    const handleOk = () => {
        if (!isEngineer) {
            const stagesIds = [];
            stages.forEach(stage => {
                if (stage.checked) {
                    stagesIds.push(stage.id);
                }
            });

            handleSet(stagesIds);
        }
        setHide();
    };

    const getButtonStyle = (stage, key) => {
        if (stage.state.name === key) {
            return {
                background: stage.state.color,
                color: '#fff',
            };
        }

        return {};
    };

    const renderStatus = stage => {
        if (stage.state) {
            if (!isEngineer) {
                return (
                    <Row type="flex" align="middle" gutter={[24, 16]}>
                        <Col>
                            <Text className="task-stages__state" style={{ background: stage.state.color }}>
                                {stage.state.title}
                            </Text>
                        </Col>
                    </Row>
                );
            } else {
                return (
                    <Row type="flex" align="middle" gutter={[8, 16]}>
                        <Col>
                            <Button
                                className="task-stages__button"
                                style={getButtonStyle(stage, 'accepted')}
                                onClick={() => changeStatus(stage.id, 'accepted', stage)}
                                disabled={stage.isLock}>
                                Принято
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className="task-stages__button"
                                style={getButtonStyle(stage, 'not_accepted')}
                                onClick={() => changeStatus(stage.id, 'not_accepted', stage)}
                                disabled={stage.isLock}>
                                Не принято
                            </Button>
                        </Col>
                        <Col>/</Col>
                        <Col>
                            <Button
                                type="link"
                                className={`task-stages__lock ${!stage.lock_activated ? 'task-stages__lock_default' : ''}`}
                                onClick={() => changeLock(stage.id, +!stage.lock_activated)}
                                disabled={stage.isLock}>
                                {stage.lock_activated ? <Icon type="lock" theme="filled" /> : <Icon type="lock" />}
                            </Button>
                        </Col>
                    </Row>
                );
            }
        }
    };

    useEffect(() => {
        let newData = [];
        // console.log('ModalStages -> data', data);

        if (!isEngineer) {
            newData = data.map(item => {
                const findItem = selectedData.find(item2 => item2.id === item.id);

                if (findItem) {
                    findItem.checked = true;
                    findItem.disabled = true;

                    return findItem;
                }

                return item;
            });
        } else {
            newData = selectedData.map(item => {
                item.checked = true;
                item.disabled = true;

                return item;
            });
        }

        setStages(newData);
    }, [data, selectedData]);

    return (
        <Modal
            title={<span>Этапы</span>}
            visible={visible}
            onCancel={setHide}
            footer={[
                <Button key="submit" type="primary" onClick={handleOk}>
                    Готово
                </Button>,
            ]}>
            <div className="task-stages">
                {stages.map((item, i) => (
                    <Row key={item.id} className={`task-stages__item ${item.isLock ? 'task-stages__item_locked' : ''}`}>
                        <Row type="flex" align="middle" gutter={[24, 16]}>
                            <Col xs={2}>
                                <Checkbox onChange={e => handleCheck(e, item, i)} checked={!!item.checked} disabled={item.disabled} />
                            </Col>
                            <Col xs={22}>
                                <Row gutter={[0, 12]}>
                                    <Col>
                                        <Text>{item.title}</Text>
                                    </Col>
                                    <Col>{renderStatus(item)}</Col>
                                </Row>
                            </Col>
                        </Row>

                        {i < stages.length - 1 && <Divider />}
                    </Row>
                ))}
                {/* {console.log('ModalStages -> stages', stages)} */}
            </div>
        </Modal>
    );
};

export default ModalStages;
