import * as types from '../../actionTypes';

const initialState = {
    projectsList: {
        data: [],
        success: false,
        errors: [],
        loading: false,
    },
    currentProject: {
        data: {},
        success: false,
        errors: [],
        loading: false,
    },
    projectUserInfo: {
        data: null,
        success: false,
        errors: [],
        loading: false,
    },
    isCreated: false,
    createPrescriptionForCounterPartyLoading: false,
};

export const getProjects = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PROJECTS_SUCCESS:
            return {
                ...state,
                projectsList: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };

        case types.GET_PROJECTS_LOADING:
            return {
                ...state,
                projectsList: {
                    data: [],
                    success: false,
                    errors: [],
                    loading: true,
                },
            };
        case types.GET_PROJECTS_USER_INFO_LOADING:
            return {
                ...state,
                projectUserInfo: {
                    data: null,
                    success: false,
                    errors: [],
                    loading: true,
                },
            };

        case types.GET_PROJECTS_USER_INFO_SUCCESS:
            return {
                ...state,
                projectUserInfo: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };
        case types.SET_PROJECTS:
            return {
                ...state,
                projectsList: {
                    ...state.projectsList,
                    data: action.data,
                },
            };

        case types.GET_PROJECTS_ERROR:
            return {
                ...state,
                projectsList: {
                    data: [],
                    success: false,
                    errors: action.errors,
                    loading: false,
                },
            };

        case types.GET_CURRENT_PROJECT_SUCCESS:
            return {
                ...state,
                currentProject: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };

        case types.GET_CURRENT_PROJECT_LOADING:
            return {
                ...state,
                currentProject: {
                    data: {},
                    success: false,
                    errors: [],
                    loading: true,
                },
            };
        case types.CREATE_PROJECT:
            return {
                ...state,
                isCreated: action.isCreated,
            };

        case types.RESET_CREATE_STATUS:
            return {
                ...state,
                isCreated: action.isCreated,
            };

        case types.DELETE_PROJECT:
            return {
                ...state,
                isDeleted: action.isDeleted,
            };

        case types.RESET_DELETE_STATUS:
            return {
                ...state,
                isDeleted: action.isDeleted,
            };
        case types.CREATE_PRESCRIPTION_FOR_COUNTERPARTY_LOADING:
            return {
                ...state,
                createPrescriptionForCounterPartyLoading: true,
            };
        case types.CREATE_PRESCRIPTION_FOR_COUNTERPARTY_SUCCESS:
            return {
                ...state,
                createPrescriptionForCounterPartyLoading: false,
            };
        default:
            return state;
    }
};
