import React from 'react';
import { Switch, Icon, Divider } from 'antd';

import '../Settings.scss';

const SettingsList = props => {
    const { handleSwitch } = props;

    const renderItem = (title, icon, propertyName, propertyValue, subTitle) => {
        return (
            <div className="flexRow spaceBetween mt16">
                <div className="flexRow">
                    <div className="iconContainer">
                        <Icon style={{ fontSize: 28 }} type={icon} />
                    </div>
                    <div className="flexColumn ml16">
                        <span className="bold14">{title}</span>
                        <span className="regular10">{subTitle ? subTitle : ''}</span>
                    </div>
                </div>
                <Switch
                    checked={propertyValue === 0 ? false : true}
                    onChange={checked => handleSwitch(checked, propertyName, propertyValue)}
                />
            </div>
        );
    };

    const { data } = props;

    return data !== {} ? (
        <div>
            <div>
                <h1>Типы уведомлений</h1>
                {renderItem('Push', 'notification', 'push', data.push)}
                {renderItem('E-mail', 'mail', 'email', data.email)}
                <Divider />
            </div>
            <div>
                <h1>Уведомления по замечаниям</h1>
                {renderItem('Все', 'notification', 'notification_all', data.notification_all)}
                {renderItem(
                    'Важные',
                    'warning',
                    'notification_important',
                    data.notification_important,
                    'при смене статуса или исполнителя',
                )}
                <Divider />
            </div>
            <div>
                <h1>Дополнительные настройки</h1>
                {renderItem('Режим восстановления', 'upload', 'showArchive', data.showArchive)}
                <Divider />
            </div>
        </div>
    ) : (
        <span>Данные не подгрузились</span>
    );
};

export default SettingsList;
