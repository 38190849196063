import React from 'react';

import markerIcon from '../../assets/marker.svg';
import markerCompleted from '../../assets/marker_green.svg';
import markerInwork from '../../assets/marker_yellow.svg';
import markerBlue from '../../assets/marker_blue.svg';

class MapMarker extends React.Component {
    render() {
        let style = {
            cursor: 'pointer',
            position: 'absolute',
            zIndex: 1000,
            left: this.props.x * this.props.zoom - 16,
            top: this.props.y * this.props.zoom - 32,
        };
        if (this.props.isActive) {
            style = {
                ...style,
                zIndex: 1001,
                transformOrigin: '50% 100%',
                transform: 'scale(1.5)',
                filter: 'drop-shadow(0px 0px 6px black)',
            };
        }
        const { note } = this.props;
        let imgIcon = '';

        if (note.state === 'inwork') {
            // @TODO - replace with constant
            imgIcon = markerInwork;
        } else if (note.state === 'completed') {
            imgIcon = markerCompleted;
        } else if (note.state === 'open') {
            imgIcon = markerIcon;
        }
        if (note.retention_status_id === 0) {
            imgIcon = markerBlue;
        }

        return <img src={imgIcon} style={style} width="32" height="32" onClick={() => this.props.markerClicked(note)} />;
    }

    getX() {
        return this.props.x;
    }

    getY() {
        return this.props.y;
    }
}

export default MapMarker;
