import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, message } from 'antd';
import axios from 'axios';

import { getNotes, createNote } from '../../redux/actions/projectModule';
import { apiConfig } from '../../services/api/config';

import { NotesList } from './Components';
import NotesListFilters from './Components/NotesListFilters/NotesListFilters';
import NotesFiles from './Components/NotesFiles';
import { Loading } from '../Loading';

import './ProjectNotes.scss';

class CommonNotes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            prescriptionIsLoading: false,
            limit: 10,
            params: '',
            reportLoading: false,
        };
    }

    setParams = (params, isNeedCounts, isNeedBreadcrumbs, defaultLimit) => {
        // console.log('PARAMS', params);
        const { limit } = this.state;
        const { notesData } = this.props;

        this.setState(
            {
                params: params,
            },
            () => {
                this.getData(
                    `offset=0&limit=${defaultLimit ? defaultLimit : limit}${params}`,
                    { ...notesData.data, notes: [] },
                    isNeedCounts,
                    isNeedBreadcrumbs,
                );
            },
        );
    };

    getData = (params, notes, isNeedCounts, isNeedBreadcrumbs) => {
        const { getNotes } = this.props;
        const { projectId, currentStructureId } = this.props.match.params;

        if (isNeedCounts) {
            params += '&calculateCounts=1';
        }

        // console.log('GETDATA', params);

        getNotes(projectId, currentStructureId, params, notes);
    };

    handleReport = () => {
        const accessToken = localStorage.getItem('accessToken');
        const { params } = this.state;

        this.setState({
            reportLoading: true,
        });

        axios({
            headers: {
                'Access-Token': accessToken,
            },
            method: 'POST',
            url: `${apiConfig.url}project/0/report/notes?${params.slice(1)}`,
        })
            .then(response => {
                if (!response.data.success) {
                    response.data.errors.forEach(element => {
                        message.error(element);
                    });
                } else {
                    window.open(response.data.data.full_path, '_blank');
                }
                this.setState({
                    reportLoading: false,
                });
            })
            .catch(errors => {
                console.log(errors.response);
                this.setState({
                    reportLoading: false,
                });
            });
    };

    handlePrescription = () => {
        const accessToken = localStorage.getItem('accessToken');
        const { params } = this.state;

        this.setState({
            prescriptionIsLoading: true,
        });

        axios({
            headers: {
                'Access-Token': accessToken,
            },
            method: 'POST',
            url: `${apiConfig.url}project/0/project-structure/0/note/prescription?${params.slice(1)}`,
        })
            .then(response => {
                if (!response.data.success) {
                    response.data.errors.forEach(element => {
                        message.error(element);
                    });
                } else {
                    message.info(response.data.data.message);
                }
                this.setState({
                    prescriptionIsLoading: false,
                });
            })
            .catch(errors => {
                console.log(errors.response);
                this.setState({
                    prescriptionIsLoading: false,
                });
            });
    };

    handleCreateNote = e => {
        e.preventDefault();
        const { createNote } = this.props,
            { projectId, currentStructureId } = this.props.match.params,
            data = {
                title: '',
                description: '',
                isEnabled: false,
                ntdData: null,
                ntdFolderName: null,
                executor: undefined,
                dictionary: [],
                date: null,
                auditors: [],
            };

        createNote(projectId, currentStructureId, data, data => {
            this.props.history.push(`${this.props.history.location.pathname}/${data.id}`);
        });
    };

    componentDidMount() {
        const { limit } = this.state;

        this.getData(`offset=0&limit=${limit}`, null, true, false);
    }

    render() {
        const { notesData, match, history } = this.props;
        const { loading } = notesData;
        const { prescriptionIsLoading, limit, params, reportLoading } = this.state;

        return (
            <div className="structure">
                {/* {notesData.data && <BreadCrumbs prevItems={notesData.data} link={match.params} />} */}
                {notesData.success && (
                    <div className="add_structure_item mt16" style={{ display: 'inline-block', width: '100%' }}>
                        <Button icon="download" onClick={this.handleReport} className="pull-right" loading={reportLoading}>
                            Отчет
                        </Button>

                        <Button
                            icon="form"
                            onClick={this.handlePrescription}
                            className="pull-right"
                            style={{ marginRight: 10 }}
                            loading={prescriptionIsLoading}>
                            Создать предписание
                        </Button>

                        <NotesFiles data={notesData.data} filters={params} match={match} />
                    </div>
                )}

                <NotesListFilters
                    history={history}
                    match={match}
                    setParams={this.setParams}
                    data={notesData.data}
                    getDataWithParams={this.getDataWithParams}
                    limit={notesData.data && notesData.data.pagination && notesData.data.pagination.currentOffset}
                    success={notesData.success}
                />
                {notesData.data ? (
                    <div className="note-list">
                        <NotesList link={match.params} data={notesData.data.notes} common={true} />
                        {notesData.data.notes && notesData.data.pagination.currentOffset !== notesData.data.pagination.totalCount && (
                            <div className="note-list__show-more-wrap">
                                {loading ? (
                                    <Loading />
                                ) : (
                                    <Button
                                        type="primary"
                                        className="note-list__show-more"
                                        onClick={() =>
                                            this.getData(
                                                `offset=${notesData.data.pagination.currentOffset}&limit=${limit}${this.state.params}`,
                                                notesData.data,
                                                false,
                                                false,
                                            )
                                        }>
                                        Показать еще
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                ) : (
                    <Loading />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    notesData: state.projectModule.notes.notesList,
});

const mapDispatchToProps = dispatch => ({
    getNotes: (projectId, structureId, params, notes) => dispatch(getNotes(projectId, structureId, params, notes)),
    createNote: (projectId, structureId, data, callback = () => {}) => dispatch(createNote(projectId, structureId, data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonNotes);
