export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const UPDATE_USER_LOADING = 'UPDATE_USER_LOADING';

export const GET_PROJECTS_USER_INFO_LOADING = 'GET_PROJECTS_USER_INFO_LOADING';
export const GET_PROJECTS_USER_INFO_SUCCESS = 'GET_PROJECTS_USER_INFO_SUCCESS';

export const GET_CURRENT_PAGE = 'GET_CURRENT_PAGE';

export const GET_PERMISSION = 'GET_PERMISSION';

export const CREATE_COMMENT = 'CREATE_COMMENT';
export const GET_COMMENTS = 'GET_COMMENTS';

export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GET_DOCUMENTS_LOADING = 'GET_DOCUMENTS_LOADING';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const SET_CURRENT_DOC = 'SET_CURRENT_DOC';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const RESET_CREATE_STATUS = 'RESET_CREATE_STATUS';
export const RESET_UPLOAD_STATUS = 'RESET_UPLOAD_STATUS';

export const GET_ANALYTICS = 'GET_ANALYTICS';
export const GET_ANALYTICS_LOADING = 'GET_ANALYTICS_LOADING';
export const RESET_ANALYTICS = 'RESET_ANALYTICS';

export const CREATE_NOTE = 'CREATE_NOTE';
export const GET_NOTES_LOADING = 'GET_NOTES_LOADING';
export const GET_NOTES_SUCCESS = 'GET_NOTES_SUCCESS';
export const SET_NOTES = 'SET_NOTES';
export const GET_DOCUMENT_NOTES_LOADING = 'GET_DOCUMENT_NOTES_LOADING';
export const GET_DOCUMENT_NOTES_SUCCESS = 'GET_DOCUMENT_NOTES_SUCCESS';
export const GET_NOTE_BY_ID_LOADING = 'GET_NOTE_BY_ID_LOADING';
export const GET_NOTE_BY_AUDITORS_ARRAY = 'GET_NOTE_BY_AUDITORS_ARRAY';
export const GET_NOTE_BY_ID_SUCCESS = 'GET_NOTE_BY_ID_SUCCESS';
export const SET_NOTE = 'SET_NOTE';
export const SET_COPIED_NOTE = 'SET_COPIED_NOTE';
export const CHANGE_DOCUMENT_NOTES_LIST = 'CHANGE_DOCUMENT_NOTES_LIST';
export const UPLOAD_NOTE_FILE = 'UPLOAD_NOTE_FILE';
export const DELETE_NOTE_FILE = 'DELETE_NOTE_FILE';
export const GET_NOTE_HISTORY = 'GET_NOTE_HISTORY';
export const SUCCESS_NOTE_ARCHIVE = 'SUCCESS_NOTE_ARCHIVE';
export const SET_NOTES_FILTERS = 'SET_NOTES_FILTERS';
export const GET_NOTES_FILES_LOADING = 'GET_NOTES_FILES_LOADING';
export const GET_NOTES_FILES_SUCCESS = 'GET_NOTES_FILES_SUCCESS';
export const CHANGE_NOTE_STATUS_LOADING = 'CHANGE_NOTE_STATUS_LOADING';
export const CHANGE_NOTE_STATUS_SUCCESS = 'CHANGE_NOTE_STATUS_SUCCESS';
export const CREATE_NOTE_COMMENT = 'CREATE_NOTE_COMMENT';
export const GET_NOTE_WORK_TYPES = 'GET_NOTE_WORK_TYPES';
export const GET_NOTE_WORK_TYPES_LOADING = 'GET_NOTE_WORK_TYPES_LOADING';
export const GET_NOTE_WORK_TYPES_RESET = 'GET_NOTE_WORK_TYPES_RESET';

export const CREATE_TASK = 'CREATE_TASK';
export const GET_TASKS_LOADING = 'GET_TASKS_LOADING';
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
export const GET_DOCUMENT_TASKS_LOADING = 'GET_DOCUMENT_TASKS_LOADING';
export const GET_DOCUMENT_TASKS_SUCCESS = 'GET_DOCUMENT_TASKS_SUCCESS';
export const GET_TASK_BY_ID_LOADING = 'GET_TASK_BY_ID_LOADING';
export const GET_TASK_BY_ID_SUCCESS = 'GET_TASK_BY_ID_SUCCESS';
export const SET_TASK = 'SET_TASK';
export const UPLOAD_TASK_FILE = 'UPLOAD_TASK_FILE';
export const DELETE_TASK_FILE = 'DELETE_TASK_FILE';
export const GET_TASK_HISTORY = 'GET_TASK_HISTORY';
export const SUCCESS_TASK_ARCHIVE = 'SUCCESS_TASK_ARCHIVE';
export const SET_TASKS_FILTERS = 'SET_TASKS_FILTERS';
export const GET_TASKS_FILES_LOADING = 'GET_TASKS_FILES_LOADING';
export const GET_TASKS_FILES_SUCCESS = 'GET_TASKS_FILES_SUCCESS';
export const CHANGE_TASK_STATUS_LOADING = 'CHANGE_TASK_STATUS_LOADING';
export const CHANGE_TASK_STATUS_SUCCESS = 'CHANGE_TASK_STATUS_SUCCESS';
export const CREATE_TASK_COMMENT = 'CREATE_TASK_COMMENT';
export const GET_ENGINEERS = 'GET_ENGINEERS';
export const GET_ENGINEERS_LOADING = 'GET_ENGINEERS_LOADING';
export const GET_FREE_TIMES = 'GET_FREE_TIMES';
export const GET_FREE_TIMES_LOADING = 'GET_FREE_TIMES_LOADING';
export const RESET_TASK = 'RESET_TASK';

export const SUCCESS_DELETE_DOCUMENT = 'SUCCESS_DELETE_DOCUMENT';
export const RESET_DELETED_DOC_STATUS = 'RESET_DELETED_DOC_STATUS';
export const SUCCESS_RESTORE_DOCUMENT = 'SUCCESS_RESTORE_DOCUMENT';
export const RESET_RESTORED_DOC_STATUS = 'RESET_RESTORED_DOC_STATUS';

export const GET_EXECUTORS_LOADING = 'GET_EXECUTORS_LOADING';
export const GET_EXECUTORS_SUCCESS = 'GET_EXECUTORS_SUCCESS';

export const GET_FILTRED_EXECUTORS_LOADING = 'GET_FILTRED_EXECUTORS_LOADING';
export const GET_FILTRED_EXECUTORS_SUCCESS = 'GET_FILTRED_EXECUTORS_SUCCESS';

export const GET_QUERY_FILTRED_EXECUTORS_LOADING = 'GET_QUERY_FILTRED_EXECUTORS_LOADING';
export const GET_QUERY_FILTRED_EXECUTORS_SUCCESS = 'GET_QUERY_FILTRED_EXECUTORS_SUCCESS';

export const GET_AUDITORS = 'GET_AUDITORS';
export const GET_FILTRED_AUDITORS = 'GET_FILTRED_AUDITORS';
export const CLEAR_AUDITORS = 'CLEAR_AUDITORS';
export const GET_DICTIONARY = 'GET_DICTIONARY';
export const GET_FILTRED_DICTIONARY = 'GET_FILTRED_DICTIONARY';
export const GET_DICTIONARY_LOADING = 'GET_DICTIONARY_LOADING';
export const GET_WORK_SECTION = 'GET_WORK_SECTION';
export const GET_WORK_SECTION_LOADING = 'GET_WORK_SECTION_LOADING';
export const GET_WORK_TYPES = 'GET_WORK_TYPES';
export const GET_WORK_TYPES_LOADING = 'GET_WORK_TYPES_LOADING';
export const GET_WORK_STAGES = 'GET_WORK_STAGES';
export const GET_WORK_STAGES_LOADING = 'GET_WORK_STAGES_LOADING';
export const GET_AUTHORS = 'GET_AUTHORS';
export const GET_FILTERED_AUTHORS = 'GET_FILTERED_AUTHORS';
export const GET_AUTHORS_LOADING = 'GET_AUTHORS_LOADING';
export const GET_TASKS_AUTHORS = 'GET_TASKS_AUTHORS';
export const GET_TASKS_AUTHORS_LOADING = 'GET_TASKS_AUTHORS_LOADING';
export const GET_NUMBER = 'GET_NUMBER';
export const GET_RETENTION_UNITS = 'GET_RETENTION_UNITS';
export const RESET_ARCHIVE_STATUS = 'RESET_ARCHIVE_STATUS';

export const GET_NTD = 'GET_NTD';
export const GET_NTD_BY_ID = 'GET_NTD_BY_ID';
export const GET_TREE_NTD = 'GET_TREE_NTD';

export const CREATE_PROJECT = 'CREATE_PROJECT';
export const RESET_PROJECT_CREATE_STATUS = 'RESET_PROJECT_CREATE_STATUS';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const RESET_DELETE_STATUS = 'RESET_DELETE_STATUS';
export const GET_PROJECTS_LOADING = 'GET_PROJECTS_LOADING';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const SET_PROJECTS = 'SET_PROJECTS';
export const GET_PROJECTS_ERROR = 'GET_PROJECTS_ERROR';
export const GET_CURRENT_PROJECT_LOADING = 'GET_CURRENT_PROJECT_LOADING';
export const GET_CURRENT_PROJECT_SUCCESS = 'GET_CURRENT_PROJECT_SUCCESS';

export const GET_STRUCTURE = 'GET_STRUCTURE';
export const SET_STRUCTURE = 'SET_STRUCTURE';
export const GET_STRUCTURE_ITEM_LOADING = 'GET_STRUCTURE_ITEM_LOADING';
export const GET_STRUCTURE_ITEM_SUCCESS = 'GET_STRUCTURE_ITEM_SUCCESS';
export const CREATE_STRUCTURE_ITEM = 'CREATE_STRUCTURE_ITEM';
export const SUCCESS_DELETE_STRUCTURE_ITEM = 'SUCCESS_DELETE_STRUCTURE_ITEM';
export const RESET_DELETED_ITEM_STATUS = 'RESET_DELETED_ITEM_STATUS';
export const SUCCESS_RESTORE_STRUCTURE_ITEM = 'SUCCESS_RESTORE_STRUCTURE_ITEM';
export const UPDATE_STRUCTURE_ITEM = 'UPDATE_STRUCTURE_ITEM';
export const CHANGE_STRUCTURE_ITEM_TITLE = 'CHANGE_STRUCTURE_ITEM_TITLE';
export const RESET_RESTORED_ITEM_STATUS = 'RESET_RESTORED_ITEM_STATUS';
export const GET_TEAM = 'GET_TEAM';
export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
export const GET_COUNTERPARTIES = 'GET_COUNTERPARTIES';
export const GET_FILTRED_ORGANIZATIONS = 'GET_FILTRED_ORGANIZATIONS';
export const GET_COUNTERPARTY_LIST = 'GET_COUNTERPARTY_LIST';
export const CLEAR_ORGANIZATIONS = 'CLEAR_ORGANIZATIONS';
export const GET_USERS = 'GET_USERS';
export const ASSIGN_ORGANIZATION_LOADING = 'ASSIGN_ORGANIZATION_LOADING';
export const ASSIGN_ORGANIZATION_SUCCESS = 'ASSIGN_ORGANIZATION_SUCCESS';
export const ASSIGN_USER = 'ASSIGN_USER';
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
export const DELETE_USER = 'DELETE_USER';

export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_USER = 'GET_USER';
export const SUCCESS_SETTINGS_UPDATE = 'SUCCESS_SETTINGS_UPDATE';

export const GET_FAVORITES_SUCCESS = 'GET_FAVORITES_SUCCESS';
export const SET_FAVORITES = 'SET_FAVORITES';
export const GET_FAVORITES_LOADING = 'GET_FAVORITES_LOADING';

export const GET_CONTRACTS_SUCCESS = 'GET_CONTRACTS_SUCCESS';
export const GET_CONTRACTS_LOADING = 'GET_CONTRACTS_LOADING';
export const SET_CONTRACT = 'SET_CONTRACT';

export const GET_INSPECTION_NOTES_LOADING = 'GET_INSPECTION_NOTES_LOADING';
export const GET_INSPECTION_NOTES = 'GET_INSPECTION_NOTES';

export const CHANGE_NOTE_LOADING = 'CHANGE_NOTE_LOADING';
export const CHANGE_NOTE_SUCCESS = 'CHANGE_NOTE_SUCCESS';
export const CREATE_INSPECTION_NOTES_LOADING = 'CREATE_INSPECTION_NOTES_LOADING';
export const CREATE_INSPECTION_NOTES = 'CREATE_INSPECTION_NOTES';

export const CREATE_PRESCRIPTION_FOR_COUNTERPARTY_LOADING = 'CREATE_PRESCRIPTION_FOR_COUNTERPARTY_LOADING';
export const CREATE_PRESCRIPTION_FOR_COUNTERPARTY_SUCCESS = 'CREATE_PRESCRIPTION_FOR_COUNTERPARTY_SUCCESS';

export const NOTES_STATUS_CHANGE_LOADING = 'NOTES_STATUS_CHANGE_LOADING';
