import React from 'react';
import { Select, Spin, Empty } from 'antd';

const { Option } = Select;

const CustomControlledSelect = props => {
    const {
        value,
        onChange,
        data,
        isMultiple,
        isDictionary,
        defaultValue,
        loading = false,
        onBlur,
        placeholder,
        disabled,
        filterOption,
        ...rest
    } = props;

    // Handlers
    const handleFilterOption = (input, option) => {
        const childrenText = option.props.children?.[1] ?? '';

        return childrenText.toLowerCase().indexOf(input.toLocaleLowerCase()) !== -1;
    };

    // Renders
    return (
        <Select
            mode={isMultiple ? 'multiple' : 'default'}
            style={{ width: '100%' }}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            showArrow
            value={value}
            disabled={disabled ? disabled : false}
            filterOption={filterOption ? filterOption : handleFilterOption}
            notFoundContent={
                loading ? (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            padding: '32px 0 ',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <Spin />
                    </div>
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )
            }
            {...rest}>
            {data.length > 0
                ? data.map((item, key) => (
                      <Option key={key} value={item.id} disabled={item.disabled || false}>
                          {item.color ? <span className="status-tag" style={{ backgroundColor: item.color }} /> : null}
                          {isDictionary ? item.title : item.fullname}
                      </Option>
                  ))
                : null}
        </Select>
    );
};

export default CustomControlledSelect;
