import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Button } from 'antd';
import moment from 'moment';

class FavoriteProjectsList extends React.Component {
    render() {
        const { data } = this.props;

        return (
            <>
                {!data || data.length === 0
                    ? null
                    : data.map(item =>
                          item.is_favorite ? (
                              <li className="projects__item" key={item.id}>
                                  <Link to={`projects/${item.id}/structure/${item.root_structure_id}`}>
                                      <div className="projects__data">
                                          <div className="projects__image">
                                              <img src={item.file ? item.file.thumb : Placeholder} />
                                          </div>
                                          <div className="projects__description">
                                              <div className="projects__title">{item.title}</div>
                                              <div className="projects__dates">
                                                  {item.date_start &&
                                                      item.date_deadline &&
                                                      moment(item.date_start).format('DD.MM.YYYY') +
                                                          ' - ' +
                                                          moment(item.date_deadline).format('DD.MM.YYYY')}
                                              </div>
                                          </div>
                                          <ul className="projects__labels">
                                              <li className="projects__label danger">{item.notes_cnt_danger}</li>
                                              <li className="projects__label warning">{item.notes_cnt_warning}</li>
                                              <li className="projects__label success">{item.notes_cnt_success}</li>
                                          </ul>
                                          <div className="projects__arrow">
                                              <Icon type="right" theme="outlined" style={{ color: '#666666' }} />
                                          </div>
                                      </div>
                                  </Link>
                                  <Button
                                      title={'Избранное'}
                                      type={'link'}
                                      onClick={() => this.props.handleFavorite('project', item.id, item.is_favorite)}
                                      className={item.is_favorite ? 'button-favorite button-favorite_active' : 'button-favorite'}>
                                      <Icon type="star" theme="filled" />
                                  </Button>
                              </li>
                          ) : null,
                      )}
            </>
        );
    }
}

export default FavoriteProjectsList;
