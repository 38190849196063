import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon } from 'antd';

import logo from '../../assets/logo1.png';

class Navigation extends React.Component {
    logout() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('currentUser');
        this.props.history.replace('/signin');
    }

    getCurrentKey = () => {
        const { location } = this.props;

        switch (location.pathname) {
            case '/favorites':
                return '2';
            case '/help':
                return '3';
            case '/settings':
                return '4';
            case '/profile':
                return '5';
            default:
                return '1';
        }
    };

    render() {
        return (
            <div>
                <img src={logo} width="100%" />
                <Menu selectedKeys={[this.getCurrentKey()]} mode="inline" theme="light">
                    <Menu.Item key="1">
                        <Link to="/projects">
                            <Icon type="folder" theme="filled" />
                            <span>Проекты</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="2">
                        <Link to="/favorites">
                            <Icon type="star" theme="filled" />
                            <span>Избранное</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="3">
                        <Link to="/help">
                            <Icon type="question-circle" theme="filled" />
                            <span>Помощь</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="4">
                        <Link to="/settings">
                            <Icon type="setting" theme="filled" />
                            <span>Настройки</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="5">
                        <Link to="/profile">
                            <Icon type="smile" theme="filled" />
                            <span>Профиль</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => {
                            this.logout();
                        }}>
                        <Icon type="logout" theme="outlined" />
                        <span>Выйти</span>
                    </Menu.Item>
                </Menu>
            </div>
        );
    }
}

export default Navigation;
