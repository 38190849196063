import axios from 'axios';
import { CREATE_COMMENT } from '../../actionTypes';
import { apiConfig } from '../../../services/api/config';
import { getNoteById } from './getNotes';

export const createComment = (projectId, structureId, id, comment) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/note/${id}/create-comment`,
        data: {
            content: comment,
        },
    })
        .then(response => {
            dispatch({
                type: CREATE_COMMENT,
                data: response.data.data,
                success: response.data.success,
                errors: response.data.errors,
            });
            if (response.data.success) {
                dispatch(getNoteById(projectId, structureId, id));
            }
        })
        .catch(errors => {
            console.log(errors);
        });
};
