import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Button } from 'antd';
import axios from 'axios';
import FileSaver from 'file-saver';

import { apiConfig } from '../../../services/api/config';

class FavoriteDocsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    downloadFile = (e, hash, id, fileName) => {
        e.preventDefault();
        let accessToken = localStorage.getItem('accessToken');

        axios({
            headers: {
                'Access-Token': accessToken,
            },
            responseType: 'blob',
            method: 'GET',
            url: `${apiConfig.url}file/${hash}/${id}`,
        })
            .then(response => {
                var blob = new Blob([response.data]);
                FileSaver.saveAs(blob, fileName, { type: response.headers['content-type'] });
            })
            .catch(errors => {
                console.log(errors.response);
            });
    };

    render() {
        const { data } = this.props;

        return (
            <>
                {data.map(item =>
                    item.is_favorite ? (
                        <div key={item.id} className="docs__list">
                            <Link
                                className={`${item.deleted_at === null ? 'linkView' : 'linkViewOpacity'}`}
                                // to={`/null`}
                                to={`/projects/${item.project_id}/documents/${item.project_structure_id}/${item.id}`}>
                                <li className="docs__item">
                                    <div className="docs__icon">
                                        <Icon type={item.type === 'folder' ? 'folder' : 'file'} theme="filled" />
                                    </div>
                                    <div className="docs__description">
                                        <div className="structure__title">{item.title}</div>
                                        <div className="docs__tool">
                                            <Icon type="right" theme="outlined" />
                                        </div>
                                    </div>
                                </li>
                            </Link>
                            <div className="structure-download-wrap">
                                {item.type === 'file' && (
                                    <a href="" onClick={e => this.downloadFile(e, item.file.hash, item.file.id, item.title)}>
                                        <Icon type="download" theme="outlined" />
                                    </a>
                                    // <a href={item.file.full_path} download target="_blank">
                                    //     <Icon type="download" theme="outlined" />
                                    // </a>
                                )}
                            </div>
                            <Button
                                title={'Избранное'}
                                type={'link'}
                                onClick={() => this.props.handleFavorite('document', item.id, item.is_favorite)}
                                className={item.is_favorite ? 'button-favorite button-favorite_active' : 'button-favorite'}>
                                <Icon type="star" theme="filled" />
                            </Button>
                        </div>
                    ) : null,
                )}
            </>
        );
    }
}

export default FavoriteDocsList;
