import * as types from '../../actionTypes';
import { apiConfig } from '../../../services/api/config';
import axios from 'axios';

export const updateUserLoading = () => dispatch => {
    dispatch({ type: types.UPDATE_USER_LOADING });
};

export const updateUserSuccess = response => dispatch => {
    dispatch({
        type: types.UPDATE_USER_SUCCESS,
        user: response,
    });
};

export const updateUserError = response => dispatch => {
    dispatch({
        type: types.UPDATE_USER_ERROR,
        user: response,
    });
};
export const updateUser = data => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}user/update/1`,
        data: data,
    })
        .then(response => {
            localStorage.setItem('currentUser', JSON.stringify(response.data.data)); //fix currentUser changing because if privateRoute.js
            dispatch({
                type: types.UPDATE_USER_SUCCESS,
                user: response.data.data,
            });
        })
        .catch(errors => {
            console.log(errors.response);
        });
};
