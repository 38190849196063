import * as types from '../../actionTypes';

const initialState = {
    data: [],
    currentDoc: {},
    breadcrumb: [],
    success: false,
    successCreate: false,
    successUpload: true,
    errors: [],
    deleteInfo: {
        isDeleted: false,
    },
    restoreInfo: {
        isRestored: false,
    },
    loading: false,
};

export const getDocuments = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DOCUMENTS:
            return {
                ...state,
                data: action.data,
                success: action.success,
                errors: action.errors,
            };
        case types.GET_DOCUMENTS_LOADING:
            return {
                ...state,
                loading: action.loading,
            };
        case types.CREATE_FOLDER:
            // let newArray = [...state.data]
            // newArray.push(action.data)
            return {
                ...state,
                successCreate: true,
            };

        case types.RESET_CREATE_STATUS:
            // let newArray = [...state.data]
            // newArray.push(action.data)
            return {
                ...state,
                successCreate: false,
            };

        case types.SET_CURRENT_DOC:
            return {
                ...state,
                data: action.data,
            };

        case types.UPLOAD_FILE:
            return {
                ...state,
                successUpload: true,
            };

        case types.RESET_UPLOAD_STATUS:
            return {
                ...state,
                successUpload: false,
            };
        case types.SUCCESS_DELETE_DOCUMENT:
            return {
                ...state,
                deleteInfo: {
                    isDeleted: true,
                },
            };
        case types.RESET_DELETED_DOC_STATUS:
            return {
                ...state,
                deleteInfo: {
                    isDeleted: false,
                },
            };
        case types.SUCCESS_RESTORE_DOCUMENT:
            return {
                ...state,
                restoreInfo: {
                    isRestored: true,
                },
            };
        case types.RESET_RESTORED_DOC_STATUS:
            return {
                ...state,
                restoreInfo: {
                    isRestored: false,
                },
            };
        default:
            return state;
    }
};
