import React from 'react';
import { Empty, Icon, Tag, Button } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';

const FavoriteNotesList = props => {
    const { data, common, handleFavorite } = props;

    const compareDate = deadline => {
        if (data && deadline !== null) {
            return moment(moment(new Date()).format('YYYY-MM-DD')).isAfter(deadline);
        }
    };

    return data ? (
        (data || []).map((item, key) =>
            item.is_favorite ? (
                <div key={key}>
                    <div className="flexRow mt16">
                        <Link
                            to={`/projects/${item.project_id}/comments/${item.structure_id}/${item.id}${common ? '?common=1' : ''}`}
                            className="note-list__link">
                            <div className={!item.deleted_at ? 'notesListView' : 'notesListView notesListView_deleted'}>
                                <div className="flexRow">
                                    <div className={item.state} />
                                    <div className="ml16">
                                        <h3>
                                            {' '}
                                            <b>{item.number}</b> {item.title}
                                        </h3>
                                        <div>
                                            <span className="userName">{item.executor.fullname}</span> &nbsp;
                                            <span className="userOrganization">
                                                {item.executor.organization ? item.executor.organization : 'Нет организации'}
                                            </span>
                                        </div>
                                        <Tag className={compareDate(item.date_deadline) ? 'note-list__date red' : 'note-list__date gray'}>
                                            {moment(item.date_created).format('l')}
                                            {item.date_deadline && <> &mdash; {moment(item.date_deadline).format('l')}</>}
                                        </Tag>

                                        {item.noteFiles && item.noteFiles.length > 0 && (
                                            <span className="note-item__icon" title="Прикреплены файлы">
                                                <Icon type="file" theme="outlined" />
                                            </span>
                                        )}

                                        {item.noteComments && item.noteComments.length > 0 && (
                                            <span className="note-item__icon" title="Есть комментарии">
                                                <Icon type="message" theme="outlined" />
                                            </span>
                                        )}

                                        {item.document_id && (
                                            <span className="note-item__icon" title="Отмечено на чертеже">
                                                <Icon type="pushpin" theme="outlined" />
                                            </span>
                                        )}

                                        {item.is_reopen === 1 && (
                                            <span className="note-item__icon" title="Повторно открытое">
                                                <Icon type="exclamation-circle" theme="outlined" style={{ color: '#ed2d23' }} />
                                            </span>
                                        )}

                                        {item.is_important === 1 && (
                                            <span className="note-item__icon" title="Важное">
                                                <Icon type="exclamation-circle" theme="outlined" style={{ color: '#f2c94c' }} />
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="flexRow alignLeft note-executor">
                                    <div className="flexColumn"></div>
                                    <div className="ml16">
                                        <Icon type="right" />
                                    </div>
                                </div>
                            </div>
                            <div className="divider" />
                        </Link>
                        <Button
                            title={'Избранное'}
                            type={'link'}
                            onClick={() => handleFavorite('note', item.id, item.is_favorite)}
                            className={item.is_favorite ? 'button-favorite button-favorite_active' : 'button-favorite'}>
                            <Icon type="star" theme="filled" />
                        </Button>
                    </div>
                </div>
            ) : null,
        )
    ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Нет данных" />
    );
};

export default FavoriteNotesList;
