import * as types from '../../actionTypes';

const initialState = {
    sectionItems: {
        data: [],
        success: false,
        errors: [],
    },
    currentItem: {
        data: {},
        success: false,
        errors: [],
    },
    deleteInfo: {
        isDeleted: false,
        message: '',
    },
    restoreInfo: {
        isRestored: false,
        message: '',
    },
    newItem: {
        success: false,
    },
};

export const getStructure = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_STRUCTURE:
            return {
                ...state,
                sectionItems: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                },
            };

        case types.SET_STRUCTURE:
            return {
                ...state,
                sectionItems: {
                    ...state.sectionItems,
                    data: action.data,
                },
            };
        case types.GET_STRUCTURE_ITEM_LOADING:
            return {
                ...state,
                currentItem: {
                    ...state.currentItem,
                    loading: true,
                },
            };

        case types.GET_STRUCTURE_ITEM_SUCCESS:
            return {
                ...state,
                currentItem: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };

        case types.CREATE_STRUCTURE_ITEM:
            return {
                ...state,
                newItem: {
                    success: action.success,
                },
            };
        case types.SUCCESS_DELETE_STRUCTURE_ITEM:
            return {
                ...state,
                deleteInfo: {
                    isDeleted: true,
                },
            };
        case types.RESET_DELETED_ITEM_STATUS:
            return {
                ...state,
                deleteInfo: {
                    isDeleted: false,
                },
            };
        case types.SUCCESS_RESTORE_STRUCTURE_ITEM:
            return {
                ...state,
                restoreInfo: {
                    isRestored: true,
                },
            };
        case types.RESET_RESTORED_ITEM_STATUS:
            return {
                ...state,
                restoreInfo: {
                    isRestored: false,
                },
            };
        case types.CHANGE_STRUCTURE_ITEM_TITLE:
            return {
                ...state,
                currentItem: {
                    ...state.currentItem,
                    data: {
                        ...state.currentItem.data,
                        children: action.data,
                    },
                },
            };
        default:
            return state;
    }
};
