import React from 'react';
import { Icon } from 'antd';

import './Loading.scss';

function Loading() {
    return (
        <div className="loading">
            <Icon type="loading" />
        </div>
    );
}

export default Loading;
