import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { Tabs, Icon } from 'antd';

import { getProjects, getStructure } from '../../redux/actions/projectModule';
import { getPermissions } from '../../redux/actions/common';

import { ProjectNotes, ProjectNotesDetails } from '../ProjectNotes';
import { ProjectAnalytics } from '../ProjectAnalytics';
import { ProjectStructure } from '../ProjectStructure';
import { ProjectTeam } from '../ProjectTeam';
import { ProjectSettings } from '../ProjectSettings';
import ProjectDocsRout from '../ProjectDocs/ProjectDocsRout';
import { ProjectTasks, ProjectTasksDetails } from '../ProjectTasks';

import './CurrentProject.scss';
const worker = (
    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="anticon">
        <path d="M12 15C7.58 15 4 16.79 4 19V21H20V19C20 16.79 16.42 15 12 15ZM8 9C8 10.0609 8.42143 11.0783 9.17157 11.8284C9.92172 12.5786 10.9391 13 12 13C13.0609 13 14.0783 12.5786 14.8284 11.8284C15.5786 11.0783 16 10.0609 16 9H8ZM11.5 2C11.2 2 11 2.21 11 2.5V5.5H10V3C10 3 7.75 3.86 7.75 6.75C7.75 6.75 7 6.89 7 8H17C16.95 6.89 16.25 6.75 16.25 6.75C16.25 3.86 14 3 14 3V5.5H13V2.5C13 2.21 12.81 2 12.5 2H11.5Z" />
    </svg>
);

const { TabPane } = Tabs;

class CurrentProject extends Component {
    setDefaultActiveKey = (section, projectId) => {
        const {
            getData: {
                permission: { getPermission },
            },
        } = this.props;

        if (section === 'structure') {
            return '1';
        }
        if (section === 'documents') {
            return '2';
        }
        if (section === 'comments') {
            getPermission(projectId);

            return '3';
        }
        if (section === 'team') {
            return '4';
        }
        if (section === 'analytics') {
            return '5';
        }
        if (section === 'settings') {
            return '6';
        }
        if (section === 'tasks') {
            getPermission(projectId);

            return '7';
        }
    };

    checkStructure = () => {
        const { structure, match } = this.props;

        if (this.props.rootDocumentId === 0 || !structure.id || structure.id !== +match.params.currentStructureId) {
            this.getCurrentStructure();
        }
    };

    getCurrentStructure = () => {
        const {
            getData: {
                structure: { getStructure },
            },
            rootStructureId,
            match,
        } = this.props;

        const { projectId, currentStructureId } = match.params;

        if (!currentStructureId) {
            getStructure(projectId, rootStructureId);
        } else {
            getStructure(projectId, currentStructureId);
        }
    };

    handleTabClick = e => {
        const { match, rootDocumentId } = this.props;
        const { path, projectId, currentStructureId } = match.params;

        switch (e) {
            case '1':
                this.props.history.push(`/${path}/${projectId}/structure/${currentStructureId}`);
                break;
            case '2':
                this.props.history.push(`/${path}/${projectId}/documents/${currentStructureId}/${rootDocumentId}`);
                break;
            case '3':
                this.props.history.push(`/${path}/${projectId}/comments/${currentStructureId}`);
                break;
            case '4':
                this.props.history.push(`/${path}/${projectId}/team/${currentStructureId}`);
                break;
            case '5':
                this.props.history.push(`/${path}/${projectId}/analytics/${currentStructureId}`);
                break;
            case '6':
                this.props.history.push(`/${path}/${projectId}/settings/${currentStructureId}`);
                break;
            case '7':
                this.props.history.push(`/${path}/${projectId}/tasks/${currentStructureId}`);
                break;
        }
    };

    componentDidMount() {
        if (this.props.match.params.section !== 'structure') {
            this.checkStructure();
        }

        if (!this.props.projects.success) {
            this.props.getProjects();
        }
    }

    componentWillReceiveProps(nextProps) {
        const { match } = this.props;

        if (
            this.props.match.params.section !== 'structure' &&
            +match.params.currentStructureId !== +nextProps.match.params.currentStructureId
        ) {
            const {
                getData: {
                    structure: { getStructure },
                },
                match,
            } = this.props;

            const { projectId } = match.params;

            getStructure(projectId, +nextProps.match.params.currentStructureId);
        }
    }

    render() {
        const { rootDocumentId, structure, projects, ...rest } = this.props;
        const { section, projectId, currentStructureId, currentSectionItemDetails } = this.props.match.params;

        let currentProject = null;
        if (projects.success) {
            currentProject = projects.data.find(item => item.id === +projectId);
        }

        let documentId = structure?.root_document_id;

        if (section === 'documents' && currentSectionItemDetails) {
            documentId = currentSectionItemDetails;
        }

        return (
            <>
                <Tabs size="large" onTabClick={this.handleTabClick} activeKey={this.setDefaultActiveKey(section, structure?.project_id)}>
                    <TabPane
                        tab={
                            <span>
                                <Icon type="apartment" theme="outlined" />
                                Структура
                            </span>
                        }
                        key="1"></TabPane>
                    <TabPane
                        tab={
                            <span>
                                <Icon type="folder" theme="outlined" />
                                Документы
                            </span>
                        }
                        key="2"
                        disabled={this.props.isLoadingStructure}></TabPane>
                    <TabPane
                        tab={
                            <span>
                                <Icon type="exclamation-circle" theme="outlined" />
                                Замечания
                            </span>
                        }
                        key="3"></TabPane>
                    {currentProject && currentProject.show_tasks && (
                        <TabPane
                            tab={
                                <span>
                                    {worker}
                                    Заявки на освидетельствование
                                </span>
                            }
                            key="7"></TabPane>
                    )}
                    <TabPane
                        tab={
                            <span>
                                <Icon type="team" theme="outlined" />
                                Участники
                            </span>
                        }
                        key="4">
                        <ProjectTeam {...rest} />
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <Icon type="rise" theme="outlined" />
                                Аналитика
                            </span>
                        }
                        key="5"></TabPane>
                    <TabPane
                        tab={
                            <span>
                                <Icon type="setting" theme="outlined" />
                                Настройки
                            </span>
                        }
                        key="6"></TabPane>
                </Tabs>

                <Route exact path="/:path/:projectId/structure/:currentStructureId" render={() => <ProjectStructure {...rest} />} />
                <Route
                    exact
                    path="/:path/:projectId/documents/:currentStructureId/:documentId(\d+)"
                    render={() => <ProjectDocsRout {...rest} rootDocumentId={documentId} />}
                />
                <Route
                    exact
                    path="/:path/:projectId/comments/:currentStructureId"
                    render={() => <ProjectNotes {...rest} currentStructureId={currentStructureId} />}
                />
                <Route
                    exact
                    path="/:path/:projectId/comments/:currentStructureId/:currentSectionItemDetails(\d+)"
                    render={() => <ProjectNotesDetails {...rest} rootDocumentId={documentId} />}
                />
                <Route
                    exact
                    path="/:path/:projectId/tasks/:currentStructureId"
                    render={() => <ProjectTasks {...rest} currentStructureId={currentStructureId} />}
                />
                <Route
                    exact
                    path="/:path/:projectId/tasks/:currentStructureId/:currentSectionItemDetails(\d+)"
                    render={() => <ProjectTasksDetails {...rest} rootDocumentId={documentId} />}
                />
                <Route
                    exact
                    path="/:path/:projectId/analytics/:currentStructureId"
                    render={() => <ProjectAnalytics {...rest} currentStructureId={currentStructureId} />}
                />
                <Route
                    exact
                    path="/:path/:projectId/settings/:currentStructureId"
                    render={() => <ProjectSettings {...rest} currentStructureId={currentStructureId} />}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        projects: state.projectModule.projects.projectsList,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getData: {
            structure: {
                getStructure: function (projectId, structureId) {
                    return dispatch(getStructure(projectId, structureId));
                },
            },
            permission: {
                getPermission: function (projectId) {
                    return dispatch(getPermissions(projectId));
                },
            },
        },
        getProjects: function () {
            // ! УДАЛИТЬ ПОСЛЕ ОДОБРЕНИЯ ПРОМЕЖУТОЧНЫХ ОБЪЕМОВ
            return dispatch(getProjects());
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentProject);
