import React from 'react';
import { Empty, Icon, Tag } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';

const TasksList = props => {
    const { data, common, link } = props;

    const compareDate = deadline => {
        if (data && deadline !== null) {
            return moment(moment(new Date()).format('YYYY-MM-DD')).isAfter(deadline);
        }
    };

    return data ? (
        (data || []).map((item, key) => (
            <div key={key}>
                <div className="flexRow mt16">
                    <Link
                        to={`/projects/${link.projectId}/tasks/${link.currentStructureId}/${item.id}${common ? '?common=1' : ''}`}
                        className="task-item__link">
                        <div className={!item.deleted_at ? 'tasksListView' : 'tasksListView tasksListView_deleted'}>
                            <div className="flexRow">
                                <div className="task-item__status" style={{ backgroundColor: item.state.color }} />
                                <div className="ml16">
                                    <div>
                                        <span className="task-item__number">{item.id}</span>
                                    </div>
                                    <h3>{item.work_section_title}</h3>
                                    <div>
                                        <span className="userName">{item.engineer.fullname}</span> &nbsp;
                                        <span className="userOrganization">
                                            {item.engineer.organization ? item.engineer.organization : 'Нет организации'}
                                        </span>
                                    </div>
                                    {/* {item.reviewDatetimes.map(date => (
                                        <Tag className="task-item__date gray">{date}</Tag>
                                    ))} */}
                                    {item.nearestReviewDatetime && (
                                        <Tag className="task-item__date gray">
                                            {moment(item.nearestReviewDatetime).format('DD.MM.YYYY HH:mm')}
                                        </Tag>
                                    )}

                                    {item.hasFiles && (
                                        <span className="task-item__icon" title="Прикреплены файлы">
                                            <Icon type="file" theme="outlined" />
                                        </span>
                                    )}

                                    {item.hasComments && (
                                        <span className="task-item__icon" title="Есть комментарии">
                                            <Icon type="message" theme="outlined" />
                                        </span>
                                    )}

                                    {/*{item.document_id && (*/}
                                    {/*    <span className="task-item__icon" title="Отмечено на чертеже">*/}
                                    {/*        <Icon type="pushpin" theme="outlined" />*/}
                                    {/*    </span>*/}
                                    {/*)}*/}

                                    {/*{item.is_reopen === 1 && (*/}
                                    {/*    <span className="task-item__icon" title="Повторно открытое">*/}
                                    {/*        <Icon type="exclamation-circle" theme="outlined" style={{ color: '#ed2d23' }} />*/}
                                    {/*    </span>*/}
                                    {/*)}*/}

                                    {/*{item.is_important === 1 && (*/}
                                    {/*    <span className="task-item__icon" title="Важное">*/}
                                    {/*        <Icon type="exclamation-circle" theme="outlined" style={{ color: '#f2c94c' }} />*/}
                                    {/*    </span>*/}
                                    {/*)}*/}
                                </div>
                            </div>
                            <div className="ml16">
                                <Icon type="right" />
                            </div>
                        </div>
                        <div className="divider" />
                    </Link>
                </div>
            </div>
        ))
    ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Нет данных" />
    );
};

export default TasksList;
