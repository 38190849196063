import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Upload, Button, Input, Icon, Popconfirm, Empty } from 'antd';

import { apiConfig } from '../../services/api/config';
import {
    createFolder,
    getDocuments,
    uploadFile,
    resetUploadStatus,
    resetCreateStatus,
    deleteDocument,
    resetDeleteDocStatus,
    restoreDocument,
    resetRestoredDocStatus,
} from '../../redux/actions/projectModule';

import { BreadCrumbs } from '../BreadCrumbs';
import { SearchForm } from '../SearchForm';
import { Loading } from '../Loading';

import axios from 'axios';
import FileSaver from 'file-saver';

import './ProjectDocs.scss';
import MarkerCopyInfo from './MarkerCopyInfo';

class ProjectDocs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeModalName: null,
            visible: false,
            folderName: null,
            fileName: null,
            selectedFile: [],
            selectedFileList: [],
            errorText: null,
            searchValue: '',
            searchedDocuments: null,
        };
    }

    setFolderName = e => {
        this.setState({
            folderName: e.target.value,
        });
    };

    setFileName = e => {
        this.setState({
            fileName: e.target.value,
        });
    };

    showModal = modalName => {
        this.setState({
            activeModalName: modalName,
            visible: true,
        });
    };

    handleOk = () => {
        const { createFolder, uploadFile, getDocuments, rootStructureId, match, rootDocumentId } = this.props;
        const { projectId, currentStructureId, currentSectionItemDetails } = match.params;
        const { activeModalName, folderName, selectedFile, selectedFileList, fileName } = this.state;

        if (activeModalName === 'createFolder') {
            createFolder(projectId, currentStructureId, rootStructureId, currentSectionItemDetails, folderName);
        } else {
            selectedFile.forEach(item => {
                uploadFile(projectId, currentStructureId, rootStructureId, currentSectionItemDetails, item, null);
            });
        }

        this.handleCancel();
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            folderName: null,
            fileName: null,
            selectedFile: [],
            selectedFileList: [],
        });
    };

    onChange = info => {
        switch (info.file.status) {
            case 'uploading':
                this.setState(state => {
                    return { selectedFileList: [...state.selectedFileList, info.file] };
                });
                break;
            case 'done':
                this.setState(state => {
                    let copyFileList = [...this.state.selectedFileList];
                    copyFileList = copyFileList.filter(item => item.uid !== info.file.uid);
                    return { selectedFileList: [...copyFileList, info.file] };
                });
                break;
            default:
                // nextState.selectedFileList = [];
                break;
        }
    };

    onRemove = () => {
        this.setState({
            selectedFile: null,
        });
    };

    handleRequest = ({ onSuccess, file }) => {
        this.setState(state => {
            return { selectedFile: [...state.selectedFile, file] };
        });

        setTimeout(() => {
            onSuccess();
        }, 0);
    };

    getRootDocumentId = () => {
        const { getStructure, rootStructureId } = this.props;
        const { projectId, currentStructureId } = this.props.match.params;

        if (!currentStructureId) {
            getStructure(projectId, rootStructureId);
        } else {
            getStructure(projectId, currentStructureId);
        }
    };

    handlePopConfirm = async (id, status) => {
        const { match } = this.props;
        const { projectId, currentStructureId, currentSectionItemDetails } = match.params;
        const { deleteDocument, restoreDocument } = this.props;

        switch (status) {
            case 'restore':
                restoreDocument(projectId, currentStructureId, currentSectionItemDetails, id);
                break;
            case 'delete':
                deleteDocument(projectId, currentStructureId, currentSectionItemDetails, id);
                break;
        }
    };

    handleSearchFilter = value => {
        const documents = this.props.documents.currentItem.data.children;
        const filteredDocuments = documents.filter(item => {
            return Object.keys(item).some(() => {
                if (item['title'] !== null) {
                    return item['title'].toLowerCase().includes(value.toLowerCase());
                }
            });
        });

        this.setState({
            searchedDocuments: !value ? null : filteredDocuments,
        });
    };

    handleSearchChange = e => {
        const { value } = e.target;

        if (value.length < 1) {
            this.setState({
                searchedDocuments: null,
            });
        }
        this.setState({
            searchValue: value,
        });
        this.handleSearchFilter(value);
    };

    handleSearchReset = () => {
        this.setState({
            searchValue: '',
            searchedDocuments: null,
        });
    };

    downloadFile = (e, hash, id, fileName) => {
        e.preventDefault();

        let accessToken = localStorage.getItem('accessToken');

        axios({
            headers: {
                'Access-Token': accessToken,
            },
            responseType: 'blob',
            method: 'GET',
            url: `${apiConfig.url}file/${hash}/${id}`,
        })
            .then(response => {
                var blob = new Blob([response.data]);
                FileSaver.saveAs(blob, fileName, { type: response.headers['content-type'] });
            })
            .catch(errors => {
                console.log(errors.response);
            });
    };

    componentDidMount() {
        // this.getRootDocumentId();
    }

    render() {
        const { rootStructureId, rootDocumentId, documents, loading } = this.props;
        const { fileName, folderName, activeModalName, visible, selectedFileList, searchValue, searchedDocuments } = this.state;
        const { currentItem } = documents;
        const { path, projectId, section, currentStructureId } = this.props.match.params;

        return (
            <div className="structure">
                {currentItem.data === null || Object.keys(currentItem.data).length === 0 ? null : (
                    <BreadCrumbs prevItems={currentItem.data} link={this.props.match.params} />
                )}
                <MarkerCopyInfo {...{ match: this.props.match }} />
                <div className="row">
                    <ul className="add_structure">
                        <li className="add_structure_item">
                            <Button
                                type="primary"
                                onClick={() => {
                                    this.showModal('createFolder');
                                }}>
                                Создать папку
                            </Button>
                        </li>
                        <li className="add_structure_item">
                            {/* <Upload
                                fileList={selectedFileList}
                                customRequest={this.handleRequest}
                                onChange={this.onChange}
                                onRemove={this.onRemove}
                                multiple={true}>
                                <Button type="primary">Загрузить файл</Button>
                            </Upload> */}
                            <Button
                                type="primary"
                                onClick={() => {
                                    this.showModal('uploadFile');
                                }}>
                                Загрузить файл
                            </Button>
                        </li>
                    </ul>
                    <SearchForm
                        searchValue={searchValue}
                        handleSearchFilter={this.handleSearchFilter}
                        handleSearchChange={this.handleSearchChange}
                        handleSearchReset={this.handleSearchReset}
                    />
                </div>
                {!loading ? (
                    <ul style={{ width: '100%' }}>
                        {!currentItem.success || currentItem.data.children.length === 0 ? (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Документов не найдено" />
                        ) : (
                            <>
                                {(searchedDocuments || currentItem.data.children).map(item => (
                                    <div key={item.id} className="docs__list">
                                        <Link
                                            className={`${item.deleted_at === null ? 'linkView' : 'linkViewOpacity'}`}
                                            to={`/${path}/${projectId}/${section}/${
                                                currentStructureId ? currentStructureId : rootStructureId
                                            }/${item.id}`}
                                            onClick={this.handleSearchReset}>
                                            <li className="docs__item">
                                                <div className="docs__icon">
                                                    <Icon type={item.type === 'folder' ? 'folder' : 'file'} theme="filled" />
                                                </div>
                                                <div className="docs__description">
                                                    <div className="structure__title">{item.title}</div>
                                                    <div className="docs__tool">
                                                        <Icon type="right" theme="outlined" />
                                                    </div>
                                                </div>
                                            </li>
                                        </Link>
                                        <div className="structure-download-wrap">
                                            {item.type === 'file' && (
                                                <a href="" onClick={e => this.downloadFile(e, item.file.hash, item.file.id, item.title)}>
                                                    <Icon type="download" theme="outlined" />
                                                </a>
                                                // <a href={item.file.full_path} download target="_blank">
                                                //     <Icon type="download" theme="outlined" />
                                                // </a>
                                            )}
                                        </div>
                                        <Popconfirm
                                            title={
                                                item.deleted_at === null
                                                    ? 'Вы точно хотите удалить документ?'
                                                    : 'Вы точно хотите восстановить документ'
                                            }
                                            onConfirm={() =>
                                                this.handlePopConfirm(item.id, item.deleted_at !== null ? 'restore' : 'delete')
                                            }
                                            onCancel={() => console.log('')}
                                            okText="Да"
                                            cancelText="Нет">
                                            <div className="structure__remove">
                                                {item.deleted_at === null ? (
                                                    <Icon type="delete" style={{ fontSize: 20, color: '#d32f2f' }} size={24} />
                                                ) : (
                                                    <Icon type="redo" style={{ fontSize: 20, color: '#666666' }} size={24} />
                                                )}
                                            </div>
                                        </Popconfirm>
                                    </div>
                                ))}
                            </>
                        )}
                    </ul>
                ) : (
                    <Loading />
                )}
                <Modal
                    title={activeModalName === 'createFolder' ? 'Создание папки' : 'Загрузка файла'}
                    okText={activeModalName === 'createFolder' ? 'Создать папку' : 'Загрузить файл'}
                    cancelText="Отменить"
                    visible={visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}>
                    {activeModalName === 'createFolder' ? (
                        <div className="modal__item">
                            <Input value={folderName} onChange={this.setFolderName} placeholder="Имя папки" />
                        </div>
                    ) : (
                        <>
                            <div className="modal__item">
                                <Upload.Dragger
                                    fileList={selectedFileList}
                                    customRequest={this.handleRequest}
                                    onChange={this.onChange}
                                    onRemove={this.onRemove}
                                    multiple={true}>
                                    <p className="ant-upload-drag-icon">
                                        <Icon type="inbox" />
                                    </p>
                                    <p>Нажмите или перетащите файл в эту область, чтобы загрузить</p>
                                </Upload.Dragger>
                            </div>
                        </>
                    )}
                </Modal>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createFolder: function (projectId, structureId, rootStructureId, documentId, folderName) {
            return dispatch(createFolder(projectId, structureId, rootStructureId, documentId, folderName));
        },
        getDocuments: function (projectId, structureId, id) {
            return dispatch(getDocuments(projectId, structureId, id));
        },
        resetUploadStatus: function () {
            return dispatch(resetUploadStatus());
        },
        resetCreateStatus: function () {
            return dispatch(resetCreateStatus());
        },
        uploadFile: function (projectId, structureId, rootStructureId, documentId, file, fileName) {
            return dispatch(uploadFile(projectId, structureId, rootStructureId, documentId, file, fileName));
        },
        deleteDocument: function (projectId, structureId, sectionId, documentId) {
            return dispatch(deleteDocument(projectId, structureId, sectionId, documentId));
        },
        resetDeleteDocStatus: function () {
            return dispatch(resetDeleteDocStatus());
        },
        restoreDocument: function (projectId, structureId, sectionId, documentId) {
            return dispatch(restoreDocument(projectId, structureId, sectionId, documentId));
        },
        resetRestoredDocStatus: function (projectId, structureId, documentId) {
            return dispatch(resetRestoredDocStatus(projectId, structureId, documentId));
        },
    };
}

function mapStateToProps(state) {
    return {
        currentStructure: state.projectModule,
        documents: {
            currentItem: state.projectModule.documents,
        },
        deleteInfo: state.projectModule.documents.deleteInfo,
        restoreInfo: state.projectModule.documents.restoreInfo,
        loading: state.projectModule.documents.loading,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDocs);
