import React, { Component } from 'react';
import { Drawer, Row, Col, Button, Select } from 'antd';
const { Option } = Select;

import './LayersControl.scss';

class LayersControl extends Component {
    constructor(props) {
        super(props);

        const location = props.history?.location?.search;

        const isQualityFromLocation = location?.indexOf('isQuality=0') !== -1 ? 0 : location?.indexOf('isQuality=1') !== -1 ? 1 : undefined;
        const isQualityFromUser = props.projectUserInfo.is_quality_control ? 1 : 0;

        this.state = {
            selectValue: isQualityFromLocation !== undefined ? isQualityFromLocation : isQualityFromUser,
            visibleMarkers: {
                open: {
                    title: 'Открытые',
                    isActive: true,
                },
                inwork: {
                    title: 'Выполненные',
                    isActive: true,
                },
                completed: {
                    title: 'Проверенные',
                    isActive: true,
                },
                elimination: {
                    title: 'Не требует устранения',
                    isActive: true,
                },
            },
        };
    }

    layerSelectValues = [
        { id: 0, title: 'УСК' },
        { id: 1, title: 'ОКК' },
    ];

    handleClick = key => {
        const { visibleMarkers } = this.state;
        visibleMarkers[key].isActive = !visibleMarkers[key].isActive;

        this.props.changeVisibleMarkers(visibleMarkers);

        this.setState({
            visibleMarkers,
        });
    };

    handleChange = value => {
        this.props.changeQualityControl(value);
        this.setState({
            selectValue: value,
        });
    };

    componentDidMount() {
        this.props.changeVisibleMarkers(this.state.visibleMarkers);
        this.props.changeQualityControl(this.state.selectValue);
    }

    renderSelect() {
        return (
            <Select
                mode={'default'}
                style={{ width: '100%' }}
                onChange={this.handleChange}
                placeholder={'Не выбран'}
                showArrow
                value={this.props.qualityControl}>
                {this.layerSelectValues.map((item, key) => (
                    <Option key={key} value={item.id}>
                        {item.title}
                    </Option>
                ))}
            </Select>
        );
    }

    renderControls() {
        const controls = [],
            { visibleMarkers } = this.state;

        for (const key in visibleMarkers) {
            controls.push(
                <Row key={key} className="layers-control__item">
                    <Col span={18}>{visibleMarkers[key].title}</Col>
                    <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            type={visibleMarkers[key].isActive ? 'primary' : 'default'}
                            shape="circle"
                            icon={visibleMarkers[key].isActive ? 'eye' : 'eye-invisible'}
                            onClick={() => this.handleClick(key)}
                        />
                    </Col>
                </Row>,
            );
        }

        return controls;
    }

    render() {
        return (
            <Drawer
                title="Слои"
                placement="right"
                visible={this.props.visible}
                closable={false}
                onClose={this.props.handleClose}
                zIndex={1002}>
                <div className="layers-control">
                    {
                        <>
                            {this.renderSelect()}
                            {this.renderControls()}
                        </>
                    }
                </div>
            </Drawer>
        );
    }
}

export default LayersControl;
