import React from 'react';
import { connect } from 'react-redux';
import { Avatar, Button, Form, Input, Upload } from 'antd';

import { updateUser } from '../../redux/actions/common/currentUser';

import './Profile.scss';

class Profile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: null,
            phone: null,
            password: null,
            confirmDirty: null,
            imageUrl: null,
            imageName: null,
        };

        this.handleInput = this.handleInput.bind(this);
    }
    componentDidMount() {
        const { currentUser, form } = this.props;

        form.setFieldsValue({
            name: currentUser.fullName,
            phone: currentUser.phone,
            email: currentUser.email,
            organization: currentUser.organization,
        });
    }
    handleInput = (e, key) => {
        let value = e.target.value;

        switch (key) {
            case 0:
                this.setState({
                    name: value,
                });
                break;
            case 1:
                this.setState({
                    phone: value,
                });
                break;
            case 2:
                this.setState({
                    password: value,
                });
                break;
        }
    };
    handleSubmit = e => {
        e.preventDefault();
        const { imageName, imageUrl } = this.state;
        const { updateUser } = this.props;

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let data = {
                    fullName: values.name,
                    phone: values.phone,
                    avatar: imageUrl ? imageUrl : null,
                    avatarName: imageName ? imageName : null,
                    password: values.password,
                    repeat_password: values.confirm,
                };
                updateUser(data);
            }
        });
    };
    getBase64 = (img, callback) => {
        const reader = new FileReader();

        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    handleUploadImg = value => {
        this.getBase64(value.file.originFileObj, imageUrl =>
            this.setState({
                imageUrl: imageUrl,
                imageName: value.file.name,
            }),
        );
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        const { currentUser } = this.props;
        const { imageUrl } = this.state;

        return (
            <div className="alignCenter">
                <div className="m16 halfWidth">
                    <div className="spaceBetween">
                        <h1>ИЗОБРАЖЕНИЕ</h1>
                        <div className="mt16">
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                onChange={this.handleUploadImg}>
                                {imageUrl ? (
                                    <Avatar size={128} shape="square" src={imageUrl} />
                                ) : currentUser.avatar ? (
                                    <Avatar size={128} shape="square" src={currentUser.avatar} />
                                ) : (
                                    <span>Загрузить</span>
                                )}
                            </Upload>
                        </div>
                    </div>
                    <div className="mt16">
                        <h1>ОСНОВНАЯ ИНФОРМАЦИЯ</h1>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Item className="inputLabel" label="Имя">
                                {getFieldDecorator('name', {
                                    rules: [{ required: true, message: 'Пожалуйста, введите ваше имя' }],
                                })(<Input onChange={e => this.handleInput(e, 0)} placeholder="Ваше имя" />)}
                            </Form.Item>
                            <Form.Item className="inputLabel" label="Организация">
                                {getFieldDecorator('organization', {
                                    rules: [{ required: false, message: 'Пожалуйста, введите ваше имя' }],
                                })(<Input disabled={true} placeholder="Ваша организация" />)}
                            </Form.Item>
                            <Form.Item className="inputLabel" label="Телефон">
                                {getFieldDecorator('phone', {
                                    rules: [{ message: 'Пожалуйста, введите ваш телефон' }],
                                })(<Input onChange={e => this.handleInput(e, 1)} placeholder="Ваш Телефон" />)}
                            </Form.Item>
                            <Form.Item className="inputLabel" label="Email">
                                {getFieldDecorator('email', {
                                    rules: [{ required: false, message: 'Пожалуйста, введите ваше email' }],
                                })(<Input disabled={true} placeholder="Ваш Email" />)}
                            </Form.Item>
                        </Form>
                        <Button className="mt16" type="primary" onClick={e => this.handleSubmit(e)}>
                            Сохранить
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    currentUser: state.user.user,
});

const mapDispatchToProps = dispatch => ({
    updateUser: data => dispatch(updateUser(data)),
});

const WrappedToForm = Form.create({ name: 'profile_form' })(Profile);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedToForm);
