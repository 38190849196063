import React from 'react';
import { connect } from 'react-redux';
import { Icon } from 'antd';

import { getSettings, updateSetting } from '../../redux/actions/projectModule';
import { SettingsList } from './Components';

import './Settings.scss';

class Settings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    logout() {
        // Just copided from Navigatoin - I think it should be in actions
        localStorage.removeItem('accessToken');
        localStorage.removeItem('currentUser');
        this.props.history.replace('/signin');
    }

    componentDidMount() {
        const { getSettings } = this.props;

        getSettings();
    }

    handleSwitch = (checked, propName, propValue) => {
        const { updateSetting } = this.props;

        let data = {
            property_name: propName,
            property_value: checked === true ? 1 : 0,
        };

        updateSetting(data);
    };

    render() {
        const { settings } = this.props;

        return (
            <>
                <div className="alignCenter mt16">
                    <div className="settingsView">
                        <SettingsList handleSwitch={this.handleSwitch} data={settings.data} />
                        <div>
                            <h1>ОСТАЛЬНОЕ:</h1>
                            <div onClick={() => this.logout()} className="flexRow spaceBetween pointer">
                                <div className="flexRow">
                                    <div className="iconContainer">
                                        <Icon style={{ fontSize: 28 }} type="logout" />
                                    </div>
                                    <div className="ml16">
                                        <span>Выйти</span>
                                    </div>
                                </div>
                                <Icon style={{ fontSize: 20 }} type="right" />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.projectModule.settings,
});

const mapDispatchToProps = dispatch => ({
    getSettings: () => dispatch(getSettings()),
    updateSetting: data => dispatch(updateSetting(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
