import { Button, Checkbox, DatePicker, Divider, Form, Icon, Input, Modal, Row, Select, Spin, Tooltip, Upload, message } from 'antd';
import locale from 'antd/es/locale/ru_RU';
import Text from 'antd/lib/typography/Text';
import axios from 'axios';
import FileSaver from 'file-saver';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { connect } from 'react-redux';

import utils from '../../../helpers/utils';
import {
    archiveNote,
    changeFavorite,
    changeNote,
    createInspectionNotes,
    createNoteComment,
    deleteNoteFile,
    getAllNtd,
    getAuditors,
    getContracts,
    getCounterParties,
    getDictionary,
    getExecutors,
    getFiltredDictionary,
    getFiltredExecutors,
    getInspectionNotes,
    getNoteById,
    getNoteWorkTypes,
    getNtdById,
    getOrganizations,
    getRetentionUnits,
    getTreeNtd,
    resetArchiveStatus,
    resetNoteWorkTypes,
    restoreNote,
    setNote,
    uploadNoteFile,
} from '../../../redux/actions/projectModule';
import { apiConfig } from '../../../services/api/config';

import ModalDictionary from './ModalDictionary';
import NoteHistory from './NoteHistory';
import { CustomControlledSelect, CustomSelect } from './index';

const { TextArea } = Input;
const { Option } = Select;

const insuranceAgentIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 401.85 464" fill="currentColor" width="1em" height="1em">
        <path d="M114.35,0a63,63,0,1,0,0,126h.1A63,63,0,1,0,114.39,0Z" />
        <path d="M210.86,271.12l16.19-7.89a96,96,0,0,0-94.66-80.3H96.49c-52.94,0-96.49,43-96.49,96V383.85H209.4a71.34,71.34,0,0,1-2.48-18V277.45A7.08,7.08,0,0,1,210.86,271.12Z" />
        <path d="M220.91,281.82v84c0,20.2,13.65,45.89,30.35,57.28L311.31,464l60.07-40.91c16.7-11.39,30.47-37.08,30.47-57.28V282l-90.47-44.34Zm132.31,39.76a7,7,0,0,1,0,9.89L304,380.75a7.07,7.07,0,0,1-4.95,2.1h0a7,7,0,0,1-5-2.13l-24.91-25.16a7,7,0,0,1,9.95-9.88l19.95,20.15,44.24-44.24A7,7,0,0,1,353.22,321.58Z" />
    </svg>
);

const ProjectNoteDetail = props => {
    const {
        data,
        statusData,
        changeStatus,
        getNoteById,
        getAllNtd,
        getNtdById,
        getTreeNtd,
        changeNote,
        getExecutors,
        getFiltredExecutors,
        getAuditors,
        getDictionary,
        getRetentionUnits,
        getNoteWorkTypes,
        getContracts,
        getFiltredDictionary,
        getCounterParties,
        getOrganizations,
        getInspectionNotes,
        createComment,
        createInspectionNotes,
        executors,
        dictionary,
        auditors,
        ntd,
        treeNtd,
        permission,
        deleteNoteFile,
        uploadNoteFile,
        isNoteArchived,
        archiveNote,
        restoreNote,
        resetArchiveStatus,
        params,
        retentionUnits,
        workTypes,
        currentStructure,
        counterparties,
        organizations,
        inspectionNotes,
        isChangeNote,
        singleNote,
        resetNoteWorkTypes,
        user,
    } = props;

    const projectId = !props.match ? +params.projectId : props.match.params.projectId;
    const rootStructureId = !props.match ? +params.currentStructureId : props.match.params.currentStructureId;

    let tempFiltredNtd = [];
    let timerSearchNtd;
    let timerSearchLocalNtd;

    const retentionStatus = [
        { id: null, title: 'Не указан' },
        { id: 1, title: 'Требует устранения' },
        { id: 0, title: 'Не требует устранения' },
    ];

    // const [workTypeText, setWorkTypeText] = useState('');
    const [titleValue, setTitleValue] = useState(null);
    const [descriptionValue, setDescriptionValue] = useState(null);
    const [correctiveActionValue, setCorrectiveActionValue] = useState(null);
    const [executorValue, setExecutorValue] = useState('');
    const [executorId, setExecutorId] = useState(null);
    const [dateDeadlineValue, setDateDeadlineValue] = useState('');
    const [selectedFileList, setSelectedFileList] = useState(null);
    const [removeBtnIsShown, setRemoveBtnIsShown] = useState(false);
    const [ntdData, setNtdData] = useState(null);
    const [ntdFolderName, setNtdFolderName] = useState(null);
    const [ntdModalIsVisible, setNtdModalIsVisible] = useState(null);
    const [ntdIsChosen, setNtdIsChosen] = useState(false);
    const [retentionCount, setRetentionCount] = useState(null);
    const [ntdBack, setNtdBack] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchNtdLocalValue, setSearchNtdLocalValue] = useState('');
    const [filtredNtd, setFiltredNtd] = useState(null);
    const [canEditRetention, setCanEditRetention] = useState(true);
    const [isShowGallery, setShowGallery] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [comment, setComment] = useState('');
    const [organizationId, setOrganizationId] = useState(null);
    const [counterParty, setcounterParty] = useState(null);

    const [isVisibleDictionary, setIsVisibleDictionary] = useState(false);
    const noteStatus = data ? utils.notesHelper(data.state) : null;

    // workType states
    const [workType, setWorkType] = useState(undefined);
    const [tempWorkTypeValue, setTempWorkTypeValue] = useState(undefined);
    const [workTypeLoading, setWorkTypeLoading] = useState(false);
    const [canEditWorkType, setCanEditWorkType] = useState(null);

    // AX warning modal
    const [axModal1Visible, setAxModal1Visible] = useState(false);
    const [axModal2Visible, setAxModal2Visible] = useState(false);
    const [axModal3Visible, setAxModal3Visible] = useState(false);
    const [confirmModalIsVisible, setConfirmModalIsVisible] = useState(false);
    const [commentVisible, setCommentVisible] = useState(false);
    const [confirmModalComment, setConfirmModalComment] = useState('');

    const [inspectionValues, setInspectionValues] = useState({
        inspection_index: null,
        inspection_number: null,
        inspection_location: null,
        noteIds: [],
        isAllowed: false,
    });

    const [inspectionModals, setInspectionModals] = useState({
        checkListModalOpen: false,
        successModal: false,
    });

    const isChosenAllNotes = useMemo(() => {
        const inspectionNotesData = inspectionNotes.data ? inspectionNotes.data : [];

        if (inspectionNotesData.length === 0) {
            return false;
        }

        return inspectionValues.noteIds.length === inspectionNotesData.length;
    }, [inspectionValues, inspectionNotes]);

    const correctivePermission = permission.data.length
        ? permission.data.find(item => item.name === 'note.change.corrective.action')
        : null;

    const checkExecutorImages = permission.data.find(item => item.name === 'note.files.required')?.isAllowed;

    const auditorsHashMapBooleanValue = useMemo(() => {
        return auditors.data?.reduce((acc, auditor) => {
            acc[auditor.id ?? 0] = true;

            return acc;
        }, {});
    }, [auditors.data]);

    const mergedDeletedAuditorsArray = useMemo(() => {
        if (!data.noteAudits?.length) {
            return auditors.data ?? [];
        }

        const auditorsCopy = [...(auditors.data ?? [])];

        for (let i = 0; i < data.noteAudits.length; i++) {
            const element = data.noteAudits?.[i];

            if (!auditorsHashMapBooleanValue[element.id]) {
                auditorsCopy.push(element);
            }
        }

        return auditorsCopy;
    }, [auditors.data, data.noteAudits]);

    const checkDeadlinePermissions = useMemo(() => {
        const isOwn = data?.user?.id === user?.id;
        const canChangeAll = permission.data.length ? !!permission.data.find(item => item.name === 'note.edit.stranger')?.isAllowed : false;
        const canChangeOwn = permission.data.length ? !!permission.data.find(item => item.name === 'note.edit.own')?.isAllowed : false;

        const canRetentionChange = data.retention_status_id !== 0;

        const canUserChange = canChangeAll || (isOwn && canChangeOwn);

        return canRetentionChange && canUserChange;
    }, [permission, data, user]);

    //// Effects
    // work type effects
    useEffect(() => {
        if (workTypes.success) {
            if (workTypes.data && workTypes.data.workTypes && workTypes.data.workTypes.length) {
                // set default work type
                if (data.workType) {
                    setWorkType(data.workType.id);
                }
            } else {
                // Reset if work types empty
                setWorkType(undefined);
            }
        }
    }, [data, workTypes]);

    useEffect(() => {
        return () => {
            resetNoteWorkTypes();
        };
    }, []);

    // Check permission
    useEffect(() => {
        if (permission.data.length) {
            const workType = permission.data.find(item => item.name === 'note.workType.set');

            if (workType) {
                setCanEditWorkType(!workType.isAllowed);
            }
        }
    }, [permission]);

    useEffect(() => {
        if (data && data.noteDictionaries.length) {
            getNoteWorkTypes(data.noteDictionaries[0].id);
        }
    }, [data]);

    // Set ntd folder name
    useEffect(() => {
        if (data.ntd && data.ntd.prevTitle) {
            setNtdFolderName(data.ntd.prevTitle);
        }
    }, []);

    // Set rounded retention_count after change
    useEffect(() => {
        setRetentionCount(data.retention_count);
    }, [data.retention_count]);

    useEffect(() => {
        setConfirmModalIsVisible(data.show_accept);
    }, [data.show_accept]);

    //// Handlers
    const getData = () => {
        // getExecutors(projectId, rootStructureId);
        getAuditors(projectId, rootStructureId);
        // getDictionary();
        // getDictionary(projectId);
        getRetentionUnits();
        getOrganizations();
        getCounterParties(projectId);
        // if (data.executor_organization_id) {
        //     getFiltredExecutors(projectId, rootStructureId, data.executor_organization_id);
        // }
    };

    // AX modal handlers
    const handleAxModalClose = e => {
        setAxModal1Visible(false);
        setAxModal2Visible(false);
        setAxModal3Visible(false);
        setConfirmModalIsVisible(false);
    };

    const handleCancel = () => {
        setCommentVisible(true);
        if (commentVisible) {
            setConfirmModalIsVisible(false);
            changeNote(projectId, data.structure_id, data.id, {
                accept_status: 'decline',
                accept_message: confirmModalComment,
            });
        }
    };

    const handleConfirmModal = () => {
        handleAxModalClose(false);
        changeNote(projectId, data.structure_id, data.id, {
            accept_status: 'accept',
        });
    };

    const formatFiles = files => {
        const arr = [];
        return files.map(item => {
            const formData = new FormData();
            formData.append('uploadFile', item.file);
            formData.append('fileName', item.fileName);

            return arr.push(formData);
        });
    };

    const handleFormChange = (e, item) => {
        const { value } = e.target;

        switch (item) {
            case 1:
                setTitleValue(value);
                break;
            case 2:
                setDescriptionValue(value);
                break;
            case 3:
                setRetentionCount(value);
                break;
            case 4:
                setCorrectiveActionValue(value);
                break;
            default:
                break;
        }
    };

    const handleFieldSubmit = item => {
        let changedData = {};
        changedData.number = data.number;

        switch (item) {
            case 1:
                changedData.title = titleValue !== null ? titleValue : data.title;
                break;
            case 2:
                changedData.description = descriptionValue !== null ? descriptionValue : data.description;
                break;
            case 3:
                changedData.retention_count = retentionCount !== null ? retentionCount : data.retention_count;
                break;
            case 4:
                changedData.correctiveAction = correctiveActionValue !== null ? correctiveActionValue : data.correctiveAction;
                break;
            default:
                break;
        }

        changeNote(projectId, data.structure_id, data.id, changedData);
    };

    const handleSelectSubmit = (value, item) => {
        let changedData = {};
        changedData.number = data.number;

        switch (item) {
            case 3:
                changedData.executor_id = value;
                break;
            case 4:
                changedData.date_deadline = value;
                break;
            case 5:
                changedData.categories = value;
                break;
            case 7:
                changedData.executor_organization_id = organizationId;
                changedData.audits = value;
                break;
            case 8:
                changedData.retention_unit_id = value;
                break;
            case 9:
                changedData.retention_status_id = value;
                break;
            case 10:
                changedData.work_type_id = value;
                break;
            default:
                break;
        }

        changeNote(projectId, data.structure_id, data.id, changedData, auditors);
    };

    const handleSelectBlur = (value, item) => {
        switch (item) {
            case 5:
                getNoteWorkTypes(value.id);
                handleSelectSubmit([{ dictionary_id: value.id }], 5);
                break;
            case 7:
                let auditorsArray = [];
                value.map(i => {
                    auditorsArray.push({ auditor_id: i });
                });
                handleSelectSubmit(auditorsArray, 7);
                break;
            case 8:
                handleSelectSubmit(value, 8);
                break;
            case 9:
                handleSelectSubmit(value, 9);
                break;
            default:
                break;
        }
    };

    const handleSelect = value => {
        if (checkExecutorImages) {
            if (!utils.hasImagesInFileList(data.noteFiles || [])) {
                utils.showError('Требуется обязательно прикрепить фотографию к замечанию');
                return;
            }
        }

        setExecutorValue(executors.data.find(i => i.id === value));
        handleSelectSubmit(value, 3);
    };

    const handleWorkType = value => {
        const feature = (currentStructure.data && currentStructure.data.feature) || null;

        setWorkType(value);
        handleSelectSubmit(value, 10);

        if (feature) {
            // Если признак указан проверяем на соответствие с планом
            const accessToken = localStorage.getItem('accessToken');

            setWorkTypeLoading(true);
            axios({
                headers: {
                    'Access-Token': accessToken,
                },
                method: 'GET',
                url: `${apiConfig.url}project/${projectId}/axapta-planning?structure_id=${data.structure_id}&work_type_id=${value}`,
            })
                .then(response => {
                    if (response.data.success) {
                        // Если признак Секция проверяем на соответствие с планом
                        if (!response.data.data.has_planning) {
                            setAxModal2Visible(true);
                        }
                    }
                })
                .catch(errors => {
                    console.log(errors);
                })
                .finally(() => {
                    setWorkTypeLoading(false);
                });
        } else {
            // Если поле Признак = null
            setAxModal1Visible(true);
        }
    };

    const handleDatePicker = date => {
        const value = date ? moment(date).format('YYYY-MM-DD') : date;
        setDateDeadlineValue(value);
        handleSelectSubmit(value, 4);
    };

    const getDefaultValues = selectData => {
        return selectData.map(item => item.id);
    };

    const getRetentionStausDefaultValue = id => {
        if (id !== null) {
            let status = retentionStatus.find(item => item.id === id);

            if (status) {
                return status.id;
            }
        }
        return null;
    };

    const handleRequest = ({ onSuccess, file }) => {
        let changedData = {};
        changedData.number = data.number;
        const formData = new FormData();
        formData.append('uploadFile', file);
        formData.append('fileName', file.name);
        uploadNoteFile(projectId, rootStructureId, data.number, formData, updateUploadedFiles);

        // setTimeout(() => {
        //     console.log('lololol');
        //     onSuccess('ok');
        //     const newFiles = filesValue;
        //     newFiles.push(file);
        //     changedData.files = formatFiles(newFiles);
        //     changeNote(projectId, data.structure_id, data.id, changedData);
        // }, 0);
    };

    const updateUploadedFiles = response => {
        // getNoteById(projectId, data.structure_id, data.id);
        // return;
        // let noteFiles = data.noteFiles.map(item => item.id);
        // let newUploadedFiles = [...noteFiles, response.id];
        let changedData = {};
        // changedData.number = data.number;
        // changedData.files = newUploadedFiles;
        // setUploadedFiles(newUploadedFiles);
        // console.log('newUploadedFiles', newUploadedFiles);
        changeNote(projectId, data.structure_id, data.id, changedData);
    };

    const handleAppChange = info => {
        // console.log(info);
        return;
        switch (info.file.status) {
            case 'uploading':
                setSelectedFileList(info.fileList);
                break;
            case 'done':
                setSelectedFileList(info.fileList);
                break;
            case 'error':
                break;
            default:
                setSelectedFileList(info.fileList);
                break;
        }
    };

    const disabledDate = current => {
        return current && current < moment().endOf('day');
    };

    const disabledDateTime = () => {
        return {
            disabledHours: () => range(0, 24).splice(4, 20),
            disabledMinutes: () => range(30, 60),
            disabledSeconds: () => [55, 56],
        };
    };

    const handleFilesRemove = e => {
        e.preventDefault();
        setRemoveBtnIsShown(!removeBtnIsShown);
    };

    const handleFileRemove = (e, fileId) => {
        e.preventDefault();
        // setFilesValue(filesValue.filter(item => item.id !== fileId));
        deleteNoteFile(projectId, data.structure_id, data.id, fileId);
    };

    const handleNtdBack = e => {
        e.preventDefault();
        getNtdById(ntd.data.prevId);
        if (ntd.data.prevId === 1 && ntd.success) {
            setNtdBack(false);
        }
        if (searchNtdLocalValue.length) {
            onLocalReset();
        }
    };

    const handleNtdOpen = e => {
        e.preventDefault();
        getAllNtd();
        getTreeNtd();
        setNtdModalIsVisible(true);
    };

    const handleNtdReset = (e, ntdInfo) => {
        e.preventDefault();

        if (data.ntd || ntdInfo) {
            let changedData = {};
            changedData.number = data.number;

            setNtdData('');
            setTitleValue('');
            setDescriptionValue('');
            setNtdFolderName('');
            if (correctivePermission.isAllowed) {
                setCorrectiveActionValue('');
                changedData.correctiveAction = null;
            }

            changedData.title = null;
            changedData.description = null;
            changedData.ntd_id = null;

            changeNote(projectId, data.structure_id, data.id, changedData);
        }
    };

    const handleNtdClose = () => {
        getAllNtd();
        setNtdModalIsVisible(false);
        setNtdBack(false);
        setSearchValue('');
        setSearchNtdLocalValue('');
        tempFiltredNtd = [];
        setFiltredNtd(null);
    };

    const handleChooseNtd = (e, item) => {
        e.preventDefault();
        let changedData = {};
        changedData.number = data.number;

        if (item.type !== 'paragraph') {
            getNtdById(item.id);
            setNtdFolderName(item.title);
            if (ntd.success) {
                setNtdBack(true);
            }
        } else {
            setNtdData(item);
            setTitleValue(item.title);
            setDescriptionValue(item.description);

            if (correctivePermission.isAllowed) {
                setCorrectiveActionValue(item.correctiveAction);
                changedData.correctiveAction = item.correctiveAction;
            }

            changedData.title = item.title;
            changedData.description = item.description;
            changedData.ntd_id = item.id;
            console.log(changedData);
            changeNote(projectId, data.structure_id, data.id, changedData);
            setNtdIsChosen(true);
            handleNtdClose();
        }
    };

    const handleDocOpen = e => {
        const isQuality = data.is_quality_control;
        e.preventDefault();

        let common =
            props.history.location.search && props.history.location.search.indexOf('common=1') >= 0
                ? `?common=1&isQuality=${isQuality}`
                : `?isQuality=${isQuality}`;
        props.history.push(`/projects/${projectId}/documents/${data.structure_id}/${data.document_id}${common}#${data.id}`);
    };

    const handlePlaceMarker = e => {
        e.preventDefault();

        data.project_id = projectId;
        localStorage.setItem('placeMarker', JSON.stringify(data));
        props.history.push(`/projects/${projectId}/documents/${data.structure_id}/${props.rootDocumentId}`);
    };

    const handleCreatePrescription = e => {
        e.preventDefault();
        let accessToken = localStorage.getItem('accessToken');

        axios({
            headers: {
                'Access-Token': accessToken,
            },
            method: 'POST',
            url: `${apiConfig.url}project/${projectId}/note/${data.id}/prescription`,
        })
            .then(response => {
                if (!response.data.success) {
                    response.data.errors.forEach(element => {
                        message.error(element);
                    });
                } else {
                    getNoteById(projectId, data.structure_id, data.id);
                    message.info('Предписание добавлено в раздел ПРИЛОЖЕНИЯ');
                }
            })
            .catch(errors => {
                console.log(errors.response);
            });
    };

    const handleCreateActFixation = e => {
        e.preventDefault();
        let accessToken = localStorage.getItem('accessToken');

        axios({
            headers: {
                'Access-Token': accessToken,
            },
            method: 'POST',
            url: `${apiConfig.url}project/${projectId}/note/${data.id}/akt-fixation`,
        })
            .then(response => {
                if (!response.data.success) {
                    response.data.errors.forEach(element => {
                        message.error(element);
                    });
                } else {
                    getNoteById(projectId, data.structure_id, data.id);
                    message.info('Акт фиксации добавлен в раздел ПРИЛОЖЕНИЯ');
                }
            })
            .catch(errors => {
                console.log(errors.response);
            });
    };

    const downloadFile = (e, hash, id, fileName) => {
        e.preventDefault();
        let accessToken = localStorage.getItem('accessToken');

        axios({
            headers: {
                'Access-Token': accessToken,
            },
            responseType: 'blob',
            method: 'GET',
            url: `${apiConfig.url}file/${hash}/${id}`,
        })
            .then(response => {
                var blob = new Blob([response.data]);
                FileSaver.saveAs(blob, fileName, { type: response.headers['content-type'] });
            })
            .catch(errors => {
                console.log(errors.response);
            });
    };

    const changeImportant = () => {
        let changedData = {};
        changedData.is_important = data.is_important ? 0 : 1;

        changeNote(projectId, data.structure_id, data.id, changedData);
    };

    const changeFavorite = () => {
        let changedData = {};

        changeNote(projectId, data.structure_id, data.id, changedData);
    };

    const onSearch = (data, search) => {
        if (data.children) {
            data.children.forEach(item => {
                if (item.type === 'paragraph') {
                    if (item['title'].toLowerCase().includes(search) || item['description'].toLowerCase().includes(search)) {
                        let copyItem = { ...item };
                        copyItem.parentId = data.id;
                        copyItem.parentTitle = data.title;

                        tempFiltredNtd.push(copyItem);
                    }
                } else {
                    onSearch(item, search);
                }
            });
        }
    };

    const onChange = e => {
        setSearchValue(e.target.value);
    };

    useEffect(() => {
        if (searchValue.length > 2) {
            clearTimeout(timerSearchNtd);
            timerSearchNtd = setTimeout(() => {
                onSearch(treeNtd.data, searchValue.toLowerCase());
                setFiltredNtd(tempFiltredNtd);
            }, 500);
        } else {
            tempFiltredNtd = [];
            setFiltredNtd(null);
        }

        return () => {
            clearTimeout(timerSearchNtd);
        };
    }, [searchValue]);

    const onReset = () => {
        setSearchValue('');
        tempFiltredNtd = [];
        setFiltredNtd(null);
    };

    const onLocalSearch = (data, search) => {
        // ntd.data.children
        if (data) {
            data.forEach(item => {
                if (item['title'].toLowerCase().includes(search) || item['description'].toLowerCase().includes(search)) {
                    let copyItem = { ...item };
                    copyItem.parentId = data.id;
                    copyItem.parentTitle = data.title;

                    tempFiltredNtd.push(copyItem);
                }
            });
        }
    };

    const onLocalChange = e => {
        setSearchNtdLocalValue(e.target.value);
    };

    const onLocalReset = () => {
        setSearchNtdLocalValue('');
        tempFiltredNtd = [];
        setFiltredNtd(null);
    };

    useEffect(() => {
        if (searchNtdLocalValue.length > 2) {
            clearTimeout(timerSearchLocalNtd);
            timerSearchLocalNtd = setTimeout(() => {
                onLocalSearch(ntd.data.children, searchNtdLocalValue.toLowerCase());
                setFiltredNtd(tempFiltredNtd);
            }, 500);
        } else {
            tempFiltredNtd = [];
            setFiltredNtd(null);
        }

        return () => {
            clearTimeout(timerSearchLocalNtd);
        };
    }, [searchNtdLocalValue]);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (data) {
            setTitleValue(data.title);
            setDescriptionValue(data.description);
            setCorrectiveActionValue(data.correctiveAction);
            setExecutorValue(data.executor.fullname);
            setDateDeadlineValue(data.date_deadline);
            setOrganizationId(data.executor_organization_id);
        }
        return () => {
            setTitleValue(null);
            setDescriptionValue(null);
            setCorrectiveActionValue(null);
            setExecutorValue('');
            setDateDeadlineValue('');
            setOrganizationId(null);
        };
    }, [data]);

    useEffect(() => {
        setDateDeadlineValue(data.date_deadline);
    }, [data]);

    useEffect(() => {
        if (isNoteArchived) {
            props.history.push(`/projects/${projectId}/comments/${data.structure_id}`);
            resetArchiveStatus();
        }
    }, [isNoteArchived]);

    useEffect(() => {
        if (permission.data.length) {
            let retentionPermission = permission.data.find(item => item.name === 'note.retention.edit');

            if (retentionPermission) {
                setCanEditRetention(!retentionPermission.isAllowed);
            }
        }
    }, [permission]);
    const handleNoteArchive = async (structureId, noteId) => {
        await archiveNote(projectId, structureId, noteId, { data: history });
    };

    const handleNoteRestore = async (structureId, noteId) => {
        restoreNote(projectId, structureId, noteId);
    };

    const handleFavorite = (section, itemId, isFavorite) => {
        let copyData = Object.assign({}, data);
        copyData.is_favorite = !copyData.is_favorite;
        props.setNote(copyData);
        props
            .changeFavorite(section, itemId, isFavorite)
            .then(response => {
                // console.log('handleFavorite -> response', response);
                // console.log('DATA', data);
            })
            .catch(error => {
                copyData.is_favorite = !copyData.is_favorite;
                props.setNote(copyData);
            });
    };

    const handleGetDictionary = () => {
        if (data.executor_organization_id !== null && data.contract_id !== null) {
            getFiltredDictionary(data.contract_id);
        } else {
            if (!dictionary.length) {
                getDictionary();
            }
        }
        setIsVisibleDictionary(true);
    };

    const handleCreateComment = () => {
        createComment(projectId, data.structure_id, data.id, comment);
        setComment('');
    };

    const handleSelectOrganizations = value => {
        let changedData = {};
        const noteAuditsArray = data.noteAudits.map(({ id }) => ({ auditor_id: id }));
        changedData.executor_organization_id = value;
        changedData.audits = noteAuditsArray;
        setOrganizationId(value);

        changeNote(projectId, data.structure_id, data.id, changedData);

        getFiltredExecutors(projectId, rootStructureId, value);
        if (data.noteAudits.length === 0 && value !== null) {
            setAxModal3Visible(true);
        }
    };

    useEffect(() => {
        if (data.executor_organization_id !== null) {
            getFiltredExecutors(projectId, rootStructureId, data.executor_organization_id);
        } else {
            if (data) {
                getExecutors(projectId, rootStructureId);
            }
        }
    }, [data.executor_organization_id]);

    useEffect(() => {
        //  Выбрана организация контрагента, но не заполнено поле Категории работ
        if (data.executor_organization_id !== null && data.noteDictionaries.length === 0) {
            getContracts(projectId, rootStructureId, data.executor_organization_id);
        }
        // Выбрана организация контрагента и заполнено поле Категории работ
        if (data.noteDictionaries.length !== 0 && data.executor_organization_id !== null) {
            const [currentNoteDictionary] = data.noteDictionaries;
            getContracts(projectId, rootStructureId, data.executor_organization_id, currentNoteDictionary.id);
        }
    }, [data.executor_organization_id, data.contract_id, data.noteDictionaries]);

    useEffect(() => {
        if (executors.data.length === 1 && data.noteAudits.length) {
            const [singleExecutor] = executors.data;
            handleSelectSubmit(singleExecutor.id, 3);
        }
    }, [executors.data]);

    useEffect(() => {
        if (data.executor_organization_id) {
            const currentOrganization = organizations.data.filter(element => element.id === data.executor_organization_id);

            if (currentOrganization.length !== 0) {
                setcounterParty(currentOrganization[0].title);
            } else {
                setcounterParty(null);
            }
        } else {
            setcounterParty(null);
        }
    }, [organizations, data.executor_organization_id]);

    const handleSelectCounterParty = value => {
        const changedData = {};
        changedData.contract_id = value;
        changeNote(projectId, data.structure_id, data.id, changedData);
    };

    // Inspection logic
    useEffect(() => {
        setInspectionValues({
            ...inspectionValues,
            inspection_number: data.inspection_number,
            inspection_index: data.inspection_index,
            inspection_location: data.inspection_location,
        });

        return () => {
            setInspectionValues({
                inspection_number: null,
                inspection_index: null,
                inspection_location: null,
                noteIds: [],
            });
        };
    }, [singleNote]);

    useEffect(() => {
        if (permission.data.length) {
            const isAllowed = permission.data.find(item => item.name === 'note.inspection').isAllowed;
            setInspectionValues({
                ...inspectionValues,
                isAllowed,
            });
        }
    }, [permission.data]);

    const handleChangeQMD = e => {
        const { name, value } = e.target;

        setInspectionValues({
            ...inspectionValues,
            [name]: value,
        });
    };

    const handleBlurQMD = e => {
        const { name, value } = e.target;
        const newData = {};

        switch (name) {
            case 'inspection_index':
                newData.inspection_index = value.length !== 0 ? value : null;
                break;
            case 'inspection_number':
                newData.inspection_number = value.length !== 0 ? value : null;
                break;
            case 'inspection_location':
                newData.inspection_location = value.length !== 0 ? value : null;
                break;
        }

        changeNote(projectId, data.structure_id, data.id, newData);
    };

    const handleToggleinspectionModals = (key, value = false) => {
        // key === 'successModal'
        // key === 'checkListModalOpen'

        setInspectionModals(prevValues => ({
            ...prevValues,
            [key]: value,
        }));
    };

    const handleSubmitinspectionValues = () => {
        if (data.document_id === null) {
            utils.showError('У замечания не проставлена метка на чертеже');
        } else {
            handleToggleinspectionModals('checkListModalOpen', true);
            getInspectionNotes(projectId, data.id);
        }
    };

    const handleChooseNotes = () => {
        handleToggleinspectionModals('checkListModalOpen', false);
        handleToggleinspectionModals('successModal', true);
    };

    const handleCancelinspectionModals = () => {
        handleToggleinspectionModals('checkListModalOpen', false);
        handleToggleinspectionModals('successModal', false);
        setInspectionValues({
            ...inspectionValues,
            noteIds: [],
        });
    };

    const handleCreateInspection = status => {
        createInspectionNotes(projectId, data.id, { noteIds: inspectionValues.noteIds, status }, () => {
            handleCancelinspectionModals();

            setInspectionValues({
                ...inspectionValues,
                noteIds: [],
            });

            message.success({ content: 'Лист осмотра успешно создан!', type: 'success' });
            getNoteById(projectId, data.structure_id, data.id);
        });
    };

    const handleChooseNote = (e, id) => {
        const { checked } = e.target;

        if (checked) {
            setInspectionValues({
                ...inspectionValues,
                noteIds: [...inspectionValues.noteIds, id],
            });
        } else {
            setInspectionValues({
                ...inspectionValues,
                noteIds: inspectionValues.noteIds.filter(noteId => noteId !== id),
            });
        }
    };

    const handleChooseAllNotes = e => {
        const { checked } = e.target;

        if (checked) {
            const noteIds = inspectionNotes.data ? inspectionNotes.data.map(item => item.id) : [];

            setInspectionValues({
                ...inspectionValues,
                noteIds,
            });
        } else {
            setInspectionValues({
                ...inspectionValues,
                noteIds: [],
            });
        }

        // if (checked) {
        //     setInspectionValues({
        //         ...inspectionValues,
        //         noteIds: [...inspectionValues.noteIds, id],
        //     });
        // } else {
        //     setInspectionValues({
        //         ...inspectionValues,
        //         noteIds: inspectionValues.noteIds.filter(noteId => noteId !== id),
        //     });
        // }
    };

    // console.log(inspectionNotes.data);

    const hasQMDValue = inspectionValues.inspection_number || inspectionValues.inspection_index;

    const handleChangeStatus = status => {
        if (checkExecutorImages && status === 'inwork') {
            if (!utils.hasImagesInFileList(data.noteFiles || [])) {
                utils.showError('Требуется обязательно прикрепить фотографию к замечанию');

                return;
            }
        }

        changeStatus(status);
    };

    // Render
    return data ? (
        <>
            <Form>
                <div className="flexRow spaceBetween">
                    <div className="">
                        <h2 className="note__title">
                            {data.title} {noteStatus ? '(' + noteStatus.title + ')' : null}
                        </h2>
                    </div>

                    <div className="note-status">
                        {data.is_counterparty === 1 && (
                            <div
                                title="Контрагент"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: 24,
                                    width: 24,
                                    height: 24,
                                }}>
                                <Icon component={insuranceAgentIcon} style={{ color: '#aaa' }} />
                            </div>
                        )}
                        <Button
                            title={'Избранное'}
                            type={'link'}
                            onClick={() => handleFavorite('note', data.id, data.is_favorite)}
                            className={data.is_favorite ? 'button-favorite button-favorite_active' : 'button-favorite'}
                            /* className={data.is_important ? 'button-important button-important_active' : 'button-important'} */
                        >
                            <Icon type="star" theme="filled" />
                        </Button>
                        {/* <FavoriteButton
                            handleFavorite={() => handleFavorite('note', data.id, data.is_favorite)}
                            isFavorite={data.is_favorite}
                        /> */}
                        <Button
                            title={'Важное'}
                            type={'link'}
                            onClick={changeImportant}
                            className={data.is_important ? 'button-important button-important_active' : 'button-important'}>
                            <Icon type="exclamation-circle" theme="filled" />
                        </Button>
                        {statusData.map((item, key) => (
                            <Button
                                className="ml16"
                                style={{
                                    borderColor: item.color,
                                    borderStyle: 'solid',
                                    borderWidth: item.status === data.state ? 3 : 1,
                                    color: '#212121',
                                }}
                                key={key}
                                onClick={() => handleChangeStatus(item.status)}
                                disabled={data.retention_status_id === 0}>
                                <span>{item.statusTitle}</span>
                            </Button>
                        ))}
                    </div>
                </div>
                <div className="flexRow">
                    <h2>№ - {data.number}</h2>
                </div>
                <div className="mt32">
                    <h2>Описание замечания</h2>
                    <div className="mt16">
                        <div className="noteDetailsView">
                            <div className="form-item">
                                <div className="form__field">
                                    <div className="form__label">Нарушен стандарт</div>
                                    <div className="form-action">
                                        {!props.match && (
                                            <>
                                                <a href="" className="form-action__btn" onClick={handleCreateActFixation}>
                                                    <Icon type="file-text" theme="outlined" />
                                                    <span>Акт фиксации</span>
                                                </a>
                                                |
                                            </>
                                        )}
                                        {!props.match && (
                                            <>
                                                <a href="" className="form-action__btn" onClick={handleCreatePrescription}>
                                                    <Icon type="file-text" theme="outlined" />
                                                    <span>Предписание</span>
                                                </a>
                                                |
                                            </>
                                        )}
                                        {!data.document_id && !props.match && (
                                            <>
                                                <a href="" className="form-action__btn" onClick={handlePlaceMarker}>
                                                    <Icon type="pushpin" theme="outlined" />
                                                    <span>Отметить на плане</span>
                                                </a>
                                                |
                                            </>
                                        )}
                                        {data.document_id && !props.match && (
                                            <>
                                                <a href="" className="form-action__btn" onClick={handleDocOpen}>
                                                    <Icon type="link" theme="outlined" />
                                                    <span>Посмотреть на карте</span>
                                                </a>
                                                |
                                            </>
                                        )}
                                        <a href="" className="form-action__btn" onClick={handleNtdOpen}>
                                            <Icon type="edit" theme="outlined" />
                                            <span>Редактировать</span>
                                        </a>
                                        |
                                        <a href="" className="form-action__btn" onClick={e => handleNtdReset(e, ntdIsChosen)}>
                                            <Icon type="close" theme="outlined" />
                                            <span>Сбросить</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="form__info">
                                    <div className="form__icon">
                                        <Icon type="file" theme="outlined" />
                                    </div>
                                    <span>{ntdFolderName}</span>
                                </div>
                            </div>
                        </div>
                        <div className="noteDetailsView">
                            <Form.Item label="Название">
                                <TextArea
                                    value={titleValue !== null ? titleValue : data.title}
                                    onChange={e => handleFormChange(e, 1)}
                                    onBlur={() => handleFieldSubmit(1)}
                                    placeholder="Название"
                                    autoSize
                                />
                            </Form.Item>
                        </div>
                        <div className="noteDetailsView">
                            <Form.Item label="Описание">
                                <TextArea
                                    value={descriptionValue !== null ? descriptionValue : data.description}
                                    onChange={e => handleFormChange(e, 2)}
                                    onBlur={() => handleFieldSubmit(2)}
                                    placeholder="Описание"
                                    autoSize
                                />
                            </Form.Item>
                        </div>
                        <div className="noteDetailsView">
                            <Form.Item label="Корректирующие мероприятия">
                                <TextArea
                                    autoSize
                                    placeholder="Корректирующие мероприятия"
                                    value={correctiveActionValue !== null ? correctiveActionValue : data.correctiveAction}
                                    disabled={!correctivePermission.isAllowed}
                                    onChange={e => handleFormChange(e, 4)}
                                    onBlur={() => handleFieldSubmit(4)}
                                />
                            </Form.Item>
                        </div>
                        {counterparties.data ? (
                            <div className="noteDetailsView">
                                <Form.Item label="Субподрядная организация">
                                    <CustomControlledSelect
                                        isMultiple={false}
                                        data={[
                                            {
                                                title: 'Не выбрано',
                                                id: null,
                                            },
                                            ...counterparties.data.filter(counterparty => counterparty.deleted_at === null),
                                        ]}
                                        value={counterParty}
                                        onChange={handleSelectOrganizations}
                                        placeholder="Не выбран"
                                        isDictionary
                                    />
                                </Form.Item>
                            </div>
                        ) : null}
                        <div className="noteDetailsView">
                            <Form.Item label="Договор контрагента">
                                <CustomControlledSelect
                                    isMultiple={false}
                                    data={data && data.contracts ? data.contracts : []}
                                    placeholder="Договор контрагента"
                                    value={data ? data.contract.title : ''}
                                    onChange={handleSelectCounterParty}
                                    isDictionary
                                    loading={data.contractsLoading || false}
                                    disabled={data.executor_organization_id !== null ? false : true}
                                />
                            </Form.Item>
                        </div>
                        <div className="noteDetailsView">
                            <Form.Item label="Исполнитель">
                                <CustomControlledSelect
                                    isMultiple={false}
                                    data={executors.data}
                                    value={data ? data.executor.fullname : ''}
                                    onChange={handleSelect}
                                    loading={data.executorsLoading || false}
                                />
                            </Form.Item>
                        </div>
                        <div className="noteDetailsView">
                            <Form.Item className="form-date" label="Дата создания / устранения">
                                <DatePicker
                                    style={{ display: 'inline-block', width: 'calc(50% - 12px)', marginRight: '20px' }}
                                    locale={locale}
                                    placeholder="Выберите дату"
                                    format="YYYY-MM-DD"
                                    showToday={false}
                                    defaultValue={moment(data.date_created, 'YYYY-MM-DD')}
                                    disabled>
                                    <DatePicker />
                                </DatePicker>
                                <DatePicker
                                    style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                                    locale={locale}
                                    onChange={handleDatePicker}
                                    value={data.date_deadline ? moment(dateDeadlineValue || data.date_deadline, 'YYYY-MM-DD') : null}
                                    placeholder="Выберите дату"
                                    format="YYYY-MM-DD"
                                    showToday={false}
                                    disabled={!checkDeadlinePermissions}
                                    disabledDate={disabledDate}
                                    disabledTime={disabledDateTime}
                                    allowClear={false}>
                                    <DatePicker />
                                </DatePicker>
                            </Form.Item>
                        </div>
                        <div className="noteDetailsView">
                            <Checkbox style={{ cursor: 'default' }} name="hasFine" checked={data.hasFine}>
                                Штраф
                            </Checkbox>
                        </div>
                        <div className="noteDetailsView">
                            <Form.Item label="Автор замечания">
                                <Input value={data.user.fullname} disabled />
                            </Form.Item>
                        </div>
                        <div className="noteDetailsView">
                            <Form.Item label="Категория работ">
                                <Input
                                    placeholder="Категория работ"
                                    value={data && data.noteDictionaries.length ? data.noteDictionaries[0].title : ''}
                                    onClick={handleGetDictionary}
                                    disabled={Boolean(workType)}
                                />
                            </Form.Item>
                            <ModalDictionary
                                visible={isVisibleDictionary}
                                data={dictionary}
                                currentItemId={data && data.noteDictionaries.length ? data.noteDictionaries[0].id : null}
                                getDictionary={getDictionary}
                                setHide={() => setIsVisibleDictionary(false)}
                                setItem={value => handleSelectBlur(value, 5)}
                            />
                        </div>
                        <div className="noteDetailsView">
                            <Form.Item label="Вид работ">
                                <Select
                                    showSearch
                                    value={workType}
                                    placeholder="Выбрать"
                                    loading={workTypes.loading}
                                    disabled={workTypeLoading || canEditWorkType}
                                    onChange={handleWorkType}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLocaleLowerCase()) !== -1
                                    }>
                                    {workTypes.success &&
                                        workTypes.data &&
                                        workTypes.data.workTypes &&
                                        workTypes.data.workTypes.map(item => (
                                            <Option key={item.id} value={item.id}>
                                                {`${item.code}. ${item.title}`}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                            <Modal
                                title="Внимание!"
                                visible={axModal1Visible}
                                okText="Да"
                                cancelText="Нет"
                                onCancel={handleAxModalClose}
                                footer={[
                                    <Button key="Ok" onClick={handleAxModalClose}>
                                        Ок
                                    </Button>,
                                ]}>
                                У элемента структуры не проставлен признак интеграции.
                            </Modal>
                            <Modal
                                title="Внимание!"
                                visible={axModal2Visible}
                                okText="Да"
                                cancelText="Нет"
                                onCancel={handleAxModalClose}
                                footer={[
                                    <Button key="Ok" onClick={handleAxModalClose}>
                                        Ок
                                    </Button>,
                                ]}>
                                Замечание создано не по заданным настройкам АВР.
                            </Modal>
                            <Modal
                                title="Внимание!"
                                visible={axModal3Visible}
                                okText="Да"
                                cancelText="Нет"
                                onCancel={handleAxModalClose}
                                footer={[
                                    <Button key="Ok" onClick={handleAxModalClose}>
                                        Ок
                                    </Button>,
                                ]}>
                                Поле “Требует внимания” обязательно для заполнения
                            </Modal>
                        </div>
                        <div className="noteDetailsView">
                            <div className="form-item files">
                                <div className="form__field">
                                    <div className="form__label">Приложения:</div>
                                    <div className={removeBtnIsShown ? 'form-action remove' : 'form-action'}>
                                        <Upload
                                            showUploadList={false}
                                            fileList={selectedFileList}
                                            customRequest={handleRequest}
                                            onChange={handleAppChange}
                                            listType="picture"
                                            multiple={true}>
                                            {!removeBtnIsShown && (
                                                <>
                                                    <a href="" className="form-action__btn" onClick={e => e.preventDefault()}>
                                                        <Icon type="upload" theme="outlined" />
                                                        <span>Загрузить</span>
                                                    </a>
                                                </>
                                            )}
                                        </Upload>
                                        {!removeBtnIsShown ? (
                                            <>
                                                |
                                                <a href="" className="form-action__btn" onClick={handleFilesRemove}>
                                                    <Icon type="delete" theme="outlined" />
                                                    <span>Удалить</span>
                                                </a>
                                            </>
                                        ) : (
                                            <a href="" className="form-action__btn green" onClick={handleFilesRemove}>
                                                <Icon type="delete" theme="outlined" />
                                                <span>Вернуться в просмотр</span>
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {data.noteFiles.length !== 0 &&
                                data.noteFiles.map((item, index) => (
                                    <div key={index} className="note-file note-file_s1">
                                        {/*<img className="note-file__img" src={item.full_path} />*/}
                                        <a
                                            target="_blank"
                                            className="note-file__info"
                                            onClick={() => {
                                                setPhotoIndex(index);
                                                setShowGallery(true);
                                            }}>
                                            <img src={item.thumb} alt={item.title} className="note-file__img" />
                                            <p className="note-file__name">{item.title}</p>
                                            <p className="secondary-info">{moment(item.created_at).format('DD.MM.YYYY, HH:mm:ss')}</p>
                                            <p className="secondary-info">{item.user.fullname}</p>
                                        </a>
                                        {/* <a href={item.full_path} target="_blank" className="note-file__info">
                                            <img src={item.thumb} alt={item.title} className="note-file__img" />
                                            <p className="note-file__name">{item.title}</p>
                                            <p className="secondary-info">{moment(item.created_at).format('DD.MM.YYYY, HH:mm:ss')}</p>
                                            <p className="secondary-info">{item.user.fullname}</p>
                                        </a> */}
                                        {removeBtnIsShown && (
                                            <a href="" className="note-file__remove" onClick={e => handleFileRemove(e, item.id)}>
                                                <Icon type="delete" theme="outlined" />
                                            </a>
                                        )}
                                        {!removeBtnIsShown && (
                                            <a
                                                href=""
                                                onClick={e => downloadFile(e, item.hash, item.id, item.title)}
                                                className="note-file__download">
                                                <Icon type="download" theme="outlined" />
                                            </a>
                                            // <a href={item.full_path} download target="_blank" className="note-file__download">
                                            //     <Icon type="download" theme="outlined" />
                                            // </a>
                                        )}
                                    </div>
                                ))}
                            {data.noteFiles.length === 0 && <span className="secondary-info">Не загружено</span>}
                        </div>
                        <div className="noteDetailsView">
                            <div className="form__field">
                                <div className="form__label">ОБЪЕМ К УДЕРЖАНИЮ:</div>
                            </div>
                            <div className="noteDetailsView__content noteDetailsView__content_row">
                                <div className="noteDetailsView__item">
                                    <Form.Item label="Единицы измерения:">
                                        <CustomSelect
                                            isMultiple={false}
                                            data={retentionUnits.success ? retentionUnits.data : []}
                                            defaultValue={data && data.retention_unit ? data.retention_unit.id : null}
                                            onChange={() => {}}
                                            onBlur={e => handleSelectBlur(e, 8)}
                                            isDictionary
                                            disabled={canEditRetention}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="noteDetailsView__item">
                                    <Form.Item label="Количество:">
                                        <Input
                                            value={retentionCount !== null ? retentionCount : data.retention_count}
                                            onChange={e => handleFormChange(e, 3)}
                                            onBlur={() => handleFieldSubmit(3)}
                                            disabled={canEditRetention}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="noteDetailsView__item">
                                    <Form.Item label="Статус работ:">
                                        <CustomSelect
                                            isMultiple={false}
                                            data={retentionStatus}
                                            defaultValue={getRetentionStausDefaultValue(data.retention_status_id)}
                                            onChange={e => handleSelectBlur(e, 9)}
                                            isDictionary
                                            disabled={canEditRetention}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <div className="noteDetailsView">
                            <Form.Item label="Требует внимания">
                                <CustomControlledSelect
                                    isMultiple
                                    placeholder="Не выбран"
                                    data={mergedDeletedAuditorsArray}
                                    value={getDefaultValues(data.noteAudits)}
                                    onChange={e => handleSelectBlur(e, 7)}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div className="divider mt32" />
                <div className="mt32">
                    <h2>Отдел контроля качества</h2>
                    <div className="mt16">
                        <div className="noteDetailsView">
                            <Form.Item label="Индекс">
                                <Input
                                    name="inspection_index"
                                    value={inspectionValues.inspection_index}
                                    placeholder="Индекс"
                                    onChange={handleChangeQMD}
                                    onBlur={handleBlurQMD}
                                    disabled={!inspectionValues.isAllowed || isChangeNote}
                                />
                            </Form.Item>
                        </div>
                        <div className="noteDetailsView">
                            <Form.Item label="Условный номер">
                                <Input
                                    name="inspection_number"
                                    value={inspectionValues.inspection_number}
                                    placeholder="Условный номер"
                                    onChange={handleChangeQMD}
                                    onBlur={handleBlurQMD}
                                    disabled={!inspectionValues.isAllowed || isChangeNote}
                                />
                            </Form.Item>
                        </div>
                        <div className="noteDetailsView">
                            <Form.Item label="Локация">
                                <Input
                                    name="inspection_location"
                                    value={inspectionValues.inspection_location}
                                    placeholder="Локация"
                                    onChange={handleChangeQMD}
                                    onBlur={handleBlurQMD}
                                    disabled={!inspectionValues.isAllowed || isChangeNote}
                                />
                            </Form.Item>
                        </div>
                        <div className="noteDetailsView">
                            <Button
                                onClick={handleSubmitinspectionValues}
                                disabled={!inspectionValues.isAllowed || !hasQMDValue || isChangeNote}>
                                Сформировать лист осмотра
                            </Button>
                        </div>
                    </div>

                    <Modal
                        title="Смотровой лист"
                        visible={inspectionModals.checkListModalOpen}
                        onCancel={handleCancelinspectionModals}
                        bodyStyle={{ overflow: 'hidden' }}
                        width={800}
                        footer={
                            <div className="confirm-modal-buttons" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div className="confirm-modal-buttons__item">
                                    <Button disabled={!inspectionValues.noteIds.length} onClick={handleChooseNotes} type="primary">
                                        Выбрать
                                    </Button>
                                </div>
                            </div>
                        }>
                        <div className="check-list">
                            <table className="check-list-table">
                                <thead className="check-list-table__head">
                                    <tr className="check-list-table__head-tr">
                                        <th className="check-list-table__head-th">Номер</th>
                                        <th colSpan="2" className="check-list-table__head-th">
                                            Название
                                        </th>
                                        <th className="check-list-table__head-th">Индекс</th>
                                        <th colSpan="1" className="check-list-table__head-th">
                                            Условный номер
                                        </th>
                                        <th className="check-list-table__head-th">
                                            {inspectionNotes.data && inspectionNotes.data.length !== 0 && (
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                    <Checkbox
                                                        // id={`chooseNoteId-${item.id}`}
                                                        name="chooseAllNotes"
                                                        checked={isChosenAllNotes}
                                                        onChange={e => handleChooseAllNotes(e)}
                                                    />
                                                </div>
                                            )}
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                            {inspectionNotes.loading ? (
                                <div style={{ margin: '32px 0', display: 'flex', justifyContent: 'center' }}>
                                    <Spin indicator={<Icon type="loading" style={{ fontSize: 40 }} spin />} />
                                </div>
                            ) : (
                                <div
                                    className="scroll-table"
                                    style={{
                                        marginRight: inspectionValues.length > 10 ? -44 : 0,
                                        paddingRight: inspectionValues.length > 10 ? 19 : 0,
                                    }}>
                                    <table className="check-list-table">
                                        <tbody className="check-list-table__body">
                                            {inspectionNotes.data &&
                                                inspectionNotes.data.map(item => {
                                                    return (
                                                        <tr key={item.id} className="check-list-table__body-tr">
                                                            <td className="check-list-table__body-td">
                                                                <label className="check-list__label" htmlFor={`chooseNoteId-${item.id}`}>
                                                                    <p className="check-list__title">{item.number}</p>
                                                                </label>
                                                            </td>
                                                            <td colSpan="2" className="check-list-table__body-td">
                                                                <label className="check-list__label" htmlFor={`chooseNoteId-${item.id}`}>
                                                                    <p className="check-list__title">{item.title}</p>
                                                                </label>
                                                            </td>
                                                            <td className="check-list-table__body-td">
                                                                <label className="check-list__label" htmlFor={`chooseNoteId-${item.id}`}>
                                                                    <p className="check-list__index">{item.inspection_index}</p>
                                                                </label>
                                                            </td>
                                                            <td colSpan="1" className="check-list-table__body-td">
                                                                <label className="check-list__label" htmlFor={`chooseNoteId-${item.id}`}>
                                                                    <p className="check-list__condition-number">{item.inspection_number}</p>
                                                                </label>
                                                            </td>
                                                            <td className="check-list-table__body-td">
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-end',
                                                                        alignItems: 'center',
                                                                    }}>
                                                                    {data.inspection_index &&
                                                                        data.inspection_index.toLowerCase() !==
                                                                            item.inspection_index.toLowerCase() && (
                                                                            <Tooltip
                                                                                placement="leftTop"
                                                                                title={`У замечания № ${item.number} не совпадает индекс`}>
                                                                                <div className="check-list__icon-wrap">
                                                                                    <Icon type="info-circle" />
                                                                                </div>
                                                                            </Tooltip>
                                                                        )}
                                                                    <Checkbox
                                                                        id={`chooseNoteId-${item.id}`}
                                                                        name="chooseNote"
                                                                        checked={inspectionValues.noteIds.includes(item.id)}
                                                                        onChange={e => handleChooseNote(e, item.id)}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </Modal>

                    <Modal
                        title="Результат осмотра"
                        visible={inspectionModals.successModal}
                        onCancel={handleCancelinspectionModals}
                        footer={null}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className="confirm-modal-buttons">
                                <div className="confirm-modal-buttons__item">
                                    <Button onClick={() => handleCreateInspection(false)}>Не принято</Button>
                                </div>
                                <div className="confirm-modal-buttons__item">
                                    <Button type="primary" onClick={() => handleCreateInspection(true)}>
                                        Принято
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>

                <div className="divider mt32" />
                <div className="mt32">
                    <h2>Комментарий</h2>
                    {data.noteComments.length !== 0 ? (
                        data.noteComments.map((item, key) => (
                            <div key={key}>
                                <Row className="view-comments__item">
                                    <div className="view-comments__row">
                                        <div className="view-comments__label">
                                            <Text>Комментарий</Text>
                                        </div>
                                        <div className="view-comments__content">
                                            <Text className="view-comments__comment">{item.content}</Text>
                                        </div>
                                    </div>
                                    <div className="view-comments__row">
                                        <div className="view-comments__label">
                                            <Text>Автор</Text>
                                        </div>
                                        <div className="view-comments__content">
                                            <Text className="view-comments__user">{item.user ? item.user.fullname : 'Нет автора'}</Text>
                                        </div>
                                    </div>
                                    <div className="view-comments__row">
                                        <div className="view-comments__label">
                                            <Text>Дата</Text>
                                        </div>
                                        <div className="view-comments__content">
                                            <Text className="view-comments__date">
                                                {moment(item.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm:ss')}
                                            </Text>
                                        </div>
                                    </div>
                                </Row>
                                <Divider />
                            </div>
                        ))
                    ) : (
                        <p>Нет комментариев</p>
                    )}
                    <div className="mt32">
                        <TextArea onChange={e => setComment(e.target.value)} value={comment} rows={4} placeholder={'Введите комментарий'} />
                        <Button onClick={handleCreateComment} className="mt16">
                            <span>Отправить</span>
                        </Button>
                    </div>
                </div>
                <NoteHistory projectId={projectId} noteId={data.id} />
                {ntdModalIsVisible && (
                    <Modal
                        title={
                            <>
                                {ntdBack && (
                                    <a href="" className="modal__back" onClick={handleNtdBack}>
                                        <Icon type="left" />
                                    </a>
                                )}
                                <span>Справочник НТД</span>
                            </>
                        }
                        visible={ntdModalIsVisible}
                        onCancel={handleNtdClose}
                        footer={null}>
                        <ul className="modal-list">
                            {ntd.success && ntd.data.children.length === 0 ? (
                                <p>Нет данных</p>
                            ) : (
                                <>
                                    {!ntdBack && treeNtd && (
                                        <div
                                            className={
                                                searchValue.length > 0 ? 'search search_full-length changed' : 'search search_full-length'
                                            }
                                            style={{ width: '100%', margin: 0 }}>
                                            <Input.Search
                                                value={searchValue}
                                                placeholder="Искать..."
                                                onSearch={onSearch}
                                                onChange={onChange}
                                            />
                                            {searchValue.length > 0 && (
                                                <span className="search__close" onClick={onReset}>
                                                    <Icon type="close" />
                                                </span>
                                            )}
                                        </div>
                                    )}

                                    {ntd.data.children && ntd.data.children.every(item => item.type === 'paragraph') && (
                                        <div
                                            className={
                                                searchNtdLocalValue.length > 0
                                                    ? 'search search_full-length changed'
                                                    : 'search search_full-length'
                                            }
                                            style={{ width: '100%', margin: 0 }}>
                                            <Input.Search
                                                value={searchNtdLocalValue}
                                                placeholder="Искать..."
                                                onSearch={onLocalSearch}
                                                onChange={onLocalChange}
                                            />
                                            {searchNtdLocalValue.length > 0 && (
                                                <span className="search__close" onClick={onLocalReset}>
                                                    <Icon type="close" />
                                                </span>
                                            )}
                                        </div>
                                    )}
                                    {(filtredNtd || ntd.data.children || []).map(item => (
                                        <li key={item.id}>
                                            <a href="" className="modal-list__item" onClick={e => handleChooseNtd(e, item)}>
                                                <span className="modal-item__icon">
                                                    <Icon type={item.type === 'folder' ? 'folder' : 'file'} theme="filled" />
                                                </span>
                                                <span>{item.title}</span>
                                            </a>
                                        </li>
                                    ))}
                                </>
                            )}
                        </ul>
                    </Modal>
                )}

                {isShowGallery && (
                    <Lightbox
                        mainSrc={data.noteFiles[photoIndex].full_path}
                        nextSrc={data.noteFiles[(photoIndex + 1) % data.noteFiles.length].full_path}
                        prevSrc={data.noteFiles[(photoIndex + data.noteFiles.length - 1) % data.noteFiles.length].full_path}
                        onCloseRequest={() => setShowGallery(false)}
                        onMovePrevRequest={() => setPhotoIndex((photoIndex + data.noteFiles.length - 1) % data.noteFiles.length)}
                        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % data.noteFiles.length)}
                    />
                )}

                {!data.deleted_at ? (
                    <div className="mt32">
                        <Button type="danger" onClick={() => handleNoteArchive(data.structure_id, data.id)}>
                            <span>Архивировать</span>
                        </Button>
                    </div>
                ) : (
                    <div className="mt32">
                        <Button type="primary" onClick={() => handleNoteRestore(data.structure_id, data.id)}>
                            <span>Восстановить</span>
                        </Button>
                    </div>
                )}
            </Form>
            <Modal
                title="Подтверждение замечание"
                visible={confirmModalIsVisible}
                okText="Да"
                cancelText="Нет"
                footer={[
                    <>
                        <div className="confirm-modal-buttons">
                            {!commentVisible && (
                                <div className="confirm-modal-buttons__item">
                                    <Button type="primary" onClick={handleConfirmModal}>
                                        Принять
                                    </Button>
                                </div>
                            )}
                            <div className="confirm-modal-buttons__item">
                                <Button type="danger" color="primary" onClick={handleCancel}>
                                    Отклонить
                                </Button>
                            </div>
                        </div>
                    </>,
                ]}>
                <>
                    {commentVisible ? (
                        <div className="confirm-modal-buttons__comment-wrap">
                            <span className="confirm-modal-buttons__comment-label">Укажите причину</span>
                            <TextArea
                                onChange={e => setConfirmModalComment(e.target.value)}
                                value={confirmModalComment}
                                rows={4}
                                placeholder={'Введите комментарий'}
                            />
                        </div>
                    ) : (
                        <span>Вы выбраны испольнителем на замечание № - {data.number}</span>
                    )}
                </>
            </Modal>
        </>
    ) : null;
};

const mapStateToProps = state => {
    return {
        currentProject: state.projectModule.projects.currentProject,
        currentStructure: state.projectModule.structure.currentItem,
        executors: state.projectModule.notes.executors,
        auditors: state.projectModule.notes.auditors,
        dictionary: state.projectModule.notes.dictionary,
        uploadedFile: state.projectModule.notes.uploadedFile,
        deletedFile: state.projectModule.notes.deletedFile,
        ntd: state.projectModule.ntd.ntd,
        treeNtd: state.projectModule.ntd.treeNtd,
        permission: state.permission,
        isNoteArchived: state.projectModule.notes.isNoteArchived,
        retentionUnits: state.projectModule.notes.retentionUnits,
        workTypes: state.projectModule.notes.workTypes,
        singleNote: state.projectModule.notes.singleNote,
        counterparties: state.projectModule.notes.counterparties,
        organizations: state.projectModule.team.organizations,
        inspectionNotes: state.projectModule.notes.inspectionNotes,
        isChangeNote: state.projectModule.notes.isChangeNote,
        user: state.user.user,
    };
};

const mapDispatchToProps = dispatch => ({
    changeNote: (projectId, structureId, noteId, data, auditors) => dispatch(changeNote(projectId, structureId, noteId, data, auditors)),
    getNoteById: (projectId, structureId, noteId) => dispatch(getNoteById(projectId, structureId, noteId)),
    getExecutors: (projectId, structureId) => dispatch(getExecutors(projectId, structureId)),
    getFiltredExecutors: (projectId, structureId, counterpartyId) => dispatch(getFiltredExecutors(projectId, structureId, counterpartyId)),
    getAuditors: (projectId, structureId) => dispatch(getAuditors(projectId, structureId)),
    getDictionary: id => dispatch(getDictionary(id)),
    getFiltredDictionary: contractId => dispatch(getFiltredDictionary(contractId)),
    getAllNtd: () => dispatch(getAllNtd()),
    getNtdById: id => dispatch(getNtdById(id)),
    getTreeNtd: () => dispatch(getTreeNtd()),
    getRetentionUnits: () => dispatch(getRetentionUnits()),
    uploadNoteFile: (projectId, structureId, noteId, uploadFile, callback) =>
        dispatch(uploadNoteFile(projectId, structureId, noteId, uploadFile, callback)),
    deleteNoteFile: (projectId, structureId, noteId, fileId) => dispatch(deleteNoteFile(projectId, structureId, noteId, fileId)),
    archiveNote: (projectId, structureId, noteId) => dispatch(archiveNote(projectId, structureId, noteId)),
    restoreNote: (projectId, structureId, noteId) => dispatch(restoreNote(projectId, structureId, noteId)),
    resetArchiveStatus: () => dispatch(resetArchiveStatus()),
    changeFavorite: (section, itemId, isFavorite, callback) => dispatch(changeFavorite(section, itemId, isFavorite, callback)),
    setNote: data => dispatch(setNote(data)),
    createComment: (projectId, structureId, itemId, comment) => dispatch(createNoteComment(projectId, structureId, itemId, comment)),
    getNoteWorkTypes: dictionaryId => dispatch(getNoteWorkTypes(dictionaryId)),
    resetNoteWorkTypes: () => dispatch(resetNoteWorkTypes()),
    getCounterParties: projectId => dispatch(getCounterParties(projectId)),
    getOrganizations: () => dispatch(getOrganizations()),
    getContracts: (projectId, structureId, counterpartyId, dictionaryId) =>
        dispatch(getContracts(projectId, structureId, counterpartyId, dictionaryId)),
    getInspectionNotes: (projectId, noteId) => dispatch(getInspectionNotes(projectId, noteId)),
    createInspectionNotes: (projectId, noteId, data, callback) => dispatch(createInspectionNotes(projectId, noteId, data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectNoteDetail);
