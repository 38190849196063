import React from 'react';
import { connect } from 'react-redux';
import { Tabs, Button, Modal, message } from 'antd';
import { pdfjs, Document, Page } from 'react-pdf';
import GrabToPan from '../../helpers/GrabToPan';
import MapMarker from '../DrawablePage/MapMarker';
import MaterialIcon from 'material-icons-react';

import { changeFavorite, setCurrentDoc } from '../../redux/actions/projectModule';
import { createComment } from '../../redux/actions/projectModule/createComment';
import {
    getNotesByDocument,
    setCopiedNote,
    changeDocumentNotesList,
    changeNote,
    getNoteById,
    createNote,
    changeNoteStatus,
} from '../../redux/actions/projectModule/getNotes';
import { getProjectUserInfo } from '../../redux/actions/projectModule/getProjects';

import { ProjectNoteDetail } from '../ProjectNotes/Components';
import { BreadCrumbs } from '../BreadCrumbs';
import { Loading } from '../Loading';
import DrawDesk from '../DrawablePage/DrawDesk';
import MarkerInfo from './MarkerInfo';
import LayersControl from './LayersControl';

import './ProjectDocs.scss';
import { getPermissions } from '../../redux/actions/common';
import MarkerCopyInfo from './MarkerCopyInfo';

const { TabPane } = Tabs;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class ProjectDocViewer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            numPages: null,
            pageNumber: 1,
            zoom: 1,
            pdfWidth: 0,
            pdfHeight: 0,
            buttonAction: 'Hand',
            buttonRepeatClick: false,
            drawAction: 'line',
            marker: null,
            visible: false,
            clickedNote: { title: '' },
            showFormNoteDetail: false,
            activeTab: '1',
            markerInfoPos: { x: 0, y: 0 },
            visibleLayersControl: false,
            visiblemarkers: null,
            qualityControl: null,
            isDocumentLoaded: false,
            isCloneModeOtherDoc: false,
            comment: '',
            fullScreen: false,
            canClick: true,
            sleep: false,
        };

        this.localNotesList = { loading: true }; // to remove flickering during requests by interval
        this.zoomFactor = 0.2;
        this.pdfDoc = null;
        this.pdfDocumentPage = null;
        this.grapToPanActivated = false;
        this.pdfPage = null;
        this.noteInterval = null;
        this.g2p = null;
        this.canvas = null;
        this._isMounted = false;

        this.sleepTimerValue = 60;
        this.sleepTimerCurrentValue = this.sleepTimerValue;
    }

    componentDidMount() {
        const { getNotesByDocument, getProjectUserInfo, documents, history, getPermission } = this.props;
        const { projectId, currentStructureId, currentSectionItemDetails } = this.props.match.params;

        this._isMounted = true;
        this.props.setEmptyNotes();

        this.unlisten = this.props.history.listen(() => {
            // Detecting, user has changed URL
            const { history } = this.props;

            if (history.action === 'POP' && this.state.activeTab === '2') {
                this.setState({
                    activeTab: '1',
                    showFormNoteDetail: false,
                });
            }
        });

        if (this.props.history.hash === '#new') {
            this.props.history.push(this.props.history.location.pathname);
        }

        // if (this._isMounted) {
        getNotesByDocument(projectId, currentStructureId, +currentSectionItemDetails);
        getProjectUserInfo(projectId);

        this.setNotesCheck();

        this.startCheckUserActive();

        getPermission(projectId);
        // }
    }

    componentWillUnmount() {
        this.props.setEmptyNotes();
        this.delNotesCheck();

        this._isMounted = false;
        // if (this.noteUpdaterInterval) clearInterval(this.noteUpdaterInterval);

        // if (this.pdfContainer) {
        //     this.pdfContainer.removeEventListener('wheel', this);
        // }

        this.removeEventsFromPdfContainer();

        this.unlisten();
        if (this.userActiveInterval) clearInterval(this.userActiveInterval);
    }

    getData = () => {
        const { getNotesByDocument, documents } = this.props;
        const { projectId, currentStructureId } = this.props.match.params;

        getNotesByDocument(projectId, currentStructureId, documents.currentDoc.id);
    };

    onDocumentLoadSuccess = document => {
        const { getNotesByDocument, documents, history, copiedNote } = this.props;
        const { projectId, currentStructureId } = this.props.match.params;

        if (this.grapToPanActivated) {
            return;
        }
        // this.g2p = new GrabToPan({
        //     element: this.pdfDocumentPage,
        // });
        // this.g2p.activate();

        this.g2p = new GrabToPan({
            element: this.test,
            // onActiveChanged: function (isActive) {
            //     console.log('Grab-to-pan is ' + (isActive ? 'activated' : 'deactivated'));
            // },
        });
        this.g2p.activate();

        this.grapToPanActivated = true;

        this.addEventsToPdfContainer();
        this.setState({
            isDocumentLoaded: true,
        });

        if (history.location.hash == '' && history.location.hash !== '#new' && history.action === 'POP') {
            this.checkActiveMarker();
        }

        if (localStorage.getItem('clickedNoteInfo')) {
            localStorage.removeItem('clickedNoteInfo');
        }

        if (history.location.hash !== '' && history.location.hash !== '#new') {
            this.emulatePressMarker();
        }
    };
    onPageLoadSuccess = page => {
        if (page.rotate % 180 !== 0) {
            this.setState(
                {
                    pdfWidth: page.height,
                    pdfHeight: page.width,
                },
                this.setState({ zoom: 0.6 }, () => {
                    this.g2p.element.scrollLeft = 5000 - this.g2p.element.offsetWidth / 2;
                    this.g2p.element.scrollTop = 5000 - this.g2p.element.offsetHeight / 2;
                }),
            );
        } else {
            let pdfHeight = page.height ? page.height : Math.abs(page.view[1]);
            this.setState(
                {
                    pdfWidth: page.width,
                    pdfHeight: pdfHeight,
                },
                this.setState({ zoom: 0.6 }, () => {
                    this.g2p.element.scrollLeft = 5000 - this.g2p.element.offsetWidth / 2;
                    this.g2p.element.scrollTop = 5000 - this.g2p.element.offsetHeight / 2;
                }),
            );
        }
        this.pdfPage = page;

        const { copiedNote, match } = this.props;
        const { currentSectionItemDetails } = match.params;

        if (this.props.copiedNote.data && parseInt(currentSectionItemDetails) !== copiedNote.data.document_id) {
            this.changeButtonAction('MarkerCopy');
            this.setState({
                isCloneModeOtherDoc: true,
            });
        }
    };
    onMouseZoom = e => {
        let currentZoom = this.state.zoom,
            nextZoom;

        if (e.deltaY < 0) {
            nextZoom = +(currentZoom + this.zoomFactor).toFixed(1);

            this.setState({ zoom: nextZoom }, () => {
                this.afterZooming(e, currentZoom);
            });
        }
        if (e.deltaY > 0) {
            nextZoom = +(currentZoom - this.zoomFactor).toFixed(1);

            if (nextZoom >= 0.4) {
                this.setState({ zoom: nextZoom }, () => {
                    this.afterZooming(e, currentZoom);
                });
            }
        }
    };

    afterZooming = (e, prevZoom) => {
        const { zoom } = this.state;
        const TnT = this.g2p.element.children[0].getBoundingClientRect();
        // console.log('afterZooming -> TnT', TnT);

        let offsetX = e.pageX - TnT.x - 5000,
            offsetY = e.pageY - TnT.y - 5000 - window.pageYOffset;
        // console.log('======================');
        // console.log('e.page', e.pageX, e.pageY);
        // console.log('TnT', TnT.x, TnT.y);
        // console.log('offsetX', offsetX, offsetY);
        // // console.log('offsetX 2', offsetX * (zoom / prevZoom), offsetY * (zoom / prevZoom));
        // // console.log('result -', offsetX - offsetX * (zoom / prevZoom), offsetY - offsetY * (zoom / prevZoom));
        // // console.log(this.g2p.element.scrollLeft, this.g2p.element.scrollTop);
        // console.log('======================');

        this.g2p.element.scrollLeft -= offsetX - offsetX * (zoom / prevZoom);
        this.g2p.element.scrollTop -= offsetY - offsetY * (zoom / prevZoom);

        if (this.state.visible) {
            this.setState(state => {
                return {
                    markerInfoPos: this.recalcMarkerInfoPos(state.clickedNote),
                };
            });
        }
    };

    closeMarkerInfoAndRemoveClickedNote = () => {
        if (this.state.visible) {
            this.setState({
                visible: false,
            });
            this.g2p.deactivate();
            this.grapToPanActivated = false;
        }
        this.g2p.deactivate();
        this.grapToPanActivated = false;
    };

    changeButtonAction = type => {
        if (type === 'MarkerMove') {
            if (type === 'MarkerMove' && !this.grapToPanActivated) {
                this.g2p.activate();
                this.grapToPanActivated = true;
            } else if (type !== 'MarkerMove' && this.grapToPanActivated) {
                this.g2p.deactivate();
                this.grapToPanActivated = false;
            }

            if (this.state.clickedNote.id) {
                this.closeMarkerInfoAndRemoveClickedNote();
            }
        } else {
            if (type === 'Hand' && !this.grapToPanActivated) {
                this.g2p.activate();
                this.grapToPanActivated = true;
            } else if (type !== 'Hand' && this.grapToPanActivated) {
                this.g2p.deactivate();
                this.grapToPanActivated = false;
            }
        }

        if (this.state.buttonAction === type) {
            this.setState(state => {
                return {
                    buttonAction: type,
                    buttonRepeatClick: !state.buttonRepeatClick,
                };
            });
        } else {
            this.setState({
                buttonAction: type,
                buttonRepeatClick: false,
            });
        }
    };

    changeDrawAction = type => {
        this.setState({
            drawAction: type,
            buttonRepeatClick: false,
        });
    };

    recalcCoordsX = x => {
        return (x * this.state.pdfWidth) / 100;
    };

    recalcCoordsY = y => {
        return (y * this.state.pdfHeight) / 100;
    };

    setMarkerMoveState = note => {
        if (this.state.clickedNote.id === note.id) {
            this.g2p.activate();
            this.grapToPanActivated = true;
            this.setState({
                clickedNote: { title: '' },
            });
        } else {
            this.g2p.deactivate();
            this.grapToPanActivated = false;
            this.setState({
                clickedNote: note,
            });
        }
    };

    markerClicked = note => {
        let markerInfoPos = { x: 0, y: 0 };

        markerInfoPos = this.recalcMarkerInfoPos(note);

        switch (this.state.buttonAction) {
            case 'MarkerMove':
                this.setMarkerMoveState(note);
                break;
            default:
                this.setState({
                    clickedNote: note,
                    visible: true,
                    markerInfoPos: markerInfoPos,
                });
                break;
        }

        if (this.state.buttonAction === 'MarkerCopy') {
            this.setState({
                buttonAction: 'Hand',
            });
        }
    };

    recalcMarkerInfoPos = note => {
        let markerInfoPos = {
            x: this.recalcCoordsX(note.pX) * this.state.zoom,
            y: this.recalcCoordsY(note.pY) * this.state.zoom + 5,
        };

        if (markerInfoPos.x < 170) {
            markerInfoPos.x += 170 - markerInfoPos.x;
        }
        if (markerInfoPos.x + 170 > this.state.pdfWidth * this.state.zoom) {
            markerInfoPos.x += this.state.pdfWidth * this.state.zoom - 190 - markerInfoPos.x;
        }

        return markerInfoPos;
    };

    handleOk = e => {
        // const { createFolder, uploadFile, getDocuments, rootStructureId, match, rootDocumentId } = this.props;
        // const { projectId, currentStructureId } = match.params;
        // const { activeModalName, folderName, selectedFile, fileName } = this.state;

        this.handleCancel();
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            clickedNote: { title: '' },
        });
    };

    handleCopy = () => {
        this.changeButtonAction('MarkerCopy');
        this.setState({ visible: false });
    };

    handleCopyOtherLayer = () => {
        const { clickedNote } = this.state;
        const { setCopiedNote, match } = this.props;
        const { currentSectionItemDetails, projectId } = match.params;

        let sendData = this.getCopiedData({
            ...clickedNote,
            pX: 0,
            pY: 0,
            state: 'open',
            document_id: parseInt(currentSectionItemDetails),
        });
        sendData.id = clickedNote.id;
        sendData.projectId = parseInt(projectId);

        this.handleCancel();
        setCopiedNote(sendData);
    };

    addEventsToPdfContainer = () => {
        this.pdfContainer.addEventListener('wheel', e => {
            e.preventDefault();

            this.onMouseZoom(e);
        });

        this.pdfDocumentPage.addEventListener('click', e => {
            if (
                this.state.buttonAction === 'MarkerDrawable' ||
                this.state.buttonAction === 'MarkerCopy' ||
                this.state.buttonAction === 'MarkerMove'
            ) {
                if (this.state.canClick && e.target.tagName === 'CANVAS') {
                    const { createNote } = this.props,
                        { marker } = this.state,
                        { projectId, currentStructureId, currentSectionItemDetails } = this.props.match.params;

                    if (this.state.buttonAction === 'MarkerMove') {
                        this.markerMoveLocal(e);
                    }

                    if (this.state.buttonAction === 'MarkerDrawable') {
                        const storageMarker = this.checkStorageMarker();
                        if (storageMarker) {
                            this.showConfirmStorageMarker(storageMarker);
                            return;
                        }

                        const sendData = {
                            title: '',
                            description: '',
                            isEnabled: false,
                            ntdData: null,
                            ntdFolderName: null,
                            executor: undefined,
                            dictionary: [],
                            date: null,
                            auditors: [],
                            pX: marker.pX,
                            pY: marker.pY,
                            document_id: parseInt(currentSectionItemDetails),
                        };

                        createNote(projectId, currentStructureId, sendData, data => {
                            this.props.history.push(`${this.props.history.location.pathname}#new`);
                            this.successSendMarker(data);
                        });

                        this.setState({
                            canClick: false,
                        });

                        return;
                    }

                    if (this.state.buttonAction === 'MarkerCopy' && !this.state.isCloneModeOtherDoc) {
                        const { marker, clickedNote } = this.state;

                        let sendData = this.getCopiedData({
                            ...clickedNote,
                            pX: marker.pX,
                            pY: marker.pY,
                            // document_id: parseInt(currentSectionItemDetails),
                            state: 'open',
                            // isCopied: true,
                        });

                        createNote(projectId, currentStructureId, sendData, data => {
                            this.props.history.push(`${this.props.history.location.pathname}#new`);
                            this.successSendMarker(data);
                        });

                        this.setState({
                            canClick: false,
                        });

                        return;
                    }

                    // Копирование на другой документ
                    if (this.state.buttonAction === 'MarkerCopy' && this.state.isCloneModeOtherDoc) {
                        const { copiedNote, match, setCopiedNote } = this.props;
                        const { currentSectionItemDetails } = match.params;

                        if (parseInt(currentSectionItemDetails) === copiedNote.data.document_id) {
                            return false;
                        }

                        const { marker } = this.state;

                        let sendData = {
                            ...copiedNote.data,
                            pX: marker.pX,
                            pY: marker.pY,
                            document_id: parseInt(currentSectionItemDetails),
                        };
                        delete sendData.id;
                        delete sendData.projectId;

                        createNote(projectId, currentStructureId, sendData, data => {
                            this.props.history.push(`${this.props.history.location.pathname}#new`);
                            this.successSendMarker(data);
                            setCopiedNote(null);
                        });

                        this.setState({
                            canClick: false,
                        });

                        return;
                    }
                }
            }
        });

        this.pdfDocumentPage.addEventListener('mousedown', e => {
            if (e.buttons === 1 && this.state.canClick && e.target.tagName === 'CANVAS') {
                if (this.state.buttonAction === 'MarkerMove') {
                    // this.grapToPanActivated = false;
                    this.markerMoveLocal(e);
                }

                if (this.state.buttonAction === 'MarkerDrawable' || this.state.buttonAction === 'MarkerCopy') {
                    const posDoc = this.pdfDocumentPage.childNodes[0].getBoundingClientRect();

                    this.delNotesCheck();

                    this.setState({
                        marker: {
                            state: 'open',
                            pX: ((e.clientX - posDoc.left) / this.state.zoom / this.state.pdfWidth) * 100,
                            pY: ((e.clientY - 1 - posDoc.top) / this.state.zoom / this.state.pdfHeight) * 100,
                        },
                    });
                }
            }
        });

        this.pdfDocumentPage.addEventListener(
            'mousemove',
            e => {
                if (!this.grapToPanActivated) {
                    if (e.buttons === 1 && this.state.canClick) {
                        if (this.state.buttonAction === 'MarkerDrawable' || this.state.buttonAction === 'MarkerCopy') {
                            const posDoc = this.pdfDocumentPage.childNodes[0].getBoundingClientRect();

                            this.setState({
                                marker: {
                                    state: 'open',
                                    pX: ((e.clientX - posDoc.left) / this.state.zoom / this.state.pdfWidth) * 100,
                                    pY: ((e.clientY - 1 - posDoc.top) / this.state.zoom / this.state.pdfHeight) * 100,
                                },
                            });
                        }

                        if (this.state.buttonAction === 'MarkerMove') {
                            this.markerMoveLocal(e);
                        }
                    }
                }

                if (this.sleepTimerCurrentValue <= 0) {
                    this.startCheckUserActive();
                }
                this.sleepTimerCurrentValue = this.sleepTimerValue;
            },
            false,
        );

        this.pdfDocumentPage.addEventListener('mouseup', e => {
            if (this.state.canClick && e.target.tagName === 'CANVAS') {
                if (this.state.buttonAction === 'MarkerMove') {
                    this.changeDataMovedMarker();
                }
            }
        });
    };

    getCopiedData = data => {
        const {
            title,
            description,
            ntd,
            executor,
            executor_organization_id,
            contract,
            noteDictionaries,
            date_deadline,
            noteAudits,
            pX,
            pY,
            document_id,
            retention_unit,
            retention_count,
            retention_status_id,
            contract_id,
        } = data;

        const newData = {
            title: title,
            description: description ? description : '',
            ntd_id: ntd && ntd.id ? ntd.id : null,
            ntdFolderName: ntd && ntd.title ? ntd.title : null,
            executor_organization_id: executor_organization_id ? executor_organization_id : null,
            executor_id: executor?.id ? executor.id : undefined,
            contract_id: contract?.id ? contract.id : null,
            categories: noteDictionaries.length ? noteDictionaries.map(item => ({ dictionary_id: item.id })) : [],
            date_deadline: date_deadline ? date_deadline : null,
            audits: noteAudits.length ? noteAudits.map(item => ({ auditor_id: item.id })) : [],
            pX: pX,
            pY: pY,
            document_id: document_id,
            state: 'open',
            retention_unit_id: retention_unit?.id ? retention_unit.id : null,
            retention_count: retention_count ? retention_count : null,
            retention_status_id: retention_status_id,
            contract_id,
            // selectedFiles: [],
            // selectedFile: null,
        };

        return newData;
    };

    removeEventsFromPdfContainer = () => {
        if (this.pdfContainer) {
            this.pdfContainer.removeEventListener('wheel', this);
        }

        if (this.pdfDocumentPage) {
            this.pdfDocumentPage.removeEventListener('click', this);
            this.pdfDocumentPage.removeEventListener('mousedown', this);
            this.pdfDocumentPage.removeEventListener('mousemove', this);
            this.pdfDocumentPage.removeEventListener('mouseup', this);
        }
    };

    markerMoveLocal = e => {
        const { clickedNote } = this.state;
        const { documentNotesList, changeDocumentNotesList } = this.props;
        const posDoc = this.pdfDocumentPage.childNodes[0].getBoundingClientRect();

        this.delNotesCheck();

        if (clickedNote.id) {
            const mappedItems = documentNotesList.data.map(item => {
                if (item.id === clickedNote.id) {
                    return {
                        ...item,
                        pX: ((e.clientX - posDoc.left) / this.state.zoom / this.state.pdfWidth) * 100,
                        pY: ((e.clientY - 1 - posDoc.top) / this.state.zoom / this.state.pdfHeight) * 100,
                    };
                }

                return item;
            });
            changeDocumentNotesList(mappedItems);
        }
    };

    changeDataMovedMarker = () => {
        const { clickedNote } = this.state;
        const { documentNotesList, match, changeNote } = this.props;
        const { projectId, currentStructureId } = match.params;

        const currentItem = documentNotesList.data.find(item => item.id === clickedNote.id);

        if (currentItem) {
            const changedData = {
                ...clickedNote,
                pX: currentItem.pX,
                pY: currentItem.pY,
            };

            changeNote(parseInt(projectId), parseInt(currentStructureId), parseInt(clickedNote.id), changedData);
        }
    };

    successSendMarker = data => {
        // const { projectId, currentStructureId, currentSectionItemDetails } = this.props.match.params;
        const { getNotesByDocument, documents, getNoteById } = this.props;
        const { projectId, currentStructureId } = this.props.match.params;

        getNotesByDocument(projectId, currentStructureId, documents.currentDoc.id);
        getNoteById(projectId, currentStructureId, data.id, () => {
            this.setState({
                activeTab: '2',
                marker: null,
                showFormNoteDetail: true,
                canClick: true,
            });
        });

        this.changeButtonAction('Hand');

        this.setNotesCheck();
    };

    setNotesCheck = () => {
        const { getNotesByDocument, documents } = this.props;
        const { projectId, currentStructureId, currentSectionItemDetails } = this.props.match.params;

        // update every 5 secs @TODO - replace it with normal push events or websocket
        this.noteUpdaterInterval = setInterval(() => {
            if (this._isMounted) {
                getNotesByDocument(projectId, currentStructureId, +currentSectionItemDetails);
            } else {
                clearInterval(this.noteUpdaterInterval);
            }
        }, 5000);
    };

    delNotesCheck = () => {
        if (this.noteUpdaterInterval) clearInterval(this.noteUpdaterInterval);
    };

    changeVisibleMarkers = visibleMarkers => {
        this.setState({
            visibleMarkers,
        });
    };

    changeQualityControl = value => {
        this.setState({
            qualityControl: value,
        });
    };

    emulatePressMarker = () => {
        const { history, documentNotesList } = this.props,
            id = parseFloat(history.location.hash.slice(1));
        setTimeout(() => {
            const marker = documentNotesList.data.find(item => item.id === id) || null;

            if (marker) {
                // const x = 5000 - this.recalcCoordsX(marker.pX) * this.state.zoom - this.g2p.element.offsetWidth / 2,
                //     y = 5000 - this.recalcCoordsY(marker.pY) * this.state.zoom - this.g2p.element.offsetHeight / 2;
                this.markerClicked(marker);

                // const posDoc = this.pdfDocumentPage.childNodes[0].getBoundingClientRect();
                // this.g2p.element.scrollTop = y;
                // this.g2p.element.scrollLeft = x;
            }
            // this.props.history.push(this.props.history.location.pathname);
        }, 100);
    };

    checkActiveMarker = () => {
        const { documents } = this.props;
        let storageMarkerInfo = localStorage.getItem('clickedNoteInfo');
        localStorage.removeItem('clickedNoteInfo');
        if (storageMarkerInfo && storageMarkerInfo !== '') {
            storageMarkerInfo = JSON.parse(storageMarkerInfo);

            if (parseInt(documents.currentDoc.id) === storageMarkerInfo.document_id) {
                this.setState({
                    clickedNote: { title: '', id: storageMarkerInfo.id },
                });
            }
        }
    };

    checkStorageMarker = () => {
        let storageMarker = localStorage.getItem('placeMarker');

        if (storageMarker !== '') {
            storageMarker = JSON.parse(storageMarker);

            return storageMarker;
        }

        return false;
    };

    showConfirmStorageMarker = storageMarker => {
        Modal.confirm({
            title: `Привязать заметку №${storageMarker.number}?`,
            content: storageMarker.title,
            zIndex: 1002,
            onOk: () => {
                this.placeStorageMarker(storageMarker);
            },
            onCancel: () => {
                localStorage.removeItem('placeMarker');
                this.changeButtonAction('Hand');
                this.setState({
                    marker: null,
                });
            },
        });
    };

    placeStorageMarker = storageMarker => {
        const { changeNote, documents, match, getNotesByDocument } = this.props;
        const { projectId, currentStructureId } = match.params;
        const { marker } = this.state;

        if (parseInt(projectId) === storageMarker.project_id) {
            let sendData = {};
            sendData.pX = marker.pX;
            sendData.pY = marker.pY;
            sendData.structure_id = parseInt(currentStructureId);
            sendData.document_id = parseInt(documents.currentDoc.id);
            sendData.number = parseInt(storageMarker.number);

            changeNote(parseInt(projectId), parseInt(storageMarker.structure_id), parseInt(storageMarker.id), sendData, undefined, () => {
                getNotesByDocument(projectId, currentStructureId, documents.currentDoc.id);
            });
        } else {
            message.error('Размещать замечания из другого проекта запрещено!');
        }

        localStorage.removeItem('placeMarker');

        this.changeButtonAction('Hand');

        this.setState({
            marker: null,
        });

        this.setNotesCheck();

        return;
    };

    handleInput = e => {
        this.setState({
            comment: e.target.value,
        });
    };

    createComment = () => {
        const { createComment, match, singleNote } = this.props;
        const { projectId, currentStructureId } = match.params;
        const { comment } = this.state;

        createComment(projectId, currentStructureId, singleNote.data.id, comment);
    };

    changeStatus = status => {
        const { changeNoteStatus, match, singleNote } = this.props;
        const { projectId, currentStructureId } = match.params;

        changeNoteStatus(projectId, currentStructureId, singleNote.data.id, status);
    };

    renderMarker = item => {
        const { zoom, clickedNote } = this.state;
        return (
            <MapMarker
                key={item.id}
                markerClicked={note => this.markerClicked(note)}
                note={item}
                x={this.recalcCoordsX(item.pX)}
                y={this.recalcCoordsY(item.pY)}
                zoom={zoom}
                isActive={(clickedNote && item.id === clickedNote.id) || false}
            />
        );
    };

    renderStatusLayers = item => {
        const markers = [];
        const { visibleMarkers } = this.state;

        if (visibleMarkers[item.state].isActive && item.retention_status_id !== 0) {
            markers.push(this.renderMarker(item));
        } else if (visibleMarkers.elimination.isActive && item.retention_status_id === 0) {
            markers.push(this.renderMarker(item));
        }

        return markers;
    };

    renderMarkers(localNotesList) {
        const markers = [];
        const { visibleMarkers } = this.state;

        if (visibleMarkers) {
            localNotesList.forEach(item => {
                if (visibleMarkers[item.state].isActive) {
                    markers.push(this.renderStatusLayers(item));
                }
            });
        }

        return markers;
    }

    handleFavorite = (section, itemId, isFavorite) => {
        let copyData = Object.assign({}, this.props.documents.currentDoc);
        copyData.is_favorite = !copyData.is_favorite;
        this.props.setCurrentDoc(copyData);

        this.props
            .changeFavorite(section, itemId, isFavorite)
            .then(response => {
                // console.log('handleFavorite -> response', response);
                // console.log('DATA', data);
            })
            .catch(error => {
                copyData.is_favorite = !copyData.is_favorite;
                this.props.setCurrentDoc(copyData);
            });
    };

    checkUserActive = () => {
        if (this.sleepTimerCurrentValue <= 0) {
            this.delNotesCheck();
            clearInterval(this.userActiveInterval);
            this.setState({
                sleep: true,
            });
        }
    };

    startCheckUserActive = () => {
        this.setState({
            sleep: false,
        });
        this.delNotesCheck();
        this.setNotesCheck();

        this.userActiveInterval = setInterval(() => {
            this.sleepTimerCurrentValue--;
            this.checkUserActive();
        }, 1000);
    };

    render() {
        // console.log(this.state);
        const { pageNumber, numPages, qualityControl, zoom, visible, marker, pdfWidth, pdfHeight, drawAction, fullScreen } = this.state;
        const { documents, rootStructureId, projectUserInfo, rootDocumentId, documentNotesList, match, copiedNote, ...rest } = this.props;
        const { projectId, currentStructureId, section, path } = match.params;
        let localNotesList = documentNotesList || { loading: true };

        if (documentNotesList.loading === false && documentNotesList.data) {
            this.localNotesList = documentNotesList;
        } else {
            localNotesList = this.localNotesList;
        }

        const getLocalNotesListData = () => {
            const hasData = localNotesList.loading === false && localNotesList.data;

            if (qualityControl === 1) {
                return hasData ? localNotesList.data.filter(item => item.is_quality_control === 1) : [];
            } else {
                return hasData ? localNotesList.data.filter(item => item.is_quality_control === 0) : [];
            }
        };

        if (!documentNotesList.success) {
            return (
                <div className="structure">
                    <Loading />
                </div>
            );
        }

        if (documents.currentDoc.id !== +match.params.currentSectionItemDetails) {
            return (
                <div className="structure">
                    <Loading />
                </div>
            );
        }

        return documents.currentDoc.type !== 'file' ? null : (
            <div className="structure">
                {!documents.currentDoc.breadcrumbs ? null : <BreadCrumbs prevItems={documents.currentDoc} link={match.params} />}
                {/* <h3>{documents.currentDoc.title}</h3> */}
                <MarkerCopyInfo {...{ match: this.props.match }} />
                <Tabs
                    activeKey={this.state.activeTab}
                    tabBarStyle={{ display: 'none' }}
                    className={fullScreen ? 'pdf-wrapper pdf-wrapper_fullscreen' : 'pdf-wrapper'}>
                    <TabPane tab="" key="1">
                        {this.state.isDocumentLoaded && (
                            <div className="structure__menu structure__menu_top structure-menu ">
                                <Button
                                    title="Избранное"
                                    onClick={() => {
                                        this.handleFavorite('document', documents.currentDoc.id, documents.currentDoc.is_favorite);
                                    }}
                                    className={
                                        documents.currentDoc.is_favorite
                                            ? 'structure-menu__item structure-menu__item_blue'
                                            : 'structure-menu__item'
                                    }>
                                    <MaterialIcon icon="star" />
                                </Button>
                                <Button
                                    title="Замечания"
                                    onClick={() => {
                                        this.props.history.push({
                                            pathname: `/projects/${projectId}/comments/${currentStructureId}`,
                                            state: { docId: documents.currentDoc.id, docTitle: documents.currentDoc.title },
                                        });
                                    }}
                                    className="structure-menu__item">
                                    <MaterialIcon icon="speaker_notes" />
                                </Button>
                                <Button
                                    title="Слои"
                                    onClick={() => {
                                        this.setState(state => {
                                            return {
                                                visibleLayersControl: !state.visibleLayersControl,
                                            };
                                        });
                                    }}
                                    className="structure-menu__item">
                                    <MaterialIcon icon="layers" />
                                </Button>
                                <Button
                                    title={fullScreen ? 'Свернуть' : 'Развернуть'}
                                    type={fullScreen ? 'primary' : 'default'}
                                    onClick={() => {
                                        this.setState(state => {
                                            return {
                                                fullScreen: !state.fullScreen,
                                            };
                                        });
                                    }}
                                    className="structure-menu__item">
                                    {fullScreen && <MaterialIcon icon="fullscreen_exit" />}
                                    {!fullScreen && <MaterialIcon icon="fullscreen" />}
                                </Button>
                            </div>
                        )}
                        {this.state.isDocumentLoaded && (
                            <div className="structure__menu structure__menu_right structure-menu">
                                <Button
                                    type={this.state.buttonAction === 'Select' ? 'primary' : 'secondary'}
                                    title="Выбор"
                                    onClick={e => {
                                        this.changeButtonAction('Select');
                                    }}
                                    className="structure-menu__item">
                                    <MaterialIcon icon="photo_size_select_small" />
                                </Button>
                                <Button
                                    type={this.state.buttonAction === 'Hand' ? 'primary' : 'secondary'}
                                    title="Перемещение"
                                    onClick={e => {
                                        this.changeButtonAction('Hand');
                                    }}
                                    className="structure-menu__item">
                                    <MaterialIcon icon="pan_tool" />
                                </Button>
                                <div className="structure-menu__item-wrap">
                                    <Button
                                        type={this.state.buttonAction === 'Draw' ? 'primary' : 'secondary'}
                                        title="Рисование"
                                        onClick={e => {
                                            this.changeButtonAction('Draw');
                                        }}
                                        className="structure-menu__item">
                                        {drawAction === 'line' && <MaterialIcon icon="linear_scale" />}
                                        {drawAction === 'arrow' && <MaterialIcon icon="arrow_forward" />}
                                        {drawAction === 'circle' && <MaterialIcon icon="panorama_fish_eye" />}
                                        {drawAction === 'rectangle' && <MaterialIcon icon="crop_landscape" />}
                                        {drawAction === 'cross' && <MaterialIcon icon="clear" />}
                                    </Button>
                                    {this.state.buttonAction === 'Draw' && this.state.buttonRepeatClick && (
                                        <div className="structure-menu__item-childrens structure-menu__item-childrens_left">
                                            <Button
                                                type={this.state.drawAction === 'arrow' ? 'primary' : 'default'}
                                                title="Стрелка"
                                                onClick={e => {
                                                    this.changeDrawAction('arrow');
                                                }}
                                                className="structure-menu__item">
                                                <MaterialIcon icon="arrow_forward" />
                                            </Button>
                                            <Button
                                                type={this.state.drawAction === 'line' ? 'primary' : 'default'}
                                                title="Прямая"
                                                onClick={e => {
                                                    this.changeDrawAction('line');
                                                }}
                                                className="structure-menu__item">
                                                <MaterialIcon icon="linear_scale" />
                                            </Button>
                                            <Button
                                                type={this.state.drawAction === 'circle' ? 'primary' : 'default'}
                                                title="Круг"
                                                onClick={e => {
                                                    this.changeDrawAction('circle');
                                                }}
                                                className="structure-menu__item">
                                                <MaterialIcon icon="panorama_fish_eye" />
                                            </Button>
                                            <Button
                                                type={this.state.drawAction === 'rectangle' ? 'primary' : 'default'}
                                                title="Прямоугольник"
                                                onClick={e => {
                                                    this.changeDrawAction('rectangle');
                                                }}
                                                className="structure-menu__item">
                                                <MaterialIcon icon="crop_landscape" />
                                            </Button>
                                            <Button
                                                type={this.state.drawAction === 'cross' ? 'primary' : 'default'}
                                                title="Крестик"
                                                onClick={e => {
                                                    this.changeDrawAction('cross');
                                                }}
                                                className="structure-menu__item">
                                                <MaterialIcon icon="clear" />
                                            </Button>
                                        </div>
                                    )}
                                </div>
                                <Button
                                    type={this.state.buttonAction === 'MarkerDrawable' ? 'primary' : 'secondary'}
                                    title="Разместить замечание"
                                    onClick={e => {
                                        this.changeButtonAction('MarkerDrawable');
                                    }}
                                    className="structure-menu__item">
                                    <MaterialIcon icon="room" />
                                </Button>
                                <Button
                                    type={this.state.buttonAction === 'MarkerMove' ? 'primary' : 'secondary'}
                                    title="Переместить замечание"
                                    onClick={e => {
                                        this.changeButtonAction('MarkerMove');
                                    }}
                                    className="structure-menu__item">
                                    <MaterialIcon icon="open_with" />
                                </Button>
                            </div>
                        )}
                        <div
                            className="pdfcontainer"
                            ref={target => {
                                this.pdfContainer = target;
                            }}>
                            <Document
                                file={documents.currentDoc.file.full_path}
                                onLoadSuccess={this.onDocumentLoadSuccess}
                                loading="Подождите. Загрузка..."
                                inputRef={el => (this.pdfDoc = el)}>
                                <div
                                    ref={target => {
                                        this.test = target;
                                    }}
                                    style={{ overflow: 'hidden', height: '100%' }}>
                                    <div style={{ position: 'relative', width: '10000px', height: '10000px', overflow: 'hidden' }}>
                                        <div
                                            style={{
                                                position: 'relative',
                                                width: '100%',
                                                height: '100%',
                                                background: '#fff',
                                            }}></div>
                                        <Page
                                            pageNumber={pageNumber}
                                            onLoadSuccess={this.onPageLoadSuccess}
                                            inputRef={el => (this.pdfDocumentPage = el)}
                                            scale={zoom}
                                            width={pdfWidth}
                                            height={pdfHeight}
                                            renderAnnotationLayer={false}
                                            renderTextLayer={false}>
                                            {this.renderMarkers(getLocalNotesListData())}
                                            {marker && (
                                                <MapMarker
                                                    note={marker}
                                                    x={this.recalcCoordsX(marker.pX)}
                                                    y={this.recalcCoordsY(marker.pY)}
                                                    zoom={zoom}
                                                />
                                            )}
                                            <DrawDesk
                                                data={{
                                                    zoom,
                                                    width: pdfWidth,
                                                    height: pdfHeight,
                                                    documentId: this.props.documents.currentDoc.id,
                                                }}
                                                buttonAction={this.state.buttonAction}
                                                drawAction={drawAction}
                                                sleep={this.state.sleep}
                                            />
                                            {visible && (
                                                <MarkerInfo
                                                    marker={this.state.clickedNote}
                                                    projectId={projectId}
                                                    currentStructureId={currentStructureId}
                                                    handleClose={this.handleCancel}
                                                    handleCopy={this.handleCopy}
                                                    handleCopyOtherLayer={this.handleCopyOtherLayer}
                                                    history={this.props.history}
                                                    style={{
                                                        top: this.state.markerInfoPos.y,
                                                        left: this.state.markerInfoPos.x,
                                                        zIndex: 1001,
                                                    }}
                                                />
                                            )}
                                        </Page>
                                    </div>
                                </div>
                            </Document>
                        </div>
                        <LayersControl
                            projectUserInfo={this.props.projectUserInfo}
                            visible={this.state.visibleLayersControl}
                            changeVisibleMarkers={this.changeVisibleMarkers}
                            changeQualityControl={this.changeQualityControl}
                            qualityControl={this.state.qualityControl}
                            handleClose={() => this.setState({ visibleLayersControl: false })}
                            history={this.props.history}
                        />
                    </TabPane>
                    <TabPane tab="" key="2">
                        {this.state.showFormNoteDetail && this.props.singleNote.data && (
                            <ProjectNoteDetail
                                {...rest}
                                {...this.props}
                                statusData={[
                                    { status: 'open', statusTitle: 'Открыто', color: '#ed2d23' },
                                    { status: 'inwork', statusTitle: 'Выполнено', color: '#f2c94c' },
                                    { status: 'completed', statusTitle: 'Проверено', color: '#27ae60' },
                                ]}
                                data={this.props.singleNote.data}
                                handleInput={this.handleInput}
                                createComment={this.createComment.bind(this)}
                                changeStatus={this.changeStatus.bind(this)}
                            />
                        )}
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    documentNotesList: state.projectModule.notes.documentNotesList,
    documents: {
        currentDoc: state.projectModule.documents.data,
        breadcrumb: state.projectModule.documents.breadcrumb,
        loading: state.projectModule.documents.loading,
    },
    projectUserInfo: state.projectModule.projects.projectUserInfo.data,
    singleNote: state.projectModule.notes.singleNote,
    copiedNote: state.projectModule.notes.copiedNote,
});

const mapDispatchToProps = dispatch => ({
    getNotesByDocument: (projectId, structureId, documentId) => dispatch(getNotesByDocument(projectId, structureId, documentId)),
    setEmptyNotes: () => {
        dispatch({
            type: 'GET_DOCUMENT_NOTES_SUCCESS',
            data: [],
            success: false,
            errors: [],
            loading: true,
        });
    },
    changeNote: (projectId, structureId, noteId, data, auditors, callback) =>
        dispatch(changeNote(projectId, structureId, noteId, data, auditors, callback)),
    getNoteById: (projectId, structureId, itemId, callback) => dispatch(getNoteById(projectId, structureId, itemId, callback)),
    createNote: (projectId, structureId, data, callback = () => {}) => dispatch(createNote(projectId, structureId, data, callback)),
    changeNoteStatus: (projectId, structureId, itemId, status) => dispatch(changeNoteStatus(projectId, structureId, itemId, status)),
    createComment: (projectId, structureId, itemId, comment) => dispatch(createComment(projectId, structureId, itemId, comment)),
    changeFavorite: (section, itemId, isFavorite, callback) => dispatch(changeFavorite(section, itemId, isFavorite, callback)),
    setCurrentDoc: data => dispatch(setCurrentDoc(data)),
    getPermission: projectId => dispatch(getPermissions(projectId)),
    getProjectUserInfo: projectId => dispatch(getProjectUserInfo(projectId)),
    setCopiedNote: data => dispatch(setCopiedNote(data)),
    changeDocumentNotesList: data => dispatch(changeDocumentNotesList(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDocViewer);
