import React, { Component } from 'react';
import moment from 'moment';
import { Input, Form, DatePicker, TimePicker } from 'antd';
import { connect } from 'react-redux';

import { updateProject, getCurrentProject } from '../../redux/actions/projectModule/getProjects';
import { Loading } from '../Loading';

import './ProjectSettings.scss';
import { getPermissions } from '../../redux/actions/common';
import utils from '../../helpers/utils';

const dateFormat = 'YYYY-MM-DD';
const timeFormat = 'HH:mm:ss';

class ProjectSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataToUpdate: null,
            input: '',
            showFields: false,
        };
    }

    setData = () => {
        const { currentProject, form } = this.props;

        if (currentProject.data) {
            form.setFieldsValue({
                projectName: currentProject.data.title,
                projectNameLong: currentProject.data.title_long ? currentProject.data.title_long : '',
                projectNameShort: currentProject.data.title_short ? currentProject.data.title_short : '',
                projectAddressLong: currentProject.data.address ? currentProject.data.address : '',
                noteTemplateNumber: currentProject.data.note_template ? currentProject.data.note_template : '',
                city: currentProject.data.city ? currentProject.data.city : '',
            });
        }
    };

    componentWillMount() {
        // const { projectId } = this.props.match.params;
        // this.props.getCurrentProject(projectId);
    }

    componentDidUpdate(prevProps, prevState) {
        const { currentProject } = this.props;

        if (!prevProps.currentProject.data.id && currentProject.data.id) {
            this.setData();
        }
    }

    handleBlurInput = (e, key) => {
        const { projectId } = this.props.match.params;
        const { updateProject } = this.props;
        let value = e.target.value;

        if (this.state.input !== value) {
            switch (key) {
                case 0:
                    updateProject(projectId, { title: value });
                    break;
                case 1:
                    updateProject(projectId, { title_long: value });
                    break;
                case 2:
                    updateProject(projectId, { address: value });
                    break;
                case 3:
                    updateProject(projectId, { note_template: value });
                    break;
                case 4:
                    updateProject(projectId, { title_short: value });
                    break;
                case 5:
                    updateProject(projectId, { city: value });
                    break;
            }
        }
    };

    handleFocusInput = e => {
        this.setState({ input: e.target.value });
    };

    handleDatePick = (date, dateString, key) => {
        const { projectId } = this.props.match.params;
        const { updateProject } = this.props;
        switch (key) {
            case 0:
                updateProject(projectId, { date_start: dateString });
                break;
            case 1:
                updateProject(projectId, { date_deadline: dateString });
                break;
            default:
                break;
        }
    };
    handleTimePick = (time, timeString) => {
        const { projectId } = this.props.match.params;
        const { updateProject } = this.props;
        updateProject(projectId, { notification_time: timeString });
    };

    checkPermission = permission => {
        const { projectId } = this.props.match.params;
        let settingsPermission = permission.find(item => item.name === 'page.view.settings');

        if (settingsPermission.isAllowed) {
            this.setState({
                showFields: true,
            });
            this.props.getCurrentProject(projectId);
        } else {
            utils.showError('Недостаточно прав для совершения операции');
        }
    };

    getPermission = () => {
        this.props
            .getPermission(this.props.match.params.projectId)
            .then(response => {
                this.checkPermission(response.data.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    componentDidMount() {
        this.getPermission();
    }

    render() {
        const { currentProject } = this.props;
        const { getFieldDecorator } = this.props.form;

        if (!this.state.showFields) {
            return null;
        }

        if (currentProject.loading) {
            return <Loading />;
        }

        return (
            <>
                {currentProject.data !== {} ? (
                    <div className="alignCenter">
                        <Form className="container">
                            <h1>ОСНОВНАЯ ИНФОРМАЦИЯ</h1>
                            <div className="flexRow spaceBetween">
                                <div className="halfView">
                                    <div>
                                        <Form.Item className="inputStyle" label="Название проекта">
                                            {getFieldDecorator('projectName', {
                                                rules: [{ required: false }],
                                            })(
                                                <Input
                                                    onBlur={e => this.handleBlurInput(e, 0)}
                                                    onFocus={e => this.handleFocusInput(e)}
                                                    placeholder="Название проекта"
                                                />,
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div className="mt24">
                                        <h1>ДАТА НАЧАЛА</h1>

                                        <DatePicker
                                            defaultValue={moment(
                                                currentProject.data.date_start ? currentProject.data.date_start : new Date(),
                                                dateFormat,
                                            )}
                                            className="fullWidth"
                                            placeholder="Выберите дату начала"
                                            format={dateFormat}
                                            onChange={(date, dateString) => this.handleDatePick(date, dateString, 0)}
                                        />
                                        <div className="mt24">
                                            <h1>ОБЪЕКТ</h1>
                                            <Form.Item className="inputStyle" label="Полное название проекта">
                                                {getFieldDecorator('projectNameLong', {
                                                    rules: [{ required: false }],
                                                })(
                                                    <Input
                                                        onBlur={e => this.handleBlurInput(e, 1)}
                                                        onFocus={e => this.handleFocusInput(e)}
                                                        placeholder="Полное название проекта"
                                                    />,
                                                )}
                                            </Form.Item>
                                            <Form.Item className="inputStyle" label="Полный адрес объекта">
                                                {getFieldDecorator('projectAddressLong', {
                                                    rules: [{ required: false }],
                                                })(
                                                    <Input
                                                        onBlur={e => this.handleBlurInput(e, 2)}
                                                        onFocus={e => this.handleFocusInput(e)}
                                                        placeholder="Полный адрес объекта"
                                                    />,
                                                )}
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="halfView">
                                    <div>
                                        <Form.Item className="inputStyle" label="Шаблон номера замечания">
                                            {getFieldDecorator('noteTemplateNumber', {
                                                rules: [{ required: false }],
                                            })(
                                                <Input
                                                    onBlur={e => this.handleBlurInput(e, 3)}
                                                    onFocus={e => this.handleFocusInput(e)}
                                                    placeholder="Шаблон номера замечания"
                                                />,
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div className="mt24">
                                        <h1>ДАТА ОКОНЧАНИЯ</h1>
                                        <DatePicker
                                            defaultValue={moment(
                                                currentProject.data.date_deadline ? currentProject.data.date_deadline : new Date(),
                                                dateFormat,
                                            )}
                                            className="fullWidth"
                                            placeholder="Выберите дату окончания"
                                            format={dateFormat}
                                            onChange={(date, dateString) => this.handleDatePick(date, dateString, 1)}
                                        />
                                        <div className="mt24">
                                            <h1>ОБЪЕКТ</h1>
                                            <Form.Item className="inputStyle" label="Краткое название объекта">
                                                {getFieldDecorator('projectNameShort', {
                                                    rules: [{ required: false }],
                                                })(
                                                    <Input
                                                        onBlur={e => this.handleBlurInput(e, 4)}
                                                        onFocus={e => this.handleFocusInput(e)}
                                                        placeholder="Краткое название объекта"
                                                    />,
                                                )}
                                            </Form.Item>
                                            <Form.Item className="inputStyle" label="Город">
                                                {getFieldDecorator('city', {
                                                    rules: [{ required: false }],
                                                })(
                                                    <Input
                                                        onBlur={e => this.handleBlurInput(e, 5)}
                                                        onFocus={e => this.handleFocusInput(e)}
                                                        placeholder="Город"
                                                    />,
                                                )}
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="halfView mt24">
                                <h1>ВРЕМЯ ОТПРАВКИ СООБЩЕНИЙ</h1>
                                <TimePicker
                                    defaultValue={moment(
                                        currentProject.data.notification_time ? currentProject.data.notification_time : '08:00:00',
                                        timeFormat,
                                    )}
                                    onChange={this.handleTimePick.bind(this)}
                                    format={timeFormat}
                                    placeholder="Время"
                                />
                            </div>
                        </Form>
                    </div>
                ) : (
                    <span>Данные не подгрузились</span>
                )}
            </>
        );
    }
}

const mapStateToProps = state => ({
    currentProject: state.projectModule.projects.currentProject,
});

const mapDispatchToProps = dispatch => ({
    getCurrentProject: projectId => dispatch(getCurrentProject(projectId)),
    updateProject: (projectId, data) => dispatch(updateProject(projectId, data)),
    getPermission: projectId => dispatch(getPermissions(projectId)),
});
const WrappedToForm = Form.create({ name: 'settings_form' })(ProjectSettings);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedToForm);
