import React, { Component } from 'react';
import { DatePicker } from 'antd';
import Bar2 from './Bar2';
import moment from 'moment';

export default class ChartAuthors extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedDate: null,
        };

        this.defaultDate = moment();
    }

    changeDate = (date, dateString) => {
        // get data
        this.setState({
            selectedDate: date,
        });
        this.props.handleGetAnalitics(date.format('YYYY-MM-DD'));
    };

    componentDidMount() {
        this.changeDate(this.defaultDate);
    }

    render() {
        const { selectedDate } = this.state;
        const today = moment(new Date(new Date().setHours(23, 59, 59, 0)));

        return (
            <div>
                <div className="alignCenter mt32">
                    <span style={{ textAlign: 'center' }}>
                        График показывает суммарное количество замечаний, созданных сотрудниками, на выбранную дату
                    </span>
                </div>
                <div className="alignCenter mt32">
                    <DatePicker
                        onChange={this.changeDate}
                        allowClear={false}
                        placeholder="Выберите дату"
                        disabledDate={value => {
                            return value > today;
                        }}
                        format="DD.MM.YYYY"
                        defaultValue={this.defaultDate}
                    />
                </div>

                {selectedDate && this.props.data.success && (
                    <div className="mt32">
                        <Bar2 data={this.props.data} />
                    </div>
                )}
            </div>
        );
    }
}
