import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/ru';
import { Button, Icon, Modal, Dropdown, Menu, DatePicker, TimePicker } from 'antd';

import { setTasksFilters, getEngineers, getWorkSections, getTasksAuthors } from '../../../redux/actions/projectModule';
import { SearchForm } from '../../SearchForm';

import { Loading } from '../../Loading';

const sortTypes = [
    { id: 0, title: 'Сначала новые', sortVal: '-created_at' },
    { id: 1, title: 'Сначала старые', sortVal: 'created_at' },
    { id: 2, title: 'Старые изменения', sortVal: 'updated_at' },
    { id: 3, title: 'Новые изменения', sortVal: '-updated_at' },
];

class TasksListFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openStatusIsActive: true,
            inworkStatusIsActive: true,
            completedStatusIsActive: true,
            openStatus: 'open',
            inworkStatus: 'accepted',
            completedStatus: 'not_accepted',
            filterModalVisible: false,
            shownFilterList: [],
            filterTitles: {
                sinceCreateDate: 'С даты приемки',
                byCreateDate: 'По дату приемки',
                author: 'Автор',
                engineers: 'Инженер',
                workSection: 'Раздел работ',
            },
            selectedEngineers: [],
            selectedAuthor: [],
            selectedWorkSection: null,
            searchValue: '',
            filterParams: '',
            statusParams: '',
            searchParams: '',
            sortParams: '',
            selectedDateFilter: 'Сначала новые',
            dateFilter: {
                begin: '',
                end: '',
                type: '',
                display: '',
            },
            currentListKey: 'filterList',
            listKey: [],
            selectedDate: null,
            selectedTime: null,
            prevStateFilters: null,
        };
    }

    handleSearchFilter = value => {
        this.setState(
            {
                searchParams: value.trim() ? `search=${value}` : '',
            },
            () => this.setParams(true, false),
        );
    };

    handleSearchChange = e => {
        const { value } = e.target;

        this.setState({
            searchValue: value,
        });

        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.handleSearchFilter(value);
        }, 1000);
    };

    handleSearchReset = () => {
        this.setState({
            searchValue: '',
        });

        clearTimeout(this.searchTimer);

        this.handleSearchFilter('');
    };

    sortByDate = filter => {
        this.setState(
            {
                selectedDateFilter: filter.title,
                sortParams: `sort=${filter.sortVal}`,
            },
            () => this.setParams(true, false),
        );
    };

    handleStatusFilter = (isNeedCounts, isNeedBreadcrumbs, limit) => {
        const { openStatusIsActive, inworkStatusIsActive, completedStatusIsActive, openStatus, inworkStatus, completedStatus } = this.state;

        let statuses = [];

        if (openStatusIsActive) {
            statuses.push(openStatus);
        }
        if (inworkStatusIsActive) {
            statuses.push(inworkStatus);
        }
        if (completedStatusIsActive) {
            statuses.push(completedStatus);
        }

        if (!openStatusIsActive && !inworkStatusIsActive && !completedStatusIsActive) {
            this.setState(
                {
                    openStatusIsActive: true,
                    inworkStatusIsActive: true,
                    completedStatusIsActive: true,
                },
                this.handleStatusFilter,
            );

            return;
        }

        this.setState(
            {
                statusParams: statuses.length ? `state=${statuses.join(',')}` : '',
            },
            () => this.setParams(isNeedCounts, isNeedBreadcrumbs, limit),
        );
    };

    handleStatusChange = (e, item) => {
        e.preventDefault();
        const { openStatusIsActive, inworkStatusIsActive, completedStatusIsActive } = this.state;

        switch (item) {
            case 1:
                this.setState(
                    {
                        openStatusIsActive: !openStatusIsActive,
                    },
                    this.handleStatusFilter,
                );
                break;
            case 2:
                this.setState(
                    {
                        inworkStatusIsActive: !inworkStatusIsActive,
                    },
                    this.handleStatusFilter,
                );
                break;
            case 3:
                this.setState(
                    {
                        completedStatusIsActive: !completedStatusIsActive,
                    },
                    this.handleStatusFilter,
                );
                break;
            default:
                break;
        }
    };

    handleFilterOpen = e => {
        this.setState(state => ({
            filterModalVisible: true,
            prevStateFilters: {
                selectedEngineers: state.selectedEngineers,
                selectedAuthor: state.selectedAuthor,
                dateFilter: state.dateFilter,
                selectedWorkSection: state.selectedWorkSection,
            },
        }));
        // this.handleFilterListOpen(e, 'filterList');
    };

    setAuthorFilter = (e, item) => {
        e.preventDefault();
        const { selectedAuthor } = this.state;

        if (selectedAuthor.some(i => i.id === item.id)) {
            this.setState(prevState => ({
                selectedAuthor: prevState.selectedAuthor.filter(n => n.id !== item.id),
            }));
        } else {
            this.setState(prevState => ({
                selectedAuthor: [...prevState.selectedAuthor, item],
            }));
        }
    };

    setEngineersFilter = (e, item) => {
        e.preventDefault();
        const { selectedEngineers } = this.state;

        if (selectedEngineers.some(i => i.id === item.id)) {
            this.setState(prevState => ({
                selectedEngineers: prevState.selectedEngineers.filter(n => n.id !== item.id),
            }));
        } else {
            this.setState(prevState => ({
                selectedEngineers: [...prevState.selectedEngineers, item],
            }));
        }
    };

    setWorkSectionFilter = (e, item) => {
        e.preventDefault();

        const { selectedWorkSection } = this.state;

        if (selectedWorkSection) {
            if (selectedWorkSection.id === item.id) {
                this.setState({ selectedWorkSection: null });
                return;
            }
        }

        this.setState({ selectedWorkSection: item });
    };

    getFilterList = () => {
        const { filterTitles, dateFilter, selectedAuthor, selectedEngineers, selectedWorkSection } = this.state;

        let filterList = [];

        // Время
        if (dateFilter.type) {
            switch (dateFilter.type) {
                case 'singleDate':
                    if (dateFilter.begin) {
                        filterList.push({
                            type: filterTitles.sinceCreateDate,
                            name: dateFilter.begin,
                            filterType: 'createAtBegin',
                        });
                    }
                    if (dateFilter.end) {
                        filterList.push({
                            type: filterTitles.byCreateDate,
                            name: dateFilter.end,
                            filterType: 'createAtEnd',
                        });
                    }
                    break;

                default:
                    filterList.push({
                        type: filterTitles[dateFilter.type],
                        name: dateFilter.display,
                        filterType: 'dateFilter',
                    });
                    break;
            }
        }

        // Автор
        if (selectedAuthor.length) {
            filterList.push({
                type: filterTitles.author,
                name: selectedAuthor.map(item => item.title),
                filterType: 'selectedAuthor',
            });
        }

        // Исполнитель
        if (selectedEngineers.length) {
            filterList.push({
                type: filterTitles.engineers,
                name: selectedEngineers.map(item => item.title),
                filterType: 'selectedEngineers',
            });
        }

        // Статус работ
        if (selectedWorkSection) {
            filterList.push({
                type: filterTitles.workSection,
                name: selectedWorkSection.title,
                filterType: 'selectedWorkSection',
            });
        }

        // console.log('filterList', filterList);
        return filterList;
    };

    getFilterParams = () => {
        const { selectedEngineers, selectedAuthor, dateFilter, selectedWorkSection } = this.state;
        let params = [];

        // Время
        if (dateFilter.type) {
            if (dateFilter.begin) {
                params.push(`start_review_date=${dateFilter.begin}`);
            }
            if (dateFilter.end) {
                params.push(`end_review_date=${dateFilter.end}`);
            }
        }

        // Автор
        if (selectedAuthor.length) {
            let authors = selectedAuthor.map(item => item.id).join(',');
            params.push(`user_id=${authors}`);
        }

        // Инженер
        if (selectedEngineers.length) {
            let engineerssId = selectedEngineers.map(item => item.id).join(',');
            params.push(`engineer_id=${engineerssId}`);
        }

        // Раздел работ
        if (selectedWorkSection) {
            params.push(`work_section_id=${selectedWorkSection.id}`);
        }

        return params.join('&');
    };

    handleFilterApply = (isNeedCounts, isNeedBreadcrumbs, limit, scrollTop) => {
        let filterParams = this.getFilterParams();
        let filterList = this.getFilterList();

        this.setState(
            {
                filterParams: filterParams,
                shownFilterList: filterList,
                filterModalVisible: false,
            },
            () => this.setParams(isNeedCounts, isNeedBreadcrumbs, limit, scrollTop),
        );
    };

    setParams = (isNeedCounts, isNeedBreadcrumbs, limit, scrollTop) => {
        const { filterParams, statusParams, searchParams, sortParams } = this.state;
        let params = '';

        if (filterParams != '') {
            params += `&${filterParams}`;
        }
        if (statusParams != '') {
            params += `&${statusParams}`;
        }
        if (searchParams != '') {
            params += `&${searchParams}`;
        }
        if (sortParams != '') {
            params += `&${sortParams}`;
        }

        this.props.setParams(params, isNeedCounts, isNeedBreadcrumbs, limit, scrollTop);
    };

    handleFilterClose = () => {
        this.setState({
            ...this.state.prevStateFilters,
            filterModalVisible: false,
            currentListKey: 'filterList',
            listKey: [],
        });
    };

    handleFilterListOpen = (e, item) => {
        e.preventDefault();
        const { getEngineers, getAuthors, getOrganizations, getWorkSections, match } = this.props;
        const { projectId = 0 } = match.params;

        switch (item) {
            case 'organiztionFilterList':
                getOrganizations();
                break;
            case 'engineersFilter':
                getEngineers(projectId);
                break;
            case 'authorFilter':
                getAuthors(projectId);
                break;
            case 'workSectionFilter':
                getWorkSections();
                break;
            default:
                break;
        }

        this.setState(state => {
            return {
                currentListKey: item,
                listKey: [...state.listKey, item],
                selectedDate: null,
                selectedTime: null,
            };
        });
    };

    filterListBack = e => {
        if (e) {
            e.preventDefault();
        }

        let copyListKey = [...this.state.listKey];
        copyListKey.pop();

        this.setState({
            currentListKey: copyListKey.length ? copyListKey[copyListKey.length - 1] : 'filterList',
            listKey: copyListKey,
        });

        return;
    };

    setDate = (value, dateString) => {
        this.setState({
            selectedDate: dateString,
        });
    };
    setTime = (time, timeString) => {
        this.setState({
            selectedTime: timeString,
        });
    };

    filterChoose = listKey => {
        let { selectedDate, selectedTime, dateFilter } = this.state;
        switch (listKey) {
            case 'createAtBegin':
                if (!selectedDate) {
                    if (dateFilter.type === 'singleDate' && dateFilter.begin) {
                        let parseDate = dateFilter.begin.split(' ');
                        selectedDate = parseDate[0];
                    } else {
                        selectedDate = moment(new Date(), 'DD-MM-YYYY').format('DD-MM-YYYY');
                    }
                }
                if (!selectedTime) {
                    if (dateFilter.type === 'singleDate' && dateFilter.begin) {
                        let parseDate = dateFilter.begin.split(' ');
                        selectedTime = parseDate[1];
                    } else {
                        selectedTime = moment('12:00:00', 'HH:mm:ss').format('HH:mm:ss');
                    }
                }
                this.setDateFilter(`${selectedDate} ${selectedTime}`, null, 'singleDate', null);
                break;
            case 'createAtEnd':
                if (!selectedDate) {
                    if (dateFilter.type === 'singleDate' && dateFilter.end) {
                        let parseDate = dateFilter.end.split(' ');
                        selectedDate = parseDate[0];
                    } else {
                        selectedDate = moment(new Date(), 'DD-MM-YYYY').format('DD-MM-YYYY');
                    }
                }
                if (!selectedTime) {
                    if (dateFilter.type === 'singleDate' && dateFilter.end) {
                        let parseDate = dateFilter.end.split(' ');
                        selectedTime = parseDate[1];
                    } else {
                        selectedTime = moment('12:00:00', 'HH:mm:ss').format('HH:mm:ss');
                    }
                }

                this.setDateFilter(null, `${selectedDate} ${selectedTime}`, 'singleDate');
                break;
            case 'day':
                if (!selectedDate) {
                    if (dateFilter.type === 'day' && dateFilter.begin) {
                        let parseDate = dateFilter.begin.split(' ');
                        selectedDate = parseDate[0];
                    } else {
                        selectedDate = moment(new Date(), 'DD-MM-YYYY').format('DD-MM-YYYY');
                    }
                }

                this.setDateFilter(`${selectedDate} 00:00:00`, `${selectedDate} 23:59:59`, 'day', selectedDate);
                break;
            default:
                break;
        }

        this.filterListBack();
    };

    setDateFilter = (dateAtBegin, dateAtEnd, dateType, display) => {
        const { dateFilter } = this.state;
        if (dateType !== 'singleDate') {
            this.setState({
                dateFilter: {
                    begin: dateAtBegin,
                    end: dateAtEnd,
                    type: dateType,
                    display: display,
                },
            });
        } else if (dateType === 'singleDate') {
            if (dateFilter.type === 'singleDate') {
                this.setState({
                    dateFilter: {
                        begin: dateAtBegin ? dateAtBegin : dateFilter.begin,
                        end: dateAtEnd ? dateAtEnd : dateFilter.end,
                        type: dateType,
                    },
                });
            } else {
                this.setState({
                    dateFilter: {
                        begin: dateAtBegin,
                        end: dateAtEnd,
                        type: dateType,
                    },
                });
            }
        }
    };

    handleRemoveFilter = filter => {
        const { dateFilter } = this.state;
        let changeState = {};

        switch (filter.filterType) {
            case 'createAtBegin':
                dateFilter.end
                    ? (changeState.dateFilter = { ...dateFilter, begin: '' })
                    : (changeState.dateFilter = { begin: '', end: '', type: '', display: '' });
                break;
            case 'createAtEnd':
                dateFilter.begin
                    ? (changeState.dateFilter = { ...dateFilter, end: '' })
                    : (changeState.dateFilter = { begin: '', end: '', type: '', display: '' });
                break;
            case 'selectedAuthor':
                changeState.selectedAuthor = [];
                break;
            case 'selectedEngineers':
                changeState.selectedEngineers = [];
                break;
            case 'selectedWorkSection':
                changeState.selectedWorkSection = null;
                break;
            default:
                break;
        }

        this.setState(changeState, () => this.handleFilterApply(true, false));
    };

    renderFilterLabels = (filter, i) => {
        return (
            <div className="filter-label" key={i}>
                <div className="filter-info">
                    <div className="filter__type">{filter.type}</div>
                    <div className="filter__name">
                        {Array.isArray(filter.name)
                            ? filter.name.map((n, i) => (i === filter.name.length - 1 ? `${n}` : `${n} / `))
                            : filter.name}
                    </div>
                </div>
                <Icon type="close" className="filter__delete" onClick={() => this.handleRemoveFilter(filter)} />
            </div>
        );
    };

    renderEngineers = (selectedEngineers, user, engineerss) => {
        return (
            <ul className="modal-list modal-filter__content">
                <li>
                    <a
                        href=""
                        className={selectedEngineers.some(i => i.id === 0) ? 'modal__item checked' : 'modal__item'}
                        onClick={e => this.setEngineersFilter(e, { title: 'Нет участника', id: 0 })}>
                        <Icon type="user" />
                        <span>Нет участника</span>
                        <Icon type="check" className="icon-check" />
                    </a>
                </li>
                <li>
                    <a
                        href=""
                        className={selectedEngineers.some(i => i.id === user.id) ? 'modal__item checked' : 'modal__item'}
                        onClick={e => this.setEngineersFilter(e, { title: 'Я', id: user.id })}>
                        <Icon type="user" />
                        <div>
                            <span>Я</span>
                            <br />
                            <span className="secondary-info">{user.organization}</span>
                        </div>
                        <Icon type="check" className="icon-check" />
                    </a>
                </li>
                {(engineerss.data.filter(i => i.id !== user.id) || []).map(item => (
                    <li key={item.id}>
                        <a
                            href=""
                            className={
                                selectedEngineers && selectedEngineers.some(i => i.id === item.id) ? 'modal__item checked' : 'modal__item'
                            }
                            onClick={e => this.setEngineersFilter(e, { title: item.fullname, id: item.id })}>
                            <Icon type="user" />
                            <div>
                                <span>{item.fullname}</span>
                                <br />
                                <span className="secondary-info">{item.organization}</span>
                            </div>
                            <Icon type="check" className="icon-check" />
                        </a>
                    </li>
                ))}
            </ul>
        );
    };

    renderSortTypes = () => {
        return (
            <Menu>
                {sortTypes.map(item => (
                    <Menu.Item key={item.id}>
                        <span onClick={() => this.sortByDate(item)}>{item.title}</span>
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    renderCreatedAtBegin = () => {
        const { dateFilter } = this.state;
        let defaultDate = moment(new Date(), 'DD-MM-YYYY'),
            defaultTime = moment('12:00:00', 'HH:mm:ss');

        if (dateFilter.type === 'singleDate') {
            if (dateFilter.begin) {
                let parseDate = dateFilter.begin.split(' ');
                defaultDate = moment(parseDate[0], 'DD-MM-YYYY');
                defaultTime = moment(parseDate[1], 'HH:mm:ss');
            }
        }

        return (
            <div className="calendar-picker">
                <DatePicker
                    placeholder="Выберите дату"
                    onChange={this.setDate}
                    onOk={value => console.log(value)}
                    className="calendar-picker__field-calendar"
                    size="large"
                    defaultValue={defaultDate}
                    format="DD-MM-YYYY"
                    showToday={false}
                    allowClear={false}
                />
                <TimePicker
                    defaultValue={defaultTime}
                    onChange={this.setTime}
                    className="calendar-picker__field-time"
                    size="large"
                    allowClear={false}
                />
            </div>
        );
    };

    renderCreatedAtEnd = () => {
        const { dateFilter } = this.state;
        let defaultDate = moment(new Date(), 'DD-MM-YYYY'),
            defaultTime = moment('12:00:00', 'HH:mm:ss');

        if (dateFilter.type === 'singleDate') {
            if (dateFilter.end) {
                let parseDate = dateFilter.end.split(' ');
                defaultDate = moment(parseDate[0], 'DD-MM-YYYY');
                defaultTime = moment(parseDate[1], 'HH:mm:ss');
            }
        }

        return (
            <div className="calendar-picker">
                <DatePicker
                    placeholder="Выберите дату"
                    onChange={this.setDate}
                    onOk={value => console.log(value)}
                    className="calendar-picker__field-calendar"
                    size="large"
                    defaultValue={defaultDate}
                    format="DD-MM-YYYY"
                    showToday={false}
                    allowClear={false}
                />
                <TimePicker
                    defaultValue={defaultTime}
                    onChange={this.setTime}
                    className="calendar-picker__field-time"
                    size="large"
                    allowClear={false}
                />
            </div>
        );
    };

    renderWorkSectionFilter = () => {
        const { selectedWorkSection } = this.state;
        const { workSections } = this.props;

        if (workSections.loading) {
            return (
                <div className="modal__loading-wrap">
                    <Loading />
                </div>
            );
        }

        if (workSections.success) {
            return (
                <ul className="modal-list modal-filter__content">
                    {workSections.data.sections.map(item => (
                        <li key={item.id}>
                            <a
                                href=""
                                className={
                                    selectedWorkSection && item.id === selectedWorkSection.id ? 'modal__item checked' : 'modal__item'
                                }
                                onClick={e => this.setWorkSectionFilter(e, item)}>
                                <div>
                                    <span>{item.title}</span>
                                </div>
                                <Icon type="check" className="icon-check" />
                            </a>
                        </li>
                    ))}
                </ul>
            );
        }
    };

    renderAuthorFilter = (user, selectedAuthor) => {
        // console.log('TasksListFilters -> renderAuthorFilter -> user', user);
        const { authors } = this.props;
        // console.log('TasksListFilters -> renderAuthorFilter -> authors', authors);
        // const { selectedOrganization } = this.state;
        // console.log('TasksListFilters -> renderAuthorFilter -> selectedOrganization', selectedOrganization);
        // return;
        if (authors.loading) {
            return (
                <div className="modal__loading-wrap">
                    <Loading />
                </div>
            );
        }

        // const showMe = !selectedOrganization || (selectedOrganization && selectedOrganization.title === user.organization);

        return (
            <ul className="modal-list modal-filter__content">
                {authors.data.map(item => (
                    <li key={item.id}>
                        <a
                            href=""
                            className={selectedAuthor.some(i => i.id === item.id) ? 'modal__item checked' : 'modal__item'}
                            onClick={e => this.setAuthorFilter(e, { title: item.fullname, id: item.id })}>
                            <Icon type="user" />
                            <div>
                                <span>{item.fullname}</span>
                                <br />
                                <span className="secondary-info">{item.organization}</span>
                            </div>
                            <Icon type="check" className="icon-check" />
                        </a>
                    </li>
                ))}
            </ul>
        );
    };

    componentDidMount() {
        const { history, tasksFilters } = this.props;

        let newState = {},
            limit = null,
            scrollTop = null;

        if (tasksFilters) {
            newState = { ...tasksFilters.filterState };
            newState.openStatusIsActive = tasksFilters.statuses.openStatusIsActive;
            newState.inworkStatusIsActive = tasksFilters.statuses.inworkStatusIsActive;
            newState.completedStatusIsActive = tasksFilters.statuses.completedStatusIsActive;
            if (history.action === 'POP') {
                limit = tasksFilters.limit;

                if (this.props.match.params.currentStructureId === tasksFilters.currentStructureId) {
                    scrollTop = tasksFilters.scrollTop;
                }
            }
        }

        // console.log(newState);

        this.setState(newState, () => {
            this.handleStatusFilter(true, true);
            this.handleFilterApply(true, true, limit, scrollTop);
        });
    }

    componentWillUnmount() {
        const {
            openStatusIsActive,
            inworkStatusIsActive,
            completedStatusIsActive,
            selectedEngineers,
            selectedAuthor,
            selectedWorkSection,
            dateFilter,
            searchValue,
        } = this.state;
        const { setTasksFilters, limit } = this.props;

        setTasksFilters({
            projectId: this.props.match.params.projectId ? this.props.match.params.projectId : null,
            currentStructureId: this.props.match.params.currentStructureId ? this.props.match.params.currentStructureId : null,
            filterState: {
                selectedEngineers,
                selectedAuthor,
                dateFilter,
                selectedWorkSection,
            },
            statuses: {
                openStatusIsActive,
                inworkStatusIsActive,
                completedStatusIsActive,
            },
            limit: searchValue ? null : limit,
            scrollTop: window.pageYOffset,
        });
    }

    render() {
        const { user, engineers, data, success } = this.props;

        const {
            openStatusIsActive,
            inworkStatusIsActive,
            completedStatusIsActive,
            filterModalVisible,
            shownFilterList,
            filterTitles,
            selectedEngineers,
            selectedAuthor,
            searchValue,
            selectedDateFilter,
            selectedWorkSection,
            currentListKey,
            dateFilter,
        } = this.state;

        if (!data || !success) return null;

        return (
            <>
                <div className="status-filter">
                    <a
                        href=""
                        className="status-filter__item"
                        style={{ color: openStatusIsActive && 'rgba(0, 0, 0, .65)' }}
                        onClick={e => this.handleStatusChange(e, 1)}>
                        <span className="status-filter__title">Открытые</span>
                        <span
                            className="status-filter__count"
                            style={{
                                borderColor: openStatusIsActive && '#00ADEF',
                            }}>
                            {data.taskCount.open_tasks}
                        </span>
                    </a>
                    <a
                        href=""
                        className="status-filter__item"
                        style={{ color: inworkStatusIsActive && 'rgba(0, 0, 0, .65)' }}
                        onClick={e => this.handleStatusChange(e, 2)}>
                        <span className="status-filter__title">Принятые</span>
                        <span
                            className="status-filter__count"
                            style={{
                                borderColor: inworkStatusIsActive && '#27AE60',
                            }}>
                            {data.taskCount.accepted_tasks}
                        </span>
                    </a>
                    <a
                        href=""
                        className="status-filter__item"
                        style={{ color: completedStatusIsActive && 'rgba(0, 0, 0, .65)' }}
                        onClick={e => this.handleStatusChange(e, 3)}>
                        <span className="status-filter__title">Не принятые</span>
                        <span
                            className="status-filter__count"
                            style={{
                                borderColor: completedStatusIsActive && '#ED2D23',
                            }}>
                            {data.taskCount.not_accepted_tasks}
                        </span>
                    </a>
                </div>
                <div className="filter-row">
                    <div className="filter-button">
                        <span className="filter__icon" onClick={this.handleFilterOpen}>
                            <Icon type="filter" />
                        </span>
                        {!shownFilterList.length > 0 ? (
                            <span className="filter__title" onClick={this.handleFilterOpen}>
                                Добавить фильтр
                            </span>
                        ) : (
                            <div className="filter-list">{shownFilterList.map((filter, i) => this.renderFilterLabels(filter, i))}</div>
                        )}
                    </div>
                </div>

                <div className="filter-row">
                    <SearchForm
                        searchValue={searchValue}
                        handleSearchFilter={this.handleSearchFilter}
                        handleSearchChange={this.handleSearchChange}
                        handleSearchReset={this.handleSearchReset}
                    />
                </div>

                <div className="date-filter">
                    <Dropdown overlay={this.renderSortTypes} placement="bottomRight" trigger={['click']}>
                        <Button>
                            {selectedDateFilter}
                            <Icon type="filter" />
                        </Button>
                    </Dropdown>
                </div>

                <Modal
                    title={
                        <>
                            {currentListKey !== 'filterList' && (
                                <a href="" className="modal__back" onClick={this.filterListBack}>
                                    <Icon type="left" />
                                </a>
                            )}
                            <span>Выберите тип фильтра</span>
                        </>
                    }
                    visible={filterModalVisible}
                    centered
                    footer={
                        currentListKey === 'filterList' ? (
                            <Button type="primary" onClick={() => this.handleFilterApply(true, false)}>
                                Применить
                            </Button>
                        ) : (
                            <Button onClick={() => this.filterChoose(currentListKey)}>Выбрать</Button>
                        )
                    }
                    onCancel={this.handleFilterClose}>
                    {currentListKey === 'filterList' && (
                        <ul className="modal-list modal-filter__content">
                            <li>
                                <a
                                    href=""
                                    className={dateFilter.type ? 'modal__item checked' : 'modal__item'}
                                    // onClick={e => this.handleFilterListOpen(e, 1)}>
                                    onClick={e => this.handleFilterListOpen(e, 'dateFilterList')}>
                                    <Icon type="clock-circle" className="filter-check__icon" />
                                    <span>Время</span>
                                    <Icon type="right" />
                                </a>
                            </li>
                            <li>
                                <a
                                    href=""
                                    className={selectedAuthor.length ? 'modal__item checked' : 'modal__item'}
                                    onClick={e => this.handleFilterListOpen(e, 'authorFilter')}>
                                    <Icon type="edit" className="filter-check__icon" />
                                    <span>Автор</span>
                                    <Icon type="right" />
                                </a>
                            </li>
                            <li>
                                <a
                                    href=""
                                    className={selectedEngineers.length ? 'modal__item checked' : 'modal__item'}
                                    onClick={e => this.handleFilterListOpen(e, 'engineersFilter')}>
                                    <Icon type="tool" className="filter-check__icon" />
                                    <span>Инженер</span>
                                    <Icon type="right" />
                                </a>
                            </li>
                            <li>
                                <a
                                    href=""
                                    className={selectedWorkSection ? 'modal__item checked' : 'modal__item'}
                                    onClick={e => this.handleFilterListOpen(e, 'workSectionFilter')}>
                                    <Icon type="folder" className="filter-check__icon" />
                                    <span>Раздел работ</span>
                                    <Icon type="right" />
                                </a>
                            </li>
                        </ul>
                    )}

                    {currentListKey === 'dateFilterList' && (
                        <ul className="modal-list modal-filter__content">
                            <li>
                                <a
                                    href=""
                                    className={dateFilter.type === 'singleDate' && dateFilter.begin ? 'modal__item checked' : 'modal__item'}
                                    onClick={e => this.handleFilterListOpen(e, 'createAtBegin')}>
                                    <Icon type="check" />
                                    <span>{filterTitles.sinceCreateDate}</span>
                                    <Icon type="right" />
                                </a>
                            </li>
                            <li>
                                <a
                                    href=""
                                    className={dateFilter.type === 'singleDate' && dateFilter.end ? 'modal__item checked' : 'modal__item'}
                                    onClick={e => this.handleFilterListOpen(e, 'createAtEnd')}>
                                    <Icon type="check" />
                                    <span>{filterTitles.byCreateDate}</span>
                                    <Icon type="right" />
                                </a>
                            </li>
                        </ul>
                    )}

                    {currentListKey === 'createAtBegin' && this.renderCreatedAtBegin()}
                    {currentListKey === 'createAtEnd' && this.renderCreatedAtEnd()}

                    {currentListKey === 'authorFilter' && this.renderAuthorFilter(user, selectedAuthor)}

                    {currentListKey === 'engineersFilter' && this.renderEngineers(selectedEngineers, user, engineers)}

                    {currentListKey === 'workSectionFilter' && this.renderWorkSectionFilter()}
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    tasksList: state.projectModule.tasks.tasksList,
    user: state.user.user,
    authors: state.projectModule.tasks.authors,
    engineers: state.projectModule.tasks.engineers,
    workSections: state.projectModule.tasks.workSections,
    tasksFilters: state.projectModule.tasks.tasksFilters,
});

const mapDispatchToProps = dispatch => ({
    getEngineers: projectId => dispatch(getEngineers(projectId)),
    getWorkSections: () => dispatch(getWorkSections()),
    getAuthors: projectId => dispatch(getTasksAuthors(projectId)),
    setTasksFilters: data => dispatch(setTasksFilters(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksListFilters);
