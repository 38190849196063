import { Button, Dropdown, Icon, Menu } from 'antd';
import moment from 'moment';
import 'moment/locale/ru';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    clearAuditors,
    clearOrganizations,
    getAuthors,
    getCounterpartyList,
    getDictionary,
    getExecutors,
    getFilteredAuthors,
    getFiltredAuditors,
    getFiltredOrganizations,
    getOrganizations,
    getQueryFiltredExecutors,
    setNotesFilters,
} from '../../../../redux/actions/projectModule';
import { SearchForm } from '../../../SearchForm';

import '../../ProjectNotes.scss';
import FiltersModal from './FiltersModal';
import StatusFilters from './StatusFilters';
import { notesListFiltersDefaultState, sortTypes } from './notesConsts';

class NotesListFilters extends Component {
    constructor(props) {
        super(props);

        this.state = notesListFiltersDefaultState;
    }

    handleSearchFilter = value => {
        this.setState({ searchParams: value.trim() ? `search=${value}` : '' }, () => this.setParams(true, false));
    };

    handleSearchChange = e => {
        const { value } = e.target;

        this.setState({ searchValue: value });

        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.handleSearchFilter(value);
        }, 1000);
    };

    handleSearchReset = () => {
        this.setState({
            searchValue: '',
        });

        clearTimeout(this.searchTimer);

        this.handleSearchFilter('');
    };

    sortByDate = filter => {
        this.setState(
            {
                selectedDateFilter: filter.title,
                sortParams: `sort=${filter.sortVal}`,
            },
            () => this.setParams(true, false),
        );
    };

    handleStatusFilter = (isNeedCounts, isNeedBreadcrumbs, limit) => {
        const { openStatusIsActive, inworkStatusIsActive, completedStatusIsActive, openStatus, inworkStatus, completedStatus } = this.state;

        let statuses = [];

        if (openStatusIsActive) {
            statuses.push(openStatus);
        }
        if (inworkStatusIsActive) {
            statuses.push(inworkStatus);
        }
        if (completedStatusIsActive) {
            statuses.push(completedStatus);
        }

        if (!openStatusIsActive && !inworkStatusIsActive && !completedStatusIsActive) {
            this.setState(
                {
                    openStatusIsActive: true,
                    inworkStatusIsActive: true,
                    completedStatusIsActive: true,
                },
                this.handleStatusFilter,
            );

            return;
        }

        this.setState(
            {
                statusParams: statuses.length ? `state=${statuses.join(',')}` : '',
            },
            () => this.setParams(isNeedCounts, isNeedBreadcrumbs, limit),
        );
    };

    handleStatusChange = (e, item) => {
        e.preventDefault();
        const { openStatusIsActive, inworkStatusIsActive, completedStatusIsActive } = this.state;

        switch (item) {
            case 1:
                this.setState(
                    {
                        openStatusIsActive: !openStatusIsActive,
                    },
                    this.handleStatusFilter,
                );
                break;
            case 2:
                this.setState(
                    {
                        inworkStatusIsActive: !inworkStatusIsActive,
                    },
                    this.handleStatusFilter,
                );
                break;
            case 3:
                this.setState(
                    {
                        completedStatusIsActive: !completedStatusIsActive,
                    },
                    this.handleStatusFilter,
                );
                break;
            default:
                break;
        }
    };

    handleFilterOpen = () => {
        this.setState(state => ({
            filterModalVisible: true,
            prevStateFilters: {
                selectedOrganization: state.selectedOrganization,
                selectedExecutor: state.selectedExecutor,
                selectedAuditor: state.selectedAuditor,
                selectedDictionaries: state.selectedDictionaries,
                selectedIsReopen: state.selectedIsReopen,
                selectedAuthor: state.selectedAuthor,
                dateFilter: state.dateFilter,
                pastDateFilter: state.pastDateFilter,
                selectedRetention: state.selectedRetention,
                selectedCounterparty: state.selectedCounterparty,
                selectedExecutorType: state.selectedExecutorType,
                docFilter: state.docFilter,
            },
        }));
        // this.handleFilterListOpen(e, 'filterList');
    };

    setAuthorFilter = (e, item) => {
        e.preventDefault();
        const { selectedAuthor } = this.state;

        if (selectedAuthor.some(i => i.id === item.id)) {
            this.setState(prevState => ({
                selectedAuthor: prevState.selectedAuthor.filter(n => n.id !== item.id),
            }));
        } else {
            this.setState(prevState => ({
                selectedAuthor: [...prevState.selectedAuthor, item],
            }));
        }
    };

    setExecutorFilter = (e, item) => {
        e.preventDefault();
        const { selectedExecutor } = this.state;

        if (selectedExecutor.some(i => i.id === item.id)) {
            this.setState(prevState => ({
                selectedExecutor: prevState.selectedExecutor.filter(n => n.id !== item.id),
            }));
        } else {
            this.setState(prevState => ({
                selectedExecutor: [...prevState.selectedExecutor, item],
            }));
        }
    };

    setAuditorFilter = (e, item) => {
        e.preventDefault();
        const { selectedAuditor } = this.state;

        if (selectedAuditor) {
            if (selectedAuditor.id === item.id) {
                this.setState(() => ({
                    selectedAuditor: null,
                }));
            } else {
                this.setState(() => ({
                    selectedAuditor: item,
                }));
            }
        } else {
            this.setState(() => ({
                selectedAuditor: item,
            }));
        }
    };

    setDictionaryFilter = (e, item) => {
        e.preventDefault();
        // if (item.type === 'folder') {
        //     this.props.getDictionary(item.id);
        //     return;
        // }
        const { selectedDictionaries } = this.state;

        if (selectedDictionaries.some(i => i.id === item.id)) {
            this.setState(prevState => ({
                selectedDictionaries: prevState.selectedDictionaries.filter(n => n.id !== item.id),
            }));
        } else {
            this.setState(prevState => ({
                selectedDictionaries: [...prevState.selectedDictionaries, item],
            }));
        }
    };

    setOrganizationFilter = (e, item) => {
        e.preventDefault();
        const { selectedOrganization } = this.state;

        if (selectedOrganization) {
            if (selectedOrganization.id === item.id) {
                this.setState(() => ({
                    selectedOrganization: null,
                }));
            } else {
                this.setState(() => ({
                    selectedOrganization: item,
                }));
            }
        } else {
            this.setState(() => ({
                selectedOrganization: item,
            }));
        }
    };

    setCounterpartyFilter = (e, item) => {
        e.preventDefault();
        const { selectedCounterparty } = this.state;

        if (selectedCounterparty) {
            if (selectedCounterparty.id === item.id) {
                this.setState(() => ({
                    selectedCounterparty: null,
                }));
            } else {
                this.setState(() => ({
                    selectedCounterparty: item,
                }));
            }
        } else {
            this.setState(() => ({
                selectedCounterparty: item,
            }));
        }
    };

    setRetentionFilter = (e, item) => {
        e.preventDefault();

        const { selectedRetention } = this.state;

        if (selectedRetention) {
            if (selectedRetention.id === item.id) {
                this.setState({ selectedRetention: null });
                return;
            }
        }

        this.setState({ selectedRetention: item });
        return;
    };

    setExecutorTypeFilter = (e, item) => {
        e.preventDefault();

        const { selectedExecutorType } = this.state;

        if (selectedExecutorType) {
            if (selectedExecutorType.id === item.id) {
                this.setState({ selectedExecutorType: null });
                return;
            }
        }

        this.setState({ selectedExecutorType: item });
        return;
    };

    setIsReopenFilter = e => {
        e.preventDefault();

        this.setState(state => {
            return { selectedIsReopen: !state.selectedIsReopen };
        });
    };

    getFilterList = () => {
        const {
            filterTitles,
            dateFilter,
            pastDateFilter,
            selectedOrganization,
            selectedAuthor,
            selectedExecutor,
            selectedAuditor,
            selectedDictionaries,
            selectedRetention,
            selectedExecutorType,
            selectedIsReopen,
            selectedCounterparty,
            docFilter,
        } = this.state;

        let filterList = [];

        // Время
        if (dateFilter.type) {
            switch (dateFilter.type) {
                case 'singleDate':
                    if (dateFilter.begin) {
                        filterList.push({
                            type: filterTitles.sinceCreateDate,
                            name: dateFilter.begin,
                            filterType: 'createAtBegin',
                        });
                    }
                    if (dateFilter.end) {
                        filterList.push({
                            type: filterTitles.byCreateDate,
                            name: dateFilter.end,
                            filterType: 'createAtEnd',
                        });
                    }
                    if (dateFilter.startDateExecution) {
                        filterList.push({
                            type: filterTitles.startDateExecution,
                            name: dateFilter.startDateExecution,
                            filterType: 'startDateExecution',
                        });
                    }
                    if (dateFilter.endDateExecution) {
                        filterList.push({
                            type: filterTitles.endDateExecution,
                            filterType: 'endDateExecution',
                            name: dateFilter.endDateExecution,
                        });
                    }
                    if (dateFilter.startCheckDate) {
                        filterList.push({
                            type: filterTitles.startCheckDate,
                            name: dateFilter.startCheckDate,
                            filterType: 'startCheckDate',
                        });
                    }
                    if (dateFilter.endCheckDate) {
                        filterList.push({
                            type: filterTitles.endCheckDate,
                            filterType: 'endCheckDate',
                            name: dateFilter.endCheckDate,
                        });
                    }
                    if (dateFilter.startCompleteDate) {
                        filterList.push({
                            type: filterTitles.startCompleteDate,
                            name: dateFilter.startCompleteDate,
                            filterType: 'startCompleteDate',
                        });
                    }
                    if (dateFilter.endCompleteDate) {
                        filterList.push({
                            type: filterTitles.endCompleteDate,
                            name: dateFilter.endCompleteDate,
                            filterType: 'endCompleteDate',
                        });
                    }
                    break;

                default:
                    filterList.push({
                        type: filterTitles[dateFilter.type],
                        name: dateFilter.display,
                        filterType: 'dateFilter',
                    });
                    break;
            }
        }

        // Просроченные на дату
        if (pastDateFilter.type) {
            filterList.push({
                type: filterTitles.onDate,
                name: pastDateFilter.begin,
                filterType: 'pastDateFilter',
            });
        }

        // Организация
        if (selectedOrganization) {
            filterList.push({
                type: filterTitles.authorOrganization,
                name: selectedOrganization.organization,
                filterType: 'selectedOrganization',
            });
        }

        // Автор
        if (selectedAuthor.length) {
            filterList.push({
                type: filterTitles.author,
                name: selectedAuthor.map(item => item.title),
                filterType: 'selectedAuthor',
            });
        }

        // Исполнитель
        if (selectedExecutor.length) {
            filterList.push({
                type: filterTitles.executor,
                name: selectedExecutor.map(item => item.title),
                filterType: 'selectedExecutor',
            });
        }

        // Требует внимание
        if (selectedAuditor) {
            filterList.push({
                type: filterTitles.auditor,
                name: selectedAuditor.title,
                filterType: 'selectedAuditor',
            });
        }

        // Контрагент
        if (selectedCounterparty) {
            filterList.push({
                type: filterTitles.counterparty,
                name: selectedCounterparty.title,
                filterType: 'selectedCounterparty',
            });
        }

        // Категории
        if (selectedDictionaries.length) {
            filterList.push({
                type: filterTitles.dictionary,
                name: selectedDictionaries.map(item => item.title),
                filterType: 'selectedDictionaries',
            });
        }

        // Повторно открытые
        if (selectedIsReopen) {
            filterList.push({
                type: filterTitles.isReopen,
                name: '',
                filterType: 'selectedIsReopen',
            });
        }

        // Статус работ
        if (selectedRetention) {
            filterList.push({
                type: filterTitles.retention,
                name: selectedRetention.title,
                filterType: 'selectedRetention',
            });
        }

        // Тип исполнителя
        if (selectedExecutorType) {
            filterList.push({
                type: filterTitles.executorType,
                name: selectedExecutorType.title,
                filterType: 'selectedExecutorType',
            });
        }

        // Документ
        if (docFilter) {
            filterList.push({
                type: filterTitles.document,
                name: docFilter.name,
                filterType: 'docFilter',
            });
        }

        // console.log('filterList', filterList);
        return filterList;
    };

    getFilterParams = () => {
        const {
            selectedOrganization,
            selectedExecutor,
            selectedDictionaries,
            selectedIsReopen,
            selectedAuthor,
            dateFilter,
            selectedRetention,
            selectedExecutorType,
            selectedAuditor,
            pastDateFilter,
            docFilter,
            selectedCounterparty,
        } = this.state;
        let params = [];

        // Время
        if (dateFilter.type) {
            if (dateFilter.begin) {
                params.push(`created_at_begin=${dateFilter.begin}`);
            }
            if (dateFilter.end) {
                params.push(`created_at_end=${dateFilter.end}`);
            }
            if (dateFilter.startDateExecution) {
                params.push(`inwork_changed_at_begin=${dateFilter.startDateExecution}`);
            }
            if (dateFilter.endDateExecution) {
                params.push(`inwork_changed_at_end=${dateFilter.endDateExecution}`);
            }
            if (dateFilter.startCheckDate) {
                params.push(`completed_changed_at_begin=${dateFilter.startCheckDate}`);
            }
            if (dateFilter.endCheckDate) {
                params.push(`completed_changed_at_end=${dateFilter.endCheckDate}`);
            }
            if (dateFilter.startCompleteDate) {
                params.push(`deadline_at_start=${dateFilter.startCompleteDate}`);
            }
            if (dateFilter.endCompleteDate) {
                params.push(`deadline_at_end=${dateFilter.endCompleteDate}`);
            }
        }

        // Просроченные на дату
        if (pastDateFilter.type) {
            if (pastDateFilter.begin) {
                params.push(`date_deadline=${pastDateFilter.begin}`);
            }
        }

        // Автор
        if (selectedAuthor.length) {
            let authors = selectedAuthor.map(item => item.id).join(',');
            params.push(`user_id=${authors}`);
        }

        // Исполнитель
        if (selectedExecutor.length) {
            let executorsId = selectedExecutor.map(item => item.id).join(',');
            params.push(`executor_id=${executorsId}`);
        }

        // Требует внимание
        if (selectedAuditor) {
            params.push(`auditor_id=${selectedAuditor.id}`);
        }

        // Контрагент
        if (selectedCounterparty) {
            params.push(`executor_organization_id=${selectedCounterparty.id}`);
        }

        // Категории
        if (selectedDictionaries.length) {
            let dictionariesId = selectedDictionaries.map(item => item.id).join(',');
            params.push(`dictionary_id=${dictionariesId}`);
        }

        // Организация
        if (selectedOrganization) {
            params.push(`author_organization_id=${selectedOrganization.id}`);
        }

        // Статус работ
        if (selectedRetention) {
            params.push(`retention_status_id=${selectedRetention.id}`);
        }

        // Тип исполнителя
        if (selectedExecutorType) {
            params.push(`is_counterparty=${selectedExecutorType.id}`);
        }

        // Повторно открытые
        if (selectedIsReopen) {
            params.push(`is_reopen=1`);
        }

        // Документ
        if (docFilter) {
            params.push(`document_id=${docFilter.id}`);
        }

        return params.join('&');
    };

    handleFilterApply = (isNeedCounts, isNeedBreadcrumbs, limit, scrollTop) => {
        const { selectedIsReopen, pastDateFilter } = this.state;

        let filterParams = this.getFilterParams();
        let filterList = this.getFilterList();

        if (selectedIsReopen || pastDateFilter.type) {
            this.setState(
                {
                    filterParams: filterParams,
                    shownFilterList: filterList,
                    filterModalVisible: false,
                    openStatusIsActive: true,
                    inworkStatusIsActive: true,
                    completedStatusIsActive: true,
                },
                () => {
                    this.handleStatusFilter(isNeedCounts, isNeedBreadcrumbs, limit);
                },
            );

            return;
        }

        this.setState(
            {
                filterParams: filterParams,
                shownFilterList: filterList,
                filterModalVisible: false,
            },
            () => this.setParams(isNeedCounts, isNeedBreadcrumbs, limit, scrollTop),
        );
    };

    setParams = (isNeedCounts, isNeedBreadcrumbs, limit, scrollTop) => {
        const { filterParams, statusParams, searchParams, sortParams } = this.state;
        let params = '';

        if (filterParams != '') {
            params += `&${filterParams}`;
        }
        if (statusParams != '') {
            params += `&${statusParams}`;
        }
        if (searchParams != '') {
            params += `&${searchParams}`;
        }
        if (sortParams != '') {
            params += `&${sortParams}`;
        }

        this.props.setParams(params, isNeedCounts, isNeedBreadcrumbs, limit, scrollTop);
    };

    handleFilterClose = () => {
        this.setState({
            ...this.state.prevStateFilters,
            filterModalVisible: false,
            currentListKey: 'filterList',
            listKey: [],
        });
    };

    handleFilterListOpen = (e, item) => {
        e.preventDefault();
        const {
            getQueryFiltredExecutors,
            getFilteredAuthors,
            getCounterpartyList,
            getDictionary,
            getFiltredOrganizations,
            getFiltredAuditors,
            match,
        } = this.props;
        const { projectId = 0, currentStructureId = 0 } = match.params;

        switch (item) {
            case 'organiztionFilterList':
                getFiltredOrganizations(projectId, currentStructureId);
                break;
            case 'executorFilter':
                getQueryFiltredExecutors(projectId, currentStructureId);
                break;
            case 'counterpartyFilter':
                getCounterpartyList(projectId, currentStructureId);
                break;
            case 'auditorFilter':
                getFiltredAuditors(projectId, currentStructureId);
                break;
            case 'authorFilter':
                // getAuthors(projectId, selectedOrganizationId);
                // getAuthors(projectId, currentStructureId);
                getFilteredAuthors(projectId, currentStructureId);
                break;
            case 'dictionaryFilter':
                getDictionary();

                break;
            default:
                break;
        }

        this.setState(state => {
            return {
                currentListKey: item,
                listKey: [...state.listKey, item],
                selectedDate: null,
                selectedTime: null,
            };
        });
    };

    filterListBack = e => {
        if (e) {
            e.preventDefault();
        }

        let copyListKey = [...this.state.listKey];
        copyListKey.pop();

        this.setState({
            currentListKey: copyListKey.length ? copyListKey[copyListKey.length - 1] : 'filterList',
            listKey: copyListKey,
        });

        return;
    };

    setDate = (value, dateString) => {
        this.setState({
            selectedDate: dateString,
        });
    };

    setTime = (time, timeString) => {
        this.setState({
            selectedTime: timeString,
        });
    };

    filterChoose = listKey => {
        let { selectedDate, selectedTime, dateFilter, pastDateFilter } = this.state;

        switch (listKey) {
            case 'createAtBegin':
                if (!selectedDate) {
                    if (dateFilter.type === 'singleDate' && dateFilter.begin) {
                        let parseDate = dateFilter.begin.split(' ');
                        selectedDate = parseDate[0];
                    } else {
                        selectedDate = moment(new Date(), 'DD-MM-YYYY').format('YYYY-MM-DD');
                    }
                }
                if (!selectedTime) {
                    if (dateFilter.type === 'singleDate' && dateFilter.begin) {
                        let parseDate = dateFilter.begin.split(' ');
                        selectedTime = parseDate[1];
                    } else {
                        selectedTime = moment('12:00:00', 'HH:mm:ss').format('HH:mm:ss');
                    }
                }

                this.setDateFilter(`${selectedDate} ${selectedTime}`, null, 'singleDate', null);
                break;

            case 'createAtEnd':
                if (!selectedDate) {
                    if (dateFilter.type === 'singleDate' && dateFilter.end) {
                        let parseDate = dateFilter.end.split(' ');
                        selectedDate = parseDate[0];
                    } else {
                        selectedDate = moment(new Date(), 'DD-MM-YYYY').format('YYYY-MM-DD');
                    }
                }
                if (!selectedTime) {
                    if (dateFilter.type === 'singleDate' && dateFilter.end) {
                        let parseDate = dateFilter.end.split(' ');
                        selectedTime = parseDate[1];
                    } else {
                        selectedTime = moment('12:00:00', 'HH:mm:ss').format('HH:mm:ss');
                    }
                }

                this.setDateFilter(null, `${selectedDate} ${selectedTime}`, 'singleDate');
                break;
            case 'startDateExecution':
                if (!selectedDate) {
                    if (dateFilter.type === 'singleDate' && dateFilter.startDateExecution) {
                        let parseDate = dateFilter.startDateExecution.split(' ');
                        selectedDate = parseDate[0];
                    } else {
                        selectedDate = moment(new Date(), 'DD-MM-YYYY').format('YYYY-MM-DD');
                    }
                }

                this.setExecutionDateFilter(`${selectedDate}`, null, 'singleDate', null);
                break;
            case 'endDateExecution':
                if (!selectedDate) {
                    if (dateFilter.type === 'singleDate' && dateFilter.endDateExecution) {
                        let parseDate = dateFilter.endDateExecution.split(' ');
                        selectedDate = parseDate[0];
                    } else {
                        selectedDate = moment(new Date(), 'DD-MM-YYYY').format('YYYY-MM-DD');
                    }
                }

                this.setExecutionDateFilter(null, `${selectedDate}`, 'singleDate');
                break;
            case 'startCheckDate':
                if (!selectedDate) {
                    if (dateFilter.type === 'singleDate' && dateFilter.startCheckDate) {
                        let parseDate = dateFilter.startCheckDate.split(' ');
                        selectedDate = parseDate[0];
                    } else {
                        selectedDate = moment(new Date(), 'DD-MM-YYYY').format('YYYY-MM-DD');
                    }
                }

                this.setCheckDateFilter(`${selectedDate}`, null, 'singleDate', null);
                break;
            case 'endCheckDate':
                if (!selectedDate) {
                    if (dateFilter.type === 'singleDate' && dateFilter.endCheckDate) {
                        let parseDate = dateFilter.endCheckDate.split(' ');
                        selectedDate = parseDate[0];
                    } else {
                        selectedDate = moment(new Date(), 'DD-MM-YYYY').format('YYYY-MM-DD');
                    }
                }

                this.setCheckDateFilter(null, `${selectedDate}`, 'singleDate');
                break;
            case 'startCompleteDate':
                if (!selectedDate) {
                    if (dateFilter.type === 'singleDate' && dateFilter.startCompleteDate) {
                        let parseDate = dateFilter.startCompleteDate.split(' ');
                        selectedDate = parseDate[0];
                    } else {
                        selectedDate = moment(new Date(), 'DD-MM-YYYY').format('YYYY-MM-DD');
                    }
                }

                this.setCompleteFilter(`${selectedDate}`, null, 'singleDate');
                break;
            case 'endCompleteDate':
                if (!selectedDate) {
                    if (dateFilter.type === 'singleDate' && dateFilter.endCompleteDate) {
                        let parseDate = dateFilter.endCompleteDate.split(' ');
                        selectedDate = parseDate[0];
                    } else {
                        selectedDate = moment(new Date(), 'DD-MM-YYYY').format('YYYY-MM-DD');
                    }
                }

                this.setCompleteFilter(null, `${selectedDate}`, 'singleDate', null);
                break;
            case 'day':
                if (!selectedDate) {
                    if (dateFilter.type === 'day' && dateFilter.begin) {
                        let parseDate = dateFilter.begin.split(' ');
                        selectedDate = parseDate[0];
                    } else {
                        selectedDate = moment(new Date(), 'DD-MM-YYYY').format('YYYY-MM-DD');
                    }
                }

                this.setDateFilter(`${selectedDate} 00:00:00`, `${selectedDate} 23:59:59`, 'day', selectedDate);
                break;
            case 'month':
                let start, end;
                if (!selectedDate) {
                    if (dateFilter.type === 'month' && dateFilter.begin) {
                        let parseDate = dateFilter.begin.split(' ');
                        selectedDate = parseDate[0];
                        let selectedMoment = moment(selectedDate, 'DD-MM-YYYY');
                        selectedDate = selectedMoment.format('MMMM YYYY');
                        start = selectedMoment.startOf('month').format('DD-MM-YYYY');
                        end = selectedMoment.endOf('month').format('DD-MM-YYYY');
                    } else {
                        let todayMoment = moment(new Date(), 'DD-MM-YYYY');
                        selectedDate = todayMoment.format('MMMM YYYY');
                        start = todayMoment.startOf('month').format('DD-MM-YYYY');
                        end = todayMoment.endOf('month').format('DD-MM-YYYY');
                    }
                } else {
                    let selectedMoment = moment(selectedDate, 'MMMM YYYY');
                    start = selectedMoment.startOf('month').format('DD-MM-YYYY');
                    end = selectedMoment.endOf('month').format('DD-MM-YYYY');
                }

                this.setDateFilter(`${start} 00:00:00`, `${end} 23:59:59`, 'month', selectedDate);
                break;
            case 'pastDueFilterDay':
                if (!selectedDate) {
                    if (pastDateFilter.type === 'pastDueFilterDay' && pastDateFilter.begin) {
                        // let parseDate = pastDateFilter.end.split(' ');
                        selectedDate = pastDateFilter.begin;
                    } else {
                        selectedDate = moment(new Date(), 'DD-MM-YYYY').format('YYYY-MM-DD');
                    }
                }

                this.setState({
                    pastDateFilter: {
                        begin: selectedDate,
                        // end: dateAtEnd,
                        type: 'pastDueFilterDay',
                    },
                });
                // this.setDateFilter(`${selectedDate} 00:00:00`, `${selectedDate} 23:59:59`, 'day');
                break;
            case 'organiztionFilter':
                if (this.state.selectedOrganization) {
                    this.setState({
                        selectedAuthor: [],
                    });
                }

                break;
            default:
                break;
        }

        this.filterListBack();
    };

    setDateFilter = (dateAtBegin, dateAtEnd, dateType, display) => {
        const { dateFilter } = this.state;
        if (dateType !== 'singleDate') {
            this.setState(state => {
                return {
                    dateFilter: {
                        ...state.dateFilter,
                        startDateExecution: '',
                        endDateExecution: '',
                        startCheckDate: '',
                        endCheckDate: '',
                        begin: dateAtBegin,
                        end: dateAtEnd,
                        type: dateType,
                        display: display,
                    },
                };
            });
        } else if (dateType === 'singleDate') {
            if (dateFilter.type === 'singleDate') {
                this.setState(state => {
                    return {
                        dateFilter: {
                            ...state.dateFilter,
                            begin: dateAtBegin ? dateAtBegin : dateFilter.begin,
                            end: dateAtEnd ? dateAtEnd : dateFilter.end,
                            type: dateType,
                        },
                    };
                });
            } else {
                this.setState(state => {
                    return {
                        dateFilter: {
                            ...state.dateFilter,
                            begin: dateAtBegin,
                            end: dateAtEnd,
                            type: dateType,
                        },
                    };
                });
            }
        }
    };

    setCompleteFilter = (dateAtBegin, dateAtEnd, dateType, display) => {
        const { dateFilter } = this.state;
        if (dateType !== 'singleDate') {
            this.setState(state => {
                return {
                    dateFilter: {
                        ...state.dateFilter,
                        startCompleteDate: dateAtBegin,
                        endCompleteDate: dateAtEnd,
                        type: dateType,
                        display: display,
                    },
                };
            });
        } else if (dateType === 'singleDate') {
            if (dateFilter.type === 'singleDate') {
                this.setState(state => {
                    return {
                        dateFilter: {
                            ...state.dateFilter,
                            startCompleteDate: dateAtBegin ? dateAtBegin : dateFilter.startCompleteDate,
                            endCompleteDate: dateAtEnd ? dateAtEnd : dateFilter.endCompleteDate,
                            type: dateType,
                        },
                    };
                });
            } else {
                this.setState(state => {
                    if (state.dateFilter?.display?.length) {
                        return {
                            dateFilter: {
                                ...state.dateFilter,
                                startCompleteDate: dateAtBegin,
                                endCompleteDate: dateAtEnd,
                                begin: '',
                                end: '',
                                display: '',
                                type: dateType,
                            },
                        };
                    }

                    return {
                        dateFilter: {
                            ...state.dateFilter,
                            startCompleteDate: dateAtBegin,
                            endCompleteDate: dateAtEnd,
                            type: dateType,
                        },
                    };
                });
            }
        }
    };

    setExecutionDateFilter = (dateAtBegin, dateAtEnd, dateType, display) => {
        const { dateFilter } = this.state;
        if (dateType !== 'singleDate') {
            this.setState(state => {
                return {
                    dateFilter: {
                        ...state.dateFilter,
                        startDateExecution: dateAtBegin,
                        endDateExecution: dateAtEnd,
                        type: dateType,
                        display: display,
                    },
                };
            });
        } else if (dateType === 'singleDate') {
            if (dateFilter.type === 'singleDate') {
                this.setState(state => {
                    return {
                        dateFilter: {
                            ...state.dateFilter,
                            startDateExecution: dateAtBegin ? dateAtBegin : dateFilter.startDateExecution,
                            endDateExecution: dateAtEnd ? dateAtEnd : dateFilter.endDateExecution,
                            type: dateType,
                        },
                    };
                });
            } else {
                this.setState(state => {
                    if (state.dateFilter?.display?.length) {
                        return {
                            dateFilter: {
                                ...state.dateFilter,
                                startDateExecution: dateAtBegin,
                                endDateExecution: dateAtEnd,
                                begin: '',
                                end: '',
                                display: '',
                                type: dateType,
                            },
                        };
                    }

                    return {
                        dateFilter: {
                            ...state.dateFilter,
                            startDateExecution: dateAtBegin,
                            endDateExecution: dateAtEnd,
                            type: dateType,
                        },
                    };
                });
            }
        }
    };

    setCheckDateFilter = (dateAtBegin, dateAtEnd, dateType, display) => {
        const { dateFilter } = this.state;
        if (dateType !== 'singleDate') {
            this.setState(state => {
                return {
                    dateFilter: {
                        ...state.dateFilter,
                        startCheckDate: dateAtBegin,
                        endCheckDate: dateAtEnd,
                        type: dateType,
                        display: display,
                    },
                };
            });
        } else if (dateType === 'singleDate') {
            if (dateFilter.type === 'singleDate') {
                this.setState(state => {
                    return {
                        dateFilter: {
                            ...state.dateFilter,
                            startCheckDate: dateAtBegin ? dateAtBegin : dateFilter.startCheckDate,
                            endCheckDate: dateAtEnd ? dateAtEnd : dateFilter.endCheckDate,
                            type: dateType,
                        },
                    };
                });
            } else {
                this.setState(state => {
                    if (state.dateFilter?.display?.length) {
                        return {
                            dateFilter: {
                                ...state.dateFilter,
                                startCheckDate: dateAtBegin,
                                endCheckDate: dateAtEnd,
                                begin: '',
                                end: '',
                                display: '',
                                type: dateType,
                            },
                        };
                    }

                    return {
                        dateFilter: {
                            ...state.dateFilter,
                            startCheckDate: dateAtBegin,
                            endCheckDate: dateAtEnd,
                            type: dateType,
                        },
                    };
                });
            }
        }
    };

    handleRemoveFilter = filter => {
        const { dateFilter } = this.state;
        let changeState = {};

        switch (filter.filterType) {
            case 'createAtBegin':
                changeState.dateFilter = { ...dateFilter, begin: '' };
                break;
            case 'createAtEnd':
                changeState.dateFilter = { ...dateFilter, end: '' };
                break;
            case 'startDateExecution':
                changeState.dateFilter = { ...dateFilter, startDateExecution: '' };
                break;
            case 'endDateExecution':
                changeState.dateFilter = { ...dateFilter, endDateExecution: '' };
                break;
            case 'startCheckDate':
                changeState.dateFilter = { ...dateFilter, startCheckDate: '' };
                break;
            case 'endCheckDate':
                changeState.dateFilter = { ...dateFilter, endCheckDate: '' };
                break;
            case 'startCompleteDate':
                changeState.dateFilter = { ...dateFilter, startCompleteDate: '' };
                break;
            case 'endCompleteDate':
                changeState.dateFilter = { ...dateFilter, endCompleteDate: '' };
                break;
            case 'dateFilter':
                changeState.dateFilter = {
                    startDateExecution: '',
                    endDateExecution: '',
                    startCheckDate: '',
                    endCheckDate: '',
                    begin: '',
                    end: '',
                    type: '',
                    display: '',
                    startCompleteDate: '',
                    endCompleteDate: '',
                };
                break;
            case 'pastDateFilter':
                changeState.pastDateFilter = { begin: '', end: '', type: '' };
                break;
            case 'selectedOrganization':
                changeState.selectedOrganization = null;
                break;
            case 'selectedAuthor':
                changeState.selectedAuthor = [];
                break;
            case 'selectedExecutor':
                changeState.selectedExecutor = [];
                break;
            case 'selectedAuditor':
                changeState.selectedAuditor = null;
                break;
            case 'selectedDictionaries':
                changeState.selectedDictionaries = [];
                break;
            case 'selectedRetention':
                changeState.selectedRetention = null;
                break;
            case 'selectedExecutorType':
                changeState.selectedExecutorType = null;
                break;
            case 'selectedCounterparty':
                changeState.selectedCounterparty = null;
                break;
            case 'selectedIsReopen':
                changeState.selectedIsReopen = false;
                break;
            case 'docFilter':
                changeState.docFilter = null;
                break;
            default:
                break;
        }

        // Очищаем тип фильтра если все dateFilter пустые
        if (Object.values(changeState.dateFilter || {})?.length) {
            const { type, ...rest } = changeState.dateFilter;
            const dateFilterValues = Object.values(rest || {});
            const allFiltersRemoved = dateFilterValues?.every(item => !item?.length);

            if (allFiltersRemoved) {
                changeState.dateFilter = { ...changeState.dateFilter, type: '' };
            }
        }

        this.setState(changeState, () => this.handleFilterApply(true, false));
    };

    renderFilterLabels = (filter, i) => {
        return (
            <div className="filter-label" key={i}>
                <div className="filter-info">
                    <div className="filter__type">{filter.type}</div>
                    <div className="filter__name">
                        {Array.isArray(filter.name)
                            ? filter.name.map((n, i) => (i === filter.name.length - 1 ? `${n}` : `${n} / `))
                            : filter.name}
                    </div>
                </div>
                <Icon type="close" className="filter__delete" onClick={() => this.handleRemoveFilter(filter)} />
            </div>
        );
    };

    renderSortTypes = () => {
        return (
            <Menu>
                {sortTypes.map(item => (
                    <Menu.Item key={item.id}>
                        <span onClick={() => this.sortByDate(item)}>{item.title}</span>
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    componentDidMount() {
        const { history, notesFilters } = this.props;

        let newState = {},
            limit = null,
            scrollTop = null;

        if (notesFilters) {
            newState = { ...notesFilters.filterState };
            newState.openStatusIsActive = notesFilters.statuses.openStatusIsActive;
            newState.inworkStatusIsActive = notesFilters.statuses.inworkStatusIsActive;
            newState.completedStatusIsActive = notesFilters.statuses.completedStatusIsActive;
            if (history.action === 'POP') {
                limit = notesFilters.limit;

                if (this.props.match.params.currentStructureId === notesFilters.currentStructureId) {
                    scrollTop = notesFilters.scrollTop;
                }
            }
        }

        if (history.location.state !== undefined) {
            newState.docFilter = {
                id: history.location.state.docId,
                name: history.location.state.docTitle,
            };
        }

        // console.log(newState);

        this.setState(newState, () => {
            this.handleStatusFilter(true, true);
            this.handleFilterApply(true, true, limit, scrollTop);
        });
    }

    componentDidUpdate() {
        const { organizations, clearOrganizations, auditors, clearAuditors } = this.props;
        if (!this.state.filterModalVisible && organizations.length) {
            clearOrganizations();
        }
        if (!this.state.filterModalVisible && auditors.data.length) {
            clearAuditors();
        }
    }

    componentWillUnmount() {
        const {
            openStatusIsActive,
            inworkStatusIsActive,
            completedStatusIsActive,
            selectedOrganization,
            selectedExecutor,
            selectedAuditor,
            selectedDictionaries,
            selectedIsReopen,
            selectedAuthor,
            selectedRetention,
            selectedExecutorType,
            selectedCounterparty,
            dateFilter,
            pastDateFilter,
            docFilter,
            searchValue,
        } = this.state;
        const { setNotesFilters, limit } = this.props;

        setNotesFilters({
            projectId: this.props.match.params.projectId ? this.props.match.params.projectId : null,
            currentStructureId: this.props.match.params.currentStructureId ? this.props.match.params.currentStructureId : null,
            filterState: {
                selectedOrganization,
                selectedExecutor,
                selectedAuditor,
                selectedDictionaries,
                selectedIsReopen,
                selectedAuthor,
                dateFilter,
                selectedRetention,
                selectedExecutorType,
                selectedCounterparty,
                pastDateFilter,
                docFilter,
            },
            statuses: {
                openStatusIsActive,
                inworkStatusIsActive,
                completedStatusIsActive,
            },
            limit: searchValue ? null : limit,
            scrollTop: window.pageYOffset,
        });
    }

    render() {
        const { user, auditors, executors, data, success, authors, organizations, dictionary } = this.props;
        const {
            openStatusIsActive,
            inworkStatusIsActive,
            completedStatusIsActive,
            filterModalVisible,
            shownFilterList,
            filterTitles,
            selectedOrganization,
            selectedExecutor,
            selectedDictionaries,
            selectedIsReopen,
            selectedAuthor,
            searchValue,
            selectedDateFilter,
            selectedRetention,
            selectedCounterparty,
            currentListKey,
            selectedExecutorType,
            selectedAuditor,
            dateFilter,
            pastDateFilter,
            test,
        } = this.state;

        if (!data || !success) return null;

        return (
            <>
                <StatusFilters
                    data={data}
                    openStatusIsActive={openStatusIsActive}
                    inworkStatusIsActive={inworkStatusIsActive}
                    completedStatusIsActive={completedStatusIsActive}
                    onHandleStatusChange={this.handleStatusChange}
                />
                <div className="filter-row">
                    <div className="filter-button">
                        <span className="filter__icon" onClick={this.handleFilterOpen}>
                            <Icon type="filter" />
                        </span>
                        {!shownFilterList.length > 0 ? (
                            <span className="filter__title" onClick={this.handleFilterOpen}>
                                Добавить фильтр
                            </span>
                        ) : (
                            <div className="filter-list">{shownFilterList.map((filter, i) => this.renderFilterLabels(filter, i))}</div>
                        )}
                    </div>
                </div>

                <div className="filter-row">
                    <SearchForm
                        searchValue={searchValue}
                        handleSearchFilter={this.handleSearchFilter}
                        handleSearchChange={this.handleSearchChange}
                        handleSearchReset={this.handleSearchReset}
                    />
                </div>

                <div className="date-filter">
                    <Dropdown overlay={this.renderSortTypes} placement="bottomRight" trigger={['click']}>
                        <Button>
                            {selectedDateFilter}
                            <Icon type="filter" />
                        </Button>
                    </Dropdown>
                </div>

                <FiltersModal
                    // Handlers
                    filterChoose={this.filterChoose}
                    filterListBack={this.filterListBack}
                    handleFilterApply={this.handleFilterApply}
                    handleFilterClose={this.handleFilterClose}
                    handleFilterListOpen={this.handleFilterListOpen}
                    // Setters
                    setIsReopenFilter={this.setIsReopenFilter}
                    setOrganizationFilter={this.setOrganizationFilter}
                    setDate={this.setDate}
                    setTime={this.setTime}
                    setRetentionFilter={this.setRetentionFilter}
                    setExecutorTypeFilter={this.setExecutorTypeFilter}
                    setCounterpartyFilter={this.setCounterpartyFilter}
                    setDictionaryFilter={this.setDictionaryFilter}
                    setAuthorFilter={this.setAuthorFilter}
                    setAuditorFilter={this.setAuditorFilter}
                    setExecutorFilter={this.setExecutorFilter}
                    // Variables
                    filterModalVisible={filterModalVisible}
                    filterTitles={filterTitles}
                    selectedOrganization={selectedOrganization}
                    selectedExecutor={selectedExecutor}
                    selectedDictionaries={selectedDictionaries}
                    selectedIsReopen={selectedIsReopen}
                    selectedAuthor={selectedAuthor}
                    selectedRetention={selectedRetention}
                    selectedCounterparty={selectedCounterparty}
                    currentListKey={currentListKey}
                    selectedExecutorType={selectedExecutorType}
                    selectedAuditor={selectedAuditor}
                    dateFilter={dateFilter}
                    pastDateFilter={pastDateFilter}
                    organizations={organizations} // this.props.organizations
                    dictionary={dictionary} // this.props.dictionary
                    test={test}
                    authors={authors}
                    user={user}
                    executors={executors}
                    auditors={auditors}
                />
            </>
        );
    }
}

const mapStateToProps = state => ({
    notesList: state.projectModule.notes.notesList,
    organizations: state.projectModule.team.organizations.data,
    user: state.user.user,
    dictionary: state.projectModule.notes.dictionary,
    authors: state.projectModule.notes.authors,
    executors: state.projectModule.notes.executors,
    notesFilters: state.projectModule.notes.notesFilters,
    auditors: state.projectModule.notes.auditors,
});

const mapDispatchToProps = dispatch => ({
    getOrganizations: () => dispatch(getOrganizations()),
    getDictionary: id => dispatch(getDictionary(id)),
    getExecutors: (projectId, structureId) => dispatch(getExecutors(projectId, structureId)),
    getAuthors: (projectId, organizationId) => dispatch(getAuthors(projectId, organizationId)),
    getFilteredAuthors: (projectId, currentStructureId) => dispatch(getFilteredAuthors(projectId, currentStructureId)),
    setNotesFilters: data => dispatch(setNotesFilters(data)),
    getQueryFiltredExecutors: (projectId, structureId) => dispatch(getQueryFiltredExecutors(projectId, structureId)),
    getFiltredOrganizations: (projectId, structureId) => dispatch(getFiltredOrganizations(projectId, structureId)),
    clearOrganizations: () => dispatch(clearOrganizations()),
    clearAuditors: () => dispatch(clearAuditors()),
    getCounterpartyList: (projectId, structureId) => dispatch(getCounterpartyList(projectId, structureId)),
    getFiltredAuditors: (projectId, structureId) => dispatch(getFiltredAuditors(projectId, structureId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesListFilters);
