import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PageHeader } from 'antd';

import './PageTitle.scss';

const PageTitle = props => {
    const { params, structure, currentPage } = props;
    const {
        currentItem: { data, success },
    } = structure;
    const { path, projectId, section, currentStructureId } = params;
    const [currentTitle, setCurrentTitle] = useState('');
    const history = useHistory();

    const onBack = () => {
        if (params.section === 'comments' && history.location.search && history.location.search.indexOf('common=1') >= 0) {
            history.push('/projects/common-comments');
            return;
        }

        if (
            params.section === 'documents' &&
            history.location.search &&
            history.location.search.indexOf('common=1') >= 0 &&
            history.location.hash
        ) {
            const id = parseFloat(history.location.hash.slice(1));
            history.push(`/projects/${params.projectId}/comments/${params.currentStructureId}/${id}?common=1`);
            // window.location.href = `/projects/${params.projectId}/comments/${params.currentStructureId}/${id}?common=1`;
            return;
        }

        if (data.breadcrumbs && data.breadcrumbs.length > 1) {
            let prevItem = data.breadcrumbs[data.breadcrumbs.length - 2];

            history.push(
                (!path ? null : `/${path}`) +
                    (!projectId ? null : `/${projectId}`) +
                    (!section ? null : `/${section}`) +
                    `/${prevItem.id}` +
                    (section === 'documents' && prevItem.type === 'project-structure' && prevItem.root_document_id
                        ? `/${prevItem.root_document_id}`
                        : ''),
            );
        } else {
            history.push('/projects');
        }
    };

    useEffect(() => {
        if (data && data.breadcrumbs && data.breadcrumbs.length > 0) {
            setCurrentTitle(data.breadcrumbs[data.breadcrumbs.length - 1].title);
        }
    }, [data]);

    const getTitle = () => {
        switch (props.path) {
            case '/settings':
                return 'Настройки';
            case '/profile':
                return 'Профиль';
            case '/help':
                return 'Помощь';
            case '/favorites':
                return 'Избранное';
            case '/projects':
                return 'СТРОИМ ДЛЯ ЖИЗНИ';
            case '/:path/:section':
                return 'СТРОИМ ДЛЯ ЖИЗНИ';
            default:
                return '';
        }
    };

    if (props.path === '/:path/:section') {
        return <p className="page-title">{getTitle()}</p>;
    }
    return (
        <>
            {data &&
                (!data.breadcrumbs || Object.keys(params).length === 0 ? (
                    <p className="page-title">{getTitle()}</p>
                ) : (
                    <PageHeader style={{ padding: '0' }} onBack={onBack} title={currentTitle} />
                ))}
        </>
    );

    // return (
    //     <>
    //         {success && (
    //             <>
    //                 {!data.breadcrumbs || Object.keys(params).length === 0 ? (
    //                     <p className="page-title">Проекты:</p>
    //                 ) : (
    //                     <PageHeader style={{ padding: '16px 0px' }} onBack={onBack} title={currentTitle} />
    //                 )}
    //             </>
    //         )}
    //     </>
    // );
};

function mapStateToProps(state) {
    return {
        currentPage: state.routing.currentPage.title,
        prevPage: state.routing.prevPage.title,
        structure: {
            currentItem: state.projectModule.structure.currentItem,
        },
    };
}

export default connect(mapStateToProps, null)(PageTitle);
