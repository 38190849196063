import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import App from './components/App.js';
import store from './redux/store';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    // console.log('DEVVVV');
} else {
    Sentry.init({ dsn: 'https://c3c1a0e9cb9747559e91b6a8bd9dd0da@sentry.netimob.com/10' });
}

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root'),
);
