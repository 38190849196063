import React from 'react';
import { Route } from 'react-router-dom';

import './TempSignIn.scss';

class TempSignIn extends React.Component {
    render() {
        const { component: Component, ...rest } = this.props;
        return (
            <>
                <Route {...rest} render={matchProps => <Component {...matchProps} />} />
            </>
        );
    }
}

export default TempSignIn;
