import * as types from '../../actionTypes';

const initialState = {
    documentTasksList: {
        data: [],
        success: false,
        errors: [],
        loading: false,
    },
    tasksList: {
        data: null,
        success: false,
        errors: [],
        loading: false,
    },
    tasksFiles: {
        data: null,
        success: false,
        errors: [],
        loading: false,
    },
    authors: {
        data: [],
        success: false,
        errors: [],
        loading: false,
    },
    engineers: {
        data: [],
        success: false,
        errors: [],
        loading: false,
    },
    freeTimes: {},
    singleTask: {
        data: null,
        success: false,
        errors: [],
        loading: false,
    },
    workSections: {
        data: [],
        success: false,
        errors: [],
        loading: false,
    },
    workTypes: {
        data: [],
        success: false,
        errors: [],
        loading: false,
    },
    workStages: {
        data: [],
        success: false,
        errors: [],
        loading: false,
    },
    taskNumber: {
        data: [],
        success: false,
        errors: [],
    },
    uploadedFile: {
        data: [],
        success: false,
        errors: [],
    },
    deletedFile: {
        data: [],
        success: false,
        errors: [],
    },
    history: {
        data: [],
        success: false,
        errors: [],
    },
    isTaskArchived: false,
    tasksFilters: null,
};

export const getTasks = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DOCUMENT_TASKS_SUCCESS:
            return {
                ...state,
                documentTasksList: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };

        case types.GET_DOCUMENT_TASKS_LOADING:
            return {
                ...state,
                documentTasksList: {
                    data: [],
                    success: false,
                    errors: [],
                    loading: true,
                },
            };

        case types.GET_TASKS_SUCCESS:
            return {
                ...state,
                tasksList: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };

        case types.GET_TASKS_LOADING:
            return {
                ...state,
                tasksList: {
                    ...state.tasksList,
                    loading: true,
                },
            };

        case types.GET_TASKS_FILES_SUCCESS:
            return {
                ...state,
                tasksFiles: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };

        case types.GET_TASKS_FILES_LOADING:
            return {
                ...state,
                tasksFiles: {
                    ...state.tasksFiles,
                    loading: true,
                },
            };

        case types.GET_TASK_BY_ID_LOADING:
            return {
                ...state,
                singleTask: {
                    data: null,
                    success: false,
                    errors: [],
                    loading: true,
                },
            };

        case types.GET_TASK_BY_ID_SUCCESS:
            return {
                ...state,
                singleTask: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };
        case types.SET_TASK:
            return {
                ...state,
                singleTask: {
                    ...state.singleTask,
                    data: action.data,
                },
            };
        case types.CHANGE_TASK_STATUS_LOADING:
            return {
                ...state,
                singleTask: {
                    data: null,
                    success: false,
                    errors: [],
                    loading: true,
                },
            };

        case types.CHANGE_TASK_STATUS_SUCCESS:
            return {
                ...state,
                singleTask: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };

        case types.GET_ENGINEERS:
            return {
                ...state,
                engineers: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };

        case types.GET_ENGINEERS_LOADING:
            return {
                ...state,
                engineers: {
                    ...state.engineers,
                    loading: true,
                },
            };

        case types.GET_FREE_TIMES:
            return {
                ...state,
                freeTimes: {
                    ...state.freeTimes,
                    [action.index]: {
                        data: action.data,
                        success: action.success,
                        errors: action.errors,
                        loading: false,
                    },
                },
            };

        case types.GET_FREE_TIMES_LOADING:
            return {
                ...state,
                freeTimes: {
                    ...state.freeTimes,
                    [action.index]: {
                        loading: true,
                        data: [],
                        success: false,
                        errors: [],
                    },
                },
            };

        case types.GET_WORK_SECTION:
            return {
                ...state,
                workSections: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };
        case types.GET_WORK_SECTION_LOADING:
            return {
                ...state,
                workSections: {
                    ...state.workSections,
                    loading: true,
                },
            };
        case types.GET_WORK_TYPES:
            return {
                ...state,
                workTypes: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };
        case types.GET_WORK_TYPES_LOADING:
            return {
                ...state,
                workTypes: {
                    ...state.workTypes,
                    loading: true,
                },
                workStages: initialState.workStages,
            };
        case types.GET_WORK_STAGES:
            return {
                ...state,
                workStages: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };
        case types.GET_WORK_STAGES_LOADING:
            return {
                ...state,
                workStages: {
                    ...state.workStages,
                    loading: true,
                },
            };
        case types.GET_TASKS_AUTHORS:
            return {
                ...state,
                authors: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };
        case types.GET_TASKS_AUTHORS_LOADING:
            return {
                ...state,
                authors: {
                    data: [],
                    loading: true,
                },
            };
        case types.GET_NUMBER:
            return {
                ...state,
                taskNumber: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                },
            };
        case types.UPLOAD_TASK_FILE:
            return {
                ...state,
                uploadedFile: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                },
            };
        case types.DELETE_TASK_FILE:
            return {
                ...state,
                deletedFile: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                },
            };
        case types.GET_RETENTION_UNITS:
            return {
                ...state,
                retentionUnits: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                },
            };
        case types.GET_TASK_HISTORY:
            return {
                ...state,
                history: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                },
            };
        case types.SUCCESS_TASK_ARCHIVE:
            return {
                ...state,
                isTaskArchived: true,
            };
        case types.RESET_ARCHIVE_STATUS:
            return {
                ...state,
                isTaskArchived: false,
            };
        case types.SET_TASKS_FILTERS:
            return {
                ...state,
                tasksFilters: action.data,
            };
        case types.CREATE_TASK_COMMENT:
            return {
                ...state,
                singleTask: {
                    ...state.singleTask,
                    data: { ...state.singleTask.data, comments: [...state.singleTask.data.comments, action.data] },
                },
            };
        case types.RESET_TASK:
            return {
                ...state,
                freeTimes: {},
                workSections: {
                    data: [],
                    success: false,
                    errors: [],
                    loading: false,
                },
                workTypes: {
                    data: [],
                    success: false,
                    errors: [],
                    loading: false,
                },
                workStages: {
                    data: [],
                    success: false,
                    errors: [],
                    loading: false,
                },
            };
        default:
            return state;
    }
};
