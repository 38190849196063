import axios from 'axios';
import * as types from '../../actionTypes';
import { apiConfig } from '../../../services/api/config';

export const getAllNtd = () => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}ntd`,
    })
        .then(response => {
            dispatch({
                type: types.GET_NTD,
                data: response.data.data,
                success: response.data.success,
                errors: response.data.errors,
            });
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getNtdById = id => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}ntd/${id}`,
    })
        .then(response => {
            dispatch({
                type: types.GET_NTD_BY_ID,
                data: response.data.data,
                success: response.data.success,
                errors: response.data.errors,
            });
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getTreeNtd = () => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}ntd-detail`,
    })
        .then(response => {
            dispatch({
                type: types.GET_TREE_NTD,
                data: response.data.data,
                success: response.data.success,
                errors: response.data.errors,
            });
        })
        .catch(errors => {
            console.log(errors.response);
        });
};
