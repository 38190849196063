export class Drawable {
    constructor(startx, starty, type, id) {
        this.startx = startx;
        this.starty = starty;
        this.type = type;
        this.id = id;
    }

    registerMovement(x, y) {
        this.x = x;
        this.y = y;
    }
}
