import axios from 'axios';
import * as types from '../../actionTypes';
import { apiConfig } from '../../../services/api/config';
import { message } from 'antd';
import utils from '../../../helpers/utils';

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
let call;
let callGetFiles;
let callHistory;

export const createTask =
    (projectId, structureId, data, callback = () => {}) =>
    dispatch => {
        let accessToken = localStorage.getItem('accessToken');

        return axios({
            headers: {
                'Access-Token': accessToken,
            },
            method: 'POST',
            url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/task`,
        })
            .then(response => {
                if (!response.data.success) {
                    utils.showError(response.data.errors[0]);
                }
                // console.log('resp', response);
                callback(response.data.data);
                return response;
            })
            .catch(errors => {
                console.log(errors);
                throw errors;
            });
    };

export const getTasks =
    (projectId, structureId, params, data, callback = () => {}) =>
    dispatch => {
        // console.log('DATA !!!', data);
        let accessToken = localStorage.getItem('accessToken');
        if (!data) {
            dispatch({
                type: types.GET_TASKS_SUCCESS,
                data: null,
                success: false,
                errors: [],
                loading: false,
            });
        }

        dispatch({ type: types.GET_TASKS_LOADING });

        if (call) {
            call.cancel('Operation canceled by the user.');
        }
        call = axios.CancelToken.source();

        const url =
            !projectId && !structureId
                ? `${apiConfig.url}common/tasks?${params}`
                : `${apiConfig.url}project/${projectId}/project-structure/${structureId}/task?${params}`;
        axios({
            headers: {
                'Access-Token': accessToken,
            },
            method: 'GET',
            url: url,
            cancelToken: call.token,
        })
            .then(response => {
                if (response.data.success) {
                    dispatch({
                        type: types.GET_TASKS_SUCCESS,
                        data: {
                            tasks: data ? [...data.tasks, ...response.data.data.tasks] : [...response.data.data.tasks],
                            pagination: response.data.data.pagination,
                            breadcrumbs: response.data.data.breadcrumbs ? response.data.data.breadcrumbs : data ? data.breadcrumbs : null,
                            taskCount: response.data.data.taskCount ? response.data.data.taskCount : data ? data.taskCount : null,
                        },
                        success: response.data.success,
                        errors: response.data.errors,
                    });

                    callback();
                } else {
                    dispatch({
                        type: types.GET_TASKS_SUCCESS,
                        data: {
                            tasks: null,
                            pagination: data.pagination,
                            breadcrumbs: data.breadcrumbs,
                            taskCount: data.taskCount,
                        },
                        success: response.data.success,
                        errors: response.data.errors,
                    });
                    utils.showError(response.data.errors[0]);
                }
            })
            .catch(errors => {
                // console.log(errors);
                // console.log(errors.response);
            });
    };

export const getTasksFiles = (projectId, structureId, params, data) => dispatch => {
    // console.log('DATA !!!', data);
    let accessToken = localStorage.getItem('accessToken');
    if (!data) {
        dispatch({
            type: types.GET_TASKS_FILES_SUCCESS,
            data: null,
            success: false,
            errors: [],
            loading: false,
        });
    }

    dispatch({ type: types.GET_TASKS_FILES_LOADING });

    if (callGetFiles) {
        callGetFiles.cancel('Operation canceled by the user.');
    }
    callGetFiles = axios.CancelToken.source();

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/task-files?${params}`,
        cancelToken: callGetFiles.token,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_TASKS_FILES_SUCCESS,
                    data: {
                        files: data ? [...data.files, ...response.data.data.files] : [...response.data.data.files],
                        pagination: response.data.data.pagination,
                    },
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                dispatch({
                    type: types.GET_TASKS_FILES_SUCCESS,
                    data: {
                        files: null,
                        pagination: data.pagination,
                    },
                    success: response.data.success,
                    errors: response.data.errors,
                });
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            // console.log(errors);
            // console.log(errors.response);
        });
};

export const getTasksByDocument = (projectId, structureId, documentId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    // dispatch({ type: types.GET_DOCUMENT_TASKS_LOADING });

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/project-document/${documentId}/task`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_DOCUMENT_TASKS_SUCCESS,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getTaskById =
    (projectId, structureId, id, needLoader = true) =>
    dispatch => {
        let accessToken = localStorage.getItem('accessToken');
        if (needLoader) {
            dispatch({ type: types.GET_TASK_BY_ID_LOADING });
        }
        axios({
            headers: {
                'Access-Token': accessToken,
            },
            method: 'GET',
            url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/task/${id}`,
        })
            .then(response => {
                if (response.data.success) {
                    dispatch({
                        type: types.GET_TASK_BY_ID_SUCCESS,
                        data: response.data.data,
                        success: response.data.success,
                        errors: response.data.errors,
                    });
                    callback();
                } else {
                    utils.showError(response.data.errors[0]);
                }
            })
            .catch(errors => {
                console.log(errors.response);
            });
    };

export const getEngineers = projectId => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    dispatch({ type: types.GET_ENGINEERS_LOADING });
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}project/${projectId}/task-engineer`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_ENGINEERS,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getFreeTimes = (projectId, id, date, index, freeTimes) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    dispatch({ type: types.GET_FREE_TIMES_LOADING, index: index });
    return axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}project/${projectId}/task-engineer/${id}/free-times?check_date=${date}`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_FREE_TIMES,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                    index: index,
                });

                return true;
            } else {
                utils.showError(response.data.errors[0]);

                if (freeTimes) {
                    dispatch({
                        type: types.GET_FREE_TIMES,
                        data: freeTimes.data,
                        success: freeTimes.success,
                        errors: freeTimes.errors,
                        index: index,
                    });
                }

                return false;
            }
        })
        .catch(errors => {
            console.log(errors.response);

            return false;
        });
};

export const getAuditors = (projectId, structureId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/auditor`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_AUDITORS,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getDictionary = id => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    const url = !id ? `${apiConfig.url}dictionary` : `${apiConfig.url}dictionary/${id}`;
    dispatch({
        type: types.GET_DICTIONARY_LOADING,
    });
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: url,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_DICTIONARY,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getWorkSections = () => dispatch => {
    let accessToken = localStorage.getItem('accessToken');

    dispatch({
        type: types.GET_WORK_SECTION_LOADING,
    });
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}dictionary/work-sections`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_WORK_SECTION,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getWorkTypes = id => dispatch => {
    let accessToken = localStorage.getItem('accessToken');

    dispatch({
        type: types.GET_WORK_TYPES_LOADING,
    });
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}dictionary/work-types/${id}`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_WORK_TYPES,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getWorkStages = id => dispatch => {
    let accessToken = localStorage.getItem('accessToken');

    dispatch({
        type: types.GET_WORK_STAGES_LOADING,
    });
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}dictionary/work-stages/${id}`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_WORK_STAGES,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getTasksAuthors = projectId => dispatch => {
    let accessToken = localStorage.getItem('accessToken');

    dispatch({
        type: types.GET_TASKS_AUTHORS_LOADING,
    });

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}project/${projectId}/task-authors`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_TASKS_AUTHORS,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const changeTaskStatus = (projectId, structureId, taskId, stageId, status, callback, comment, state) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    // dispatch({ type: types.CHANGE_TASK_STATUS_LOADING });

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/task/${taskId}/stage/${stageId}`,
        data: {
            state: status,
            comment: comment || null,
            lastState: state,
        },
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_TASK_BY_ID_SUCCESS,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
                callback();
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const changeTask =
    (projectId, structureId, taskId, data, callback = () => {}) =>
    dispatch => {
        let accessToken = localStorage.getItem('accessToken');
        // dispatch({ type: types.GET_TASK_BY_ID_LOADING });
        return axios({
            headers: {
                'Access-Token': accessToken,
            },
            method: 'POST',
            url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/task/${taskId}`,
            data: {
                work_section_id: data.work_section_id,
                work_type_id: data.work_type_id,
                work_stage_ids: data.work_stage_ids,
                engineer_id: data.engineer_id,
                review_datetimes: data.review_datetimes,
            },
        })
            .then(response => {
                if (response.data.success) {
                    dispatch({
                        type: types.GET_TASK_BY_ID_SUCCESS,
                        data: response.data.data,
                        success: response.data.success,
                        errors: response.data.errors,
                    });
                    setTimeout(() => {
                        dispatch(getTaskHistory(projectId, structureId, taskId));
                    }, 3000);
                    callback();
                    return response.data.data;
                } else {
                    utils.showError(response.data.errors[0]);

                    return false;
                }
            })
            .catch(errors => {
                console.log(errors);
            });

        return false;
    };

export const changeStageLock = (projectId, structureId, taskId, stageId, status) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/task/${taskId}/stage/${stageId}/lock`,
        data: {
            is_block: status,
        },
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_TASK_BY_ID_SUCCESS,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
                callback();
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const setTask = data => dispatch => {
    dispatch({
        type: types.SET_TASK,
        data: data,
    });
};

export const uploadTaskFile =
    (projectId, structureId, taskId, uploadFile, callback = () => {}) =>
    dispatch => {
        let accessToken = localStorage.getItem('accessToken');
        axios({
            headers: {
                'Access-Token': accessToken,
                'content-type': 'multipart/form-data',
            },
            method: 'POST',
            url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/task/${taskId}/load-file`,
            data: uploadFile,
        })
            .then(response => {
                if (response.data.success) {
                    callback(response.data.data);
                    dispatch({
                        type: types.UPLOAD_TASK_FILE,
                        data: response.data.data,
                        success: response.data.success,
                        errors: response.data.errors,
                    });
                } else {
                    utils.showError(response.data.errors[0]);
                }
            })
            .catch(errors => {
                console.log(errors.response);
            });
    };

export const deleteTaskFile = (projectId, structureId, taskId, fileId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'DELETE',
        url: `${apiConfig.url}task/${taskId}/file/${fileId}`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.DELETE_TASK_FILE,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
                dispatch(changeTask(projectId, structureId, taskId, {}));
                // dispatch(getTaskById(projectId, structureId, taskId));
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getRetentionUnits = () => dispatch => {
    let accessToken = localStorage.getItem('accessToken');

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}retention-unit/index`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_RETENTION_UNITS,
                    data: response.data.data.units,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getTaskHistory = (projectId, structureId, taskId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');

    if (callHistory) {
        callHistory.cancel();
    }
    callHistory = axios.CancelToken.source();

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/task/${taskId}/history`,
        cancelToken: callHistory.token,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_TASK_HISTORY,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};
export const archiveTask = (projectId, structureId, taskId, goBackAction) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'DELETE',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/task/${taskId}`,
    })
        .then(response => {
            if (response.data.success) {
                message.info(response.data.data.message);
                dispatch({ type: types.SUCCESS_TASK_ARCHIVE });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};
export const resetArchiveStatus = () => dispatch => {
    dispatch({ type: types.RESET_ARCHIVE_STATUS });
};

export const restoreTask = (projectId, structureId, taskId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/task/${taskId}/restore`,
    })
        .then(response => {
            if (response.data.success) {
                // message.info(response.data.data[0]);
                dispatch(getTaskById(projectId, structureId, taskId));
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const setTasksFilters = data => dispatch => {
    dispatch({ type: types.SET_TASKS_FILTERS, data: data });
};

export const createTaskComment = (id, comment) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}task/${id}/comment`,
        data: {
            content: comment,
        },
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.CREATE_TASK_COMMENT,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors);
        });
};

export const resetTaskStore = () => dispatch => {
    dispatch({ type: types.RESET_TASK });
};
