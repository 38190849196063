import * as types from '../../actionTypes';

const initialState = {
    documentNotesList: {
        data: [],
        success: false,
        errors: [],
        loading: false,
    },
    notesList: {
        data: null,
        success: false,
        errors: [],
        loading: false,
    },
    notesFiles: {
        data: null,
        success: false,
        errors: [],
        loading: false,
    },
    executors: {
        data: [],
        success: false,
        errors: [],
    },
    counterparties: {
        data: [],
        success: false,
        errors: [],
    },
    auditors: {
        data: [],
        success: false,
        errors: [],
    },
    singleNote: {
        data: null,
        success: false,
        errors: [],
        loading: false,
    },
    copiedNote: {
        data: null,
    },
    dictionary: {
        data: [],
        success: false,
        errors: [],
        loading: false,
    },
    workTypes: {
        data: [],
        success: false,
        errors: [],
        loading: false,
    },
    authors: {
        data: [],
        success: false,
        errors: [],
        loading: false,
    },
    noteNumber: {
        data: [],
        success: false,
        errors: [],
    },
    uploadedFile: {
        data: [],
        success: false,
        errors: [],
    },
    deletedFile: {
        data: [],
        success: false,
        errors: [],
    },
    retentionUnits: {
        data: [],
        success: false,
        errors: '',
    },
    history: {
        data: [],
        success: false,
        errors: [],
    },
    inspectionNotes: {
        data: [],
        success: false,
        errors: '',
        loading: false,
    },
    isNoteArchived: false,
    notesFilters: null,
    workType: null,
    isChangeNote: false,
    isNotesChangeStatusLoading: false,
};

export const getNotes = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_INSPECTION_NOTES_LOADING:
            return {
                ...state,
                inspectionNotes: {
                    data: [],
                    success: false,
                    errors: '',
                    loading: true,
                },
            };

        case types.GET_INSPECTION_NOTES:
            return {
                ...state,
                inspectionNotes: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };

        case types.GET_DOCUMENT_NOTES_SUCCESS:
            return {
                ...state,
                documentNotesList: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };

        case types.CHANGE_DOCUMENT_NOTES_LIST:
            return {
                ...state,
                documentNotesList: {
                    ...state.documentNotesList,
                    data: action.data,
                },
            };

        case types.GET_DOCUMENT_NOTES_LOADING:
            return {
                ...state,
                documentNotesList: {
                    data: [],
                    success: false,
                    errors: [],
                    loading: true,
                },
            };

        case types.GET_NOTES_SUCCESS:
            return {
                ...state,
                notesList: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };

        case types.GET_NOTES_LOADING:
            return {
                ...state,
                notesList: {
                    ...state.notesList,
                    loading: true,
                },
            };

        case types.GET_COUNTERPARTIES:
            return {
                ...state,
                counterparties: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };

        case types.SET_NOTES:
            return {
                ...state,
                notesList: {
                    ...state.notesList,
                    data: {
                        ...state.notesList.data,
                        notes: action.data,
                    },
                },
            };

        case types.GET_NOTES_FILES_SUCCESS:
            return {
                ...state,
                notesFiles: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };

        case types.GET_NOTES_FILES_LOADING:
            return {
                ...state,
                notesFiles: {
                    ...state.notesFiles,
                    loading: true,
                },
            };

        case types.GET_NOTE_BY_ID_LOADING:
            return {
                ...state,
                singleNote: {
                    data: null,
                    success: false,
                    errors: [],
                    loading: true,
                },
            };

        case types.GET_NOTE_BY_AUDITORS_ARRAY:
            return {
                ...state,
                singleNote: {
                    ...state.singleNote,
                    data: { ...state.singleNote.data, noteAudits: action.data.noteAudits },
                },
            };

        case types.GET_NOTE_BY_ID_SUCCESS:
            return {
                ...state,
                singleNote: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };
        case types.SET_NOTE:
            return {
                ...state,
                singleNote: {
                    ...state.singleNote,
                    data: action.data,
                },
            };

        case types.SET_COPIED_NOTE:
            return {
                ...state,
                copiedNote: {
                    data: action.data,
                },
            };
        case types.CHANGE_NOTE_STATUS_LOADING:
            return {
                ...state,
                singleNote: {
                    data: null,
                    success: false,
                    errors: [],
                    loading: true,
                },
            };

        case types.CHANGE_NOTE_STATUS_SUCCESS:
            return {
                ...state,
                singleNote: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };

        case types.GET_EXECUTORS_LOADING:
        case types.GET_FILTRED_EXECUTORS_LOADING:
        case types.GET_QUERY_FILTRED_EXECUTORS_LOADING:
            return {
                ...state,
                singleNote: {
                    ...state.singleNote,
                    data: { ...state.singleNote.data, executorsLoading: true },
                },
            };

        case types.GET_EXECUTORS_SUCCESS:
        case types.GET_QUERY_FILTRED_EXECUTORS_SUCCESS:
        case types.GET_FILTRED_EXECUTORS_SUCCESS:
            return {
                ...state,
                executors: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                },
                singleNote: {
                    ...state.singleNote,
                    data: { ...state.singleNote.data, executorsLoading: false },
                },
            };

        case types.GET_AUDITORS:
        case types.GET_FILTRED_AUDITORS:
            return {
                ...state,
                auditors: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                },
            };

        case types.CLEAR_AUDITORS:
            return {
                ...state,
                auditors: {
                    data: [],
                    success: false,
                    errors: [],
                },
            };

        case types.GET_DICTIONARY_LOADING:
            return {
                ...state,
                dictionary: {
                    ...state.dictionary,
                    loading: true,
                },
            };

        case types.GET_DICTIONARY:
        case types.GET_FILTRED_DICTIONARY:
            return {
                ...state,
                dictionary: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };

        case types.GET_AUTHORS:
            return {
                ...state,
                authors: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };
        case types.GET_FILTERED_AUTHORS:
            return {
                ...state,
                authors: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };
        case types.GET_AUTHORS_LOADING:
            return {
                ...state,
                authors: {
                    data: [],
                    loading: true,
                },
            };
        case types.GET_NUMBER:
            return {
                ...state,
                noteNumber: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                },
            };
        case types.UPLOAD_NOTE_FILE:
            return {
                ...state,
                uploadedFile: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                },
            };
        case types.DELETE_NOTE_FILE:
            return {
                ...state,
                deletedFile: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                },
            };
        case types.GET_RETENTION_UNITS:
            return {
                ...state,
                retentionUnits: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                },
            };
        case types.GET_NOTE_HISTORY:
            return {
                ...state,
                history: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                },
            };
        case types.SUCCESS_NOTE_ARCHIVE:
            return {
                ...state,
                isNoteArchived: true,
            };
        case types.RESET_ARCHIVE_STATUS:
            return {
                ...state,
                isNoteArchived: false,
            };
        case types.SET_NOTES_FILTERS:
            return {
                ...state,
                notesFilters: action.data,
            };
        case types.CREATE_NOTE_COMMENT:
            return {
                ...state,
                singleNote: {
                    ...state.singleNote,
                    data: { ...state.singleNote.data, noteComments: action.data },
                },
            };
        case types.GET_NOTE_WORK_TYPES:
            return {
                ...state,
                workTypes: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                    loading: false,
                },
            };
        case types.GET_NOTE_WORK_TYPES_LOADING:
            return {
                ...state,
                workTypes: {
                    ...state.workTypes,
                    loading: true,
                },
            };
        case types.GET_NOTE_WORK_TYPES_RESET:
            return {
                ...state,
                workTypes: {
                    ...state.workTypes,
                    data: [],
                    loading: false,
                },
            };
        case types.GET_CONTRACTS_LOADING:
            return {
                ...state,
                singleNote: {
                    ...state.singleNote,
                    data: { ...state.singleNote.data, contracts: action.data, contractsLoading: true },
                },
            };
        case types.GET_CONTRACTS_SUCCESS:
            return {
                ...state,
                singleNote: {
                    ...state.singleNote,
                    data: { ...state.singleNote.data, contracts: action.data, contractsLoading: false },
                },
            };

        case types.CHANGE_NOTE_LOADING:
            return {
                ...state,
                isChangeNote: true,
            };
        case types.CHANGE_NOTE_SUCCESS:
            return {
                ...state,
                isChangeNote: false,
            };
        case types.NOTES_STATUS_CHANGE_LOADING:
            return {
                ...state,
                isNotesChangeStatusLoading: !state.isNotesChangeStatusLoading,
            };
        default:
            return state;
    }
};
