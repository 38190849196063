import React from 'react';
import { Icon, Modal } from 'antd';
import { Loading } from '../../Loading';

const ModalDictionary = ({ data, visible, getDictionary, setHide, setItem, currentItemId }) => {
    const handleBack = e => {
        e.preventDefault();
        getDictionary(data.data.breadcrumbs[data.data.breadcrumbs.length - 1].id);
    };

    const handleChoose = (e, item) => {
        e.preventDefault();

        // if (item.type !== 'paragraph') {
        //     getDictionary(item.id);
        // } else {
        setItem(item);
        setHide();
        // }
    };

    return (
        <Modal
            title={
                <>
                    {data.success && data.data.breadcrumbs.length > 0 && (
                        <a href="" className="modal__back" onClick={handleBack}>
                            <Icon type="left" />
                        </a>
                    )}
                    <span>Справочник видов работ</span>
                </>
            }
            visible={visible}
            onCancel={setHide}
            footer={null}>
            <ul className="modal-list modal-filter__content">
                <>
                    {data.loading ? (
                        <div className="modal__loading-wrap">
                            <Loading />
                        </div>
                    ) : (
                        data.success &&
                        data.data.children.map(item => (
                            <li key={item.id}>
                                <a
                                    href=""
                                    className={currentItemId === item.id ? 'modal-list__item modal-list__item_active' : 'modal-list__item'}
                                    onClick={e => handleChoose(e, item)}>
                                    {/* <span className="modal-item__icon">
                                        <Icon type={item.type === 'folder' ? 'folder' : 'file'} theme="outlined" />
                                    </span> */}
                                    <span>{item.title}</span>
                                </a>
                            </li>
                        ))
                    )}
                </>
            </ul>
        </Modal>
    );
};

export default ModalDictionary;
