import React from 'react';
import { Col } from 'antd';
import { ChartNotesCount, ChartAuthors, ChartExecutors } from './Components';
import { connect } from 'react-redux';
import { getAnalytics, resetAnalytics } from '../../redux/actions/projectModule';
import { Select } from 'antd';

import { getPermissions } from '../../redux/actions/common';
import utils from '../../helpers/utils';

import './ProjectAnalytics.scss';

const { Option } = Select;

class ProjectAnalytics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedType: 0,
            showFields: false,
        };

        this.onSelectChange = this.onSelectChange.bind(this);
    }

    onSelectChange = value => {
        this.props.resetAnalytics();
        this.setState({
            selectedType: value,
        });
    };

    checkPermission = permission => {
        let analyticPermission = permission.find(item => item.name === 'page.view.reports');

        if (analyticPermission.isAllowed) {
            this.setState({
                showFields: true,
            });
        } else {
            utils.showError('Недостаточно прав для совершения операции');
        }
    };

    getPermission = () => {
        this.props
            .getPermission(this.props.match.params.projectId)
            .then(response => {
                this.checkPermission(response.data.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    handleGetAnalitics = date => {
        const { getAnalytics } = this.props;
        const { projectId } = this.props.match.params;

        let url = '',
            data = {};

        switch (this.state.selectedType) {
            case 0:
                url = projectId ? 'consolidated' : 'analytics';
                data = {
                    date_start: `${date[0]} 08:00:00`,
                    date_end: `${date[1]} 08:00:00`,
                };
                break;
            case 1:
                url = projectId ? 'user-rating-all-notes' : 'analytics-rating-all-notes';
                data = {
                    date_end: `${date} 08:00:00`,
                };
                break;
            case 2:
                url = projectId ? 'user-rating-reopen-notes' : 'analytics-rating-reopen-notes';
                data = {
                    date_start: `${date[0]} 08:00:00`,
                    date_end: `${date[1]} 08:00:00`,
                };
                break;
            default:
                break;
        }

        getAnalytics(projectId, data, url);
    };

    renderChart = (type, analytics) => {
        switch (type) {
            case 0:
                return <ChartNotesCount handleGetAnalitics={this.handleGetAnalitics} data={analytics} />;
            case 1:
                return <ChartAuthors handleGetAnalitics={this.handleGetAnalitics} data={analytics} />;
            case 2:
                return <ChartExecutors handleGetAnalitics={this.handleGetAnalitics} data={analytics} />;
            default:
                return null;
        }
    };

    componentDidMount() {
        this.getPermission();
    }

    render() {
        const { selectedType, showFields } = this.state;

        if (!showFields) {
            return null;
        }

        return (
            <Col className="alignCenter">
                <Col className="analyticsView">
                    <div className="alignCenter">
                        <div className="actionsView">
                            <div className="alignCenter">
                                <Select
                                    style={{ width: '95%', maxWidth: '600px' }}
                                    placeholder="Выберите тип"
                                    optionFilterProp="children"
                                    onChange={this.onSelectChange}
                                    defaultValue={0}>
                                    <Option value={0}>Сводный график по количеству замечаний</Option>
                                    <Option value={1}>Рейтинг авторов по общему количеству замечаний</Option>
                                    <Option value={2}>Рейтинг исполнителей по повторно открытым замечаниям за выбранный период</Option>
                                </Select>
                            </div>

                            {this.renderChart(selectedType, this.props.analytics)}
                        </div>
                    </div>
                </Col>
            </Col>
        );
    }
}

const mapStateToProps = state => ({
    analytics: state.projectModule.analytics,
});

const mapDispatchToProps = dispatch => ({
    getAnalytics: (projectId, data, url) => dispatch(getAnalytics(projectId, data, url)),
    resetAnalytics: () => dispatch(resetAnalytics()),
    getPermission: projectId => dispatch(getPermissions(projectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAnalytics);
