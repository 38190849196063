import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Empty } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import { deleteProject, changeFavorite, setProjects } from '../../redux/actions/projectModule';

import Placeholder from '../../assets/placeholder.png';
import './ProjectsList.scss';

class ProjectsList extends React.Component {
    // handleFavorite = (section, itemId, isFavorite) => {
    //     this.props.changeFavorite(section, itemId, isFavorite, this.props.favoriteAction);
    // };

    handleFavorite = (section, itemId, isFavorite) => {
        let copyData = [...this.props.data];

        const itemIndex = copyData.findIndex(item => item.id === itemId);
        if (itemIndex > -1) {
            copyData[itemIndex].is_favorite = !copyData[itemIndex].is_favorite;
        }

        this.props.setProjects(copyData);
        this.props
            .changeFavorite(section, itemId, isFavorite)
            .then(response => {
                // console.log('handleFavorite -> response', response);
            })
            .catch(error => {
                copyData = [...this.props.data];
                // copyData.is_favorite = !copyData.is_favorite;
                const itemIndex = copyData.findIndex(item => item.id === itemId);
                if (itemIndex > -1) {
                    copyData[itemIndex].is_favorite = !copyData[itemIndex].is_favorite;
                    this.props.setProjects(copyData);
                }
            });
    };

    render() {
        const { data, deleteProject } = this.props;

        return (
            <>
                {!data || data.length === 0 ? (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Нет данных" />
                ) : (
                    data.map(item => (
                        <li className="projects__item" key={item.id}>
                            {/* <div className="projects__tools">
                                <div className="projects__tools-icon">
                                    <div className="projects__tools-iconItem"></div>
                                    <div className="projects__tools-iconItem"></div>
                                    <div className="projects__tools-iconItem"></div>
                                </div>
                                <ul className="projects__tools-nav">
                                    <a
                                        className="projects__tools-navItem"
                                        onClick={() => {
                                            deleteProject(item.id);
                                        }}>
                                        Удалить проект
                                    </a>
                                </ul>
                            </div> */}
                            <Link to={`projects/${item.id}/structure/${item.root_structure_id}`}>
                                <div className={!item.deleted_at ? 'projects__data' : 'projects__data projects__data_deleted'}>
                                    <div className="projects__image">
                                        <img src={item.file ? item.file.thumb : Placeholder} />
                                    </div>
                                    <div className="projects__description">
                                        <div className="projects__title">{item.title}</div>
                                        <div className="projects__dates">
                                            {item.date_start &&
                                                item.date_deadline &&
                                                moment(item.date_start).format('DD.MM.YYYY') +
                                                    ' - ' +
                                                    moment(item.date_deadline).format('DD.MM.YYYY')}
                                        </div>
                                    </div>
                                    <ul className="projects__labels">
                                        <li className="projects__label danger">{item.notes_cnt_danger}</li>
                                        <li className="projects__label warning">{item.notes_cnt_warning}</li>
                                        <li className="projects__label success">{item.notes_cnt_success}</li>
                                    </ul>
                                    <div className="projects__arrow">
                                        <Icon type="right" theme="outlined" style={{ color: '#666666' }} />
                                    </div>
                                </div>
                            </Link>
                            {/* <Button
                                title={'Избранное'}
                                type={'link'}
                                onClick={() => this.handleFavorite('project', item.id, item.is_favorite)}
                                className={item.is_favorite ? 'button-favorite button-favorite_active' : 'button-favorite'}>
                                <Icon type="star" theme="filled" />
                            </Button> */}
                        </li>
                    ))
                )}
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteProject: function (itemId) {
            return dispatch(deleteProject(itemId));
        },
        changeFavorite: function (section, itemId, isFavorite, callback) {
            return dispatch(changeFavorite(section, itemId, isFavorite, callback));
        },
        setProjects: function (data) {
            return dispatch(setProjects(data));
        },
    };
}

export default connect(null, mapDispatchToProps)(ProjectsList);
