import React, { useState, useEffect } from 'react';
import { Input, Modal, Button } from 'antd';

const ModalNotAcceptedStage = ({ visible, setHide, handleOkay }) => {
    const [comment, setComment] = useState('');

    useEffect(() => {
        if (!visible) {
            setComment('');
        }
    }, [visible]);

    const handleChangeComment = e => {
        setComment(e.target.value);
    };

    const handleOk = () => {
        handleOkay(comment);
        setHide();
    };

    return (
        <Modal
            title={<span>Комментарий</span>}
            visible={visible}
            onCancel={setHide}
            footer={[
                <Button key="submit" type="primary" onClick={handleOk} disabled={!comment}>
                    Готово
                </Button>,
                <Button key="submit" type="primary" onClick={setHide}>
                    Отмена
                </Button>,
            ]}>
            <div className="">
                <Input placeholder="Комментарий" value={comment} onChange={handleChangeComment} />
            </div>
        </Modal>
    );
};

export default ModalNotAcceptedStage;
