import React, { Component, PureComponent } from 'react';
import { ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart, Line, Surface, Symbols } from 'recharts';
import { Empty } from 'antd';
import moment from 'moment';

class Chart2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeLegend: {
                open_notes_count: true,
                inWork_notes_count: true,
                completed_notes_count: true,
                all_notes_count: true,
                expired_notes_count: true,
            },
        };
    }

    getData = data => {
        const changedData = [];

        if (data.labels) {
            data.labels.forEach((item, i) => {
                changedData.push({
                    name: moment(item, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                    open_notes_count: data.open_notes_count.data[i],
                    inWork_notes_count: data.inWork_notes_count.data[i],
                    completed_notes_count: data.completed_notes_count.data[i],
                    all_notes_count: data.all_notes_count.data[i],
                    expired_notes_count: data.expired_notes_count.data[i],
                });
            });
        }

        return changedData;
    };

    handleClick = dataKey => {
        this.setState(state => {
            return {
                activeLegend: { ...state.activeLegend, [dataKey]: !state.activeLegend[dataKey] },
            };
        });
    };

    renderCusomizedLegend = ({ payload }) => {
        return (
            <div className="customized-legend" style={{ marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
                {payload.map(entry => {
                    const { dataKey, color, value } = entry;
                    const active = this.state.activeLegend[dataKey];

                    const style = {
                        marginRight: 15,
                        color: !active ? '#AAA' : '#333',
                    };

                    return (
                        <span key={dataKey} className="legend-item" onClick={() => this.handleClick(dataKey)} style={style}>
                            <Surface width={15} height={10}>
                                <Symbols cx={5} cy={5} type="square" size={50} fill={color} />
                                {!active && <Symbols cx={5} cy={5} type="square" size={25} fill={'#FFF'} />}
                            </Surface>
                            <span>{value}</span>
                        </span>
                    );
                })}
            </div>
        );
    };

    render() {
        const { data } = this.props.data;

        const { activeLegend } = this.state;

        if (!this.props.data) {
            return null;
        }

        const cData = this.getData(data);

        if (cData.length) {
            return (
                <div style={{ width: '100%', height: '80vh' }}>
                    <ResponsiveContainer>
                        <LineChart
                            width={500}
                            height={300}
                            data={cData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 25,
                            }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" angle={-30} x={0} y={0} dx={0} dy={16} tick={{ fontSize: 12 }} />
                            <YAxis />
                            <Tooltip />
                            <Legend
                                verticalAlign="top"
                                margin={{ bottom: 10 }}
                                content={this.renderCusomizedLegend}
                                payload={[
                                    {
                                        dataKey: 'open_notes_count',
                                        color: data.open_notes_count.color,
                                        value: data.open_notes_count.label,
                                    },
                                    {
                                        dataKey: 'inWork_notes_count',
                                        color: data.inWork_notes_count.color,
                                        value: data.inWork_notes_count.label,
                                    },
                                    {
                                        dataKey: 'completed_notes_count',
                                        color: data.completed_notes_count.color,
                                        value: data.completed_notes_count.label,
                                    },
                                    {
                                        dataKey: 'all_notes_count',
                                        color: data.all_notes_count.color,
                                        value: data.all_notes_count.label,
                                    },
                                    {
                                        dataKey: 'expired_notes_count',
                                        color: data.expired_notes_count.color,
                                        value: data.expired_notes_count.label,
                                    },
                                ]}
                            />
                            {activeLegend['open_notes_count'] && (
                                <Line
                                    type="monotone"
                                    dataKey="open_notes_count"
                                    stroke={data.open_notes_count.color}
                                    name={data.open_notes_count.label}
                                />
                            )}
                            {activeLegend['inWork_notes_count'] && (
                                <Line
                                    type="monotone"
                                    dataKey="inWork_notes_count"
                                    stroke={data.inWork_notes_count.color}
                                    name={data.inWork_notes_count.label}
                                />
                            )}
                            {activeLegend['completed_notes_count'] && (
                                <Line
                                    type="monotone"
                                    dataKey="completed_notes_count"
                                    stroke={data.completed_notes_count.color}
                                    name={data.completed_notes_count.label}
                                />
                            )}
                            {activeLegend['all_notes_count'] && (
                                <Line
                                    type="monotone"
                                    dataKey="all_notes_count"
                                    stroke={data.all_notes_count.color}
                                    name={data.all_notes_count.label}
                                />
                            )}
                            {activeLegend['expired_notes_count'] && (
                                <Line
                                    type="monotone"
                                    dataKey="expired_notes_count"
                                    stroke={data.expired_notes_count.color}
                                    name={data.expired_notes_count.label}
                                />
                            )}
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            );
        }

        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Данных нет" />;
    }
}

export default Chart2;
