import React from 'react';
import { Circle } from 'react-konva';

import { Drawable } from './Drawable';

class CircleDrawable extends Drawable {
    constructor(startx, starty, type, id) {
        super(startx, starty, type, id);
        this.x = startx;
        this.y = starty;
    }

    render(i) {
        const dx = this.startx - this.x;
        const dy = this.starty - this.y;
        const radius = Math.sqrt(dx * dx + dy * dy);

        return <Circle key={`circle-${i}`} radius={radius} x={this.startx} y={this.starty} stroke="#f00000" />;
    }
}

export default CircleDrawable;
