import React, { Component } from 'react';
import { DatePicker } from 'antd';
import Bar2 from './Bar2';
import moment from 'moment';
const { RangePicker } = DatePicker;

export default class ChartExecutors extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedDate: null,
        };

        this.defaultDate = [moment().subtract(6, 'days'), moment()];
    }

    changeDate = (date, dateString) => {
        // get data
        this.setState({
            selectedDate: date,
        });
        this.props.handleGetAnalitics([date[0].format('YYYY-MM-DD'), date[1].format('YYYY-MM-DD')]);
    };

    componentDidMount() {
        this.changeDate(this.defaultDate);
    }

    render() {
        const { selectedDate } = this.state;
        const today = moment(new Date(new Date().setHours(23, 59, 59, 0)));

        return (
            <div>
                <div className="alignCenter mt32">
                    <span style={{ textAlign: 'center' }}>
                        График показывает суммарное количество замечаний, находящихся в статусе "Повторно открыто", по Исполнителям
                        замечаний за выбранный период
                    </span>
                </div>
                <div className="alignCenter mt32">
                    <RangePicker
                        onChange={this.changeDate}
                        allowClear={false}
                        placeholder={['Начало', 'Конец']}
                        disabledDate={value => {
                            return value > today;
                        }}
                        format="DD.MM.YYYY"
                        defaultValue={this.defaultDate}
                    />
                </div>

                {selectedDate && this.props.data.success && (
                    <div className="mt32">
                        <Bar2 data={this.props.data} />
                    </div>
                )}
            </div>
        );
    }
}
