import React from 'react';

const StatusFilters = props => {
    const { openStatusIsActive, inworkStatusIsActive, completedStatusIsActive, data, onHandleStatusChange } = props;

    return (
        <>
            <div className="status-filter">
                <a
                    href=""
                    className="status-filter__item"
                    style={{ color: openStatusIsActive && 'rgba(0, 0, 0, .65)' }}
                    onClick={e => onHandleStatusChange(e, 1)}>
                    <span className="status-filter__title">Открытые</span>
                    <span
                        className="status-filter__count"
                        style={{
                            borderColor: openStatusIsActive && '#ed2d23',
                        }}>
                        {data.noteCount.notes_cnt_danger}
                    </span>
                </a>
                <a
                    href=""
                    className="status-filter__item"
                    style={{ color: inworkStatusIsActive && 'rgba(0, 0, 0, .65)' }}
                    onClick={e => onHandleStatusChange(e, 2)}>
                    <span className="status-filter__title">Выполненные</span>
                    <span
                        className="status-filter__count"
                        style={{
                            borderColor: inworkStatusIsActive && '#f2c94c',
                        }}>
                        {data.noteCount.notes_cnt_warning}
                    </span>
                </a>
                <a
                    href=""
                    className="status-filter__item"
                    style={{ color: completedStatusIsActive && 'rgba(0, 0, 0, .65)' }}
                    onClick={e => onHandleStatusChange(e, 3)}>
                    <span className="status-filter__title">Проверенные</span>
                    <span
                        className="status-filter__count"
                        style={{
                            borderColor: completedStatusIsActive && '#27ae60',
                        }}>
                        {data.noteCount.notes_cnt_success}
                    </span>
                </a>
            </div>
        </>
    );
};

export default StatusFilters;
