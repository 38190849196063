import axios from 'axios';
import * as types from '../../actionTypes';
import { apiConfig } from '../../../services/api/config';
import utils from '../../../helpers/utils';

export const getTeam = (projectId, structureId, callback = () => {}) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    const url =
        !projectId && !structureId
            ? `${apiConfig.url}common/roles`
            : `${apiConfig.url}project/${projectId}/project-structure/${structureId}/roles`;
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: url,
    })
        .then(response => {
            dispatch({
                type: types.GET_TEAM,
                data: response.data.data,
                success: response.data.success,
                errors: response.data.errors,
            });
            callback();
            if (!response.data.success) {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors);
        });
};

export const getOrganizations = () => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}organization`,
    })
        .then(response => {
            dispatch({
                type: types.GET_ORGANIZATIONS,
                data: response.data.data,
                success: response.data.success,
            });
        })
        .catch(errors => {
            console.log(errors);
        });
};

export const getFiltredOrganizations = (projectId, structureId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    const url = !structureId
        ? `${apiConfig.url}project/${projectId}/organization-list-for-filter`
        : `${apiConfig.url}project/${projectId}/organization-list-for-filter?structureId=${structureId}`;

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: url,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_FILTRED_ORGANIZATIONS,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getCounterpartyList = (projectId, structureId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    const url = !structureId
        ? `${apiConfig.url}project/${projectId}/counterparty-list-for-filter`
        : `${apiConfig.url}project/${projectId}/counterparty-list-for-filter?structureId=${structureId}`;

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: url,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_COUNTERPARTY_LIST,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const clearOrganizations = () => dispatch => {
    dispatch({ type: types.CLEAR_ORGANIZATIONS });
};

export const getUsers = organizationId => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}organization/${organizationId}/users`,
    })
        .then(response => {
            dispatch({
                type: types.GET_USERS,
                data: response.data.data,
                success: response.data.success,
                errors: response.data.errors,
            });
            if (!response.data.success) {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors);
        });
};

export const assignOrganization = (projectId, structureId, role, organizationId, callback = () => {}) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    dispatch({ type: types.ASSIGN_ORGANIZATION_LOADING });

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/roles/organization`,
        data: {
            role_name: role,
            organization_id: organizationId,
        },
    })
        .then(response => {
            dispatch({
                type: types.ASSIGN_ORGANIZATION_SUCCESS,
                data: response.data.data,
                success: response.data.success,
                errors: response.data.errors,
            });

            if (response.data.success) {
                dispatch(getTeam(projectId, structureId, callback));
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors);
        });
};

export const assignUser = (projectId, structureId, role, userId, organizationId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');

    return axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/roles`,
        data: {
            role_name: role,
            user_id: userId,
            organization_id: organizationId,
        },
    })
        .then(response => {
            dispatch({
                type: types.ASSIGN_USER,
                data: response.data.data,
                success: response.data.success,
                errors: response.data.errors,
            });

            return response;
        })
        .catch(errors => {
            console.log(errors);
            return errors;
        });
};

export const deleteOrganization = (projectId, structureId, role, organizationId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/roles/${role}/organization/${organizationId}`,
    })
        .then(response => {
            dispatch({
                type: types.DELETE_ORGANIZATION,
                data: response.data.data,
                success: response.data.success,
                errors: response.data.errors,
            });

            if (response.data.success) {
                dispatch(getTeam(projectId, structureId));
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors);
        });
};

export const deleteUser = (projectId, structureId, role, userId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/roles/${role}/user/${userId}`,
    })
        .then(response => {
            dispatch({
                type: types.DELETE_USER,
                data: response.data.data,
                success: response.data.success,
                errors: response.data.errors,
            });

            if (response.data.success) {
                dispatch(getTeam(projectId, structureId));
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors);
        });
};
