import React, { Component } from 'react';
import { connect } from 'react-redux';

import { CurrentProject } from '../../components/CurrentProject';

import './ProjectDetails.scss';

class ProjectDetails extends Component {
    render() {
        const { getData, structure, data, ...rest } = this.props;
        const { currentStructure } = structure;

        const rootDocumentId = currentStructure.data && currentStructure.data.root_document_id ? currentStructure.data.root_document_id : 0;

        return (
            <div className="page-project-detail">
                <CurrentProject
                    {...rest}
                    rootDocumentId={rootDocumentId}
                    isLoadingStructure={currentStructure.loading}
                    structure={currentStructure.data}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        structure: {
            currentStructure: state.projectModule.structure.currentItem,
        },
    };
}

export default connect(mapStateToProps)(ProjectDetails);
