import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, Modal } from 'antd';

import { getNoteHistory } from '../../../redux/actions/projectModule';

class NoteHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
        };
    }

    componentDidMount() {
        const { projectId, noteId, getNoteHistory } = this.props;

        getNoteHistory(projectId, noteId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.noteId !== this.props.noteId) {
            this.props.getNoteHistory(this.props.projectId, this.props.noteId);
        }
    }

    toggleVisible = () => {
        this.setState(state => {
            return {
                visible: !state.visible,
            };
        });
    };

    renderHistory() {
        return this.props.history.map((item, i) => (
            <div className="note-history__item" key={`${i}-${item.created_at}`}>
                <div className="note-history__text">{item.content}</div>
                <div className="note-history__info">
                    {item.user.fullname} {moment(item.created_at).format('DD MMMM YYYY HH:mm')}
                </div>
            </div>
        ));
    }

    renderFirstThree() {
        let hist = [];

        if (this.props.history !== null) {
            for (let i = 0; i < this.props.history.length; i++) {
                if (i > 2) break;

                const item = this.props.history[i];

                hist.push(
                    <div className="note-history__item" key={`${i}-${item.created_at}`}>
                        <div className="note-history__text">{item.content}</div>
                        <div className="note-history__info">
                            {item.user.fullname} {moment(item.created_at).format('DD MMMM YYYY HH:mm')}
                        </div>
                    </div>,
                );
            }
        }
        return hist;
    }

    render() {
        const { history } = this.props;

        if (history == null) {
            return null;
        }

        return (
            <div className="mt32">
                <h2>История замечаний</h2>
                <div className="note-history">{this.renderFirstThree()}</div>
                {history.length > 3 && (
                    <>
                        <Button onClick={this.toggleVisible}>Посмотреть всю историю</Button>
                        <Modal
                            visible={this.state.visible}
                            title="История замечаний"
                            onCancel={this.toggleVisible}
                            footer={[
                                <Button key="submit" type="primary" onClick={this.toggleVisible}>
                                    Скрыть историю
                                </Button>,
                            ]}>
                            <div className="note-history">{this.renderHistory()}</div>
                        </Modal>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        history: state.projectModule.notes.history.data,
    };
};

const mapDispatchToProps = dispatch => ({
    getNoteHistory: (projectId, noteId) => dispatch(getNoteHistory(projectId, noteId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoteHistory);
