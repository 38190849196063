import { GET_PERMISSION } from '../../actionTypes';
import { apiConfig } from '../../../services/api/config';
import axios from 'axios';
import utils from '../../../helpers/utils';

export const getPermissions = (projectId = 0) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');

    return axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}project/${projectId}/permission`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: GET_PERMISSION,
                    data: response.data,
                });
                return response;
            } else {
                utils.showError(response.data.errors[0]);
                throw response.data.errors[0];
            }
        })
        .catch(errors => {
            console.log(errors.response);
            throw errors;
        });
};
