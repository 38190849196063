import * as types from '../../actionTypes';

const initialState = {
    user: null,
    loading: false,
    error: null,
};

export const currentUser = (state = initialState, action) => {
    switch (action.type) {
        case types.UPDATE_USER_LOADING:
            return {
                user: null,
                loading: true,
                error: null,
            };

        case types.UPDATE_USER_SUCCESS:
            return {
                user: action.user,
                loading: false,
                error: null,
            };

        case types.UPDATE_USER_ERROR:
            return {
                user: null,
                loading: false,
                error: action.data,
            };

        default:
            return state;
    }
};
