import React, { Component } from 'react';
import { ResponsiveContainer, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Empty } from 'antd';

class Bar2 extends Component {
    render() {
        const { data } = this.props.data;

        if (!this.props.data) {
            return null;
        }

        if (data.reportData.length) {
            return (
                <div style={{ width: '100%', height: data.reportData.length * 60 + 60 }}>
                    <ResponsiveContainer>
                        <BarChart
                            width={600}
                            height={300}
                            data={data.reportData}
                            maxBarSize={30}
                            layout={'vertical'}
                            margin={{ top: 20, right: 35, left: 35, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis type={'number'} orientation={'bottom'} />
                            <YAxis type={'category'} orientation={'left'} dataKey={'fullname'} width={180} tick={{ fontSize: 12 }} />
                            <Bar
                                dataKey={'noteCount'}
                                fill={'rgba(0, 173, 239,0.6)'}
                                label={{ position: 'right', fontSize: 12, fill: '#333' }}
                                name={data.reportTitle}
                            />
                            <Legend verticalAlign="top" height={25} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            );
        }

        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Данных нет" />;
    }
}

export default Bar2;
