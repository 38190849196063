import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getDocuments } from '../../redux/actions/projectModule';
import ProjectDocs from './ProjectDocs';
import ProjectDocViewer from './ProjectDocViewer';
import { Loading } from '../Loading';

class ProjectDocsRout extends Component {
    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps, prevState) {
        const { match } = this.props;
        const { currentSectionItemDetails } = match.params;

        if (prevProps.match.params.currentSectionItemDetails !== currentSectionItemDetails) {
            this.getData();
        }
    }

    getData = () => {
        const { getDocuments } = this.props;
        const { projectId, currentStructureId, currentSectionItemDetails } = this.props.match.params;
        getDocuments(projectId, currentStructureId, currentSectionItemDetails);
    };

    renderDoc = documents => {
        if (!documents.data) {
            return <div></div>;
        }
        switch (documents.data.type) {
            case 'folder':
                return <ProjectDocs {...this.props} />;
            case 'file':
                // console.log(this.props, 'this.props');
                return <ProjectDocViewer {...this.props} />;
            default:
                return (
                    <div className="structure">
                        <Loading />
                    </div>
                );
        }
    };

    render() {
        return this.renderDoc(this.props.documents);
    }
}

function mapStateToProps(state) {
    return {
        documents: state.projectModule.documents,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getDocuments: (projectId, structureId, id) => dispatch(getDocuments(projectId, structureId, id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDocsRout);
