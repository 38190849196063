import axios from 'axios';
import { apiConfig } from '../../services/api/config';

export const getFigures = async documentId => {
    let accessToken = localStorage.getItem('accessToken');
    return await axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}document/${documentId}/figure`,
    })
        .then(response => {
            return response;
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const createFigure = async (documentId, data) => {
    let accessToken = localStorage.getItem('accessToken');
    return await axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}document/${documentId}/figure`,
        data: data,
    })
        .then(response => {
            return response;
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const deleteFigures = async (documentId, data) => {
    let accessToken = localStorage.getItem('accessToken');
    return await axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'DELETE',
        url: `${apiConfig.url}document/${documentId}/figure`,
        data: data,
    })
        .then(response => {
            return response;
        })
        .catch(errors => {
            console.log(errors.response);
        });
};
