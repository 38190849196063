import React from 'react';
import { connect } from 'react-redux';
// import { Icon, Button, Modal, Input, DatePicker } from 'antd';
import { getProjects, createProject, resetCreatedStatus } from '../../redux/actions/projectModule';

import { ProjectsList } from '../../components/ProjectsList';
import { Loading } from '../../components/Loading';

import './Projects.scss';

class Projects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            projectTitle: '',
            projectSubTitle: '',
            projectAddress: '',
            projectDateStart: null,
            projectDateEnd: null,
            confirmLoading: false,
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleInput = this.handleInput.bind(this);
    }
    handleOpen = () => {
        this.setState({
            isModalVisible: true,
            projectTitle: '',
            projectSubTitle: '',
            projectAddress: '',
            projectDateStart: null,
            projectDateEnd: null,
        });
    };
    handleCancel = () => {
        this.setState({
            isModalVisible: false,
        });
    };

    handleConfirm = async () => {
        this.setState({
            confirmLoading: true,
        });

        this.createProject();
    };

    createProject = async () => {
        const { createProject } = this.props,
            { projectTitle, projectSubTitle, projectAddress, projectDateStart, projectDateEnd } = this.state;

        await createProject({
            title: projectTitle,
            title_short: projectSubTitle,
            address: projectAddress,
            date_start: projectDateStart ? projectDateStart.format('YYYY-MM-DD') : '',
            date_deadline: projectDateEnd ? projectDateEnd.format('YYYY-MM-DD') : '',
        })
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        isModalVisible: false,
                        confirmLoading: false,
                    });
                } else {
                    this.setState({
                        confirmLoading: false,
                    });
                }
            })
            .catch(error => {
                console.log('error', error);
                this.setState({
                    confirmLoading: false,
                });
            });
    };

    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    disabledStartDate = projectDateStart => {
        const { projectDateEnd } = this.state;
        if (!projectDateStart || !projectDateEnd) {
            return false;
        }
        return projectDateStart.valueOf() > projectDateEnd.valueOf();
    };

    disabledEndDate = projectDateEnd => {
        const { projectDateStart } = this.state;
        if (!projectDateEnd || !projectDateStart) {
            return false;
        }
        return projectDateEnd.valueOf() <= projectDateStart.valueOf();
    };

    onChange = (field, value) => {
        this.setState({
            [field]: value,
        });
    };

    onStartChange = value => {
        this.onChange('projectDateStart', value);
    };

    onEndChange = value => {
        this.onChange('projectDateEnd', value);
    };

    changeFavorite = () => {
        this.props.getProjects();
    };

    componentDidMount() {
        const { isCreated, resetCreatedStatus, getProjects } = this.props;

        getProjects();

        if (isCreated) {
            resetCreatedStatus();
        }
    }

    render() {
        const { projects, isCreated, getProjects, ...rest } = this.props;
        const { data, success, loading } = projects;
        const {
            isModalVisible,
            projectTitle,
            projectSubTitle,
            projectAddress,
            projectDateStart,
            projectDateEnd,
            confirmLoading,
        } = this.state;

        return (
            <ul>
                {loading ? (
                    <div className="structure">
                        <Loading />
                    </div>
                ) : (
                    <>
                        {/* <div className="projects__toolbar">
                            <Button
                                onClick={() => {
                                    this.handleOpen();
                                }}
                                type="primary">
                                Добавить проект
                            </Button>
                        </div> */}
                        <ProjectsList {...rest} data={data} favoriteAction={this.changeFavorite} />
                    </>
                )}
                {/* <Modal
                    title="Добавление проекта"
                    visible={isModalVisible}
                    onOk={this.handleConfirm}
                    onCancel={this.handleCancel}
                    confirmLoading={confirmLoading}
                    okText="Добавить"
                    cancelText="Отменить">
                    <div>
                        <div className="mt24">
                            <Input
                                onChange={e => {
                                    this.handleInput(e);
                                }}
                                name="projectTitle"
                                placeholder="Наименование проекта"
                                value={projectTitle}
                            />
                        </div>
                        <div className="mt24">
                            <Input
                                onChange={e => {
                                    this.handleInput(e);
                                }}
                                name="projectSubTitle"
                                placeholder="Сокращенное наименование"
                                value={projectSubTitle}
                            />
                        </div>
                        <div className="mt24">
                            <Input
                                onChange={e => {
                                    this.handleInput(e);
                                }}
                                name="projectAddress"
                                placeholder="Адрес"
                                value={projectAddress}
                            />
                        </div>
                        <div className="mt24 flexRow spaceBetween">
                            <DatePicker
                                disabledDate={this.disabledStartDate}
                                format="YYYY-MM-DD"
                                value={projectDateStart}
                                placeholder="Дата начала"
                                onChange={this.onStartChange}
                                className="halfView"
                            />
                            <DatePicker
                                disabledDate={this.disabledEndDate}
                                format="YYYY-MM-DD"
                                value={projectDateEnd}
                                placeholder="Дата окончания"
                                onChange={this.onEndChange}
                                className="halfView"
                            />
                        </div>
                    </div>
                </Modal> */}
            </ul>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProjects: function() {
            return dispatch(getProjects());
        },
        createProject: function(projectTitle) {
            return dispatch(createProject(projectTitle));
        },
        resetCreatedStatus: function(projectTitle) {
            return dispatch(resetCreatedStatus(projectTitle));
        },
    };
}

function mapStateToProps(state) {
    return {
        projects: state.projectModule.projects.projectsList,
        isCreated: state.projectModule.projects.isCreated,
        isDeleted: state.projectModule.projects.isDeleted,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
