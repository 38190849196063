import React from 'react';
import { Input, Icon } from 'antd';

import './SearchForm.scss';

const { Search } = Input;

const SearchForm = (props) => {
    const { searchValue, handleSearchFilter, handleSearchChange, handleSearchReset } = props;

    const onSearch = (value) => {
        handleSearchFilter(value);
    };

    const onChange = (e) => {
        handleSearchChange(e);
    };

    const onReset = () => {
        handleSearchReset();
    };

    return (
        <div className={searchValue.length > 0 ? "search changed" : "search"}>
            <Search
                value={searchValue}
                placeholder="Искать..."
                onSearch={onSearch}
                onChange={onChange}
            />
            {searchValue.length > 0 && (
                <span className="search__close" onClick={onReset}>
                    <Icon type="close" />
                </span>
            )}
        </div>
    );
};

export default SearchForm;
