import React from 'react';

import './Error.scss';

class Error extends React.Component {
    render() {
        return <>Error</>;
    }
}

export default Error;
