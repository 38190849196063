import { GET_USER } from '../../actionTypes';

const initialState = {
    user: {
        data: {},
        errors: null,
        success: false,
    },
};

export const user = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER:
            return {
                ...state,
                user: {
                    data: action.data.data,
                    errors: action.data.errors,
                    success: action.data.success,
                },
            };
        default:
            return state;
    }
};
