import { CREATE_COMMENT } from '../../actionTypes';

const initialState = {
    comment: {
        data: {},
        success: false,
        errors: [],
    },
};

export const createComment = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_COMMENT:
            return {
                ...state,
                comment: {
                    data: action.data,
                    success: action.success,
                    errors: action.errors,
                },
            };
        default:
            return state;
    }
};
