import React from 'react';
import { Icon, Button, Modal, Input, DatePicker, Tabs } from 'antd';
const { TabPane } = Tabs;

import { ProjectAnalytics } from '../../components/ProjectAnalytics';
import { CommonNotes } from '../../components/ProjectNotes';
import { CommonTeam } from '../../components/ProjectTeam';

import { Route } from 'react-router-dom';
import { Projects } from '../Projects';

class CommonPanel extends React.Component {
    getActiveTab = () => {
        const { params } = this.props.match;
        if (params.section) {
            switch (params.section) {
                case 'common-comments':
                    return '1';
                case 'common-team':
                    return '2';
                case 'common-analytics':
                    return '3';
                default:
                    return '0';
            }
        }

        return '0';
    };

    handleTabClick = key => {
        switch (key) {
            case '0':
                this.props.history.push(`/projects`);
                break;
            case '1':
                this.props.history.push(`/projects/common-comments`);
                break;
            case '2':
                this.props.history.push(`/projects/common-team`);
                break;
            case '3':
                this.props.history.push(`/projects/common-analytics`);
                break;

            default:
                break;
        }
    };

    render() {
        const { ...rest } = this.props;

        return (
            <ul className="projects">
                <>
                    <Tabs
                        size="large"
                        onTabClick={this.handleTabClick}
                        defaultActiveKey={this.getActiveTab()}
                        tabBarStyle={{ textAlign: 'center' }}>
                        <TabPane
                            tab={
                                <span>
                                    <Icon type="folder" theme="outlined" />
                                    Проекты
                                </span>
                            }
                            key="0">
                            <Route exact path="/projects" render={() => <Projects {...rest} />} />
                        </TabPane>
                        <TabPane
                            tab={
                                <span>
                                    <Icon type="exclamation-circle" theme="outlined" />
                                    Замечания
                                </span>
                            }
                            key="1">
                            <Route
                                exact
                                path="/projects/common-comments"
                                render={() => <CommonNotes {...rest} currentStructureId={0} rootStructureId={0} common={true} />}
                            />
                        </TabPane>
                        <TabPane
                            tab={
                                <span>
                                    <Icon type="team" theme="outlined" />
                                    Участники
                                </span>
                            }
                            key="2">
                            <CommonTeam {...rest} />
                        </TabPane>
                        <TabPane
                            tab={
                                <span>
                                    <Icon type="rise" theme="outlined" />
                                    Аналитика
                                </span>
                            }
                            key="3">
                            <Route
                                exact
                                path="/projects/common-analytics"
                                render={() => <ProjectAnalytics {...rest} currentStructureId={0} rootStructureId={0} />}
                            />
                        </TabPane>
                    </Tabs>
                </>
            </ul>
        );
    }
}

export default CommonPanel;
