import React, { useCallback } from 'react';
import { Empty, Icon, Tag, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
// import InsuranceAgentIcon from '../../../assets/insurance-agent.svg';
import moment from 'moment';

import '../ProjectNotes.scss';

const allowedFileFormats = {
    png: 'png',
    jpg: 'jpg',
    bmp: 'bmp',
    jpeg: 'jpeg',
};

const NotesList = props => {
    const { data, common, selectedNotes, handleToggleCheckNote } = props;

    const compareDate = deadline => {
        if (data && deadline !== null) {
            return moment(moment(new Date()).format('YYYY-MM-DD')).isAfter(deadline);
        }
    };

    const insuranceAgentIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 401.85 464" fill="currentColor" width="1em" height="1em">
            <path d="M114.35,0a63,63,0,1,0,0,126h.1A63,63,0,1,0,114.39,0Z" />
            <path d="M210.86,271.12l16.19-7.89a96,96,0,0,0-94.66-80.3H96.49c-52.94,0-96.49,43-96.49,96V383.85H209.4a71.34,71.34,0,0,1-2.48-18V277.45A7.08,7.08,0,0,1,210.86,271.12Z" />
            <path d="M220.91,281.82v84c0,20.2,13.65,45.89,30.35,57.28L311.31,464l60.07-40.91c16.7-11.39,30.47-37.08,30.47-57.28V282l-90.47-44.34Zm132.31,39.76a7,7,0,0,1,0,9.89L304,380.75a7.07,7.07,0,0,1-4.95,2.1h0a7,7,0,0,1-5-2.13l-24.91-25.16a7,7,0,0,1,9.95-9.88l19.95,20.15,44.24-44.24A7,7,0,0,1,353.22,321.58Z" />
        </svg>
    );

    const getPhotos = useCallback(item => {
        if (!item?.noteFiles?.length) {
            return [];
        }

        return item.noteFiles?.filter(item => {
            const splittedFilePath = item?.full_path?.split('.');
            const photoExt = (splittedFilePath?.[splittedFilePath?.length - 1] ?? '')?.trim().toLowerCase();

            if (allowedFileFormats?.[photoExt]) {
                return true;
            }

            return false;
        });
    }, []);

    return data ? (
        (data || []).map((item, key) => {
            const photos = getPhotos(item);

            return (
                <div key={key}>
                    <div className="flexRow mt16">
                        <Link
                            to={`/projects/${item.project_id}/comments/${item.structure_id}/${item.id}${common ? '?common=1' : ''}`}
                            className="note-list__link">
                            <div className={!item.deleted_at ? 'notesListView' : 'notesListView notesListView_deleted'}>
                                <div className="flexRow">
                                    <div className={item.retention_status_id === 0 ? 'retention' : item.state} />
                                    <div className="ml16">
                                        <h3>
                                            {' '}
                                            <b>{item.number}</b> {item.title}
                                        </h3>

                                        <div style={{ marginBottom: 6 }}>
                                            <span className="noteBreadcrumbs">{item.breadcrumbs ? item.breadcrumbs : ''}</span>
                                        </div>

                                        <div>
                                            <span className="userName">
                                                Исполнитель: {item.executor.fullname ? item.executor.fullname : 'Не назначен'}
                                            </span>{' '}
                                            &nbsp;
                                            <span className="userOrganization">
                                                {item.executor.organization ? item.executor.organization : 'Нет организации'}
                                            </span>
                                        </div>
                                        <div style={{ marginBottom: 6 }}>
                                            <span className="userName">Автор: {item.user.fullname}</span> &nbsp;
                                            <span className="userOrganization">
                                                {item.user.organization ? item.user.organization : 'Нет организации'}
                                            </span>
                                        </div>

                                        <Tag className={compareDate(item.date_deadline) ? 'note-list__date red' : 'note-list__date gray'}>
                                            {moment(item.date_created).format('l')}
                                            {item.date_deadline && <> &mdash; {moment(item.date_deadline).format('l')}</>}
                                        </Tag>

                                        {item.noteComments && item.noteComments.length > 0 && (
                                            <span className="note-item__icon" title="Есть комментарии">
                                                <Icon type="message" theme="outlined" />
                                            </span>
                                        )}

                                        {item.document_id && (
                                            <span className="note-item__icon" title="Отмечено на чертеже">
                                                <Icon type="pushpin" theme="outlined" />
                                            </span>
                                        )}

                                        {item.is_reopen === 1 && (
                                            <span className="note-item__icon" title="Повторно открытое">
                                                <Icon type="exclamation-circle" theme="outlined" style={{ color: '#ed2d23' }} />
                                            </span>
                                        )}

                                        {item.is_important === 1 && (
                                            <span className="note-item__icon" title="Важное">
                                                <Icon type="exclamation-circle" theme="outlined" style={{ color: '#f2c94c' }} />
                                            </span>
                                        )}
                                        {item.is_counterparty === 1 && (
                                            <span className="note-item__icon" title="Контрагент">
                                                <Icon component={insuranceAgentIcon} style={{ color: 'rgba(0, 0, 0, 0.85)' }} />
                                            </span>
                                        )}

                                        {item.noteFiles && item.noteFiles.length > 0 && (
                                            <span className="note-item__icon" title="Прикреплены файлы">
                                                <Icon type="paper-clip" theme="outlined" />
                                            </span>
                                        )}

                                        {!!photos.length && (
                                            <span className="note-item__icon" title="Прикреплены фотографии">
                                                <Icon type="picture" theme="outlined" />
                                                <sup className="note-item__counter">{photos.length}</sup>
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="flexRow alignLeft note-executor">
                                    <div className="flexColumn"></div>
                                    <div className="ml16">
                                        <Icon type="right" />
                                    </div>
                                </div>
                            </div>
                        </Link>
                        {selectedNotes && (
                            <Checkbox
                                onChange={() => handleToggleCheckNote(item)}
                                style={{ marginLeft: '25px' }}
                                checked={!!selectedNotes[item.id] && item.retention_status_id !== 0}
                                disabled={item.retention_status_id === 0}
                            />
                        )}
                    </div>
                    <div className="divider" />
                </div>
            );
        })
    ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Нет данных" />
    );
};

export default NotesList;
