import { GET_CURRENT_PAGE } from '../../actionTypes';

const initialState = {
    currentPage: {
        title: null,
    },
    prevPage: {
        title: null,
    },
};

export const getCurrentPage = (state = initialState, action) => {
    switch (action.type) {
        case GET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: {
                    title: action.currentPage,
                },
                prevPage: {
                    title: '',
                },
            };
        default:
            return state;
    }
};
