import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon, Popconfirm } from 'antd';

import { FavoriteButton } from '../FavoriteButton';
import { changeFavorite } from '../../redux/actions/projectModule';

class ProjectStructureList extends Component {
    changeFavorite = () => {};

    handlePopConfirm = (id, status) => {
        const { deleteStructureItem, restoreStructureItem, match } = this.props;
        const { projectId, currentStructureId } = match.params;

        switch (status) {
            case 'restore':
                restoreStructureItem(projectId, currentStructureId, id);
                break;
            case 'delete':
                deleteStructureItem(projectId, currentStructureId, id);
                break;
        }
    };

    handleFavorite = (section, itemId, isFavorite) => {
        this.props.changeFavorite(section, itemId, isFavorite, this.props.favoriteAction);
    };

    renderItems = data => {
        return data.map(item => (
            <div key={item.id} className="flexRow">
                <Link
                    style={{ width: '100%' }}
                    className={`${item.deleted_at === null ? 'structure__link flexRow' : 'structure__link flexRowOpacity'}`}
                    // to={`/projects/0/section/${item.id}`}
                    to={`/projects/${item.project_id}/${'section'}/${item.id}`}
                    onClick={this.handleSearchReset}>
                    <li className="structure__item">
                        <div className="structure__data">
                            <div className="structure__description">
                                <div className="structure__title">
                                    {item.title}
                                    {item.hasDocument && (
                                        <div>
                                            <span className="note-item__icon" title="Прикреплены документы">
                                                <Icon type="file" theme="outlined" />
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className="structure__dates">{item.date}</div>
                            </div>
                            <ul className="structure__labels">
                                <li className="structure__label danger">{item.notes_cnt_danger}</li>
                                <li className="structure__label warning">{item.notes_cnt_warning}</li>
                                <li className="structure__label success">{item.notes_cnt_success}</li>
                            </ul>
                            <div className="structure__arrow">
                                <Icon type="right" theme="outlined" style={{ color: '#666666' }} />
                            </div>
                        </div>
                    </li>
                </Link>
                <FavoriteButton
                    handleFavorite={() => this.handleFavorite('structure', item.id, item.is_favorite)}
                    isFavorite={item.is_favorite}
                />
                {this.props.showRemove && (
                    <Popconfirm
                        title={
                            item.deleted_at === null
                                ? 'Вы точно хотите удалить элемент структуры?'
                                : 'Вы точно хотите восстановить элемент структуры?'
                        }
                        onConfirm={() => this.handlePopConfirm(item.id, item.deleted_at !== null ? 'restore' : 'delete')}
                        onCancel={() => console.log('')}
                        okText="Да"
                        cancelText="Нет">
                        <div className="structure__remove">
                            {item.deleted_at === null ? (
                                <Icon type="delete" style={{ fontSize: 20, color: '#d32f2f' }} size={24} />
                            ) : (
                                <Icon type="redo" style={{ fontSize: 20, color: '#666666' }} size={24} />
                            )}
                        </div>
                    </Popconfirm>
                )}
            </div>
        ));
    };

    render() {
        const { data } = this.props;

        if (data) {
            return this.renderItems(data);
        }

        return null;
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeFavorite: function(section, itemId, isFavorite, callback) {
            return dispatch(changeFavorite(section, itemId, isFavorite, callback));
        },
    };
}

export default connect(null, mapDispatchToProps)(ProjectStructureList);
