import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateUserSuccess } from '../redux/actions/common';

function PrivateRouteWrapper(ComposedWrapper) {
    return class extends Component {
        checkAuth() {
            const { updateUserSuccess } = this.props;
            const accessToken = localStorage.getItem('accessToken');
            const currentUser = localStorage.getItem('currentUser');

            if (accessToken && currentUser) {
                updateUserSuccess(JSON.parse(currentUser));
                return true;
            } else {
                this.props.history.push('/signin');
            }
            return false;
        }

        render() {
            return <>{this.checkAuth() && <ComposedWrapper {...this.props} />}</>;
        }
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateUserSuccess: function(data) {
            return dispatch(updateUserSuccess(data));
        },
    };
}

const PrivateRoute = compose(connect(null, mapDispatchToProps), PrivateRouteWrapper);

export default PrivateRoute;
