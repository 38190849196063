import axios from 'axios';
import * as types from '../../actionTypes';
import { apiConfig } from '../../../services/api/config';
import { message } from 'antd';
import utils from '../../../helpers/utils';

let call;
let callGetFiles;

export const createNote =
    (projectId, structureId, data, callback = () => {}) =>
    dispatch => {
        let accessToken = localStorage.getItem('accessToken');

        return axios({
            headers: {
                'Access-Token': accessToken,
            },
            method: 'POST',
            url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/note`,
            data: {
                title: data.title,
                number: Number(data.number),
                date_deadline: data.date_deadline,
                audits: data.audits,
                executor_organization_id: data.executor_organization_id,
                executor_id: data.executor_id,
                contract_id: data.contract_id,
                ntd_id: data.ntd_id,
                description: data.description,
                categories: data.categories,
                state: data.state,
                files: data.files,
                document_id: data.document_id ? data.document_id : null,
                pX: data.pX ? data.pX : null,
                pY: data.pY ? data.pY : null,
                retention_unit_id: data.retention_unit_id,
                retention_count: data.retention_count,
                retention_status_id: data.retention_status_id,
            },
        })
            .then(response => {
                if (!response.data.success) {
                    utils.showError(response.data.errors[0]);
                }
                // console.log('resp', response);
                callback(response.data.data);
                return response;
            })
            .catch(errors => {
                console.log(errors);
                throw errors;
            });
    };

export const setCopiedNote = data => dispatch => {
    dispatch({
        type: types.SET_COPIED_NOTE,
        data: data,
    });
};

export const changeDocumentNotesList = data => dispatch => {
    dispatch({
        type: types.CHANGE_DOCUMENT_NOTES_LIST,
        data: data,
    });
};

export const getNotes =
    (projectId, structureId, params, data, callback = () => {}) =>
    dispatch => {
        // console.log('DATA !!!', data);
        let accessToken = localStorage.getItem('accessToken');
        if (!data) {
            dispatch({
                type: types.GET_NOTES_SUCCESS,
                data: null,
                success: false,
                errors: [],
                loading: false,
            });
        }

        dispatch({ type: types.GET_NOTES_LOADING });

        if (call) {
            call.cancel('Operation canceled by the user.');
        }
        call = axios.CancelToken.source();

        const url =
            !projectId && !structureId
                ? `${apiConfig.url}common/notes?${params}`
                : `${apiConfig.url}project/${projectId}/project-structure/${structureId}/note-pagination?${params}`;
        axios({
            headers: {
                'Access-Token': accessToken,
            },
            method: 'GET',
            url: url,
            cancelToken: call.token,
        })
            .then(response => {
                if (response.data.success) {
                    dispatch({
                        type: types.GET_NOTES_SUCCESS,
                        data: {
                            notes: data ? [...data.notes, ...response.data.data.notes] : [...response.data.data.notes],
                            pagination: response.data.data.pagination,
                            breadcrumbs: response.data.data.breadcrumbs ? response.data.data.breadcrumbs : data ? data.breadcrumbs : null,
                            noteCount: response.data.data.noteCount ? response.data.data.noteCount : data ? data.noteCount : null,
                        },
                        success: response.data.success,
                        errors: response.data.errors,
                    });

                    callback();
                } else {
                    utils.showError(response.data.errors[0]);

                    if (data) {
                        dispatch({
                            type: types.GET_NOTES_SUCCESS,
                            data: {
                                notes: null,
                                pagination: data.pagination,
                                breadcrumbs: data.breadcrumbs,
                                noteCount: data.noteCount,
                            },
                            success: response.data.success,
                            errors: response.data.errors,
                        });
                    }
                }
            })
            .catch(errors => {
                // console.log(errors);
                // console.log(errors.response);
            });
    };

export const setNotes = data => dispatch => {
    dispatch({
        type: types.SET_NOTES,
        data: data,
    });
};

export const getNotesFiles = (projectId, structureId, params, data) => dispatch => {
    // console.log('DATA !!!', data);
    let accessToken = localStorage.getItem('accessToken');
    if (!data) {
        dispatch({
            type: types.GET_NOTES_FILES_SUCCESS,
            data: null,
            success: false,
            errors: [],
            loading: false,
        });
    }

    dispatch({ type: types.GET_NOTES_FILES_LOADING });

    if (callGetFiles) {
        callGetFiles.cancel('Operation canceled by the user.');
    }
    callGetFiles = axios.CancelToken.source();

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/note-files?${params}`,
        cancelToken: callGetFiles.token,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_NOTES_FILES_SUCCESS,
                    data: {
                        files: data ? [...data.files, ...response.data.data.files] : [...response.data.data.files],
                        pagination: response.data.data.pagination,
                    },
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                dispatch({
                    type: types.GET_NOTES_FILES_SUCCESS,
                    data: {
                        files: null,
                        pagination: data.pagination,
                    },
                    success: response.data.success,
                    errors: response.data.errors,
                });
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            // console.log(errors);
            // console.log(errors.response);
        });
};

export const getNotesByDocument = (projectId, structureId, documentId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    // dispatch({ type: types.GET_DOCUMENT_NOTES_LOADING });

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/project-document/${documentId}/note`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_DOCUMENT_NOTES_SUCCESS,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getNoteById =
    (projectId, structureId, id, callback = () => {}) =>
    dispatch => {
        let accessToken = localStorage.getItem('accessToken');
        dispatch({ type: types.GET_NOTE_BY_ID_LOADING });
        axios({
            headers: {
                'Access-Token': accessToken,
            },
            method: 'GET',
            url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/note/${id}`,
        })
            .then(response => {
                if (response.data.success) {
                    dispatch({
                        type: types.GET_NOTE_BY_ID_SUCCESS,
                        data: response.data.data,
                        success: response.data.success,
                        errors: response.data.errors,
                    });
                    callback();
                } else {
                    utils.showError(response.data.errors[0]);
                }
            })
            .catch(errors => {
                console.log(errors.response);
            });
    };

export const getExecutors = (projectId, structureId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    dispatch({
        type: types.GET_EXECUTORS_LOADING,
    });
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/executor`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_EXECUTORS_SUCCESS,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getQueryFiltredExecutors = (projectId, structureId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    dispatch({
        type: types.GET_QUERY_FILTRED_EXECUTORS_LOADING,
    });
    const url = !structureId
        ? `${apiConfig.url}project/${projectId}/executor-list-for-filter`
        : `${apiConfig.url}project/${projectId}/executor-list-for-filter?structureId=${structureId}`;

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: url,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_QUERY_FILTRED_EXECUTORS_SUCCESS,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getFiltredExecutors = (projectId, structureId, counterpartyId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    dispatch({
        type: types.GET_FILTRED_EXECUTORS_LOADING,
    });
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/executor?counterpartyId=${counterpartyId}`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_FILTRED_EXECUTORS_SUCCESS,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getAuditors = (projectId, structureId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/auditor`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_AUDITORS,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getFiltredAuditors = (projectId, structureId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    const url = !structureId
        ? `${apiConfig.url}project/${projectId}/auditor-list-for-filter`
        : `${apiConfig.url}project/${projectId}/auditor-list-for-filter?structureId=${structureId}`;

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: url,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_FILTRED_AUDITORS,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const clearAuditors = () => dispatch => {
    dispatch({ type: types.CLEAR_AUDITORS });
};

export const getDictionary = id => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    const url = !id ? `${apiConfig.url}dictionary` : `${apiConfig.url}dictionary/${id}`;
    dispatch({
        type: types.GET_DICTIONARY_LOADING,
    });
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: url,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_DICTIONARY,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getFiltredDictionary = contractId => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    // dispatch({
    //     type: types.GET_DICTIONARY_LOADING,
    // });
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}dictionary?contractId=${contractId}`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_FILTRED_DICTIONARY,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getFilteredAuthors = (projectId, structureId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    dispatch({
        type: types.GET_AUTHORS_LOADING,
    });

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}project/${projectId}/author-list-for-filter${structureId ? `?structureId=${structureId}` : ''}`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_FILTERED_AUTHORS,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getAuthors = (projectId, organizationId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    dispatch({
        type: types.GET_AUTHORS_LOADING,
    });

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}project/${projectId}/author${organizationId ? `?organizationId=${organizationId}` : ''}`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_AUTHORS,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getCounterParties = projectId => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}project/${projectId}/counterparties`,
    })
        .then(response => {
            dispatch({
                type: types.GET_COUNTERPARTIES,
                data: response.data.data,
                success: response.data.success,
            });
        })
        .catch(errors => {
            console.log(errors);
        });
};

export const getInspectionNotes = (projectId, noteId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    dispatch({
        type: types.GET_INSPECTION_NOTES_LOADING,
    });

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}project/${projectId}/inspection/${noteId}`,
    })
        .then(response => {
            dispatch({
                type: types.GET_INSPECTION_NOTES,
                data: response.data.data,
                success: response.data.success,
                errors: response.data.errors,
            });
        })
        .catch(errors => {});
};

export const createInspectionNotes =
    (projectId, noteId, data, callback = () => {}) =>
    dispatch => {
        let accessToken = localStorage.getItem('accessToken');
        dispatch({
            type: types.CREATE_INSPECTION_NOTES_LOADING,
        });

        axios({
            headers: {
                'Access-Token': accessToken,
            },
            method: 'POST',
            url: `${apiConfig.url}project/${projectId}/inspection/${noteId}`,
            data: {
                noteIds: data.noteIds,
                status: data.status,
            },
        })
            .then(response => {
                dispatch({
                    type: types.CREATE_INSPECTION_NOTES,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });

                callback();
            })
            .catch(errors => {
                console.log(errors);
            });
    };

export const changeNoteStatus = (projectId, structureId, id, status) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    // dispatch({ type: types.CHANGE_NOTE_STATUS_LOADING });

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/note/${id}/change-status`,
        data: {
            state: status,
        },
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.CHANGE_NOTE_STATUS_SUCCESS,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const changeNote =
    (projectId, structureId, noteId, data, auditors = [], callback = () => {}) =>
    dispatch => {
        let accessToken = localStorage.getItem('accessToken');

        if (data.audits) {
            const auditorsArr = auditors.data || [];

            const newAuditors = data.audits
                .map(item => {
                    const id = item.auditor_id;
                    return auditorsArr.find(item => item.id === id) || null;
                })
                .filter(item => item !== null)
                .sort((a, b) => {
                    const aName = a.fullname || '';
                    const bName = b.fullname || '';

                    if (aName < bName) {
                        return 1;
                    }
                    if (aName > bName) {
                        return -1;
                    }
                    // a должно быть равным b
                    return 0;
                });

            dispatch({
                type: types.GET_NOTE_BY_AUDITORS_ARRAY,
                data: { noteAudits: newAuditors },
            });
        }

        dispatch({
            type: types.CHANGE_NOTE_LOADING,
        });

        axios({
            headers: {
                'Access-Token': accessToken,
            },
            method: 'POST',
            url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/note/${noteId}`,
            data: {
                title: data.title,
                number: Number(data.number),
                date_deadline: data.date_deadline,
                audits: data.audits,
                executor_id: data.executor_id,
                ntd_id: data.ntd_id,
                description: data.description,
                correctiveAction: data.correctiveAction,
                categories: data.categories,
                state: data.state,
                files: data.files,
                pX: data.pX,
                pY: data.pY,
                document_id: data.document_id,
                structure_id: data.structure_id,
                retention_unit_id: data.retention_unit_id,
                retention_count: data.retention_count,
                retention_status_id: data.retention_status_id,
                is_important: data.is_important,
                work_type_id: data.work_type_id,
                contract_id: data.contract_id,
                executor_organization_id: data.executor_organization_id,
                accept_status: data.accept_status,
                accept_message: data.accept_message,
                inspection_index: data.inspection_index,
                inspection_number: data.inspection_number,
                inspection_location: data.inspection_location,
            },
        })
            .then(response => {
                if (response.data.success) {
                    dispatch({
                        type: types.GET_NOTE_BY_ID_SUCCESS,
                        data: response.data.data,
                        success: response.data.success,
                        errors: response.data.errors,
                    });
                    dispatch(getNoteHistory(projectId, noteId));
                    dispatch({
                        type: types.CHANGE_NOTE_SUCCESS,
                    });
                    callback();
                } else {
                    utils.showError(response.data.errors[0]);
                }
            })
            .catch(errors => {
                console.log(errors);
            });
    };

export const setNote = data => dispatch => {
    dispatch({
        type: types.SET_NOTE,
        data: data,
    });
};

export const uploadNoteFile =
    (projectId, structureId, noteId, uploadFile, callback = () => {}) =>
    dispatch => {
        let accessToken = localStorage.getItem('accessToken');
        axios({
            headers: {
                'Access-Token': accessToken,
                'content-type': 'multipart/form-data',
            },
            method: 'POST',
            url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/note/${noteId}/upload-file`,
            data: uploadFile,
        })
            .then(response => {
                if (response.data.success) {
                    callback(response.data.data);
                    dispatch({
                        type: types.UPLOAD_NOTE_FILE,
                        data: response.data.data,
                        success: response.data.success,
                        errors: response.data.errors,
                    });
                } else {
                    utils.showError(response.data.errors[0]);
                }
            })
            .catch(errors => {
                console.log(errors.response);
            });
    };

export const deleteNoteFile = (projectId, structureId, noteId, fileId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'DELETE',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/note/${noteId}/file/${fileId}`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.DELETE_NOTE_FILE,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
                dispatch(changeNote(projectId, structureId, noteId, {}));
                // dispatch(getNoteById(projectId, structureId, noteId));
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getRetentionUnits = () => dispatch => {
    let accessToken = localStorage.getItem('accessToken');

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}retention-unit/index`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_RETENTION_UNITS,
                    data: response.data.data.units,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const getNoteHistory = (projectId, noteId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}project/${projectId}/note/${noteId}/history`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_NOTE_HISTORY,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};
export const archiveNote = (projectId, structureId, noteId, goBackAction) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'DELETE',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/note/${noteId}`,
    })
        .then(response => {
            if (response.data.success) {
                message.info(response.data.data[0]);
                dispatch({ type: types.SUCCESS_NOTE_ARCHIVE });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};
export const resetArchiveStatus = () => dispatch => {
    dispatch({ type: types.RESET_ARCHIVE_STATUS });
};

export const restoreNote = (projectId, structureId, noteId) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/note/${noteId}/restore`,
    })
        .then(response => {
            if (response.data.success) {
                // message.info(response.data.data[0]);
                dispatch(getNoteById(projectId, structureId, noteId));
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const setNotesFilters = data => dispatch => {
    dispatch({ type: types.SET_NOTES_FILTERS, data: data });
};

export const createNoteComment = (projectId, structureId, id, comment) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}project/${projectId}/project-structure/${structureId}/note/${id}/create-comment`,
        data: {
            content: comment,
        },
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.CREATE_NOTE_COMMENT,
                    data: response.data.data.reverse(),
                    success: response.data.success,
                    errors: response.data.errors,
                });
                dispatch(getNoteHistory(projectId, id));
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors);
        });
};

export const getNoteWorkTypes = dictionaryId => dispatch => {
    let accessToken = localStorage.getItem('accessToken');

    dispatch({
        type: types.GET_NOTE_WORK_TYPES_LOADING,
    });

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: `${apiConfig.url}dictionary/work-type/${dictionaryId}`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_NOTE_WORK_TYPES,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.response);
        });
};

export const resetNoteWorkTypes = () => dispatch => {
    dispatch({ type: types.GET_NOTE_WORK_TYPES_RESET });
};

export const getContracts = (projectId, structureId, counterpartyId, dictionaryId) => dispatch => {
    const accessToken = localStorage.getItem('accessToken');
    dispatch({
        type: types.GET_CONTRACTS_LOADING,
    });
    let requestUrl = `${apiConfig.url}project/${projectId}/project-structure/${structureId}/contract`;

    if (counterpartyId) {
        requestUrl += `?counterpartyId=${counterpartyId}`;
    }

    if (dictionaryId) {
        requestUrl += `&dictionaryId=${dictionaryId}`;
    }

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'GET',
        url: requestUrl,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.GET_CONTRACTS_SUCCESS,
                    data: response.data.data,
                    success: response.data.success,
                    errors: response.data.errors,
                });
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors.reponse);
        });
};

export const notesStatusChange = noteIds => dispatch => {
    let accessToken = localStorage.getItem('accessToken');

    dispatch({ type: types.NOTES_STATUS_CHANGE_LOADING });

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}note/notes-status-change`,
        data: { noteIds },
    })
        .then(response => {
            console.log('respone', response.data);
            response.data.errors === null
                ? response.data.data?.message && message.info(response.data.data?.message)
                : message.info(response.data.errors);
            dispatch({ type: types.NOTES_STATUS_CHANGE_LOADING });
        })
        .catch(errors => {
            console.log(errors);
            dispatch({ type: types.NOTES_STATUS_CHANGE_LOADING });
        });
};
