import { message } from 'antd';

const utils = {
    isObjEmpty: object => {
        for (let prop in object) {
            if (object.hasOwnProperty(prop)) return false;
        }
        return true;
    },

    notesHelper: noteStatus => {
        let noteData = {};
        switch (noteStatus) {
            case 'open':
                return (noteData = {
                    color: 'red',
                    title: 'Открыто',
                });
            case 'inwork':
                return (noteData = {
                    color: 'yellow',
                    title: 'Выполнено',
                });

            case 'completed':
                return (noteData = {
                    color: 'green',
                    title: 'Проверено',
                });
            default:
                break;
        }
    },

    showError: msg => {
        message.error(msg);
    },

    hasImagesInFileList: list => {
        return list.find(item => {
            const name = item.full_path?.toLowerCase();

            if (!name) {
                return false;
            }

            return name.endsWith('.jpg') || name.endsWith('.jpeg') || name.endsWith('.png') || name.endsWith('.bmp');
        });
    },
};

export default utils;
