import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const CustomSelect = props => {
    const { onChange, data, isMultiple, isDictionary, defaultValue, onBlur, placeholder, disabled } = props;

    return (
        <Select
            mode={isMultiple ? 'multiple' : 'default'}
            style={{ width: '100%' }}
            onChange={onChange}
            defaultValue={defaultValue}
            onBlur={onBlur}
            placeholder={placeholder}
            showArrow
            disabled={disabled ? disabled : false}>
            {data.length > 0
                ? data.map((item, key) => (
                      <Option key={key} value={item.id} disabled={item.disabled || false}>
                          {item.color && <span className="status-tag" style={{ backgroundColor: item.color }} />}
                          {isDictionary ? item.title : item.fullname}
                      </Option>
                  ))
                : null}
        </Select>
    );
};

export default CustomSelect;
