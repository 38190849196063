import axios from 'axios';
import { api } from '../../../services/api';
import * as types from '../../actionTypes';
import { apiConfig } from '../../../services/api/config';
import { message } from 'antd';
import utils from '../../../helpers/utils';

export const createProject = data => async dispatch => {
    let accessToken = localStorage.getItem('accessToken');

    const res = await axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}project`,
        data: data,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.CREATE_PROJECT,
                    success: response.data.success,
                });

                dispatch({
                    type: types.CREATE_PROJECT,
                    isCreated: response.data.success,
                });

                dispatch(getProjects());
            } else {
                utils.showError(response.data.errors[0]);
            }

            return response;
        })
        .catch(errors => {
            console.log(errors);
            return null;
        });

    return res;
};

export const resetCreatedStatus = () => dispatch => {
    dispatch({
        type: types.RESET_PROJECT_CREATE_STATUS,
        isCreated: false,
    });
};

export const deleteProject = itemId => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'DELETE',
        url: `${apiConfig.url}project/${itemId}`,
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: types.CHANGE_NOTE_STATUS_SUCCESS,
                    isDeleted: response.data.success,
                });

                dispatch(getProjects());
            } else {
                utils.showError(response.data.errors[0]);
            }
        })
        .catch(errors => {
            console.log(errors);
        });
};

export const resetDeleteStatus = () => dispatch => {
    dispatch({
        type: types.RESET_DELETE_STATUS,
        isDeleted: false,
    });
};

export const getProjects = () => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    dispatch({ type: types.GET_PROJECTS_LOADING });

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: api.projectsModule.getProjects.method,
        url: api.projectsModule.getProjects.url,
    })
        .then(response => {
            if (!response.data.success) {
                utils.showError(response.data.errors[0]);
            }
            dispatch({
                type: types.GET_PROJECTS_SUCCESS,
                data: response.data.data,
                success: response.data.success,
                errors: response.data.errors,
            });
        })
        .catch(errors => {
            dispatch({ type: types.GET_PROJECTS_LOADING, errors: errors });
        });
};

export const getProjectUserInfo = projectId => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    dispatch({ type: types.GET_PROJECTS_USER_INFO_LOADING });

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: api.projectsModule.getProjects.method,
        url: `${apiConfig.url}project/${projectId}/user/profile`,
    })
        .then(response => {
            if (!response.data.success) {
                utils.showError(response.data.errors[0]);
            }
            dispatch({
                type: types.GET_PROJECTS_USER_INFO_SUCCESS,
                data: response.data.data,
                success: response.data.success,
                errors: response.data.errors,
            });
        })
        .catch(errors => {
            dispatch({ type: types.GET_PROJECTS_USER_INFO_LOADING, errors: errors });
        });
};

export const setProjects = data => dispatch => {
    dispatch({
        type: types.SET_PROJECTS,
        data: data,
    });
};

export const getCurrentProject = projectId => dispatch => {
    let accessToken = localStorage.getItem('accessToken');
    dispatch({ type: types.GET_CURRENT_PROJECT_LOADING });

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: api.projectsModule.getProjects.method,
        url: `${apiConfig.url}project/` + projectId,
    })
        .then(response => {
            dispatch({
                type: types.GET_CURRENT_PROJECT_SUCCESS,
                data: response.data.data,
                success: response.data.success,
                errors: response.data.errors,
            });
        })
        .catch(errors => {
            console.log(errors);
        });
};

export const updateProject = (projectId, data) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}project/` + projectId,
        data: data,
    })
        .then(response => {
            console.log('respone', response);
            response.data.errors === null ? message.info('Успешно сохранено') : message.info('Ошибка сохранения');
        })
        .catch(errors => {
            console.log(errors);
        });
};

export const createPrescriptionForCounterparty = (projectId, data, callback) => dispatch => {
    let accessToken = localStorage.getItem('accessToken');

    dispatch({ type: types.CREATE_PRESCRIPTION_FOR_COUNTERPARTY_LOADING });

    axios({
        headers: {
            'Access-Token': accessToken,
        },
        method: 'POST',
        url: `${apiConfig.url}project/${projectId}/prescription/for-counterparty`,
        data: data,
    })
        .then(response => {
            dispatch({ type: types.CREATE_PRESCRIPTION_FOR_COUNTERPARTY_SUCCESS });
            console.log('respone', response.data);
            response.data.errors === null ? message.info(response.data.data) : message.info(response.data.errors);

            if (callback) {
                callback();
            }
        })
        .catch(errors => {
            console.log(errors);
        });
};
